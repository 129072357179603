import { useAuth0 } from '@auth0/auth0-react'
import { useAnalytics } from '@healthblocks-io/core/analytics'
import { useAuth } from '@healthblocks-io/core/auth'
import { usePid } from '@healthblocks-io/core/project'
import { HBAuth0Provider } from 'lib/auth0'
import React, { useEffect } from 'react'

/** This component makes sure that we are also signed out of Auth0 */
export default function SignOutAuth0() {
  return (
    <HBAuth0Provider>
      <ActualSignOutAuth0 />
    </HBAuth0Provider>
  )
}

function ActualSignOutAuth0() {
  const pid = usePid()
  const { isLoading, isAuthenticated, logout } = useAuth0()
  const { signOut } = useAuth()
  const { track } = useAnalytics()
  useEffect(() => {
    if (isLoading) return
    ;(async () => {
      track('User Signed Out')

      // 1. Sign out of Auth0
      if (isAuthenticated) {
        console.log('logout return to', window.location.origin + '/' + pid)
        await logout({ returnTo: window.location.origin + '/' + pid })
      }

      // 2. Sign out of Healthblocks
      signOut()
    })()
  }, [isLoading])

  return null
}
