import React, { ReactNode } from 'react'

export default function Wrapper({ children }: { children: ReactNode }) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100%',
        padding: '2em',
      }}
    >
      <div>{children}</div>
    </div>
  )
}
