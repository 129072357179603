import React, { useEffect, useState } from 'react'

import { useAnalytics } from '@healthblocks-io/core/analytics'
import { useAuth, useUser } from '@healthblocks-io/core/auth'

import Wrapper from '@components/Wrapper'
import Button from 'pages/atoms/Button'
import SignOutAuth0 from '@components/SignOutAuth0'

export default function EmailVerification() {
  const user = useUser()
  const { refresh } = useAuth()
  useEffect(() => {
    window.addEventListener('focus', refresh)
    return () => {
      window.removeEventListener('focus', refresh)
    }
  }, [refresh])

  const [signingOut, setSigningOut] = useState(false)
  if (signingOut) return <SignOutAuth0 />

  return (
    <Wrapper>
      <h1>Verify your email address</h1>
      verify your email address to continue
      <p>{user.email}</p>
      <Button onClick={refresh} inline>
        Reload
      </Button>
      <Button onClick={() => setSigningOut(true)} inline className="ml-3">
        Logout
      </Button>
    </Wrapper>
  )
}
