import format from 'date-fns/format'

export function humanDate(value: Date) {
  if (value.getFullYear() === new Date().getFullYear()) {
    return format(value, 'MMMM do')
  }
  return format(value, 'PPP')
}

export function humanDatetime(value: Date | string) {
  if (typeof value === 'string') {
    value = new Date(value)
  }
  if (!value) {
    return ''
  }
  if (value.getFullYear() === new Date().getFullYear()) {
    return format(value, 'MMMM do, HH:mm')
  }
  return format(value, 'PPP, HH:mm')
}
