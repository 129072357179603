import { str62 } from '@bothrs/util/random'
import storage from './storage'

export let deviceId = getDeviceId()

export const deviceIdPromise = Promise.resolve(deviceId)

export function useDeviceId() {
  return deviceId
}

export function getDeviceId(): string {
  // Web support only
  let id = storage.getItem('deviceId')
  if (!id) {
    id = str62(10)
    storage.setItem('deviceId', id)
  }
  return id
}
