import React, { useEffect, useMemo, useState } from 'react'

import Modal from '@duik/modal'

import { Questionnaire } from '@healthblocks-io/core/types'
import { useApolloClient } from '@healthblocks-io/apollo/'
import { useProject } from '@healthblocks-io/core/project'

import { addQuestionnaire } from '../../pages/Questionnaires'
import ActivityIcon from '../../pages/atoms/ActivityIcon'
import Button from '../../pages/atoms/Button'
import useProjectEditor, { AdminProject } from 'lib/useProjectEditor'

export default function ImportConfigurationModal({
  close,
  isOpen,
}: ImportConfigurationModalProps) {
  const [step, setStep] = useState(0)
  const [importing, setImporting] = useState<AdminProject>(null)
  const client = useApolloClient()
  const { updateProject, updateConfig, save } = useProjectEditor()

  const project = useProject()
  const [message, setMessage] = useState('')

  const log = (text: string) => setMessage(m => m + '\n' + text)

  useEffect(() => {
    if (step === 3) {
      saveConfig()
    }
  }, [step === 3])

  const apply = () => {
    const {
      auth0,
      blocks,
      config,
      dashboard,
      secret_airtable,
      secret_auth0,
      secret_google,
      secret_twilio,
      shopify,
    } = importing
    updateConfig(config)
    updateProject({
      auth0,
      blocks,
      dashboard,
      secret_airtable,
      secret_auth0,
      secret_google,
      secret_twilio,
      shopify,
    })
    setStep(3)
  }

  const saveConfig = async () => {
    try {
      log('Creating questionnaires')
      await importQuestionnaires((importing as any).questionnaires)
      log('Updating project settings')
      await save()
      log('Saved')
      setStep(4)
    } catch (error) {
      console.log(error)
    }
  }

  const importQuestionnaires = async (
    importedQuestionnaires: Questionnaire[]
  ) => {
    try {
      for (const questionnaire of importedQuestionnaires) {
        const { name, title, date, description, status, questions } =
          questionnaire
        log('Create ' + name + ': ' + title)
        const ok = await addQuestionnaire(client, {
          name,
          title,
          date,
          description,
          status,
          questions: {
            data: questions.map(q => ({ ...q, id: undefined })),
          },
        })
        const { id } = ok.data?.insert_questionnaires_one
        if (!id) {
          log('Failed to import ' + name)
        }
      }
    } catch (e) {
      console.log('import err', e)
      log('Failed to import questionnaires: ' + e.message)
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      handleClose={close}
      closeOnOuterClick
      style={{ height: 'auto' }}
    >
      <Modal.Body
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: 513,
        }}
      >
        <Modal.Title className="font-weight-bold mb-5">
          Import project configuration
        </Modal.Title>
        <pre>{message}</pre>
        {!step && (
          <label className="inp inp--upload" style={{ cursor: 'pointer' }}>
            <ActivityIcon icon="upload" color="#8F92A1" size={18} />
            <span className="ml-2 mr-2">
              {'Upload project configuration file'}
            </span>
            <input
              onChange={async evt => {
                var files = evt.target.files
                const config = await readJsonFileAsText(files?.[0])
                const parsedConfig = JSON.parse(config)
                setImporting(parsedConfig)
                setStep(2)
              }}
              type="file"
              name="attachment"
              id="attachment"
              accept="application/json"
            />
          </label>
        )}
        {step === 2 && (
          <>
            {'Import project settings from ' +
              importing.name +
              ' to ' +
              project.name +
              '?'}
            <Button
              secondary
              inline
              style={{ marginTop: 'auto' }}
              onClick={apply}
            >
              Import
            </Button>
          </>
        )}
        {step === 4 && (
          <Button
            secondary
            inline
            style={{ marginTop: 'auto' }}
            onClick={() => {
              setStep(0)
              close()
            }}
          >
            Close
          </Button>
        )}
      </Modal.Body>
    </Modal>
  )
}

interface ImportConfigurationModalProps {
  isOpen: boolean
  close?: () => void
  open?: () => void
}

export async function readJsonFileAsText(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onerror = reject
    reader.onload = function (e) {
      if (!e.target?.result) {
        const error = new Error('Could not read file')
        return reject(error)
      }
      resolve(e.target.result as string)
    }
    reader.readAsText(file)
  })
}
