import React, { useMemo } from 'react'
import { Link, useParams } from 'react-router-dom'

import { useContent, useQuestionnaires } from '@healthblocks-io/core/content'
import { useConfig, usePid } from '@healthblocks-io/core/project'
import { PlanDefinition } from '@healthblocks-io/core/types'

import HealthblocksTopBar from '../modules/HealthblocksTopBar'
import Card from 'pages/atoms/Card'
import { useTranslation } from 'lib/i18n'
import { humanDatetime } from 'lib/date-fns'
import Badge from '@components/Badge'

export default function PlanDefinitions() {
  const plans = useConfig(p => p.config.PlanDefinition || [])
  const { name } = useParams<{ name: string }>()
  const detail = plans.find(p => p.name === name)

  return (
    <>
      <HealthblocksTopBar>System</HealthblocksTopBar>
      <div className="scroll-area-main">
        {detail ? (
          <PlanDetail plan={detail} />
        ) : (
          <>
            <h2>Plans</h2>
            {plans.length ? (
              plans.map((plan, key) => <PlanCard key={key} plan={plan} />)
            ) : (
              <p>No care plan templates</p>
            )}
          </>
        )}
      </div>
    </>
  )
}

function PlanCard({ plan }: { plan: PlanDefinition }) {
  const pid = usePid()
  return (
    <Link to={'/' + pid + '/plandefinitions/' + plan.name}>
      <Card className="mb-3">
        <h2>{plan.title || 'Plan'}</h2>
        {plan.name && <p className="m-0">{plan.name || 'NO ID!'}</p>}
        <p className="flex-grow-1 mt-1" style={{ color: 'var(--text-main)' }}>
          {plan.action?.length || 0} actions
        </p>
      </Card>
    </Link>
  )
}

function PlanDetail({ plan }: { plan: PlanDefinition }) {
  const pid = usePid()
  const { t } = useTranslation()
  const actions = useMemo(
    () => (plan.action || []).slice().sort((a, b) => a.offset - b.offset),
    [plan]
  )
  const questionnaires = useQuestionnaires()
  const suggestions = useContent('Suggestions')
  return (
    <div>
      <Link
        to={'/' + pid + '/plandefinitions'}
        style={{ fontSize: 13, color: '#818182' }}
      >
        {t('Plans')}
      </Link>
      <h2 className="mt-0">{plan.title}</h2>

      <table className="tbl tbl--subtle tbl--compact table-align-middle">
        <tbody>
          {actions.map((a, key) => (
            <tr key={key}>
              <td>
                <Badge primary sm>
                  {a.kind || a.definitionUri?.split('/')[0]}
                </Badge>
              </td>
              <td>
                <p className="m-0">
                  {a.title ||
                    a.definitionUri?.split('/')[1] ||
                    a.kind ||
                    a.definitionUri?.split('/')[0]}
                </p>
              </td>
              <td className="text-right">
                {a.planned_at ? (
                  humanDatetime(a.planned_at)
                ) : (
                  <>
                    {a.offset >= 0 ? '+' : ''} {a.offset} {a.offsetUnit}
                  </>
                )}
              </td>
              <td>
                {a.definitionUri?.startsWith('Questionnaire/')
                  ? questionnaires.some(
                      q => q.name === a.definitionUri?.split('/')[1]
                    )
                    ? 'ok'
                    : a.definitionUri?.split('/')[1] + '???'
                  : a.definitionUri?.startsWith('Article/')
                  ? suggestions.some(
                      q => q.name === a.definitionUri?.split('/')[1]
                    )
                    ? 'ok'
                    : a.definitionUri?.split('/')[1] + '???'
                  : null}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
