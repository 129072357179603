import { useCallback, useEffect, useMemo, useState } from 'react'
import useSWR from 'swr'

import { useUser } from './auth'
import { useFetch } from './fetch'
import { deepMerge, deepSet } from './set'
import { Profile, UserDoc } from './types'
import useMounted from './useMounted'

export function useUserEditor(options: { uid?: string; lazy?: boolean }) {
  const mounted = useMounted()
  const me = useUser()
  const uid = options.uid || me!.sub

  // Online state
  const { getJSON, patchJSON } = useFetch()
  const { data, error, mutate } = useSWR<Profile>('/api/users/' + uid, getJSON)

  // Local state
  const [state, setState] = useState(createEditor)
  const patch = useCallback(
    async (key: string, data: any) =>
      setState(s => {
        if (!key.startsWith('user.')) throw new Error('Unexpected update')
        return key.startsWith('user.doc.')
          ? deepSet(s, key.replace('user.', ''), data)
          : deepSet(s, key, data)
      }),
    []
  )
  const patchUser = useCallback(
    (data: Partial<Profile>) => setState(s => deepMerge(s, 'user', data)),
    []
  )
  const patchUserDoc = useCallback(
    (data: Partial<UserDoc>) => setState(s => deepMerge(s, 'doc', data)),
    []
  )

  // Merged
  const user = useMemo(
    () =>
      data && { ...data, ...state.user, doc: { ...data.doc, ...state.doc } },
    [state.user, state.doc, data]
  )
  const save = useCallback(async () => {
    if (Object.keys(state.user).length || Object.keys(state.doc).length) {
      mutate(user, false)
      await patchJSON('/api/users/' + uid, { user: state.user, doc: state.doc })
      mutate()
      mounted.current && setState(createEditor)
    }
  }, [user])

  // Debounced save if not lazy
  useEffect(() => {
    if (!options.lazy) {
      const t = setTimeout(save, 1000)
      return () => {
        clearTimeout(t)
      }
    }
  }, [save])

  return {
    user,
    error,
    save,
    refetch: mutate,

    patch,
    patchUser,
    patchUserDoc,
  }
}

function createEditor() {
  return {
    user: {},
    doc: {},
    saving: 0,
    version: 0,
    ref: { latest: 0 },
  }
}
