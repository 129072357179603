import addDays from 'date-fns/addDays'
import addYears from 'date-fns/addYears'

export const numberOptions = [
  { label: 'equals', value: 'eq' },
  { label: 'greater than', value: 'gt' },
  { label: 'lower than', value: 'lt' },
]
export const dateOptions = [
  { label: 'All time', value: 0, unit: '' },
  { label: 'Past week', value: 7, unit: 'd' },
  { label: 'Past 2 weeks', value: 14, unit: 'd' },
  { label: 'Past 30 days', value: 30, unit: 'd' },
  { label: 'Past year', value: 1, unit: 'a' },
]

function addTime(date: Date, value: number, unit: string) {
  if (unit.startsWith('d')) {
    return addDays(date, value)
  }
  return addYears(date, value)
}

export const filterTypes = {
  period: (rows, id, value) => {
    if (!value || !value.value) return rows
    const since = addTime(new Date(), -value.value, value.unit).valueOf()
    return rows.filter(row => row.values[id] > since)
  },
  fuzzyText: (rows, id, filterValue) => {
    if (!filterValue || !filterValue.trim()) return rows
    return rows.filter(row => {
      const rowValue = row.values[id]
      return rowValue !== undefined
        ? String(rowValue)
            .toLowerCase()
            .includes(String(filterValue).trimLeft().toLowerCase())
        : false
    })
  },
  number: (rows, id, filterValue) => {
    if (!filterValue) return rows
    return rows.filter(row => {
      const value = parseFloat(filterValue.value)
      const type = filterValue.type
      const rowValue = parseFloat(row.values[id])
      return type === 'gt'
        ? rowValue > value
        : type === 'lt'
        ? rowValue < value
        : rowValue === value
    })
  },
}
