import React, { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import useSWR from 'swr'

import { useAnalytics } from '@healthblocks-io/core/analytics'
import { useAuth, useUser } from '@healthblocks-io/core/auth'
import { useFetch } from '@healthblocks-io/core/fetch'
import { usePid } from '@healthblocks-io/core/project'

import { unsafeDecode } from 'lib/jwt'

import Button from './atoms/Button'
import HealthblocksTopBar from './modules/HealthblocksTopBar'
import SignOutAuth0 from '@components/SignOutAuth0'

export default function NoAccess({ allowedRoles }) {
  const auth = useAuth()
  const user = useUser()
  const pid = usePid()
  const history = useHistory()
  const { getJSON, postJSON } = useFetch()
  const { data } = useSWR('/api/invites', getJSON)
  const currentRoles = useMemo(
    () =>
      user?.['https://hasura.io/jwt/claims']?.['x-hasura-allowed-roles'] || [
        'user',
      ],
    [user]
  )
  const invite = Array.isArray(data) && data.find(inv => inv.pid === pid)

  const alreadyHasRole = currentRoles.includes(invite?.role)
  const inviteIsApplicable = allowedRoles.includes(invite?.role)

  useEffect(() => {
    if (
      data &&
      !invite &&
      currentRoles.length === 1 &&
      currentRoles[0] === 'user'
    ) {
      history.push('/' + pid + '/users')
    }
    // eslint-disable-next-line
  }, [data, invite, currentRoles])

  const [signingOut, setSigningOut] = useState(false)
  if (signingOut) return <SignOutAuth0 />

  return (
    <div>
      <HealthblocksTopBar>
        {!data
          ? null
          : inviteIsApplicable && !alreadyHasRole
          ? 'Invited'
          : 'Access denied'}
      </HealthblocksTopBar>
      <div className="scroll-area-main">
        {!data ? null : invite ? (
          <div>
            <p>
              You have been invited for this project as <b>{invite.role}</b>
            </p>
            <p>Your current role: {currentRoles.join(', ')}</p>

            <Button
              onClick={async () => {
                const ok = await postJSON('/api/invites/accept', { invite })
                console.log('ok', ok)
                await auth.refresh()
              }}
              inline
            >
              Accept invite
            </Button>
          </div>
        ) : (
          <div>
            <p className="mb-5">
              You don't have access to this page.
              <br />
              Your role: {currentRoles.join(', ')}
              <br />
              {allowedRoles && (
                <span>
                  Required role: {allowedRoles.join(', ')} <br />
                </span>
              )}
              {new Date((user.iat + 24 * 3600) * 1000).toString()}
            </p>
            <Button onClick={auth.refresh} primary inline>
              Check my role again
            </Button>
            <Button onClick={() => setSigningOut(true)} secondary inline>
              Log out
            </Button>
            <pre style={{ fontSize: 12 }}>{JSON.stringify(user, null, 2)}</pre>
            <pre style={{ fontSize: 12 }}>
              {JSON.stringify(unsafeDecode(auth.data.refresh_token), null, 2)}
            </pre>
          </div>
        )}
      </div>
    </div>
  )
}
