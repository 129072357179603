import React from 'react'

export let theme = window.localStorage.theme

export function BtnSwitchTheme() {
  return (
    <button onClick={() => toggleDarkMode()} type="button">
      Theme
    </button>
  )
}

loadDarkMode()

export function loadDarkMode() {
  if (theme === 'dark') {
    toggleDarkMode()
  }
}

export function toggleDarkMode(toggleStorage = true) {
  const id = 'dark-theme-css'
  const elem = document.getElementById(id)
  if (toggleStorage === true) {
    window.localStorage.theme = theme = elem ? null : 'dark'
  }
  if (elem) {
    return elem.remove()
  }
  const style = document.createElement('style')
  style.id = id
  style.type = 'text/css'
  style.appendChild(
    document.createTextNode(`body {
    --bg-main: #252529;
    --bg-base: #2c2c31;
    --bg-card: #2c2c31;
    --bg-nav: #252529;
    --border-color-base: #34343a;
    --border-color-main: #44444e;
    --text-base: #ccc;
    --text-main: #ddd;
    --text-nav: #fff;
    --input-bg:#000;

    /*Meh*/
    --dark-text:var(--hb-white);


  --bg-depth: #111;

  --input-bg: var(--hb-dark);
  --input-color: var(--hb-white);
  --border-soft: #ffffff11;
  --text-color: #ddd;
  }`)
  )
  document.head.appendChild(style)
}

export default BtnSwitchTheme
