import React, { useState } from 'react'

import { gql, useQuery } from '@healthblocks-io/apollo'
import { useFetch } from '@healthblocks-io/core/fetch'
import type { Activity } from '@healthblocks-io/core/types'

import useAsync from 'lib/useAsync'
import Button from 'pages/atoms/Button'

export default function MigrateGoals1({ uid }: { uid: string }) {
  const { postJSON } = useFetch()
  const { data } = useQuery(UserGoalsQuery, { variables: { uid } })
  const plan = useAsync<{ activities: Activity[] }>(
    // () => postJSON('http://localhost:8080/api/custom/biosil', { uid }, { auth })
    () => postJSON('/api/custom/biosil', { uid }),
    [postJSON]
  )

  const [loading, setLoading] = useState(false)

  if (!data) {
    return <div>No data</div>
  }

  const scheduled =
    data.users_by_pk.activities?.filter(a => a.status === 'scheduled') || []
  // const other =
  //   data.users_by_pk.activities?.filter(a => a.status !== 'scheduled') || []

  const migrationScheduled =
    plan?.activities?.filter(a => a.status === 'scheduled') || []
  // const migrationOther =
  //   plan?.activities?.filter(a => a.status !== 'scheduled') || []

  return (
    <div>
      <div className="row">
        <div className="col col-6">
          <h2>Actual</h2>
          <p>The user is receiving reminders for these activities:</p>
          <ActivityTable activities={scheduled} />
        </div>
        <div className="col col-6">
          <h2>Migration plan</h2>
          <p>The user will receive reminders for these activities:</p>
          <ActivityTable activities={migrationScheduled} />
          <Button
            disabled={loading}
            onClick={async () => {
              setLoading(true)
              try {
                await postJSON('/api/custom/biosil', { uid, migrate: 1 })
                window.location.reload()
              } catch (e) {
                alert('error')
              }
            }}
          >
            Add activities
          </Button>
        </div>
      </div>
    </div>
  )
}

function ActivityTable({ activities }) {
  if (!activities?.length) {
    return <div>No activities</div>
  }
  const now = new Date().toJSON()
  return (
    <div style={{ marginLeft: -16 }}>
      <table className="tbl tbl--wide">
        <thead>
          <tr>
            <th>Planned</th>
            <th>Completed</th>
            <th>Reminder</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {activities.map((a, key) => (
            <tr key={key}>
              <td>{a.planned_at?.slice(0, 10)}</td>
              <td>{a.completed_at?.slice(0, 10)}</td>
              <td style={{ fontWeight: a.reminder_at < now ? 'bold' : null }}>
                {a.reminder_at?.slice(0, 16).replace('T', ' ') || 'no reminder'}
              </td>
              <td>{a.doc.definitionUri?.replace('Questionnaire/', '')}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

const UserGoalsQuery = gql`
  query UserGoalsQuery($uid: String!) {
    users_by_pk(uid: $uid) {
      uid
      doc
      activities {
        completed_at
        created_at
        doc
        expired_at
        id
        kind
        planned_at
        reminder_at
        started_at
        status
      }
    }
  }
`
