import React from 'react'

import { useFetch } from '@healthblocks-io/core/fetch'
import { usePid } from '@healthblocks-io/core/project'

import useBlocks from '../../lib/useBlocks'
import Button from '../atoms/Button'
import useSWR from 'swr'

export function Conversations({ uid }: { uid: string }) {
  const pid = usePid()
  const blocks = useBlocks()
  const { getJSON } = useFetch()
  const { data } = useSWR<{ messages: any[] }>('/api/users/' + uid, getJSON)
  const lastThread = data?.messages[0]?.tid
  return (
    <div>
      {lastThread ? (
        <Button inline href={`/${pid}/assess/conversations/${lastThread}`}>
          Show last active conversation
        </Button>
      ) : (
        <span>No active conversation</span>
      )}
      {blocks.includes('twilio') ? (
        <Button inline href={`/${pid}/assess/twilio/${uid}`}>
          Twilio Chat
        </Button>
      ) : null}
    </div>
  )
}
