import React from 'react'
import Icon from '@duik/icon'

import cls from './Summary.module.scss'

const Summary = ({ user, category, scoresData }) => {
  // console.log('Summary.scoresData', scoresData)
  return (
    <details className={cls.summary}>
      <summary>
        <span>
          {user &&
            (user.name ||
              user.email ||
              user.doc.name ||
              user.doc.email ||
              user.uid.replace('anonymous', ''))}{' '}
          completed a <strong>{category}</strong> assessment
        </span>
        <Icon className={cls.arrow}>arrow_right</Icon>
      </summary>
      <div className={cls.details}>
        {scoresData.map((s, key) => {
          return (
            <span key={key}>
              {s.question} <strong>{s.score}</strong>
            </span>
          )
        })}
      </div>
    </details>
  )
}

export default Summary
