import React from 'react'

import cls from '../Dashboard.module.scss'

import Card from '../atoms/Card'
import Pill from '../atoms/Pill'

const popularSuggestions = [
  { title: 'Suggestion title can be short', category: 'nails', clicks: 27 },
  {
    title: 'Suggestion title can be longer as wel can be longer as wel',
    category: 'hair',
    clicks: 21,
  },
  { title: 'Suggestion title ', category: 'skin', clicks: 16 },
  { title: 'Suggestion title can be short', category: 'nails', clicks: 11 },
]

export default function SuggestionsClicks({ title }) {
  return (
    <div className="mb-4">
      <h3 className="title">{title || 'Popular suggestions'}</h3>
      <Card>
        <h4 className="subTitle">Popular suggestion-clicks</h4>
        {popularSuggestions.map((sugg, i) => {
          return (
            <div key={i} className={cls.row}>
              <span className={cls.suggestionTitle}>{sugg.title}</span>{' '}
              <Pill pillType={sugg.category}>{sugg.category}</Pill>
              <span>{sugg.clicks}</span>
            </div>
          )
        })}
      </Card>
    </div>
  )
}
