import React from 'react'
import useSWR from 'swr'

import { useFetch } from '@healthblocks-io/core/fetch'

import ReviewAssessmentBlock from '../modules/ReviewAssessmentBlock'
import Card from '../atoms/Card'

interface Reviewable {
  name: string
  uid: string
  bodypart: string
  lastAssessment: string
  validated: boolean
  mid: string
  tid: string
  search: string
}

export default function AssessmentsReview({ title }) {
  const { getJSON } = useFetch()
  const { data: users } = useSWR<Reviewable[]>(
    '/api/custom/biosil_assessment_review',
    getJSON
  )

  return (
    <div className="mb-4">
      <h3 className="title">{title || 'Assessments review'}</h3>
      <Card>
        <ReviewAssessmentBlock
          totalReviews={users?.length}
          users={users}
          smallView
        />
      </Card>
    </div>
  )
}
