import * as React from 'react'

export function Back() {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.833 10H4.167M10 15.833L4.167 10 10 4.167"
        stroke="#787979"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export function ImageIcon() {
  return (
    <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.2 5H5.8C4.8 5 4 5.8 4 6.8v12.4c0 1 .8 1.8 1.8 1.8h12.4c1 0 1.8-.8 1.8-1.8V6.8c0-1-.8-1.8-1.8-1.8z"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.9 11.2a1.3 1.3 0 100-2.7 1.3 1.3 0 000 2.7zM20 15.7l-4.4-4.5L5.8 21"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export function PlusIcon() {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 3.545v9.414M3.333 8.252h9.334"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export function QuestionIcon() {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ flexShrink: 0 }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 4c0-1.103.897-2 2-2s2 .897 2 2-.897 2-2 2-2-.897-2-2zm5.86 1h9.144l-.424.424a1 1 0 101.415 1.412l1.422-1.426c.777-.78.777-2.04 0-2.82l-1.42-1.426a1 1 0 10-1.417 1.412l.424.424H7.86A4 4 0 003.051.109a3.975 3.975 0 00-2.94 2.937A4.006 4.006 0 004 8c1.861 0 3.412-1 3.858-3zM2 16c0-1.102.897-2 2-2s2 .898 2 2c0 1.104-.897 2-2 2s-2-.896-2-2zm5.86 1h9.144l-.424.424a1.001 1.001 0 001.415 1.413l1.422-1.426a1.997 1.997 0 000-2.82l-1.42-1.426a1 1 0 10-1.417 1.412l.424.423H7.86a4 4 0 00-4.808-2.89 3.972 3.972 0 00-2.94 2.937A4.006 4.006 0 004 20c1.861 0 3.412-1 3.858-3z"
        fill="#0659FD"
      />
    </svg>
  )
}

export function AlertCircleIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 20a8 8 0 100-16 8 8 0 000 16zM12 8v4M12 16h.01"
        stroke="#0658FD"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export function UsersIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.409 16.138v-1.364a2.727 2.727 0 00-2.727-2.727H5.227A2.727 2.727 0 002.5 14.774v1.364M7.954 9.318a2.727 2.727 0 100-5.455 2.727 2.727 0 000 5.455zM17.5 16.135v-1.364a2.727 2.727 0 00-2.045-2.638M12.727 3.953a2.727 2.727 0 010 5.284"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
