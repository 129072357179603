import React, {
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import debug from './log'
import { useApi, useApp, useAuth0, usePid } from './project'
import storage from './storage'
import { getJSON, postJSON } from '@bothrs/util/fetch'
import { DecodedJWT } from './types'
import { emptyAutoLoadable } from './loadable'
import { useFocusListener } from './focus'

const { log, warn, error } = debug('core/auth')

export interface AuthState {
  anonymous: string
  id_token: string
  refresh_token: string
  signature: string
}
export interface ErrorResponse {
  error?: {
    message: string
    type: string
  }
  original?: { error: string; error_description: string; mfa_token: string }
  authenticators?: Authenticator[]
}

export interface Authenticator {
  id: string
  authenticator_type: string
  active: boolean
  oob_channel?: string
  name?: string
}

export interface AuthStateData {
  data: AuthState | null
  error: Error | null
  loading: boolean
}

export interface MultiFactorState {
  mfa_token?: string
  authenticators?: Authenticator[]
}

export interface AuthContextType {
  data: AuthState | null
  error: Error | null
  loading: boolean
  mfa: MultiFactorState | null
  setData: (state: AuthState | null) => void
  handleAuth: (state: AuthState & ErrorResponse) => AuthState & ErrorResponse

  dismissError: () => void
  signInAnonymously: () => void
  signInWithToken: (id_token: string) => void
  /**
   * Also known as "register"
   */
  createUserWithEmailAndPassword: (
    email: string,
    password: string,
    code?: string
  ) => Promise<{ success: boolean }>
  /**
   * Signs the user in with a random id
   */
  signInWithEmailAndPassword: (
    email: string,
    password: string
  ) => Promise<AuthState & ErrorResponse>
  sendPasswordResetEmail: (email: string) => Promise<{ sent: [string] }>
  refresh: () => void
  signOut: () => void
}

// @ts-ignore
export const Auth = createContext<AuthContextType>(null)

const DEBOUNCE_REFRESH = 60 // seconds
const LAZY_REFRESH = 300 // seconds

export function AuthProvider({ children }: { children: ReactElement }) {
  const pid = usePid()
  const app = useApp()
  const api = useApi()
  const key = api + '/' + pid + '/' + app + '/auth'
  const lastRefresh = useRef(0)
  const initFocus = useFocusListener()
  const [mfa, setMFA] = useState<MultiFactorState | null>(null)
  const [{ data, error, loading }, setState] = useState<AuthStateData>(() =>
    initial(key)
  )
  /** @deprecated This function does not save state to storage */
  const setData = useCallback(
    (data: AuthState | null) => setState(s => ({ ...s, data })),
    []
  )
  // const [data, setData] = useState<AuthState | null>(initialState.data)

  const p = (url: string) =>
    api + url + (url.includes('?') ? '&' : '?') + 'pid=' + pid
  const errors = (error: Error) => ({ error })
  const handleAuth = (data: AuthState & ErrorResponse) => {
    if (data?.id_token) {
      setState({ error: null, data, loading: false })
      storage.setItem(key, JSON.stringify(data))
    } else {
      if (data.original || data.authenticators) {
        setMFA({
          mfa_token: data.original?.mfa_token,
          authenticators: data.authenticators,
        })
        setState({ error: null, data: null, loading: false })
      } else {
        warn('auth response', data)
        setState({
          error: new Error(data?.error?.message || 'Authentication failed'),
          data: null,
          loading: false,
        })
      }
      storage.removeItem(key)
    }
    return data
  }
  const refresh = () => {
    if (!data) {
      throw new Error('Must be signed in to refresh auth')
    }
    const ago = Date.now() / 1000 - lastRefresh.current
    if (ago < 2) {
      return
    } else if (ago < 400) {
      log('refresh: last refresh was ', ago, ' seconds ago')
    }
    lastRefresh.current = Date.now() / 1000
    return getJSON(p('/api/oauth?refresh_token=' + data.refresh_token))
      .catch(errors)
      .then(state => {
        if (state?.id_token) {
          return handleAuth(state)
        }
        log('Not refreshed...', state)
        if (state.error && state.error.message === 'Invalid refresh token') {
          setState(state => {
            if (state.data === data) {
              // Discard auth data if it's invalid
              storage.removeItem(key)
              return { error: null, data: null, loading: false }
            }

            return state
          })
        } else {
          // Not sure if it should complain here
          warn('Failed to refresh', state)
        }
        return state
      })
  }
  /** Consider refreshing  */
  const revalidate = (showlog = false) => {
    if (!data?.refresh_token) return

    const now = Date.now() / 1000
    const id_token = evaluateToken(data.id_token)
    const refresh_token = evaluateToken(data.refresh_token)

    if (showlog) {
      log(
        'age/id/refresh',
        id_token.age / 3600,
        '/',
        id_token.period / 3600,
        '/',
        refresh_token.period / 3600
      )
    }

    // Currently valid?
    if (id_token.expired) {
      // Hmm, let's refresh
      if (refresh_token.expired) {
        log('expired')
        // Must sign in again...
        setState({ error: null, data: null, loading: false })
      } else if (lastRefresh.current < now - DEBOUNCE_REFRESH) {
        // Go for it!
        log('recover')
        refresh()
      } else {
        // Take it easy, already refreshed in the last minute
        log('refresh too fast')
      }
    } else {
      // Alright, chill
      if (id_token.age > LAZY_REFRESH) {
        log('rotate')
        refresh()
      }
    }
  }

  // Initial loading of the data happens async if localStorage is not available
  useEffect(() => {
    if (!data && loading) {
      Promise.resolve(storage.getItem(key)).then(json => {
        const data = stateFromStorage(json)
        setState({ error: null, data, loading: false })
      })
    }
  }, [key])

  // Refresh tokens
  useEffect(() => {
    if (!data?.refresh_token) return
    revalidate(true)
  }, [data])

  // Refresh tokens
  useEffect(() => {
    if (!data?.refresh_token) return
    if (initFocus) {
      const unsubscribe = initFocus(() => revalidate())
      if (typeof unsubscribe === 'function') {
        return unsubscribe
      }
    }
  }, [initFocus, revalidate])

  return (
    <Auth.Provider
      children={children}
      value={{
        data,
        error,
        loading,
        mfa,
        setData,
        handleAuth,
        dismissError: () => setState(s => ({ ...s, error: null })),
        signInAnonymously: () =>
          getJSON(p('/api/oauth/token')).catch(errors).then(handleAuth),
        signInWithToken: (id_token: string) =>
          postJSON(p('/api/auth'), { id_token }).catch(errors).then(handleAuth),
        signInWithEmailAndPassword: (username: string, password: string) =>
          postJSON(p('/api/auth'), { username, password })
            .catch(errors)
            .then(handleAuth),
        createUserWithEmailAndPassword: (
          email: string,
          password: string,
          code?: string
        ) => {
          return postJSON(p('/api/users'), { email, password, code }).then(
            body => {
              if (body.success) return body
              if (body.error) throw new Error(body.error.message)
              log('createUserWithEmailAndPassword body', body)
              throw new Error('Unexpected error during sign up')
            }
          )
        },
        sendPasswordResetEmail: (email: string): any => {
          return postJSON(p('/api/tickets/password-change'), {
            pid,
            app,
            email,
          })
        },
        refresh,
        signOut: () => {
          setState({ error: null, data: null, loading: false })
          storage.removeItem(key)
        },
      }}
    />
  )
}

function initial(key: string) {
  if (typeof localStorage === 'undefined') return emptyAutoLoadable()
  try {
    return {
      data: stateFromStorage(localStorage[key]),
      error: null,
      loading: false,
    }
  } catch (e) {
    return emptyAutoLoadable()
  }
}

export const Registration = createContext(null)

export function useAuth() {
  const current = useContext(Auth)
  return current
}

export function useAuthState() {
  return useContext(Auth).data
}

export function useHandleAuthenticators() {
  const api = useApi()
  const pid = usePid()
  const auth0 = useAuth0()
  return useCallback(
    async ({
      original,
      mfa_token,
      authenticators,
    }: {
      authenticators?: Authenticator[]
      /** Based on useAuth */
      mfa_token?: string
      /** Based on sign in API response */
      original?: { mfa_token?: string }
    }): Promise<[any, any] | void> => {
      if (!authenticators) return
      const client_id = auth0?.clientId
      if (!client_id) return warn('Auth0 client_id missing')
      mfa_token = mfa_token || original?.mfa_token
      if (!mfa_token) return warn('Auth0 mfa_token missing')
      const active = authenticators.filter(
        a => a.active && a.oob_channel === 'sms'
      )
      if (active.length === 0) {
        return ['MultiFactorEnroll', {}]
      } else if (active.length === 1) {
        const first = active[0]
        const result = await postJSON(
          api + '/api/auth0/mfa/challenge?pid=' + pid,
          {
            client_id,
            challenge_type: first.authenticator_type || 'oob',
            authenticator_id: first.id,
            mfa_token,
          }
        )
        if (!result.oob_code) {
          throw new Error(
            result.error?.message || 'Unexpected MFA challenge error'
          )
        }
        return ['MultiFactorChallenge', { name: first.name, ...result }]
      }

      return ['MultiFactorAuthenticators', {}]
    },
    [api, pid, auth0]
  )
}

// Internal

export function useToken() {
  return useAuth()?.data?.id_token
}

export function useAsyncToken() {
  const api = useApi()
  const auth = useAuth().data
  if (!auth) {
    throw new Error('Must load auth before using useAsyncToken?')
  }

  return async () => {
    // First load
    // if (!auth && storage) {
    //   const json = await storage.getItem(pid + '/' + storageKey)
    //   if (json) {
    //     auth = stateFromStorage(json)
    //   }
    // }
    // if (!auth) {
    //   log('not authenticated')
    //   return
    // }
    // log("au", auth);
    // Use current token if not expired
    if (auth.id_token) {
      const { exp } = unsafeDecode(auth.id_token)
      if (exp * 1000 > Date.now()) {
        return auth.id_token
      }
      log('expired')
    }
    // Refresh token
    if (auth.refresh_token) {
      const { refresh_token } = auth
      const r = await getJSON(api + '/api/oauth?refresh_token=' + refresh_token)
      if (r && r.id_token) {
        log('refreshed id_token', r)
        // const json = stateToStorage(r)
        // if (!json) {
        //   throw new Error('Authentication failed')
        // }
        // storage.setItem(pid + '/' + storageKey, json)
        const { exp } = unsafeDecode(r.id_token)
        if (exp * 1000 > Date.now()) {
          return r.id_token
        }
        log('expired')
      }
      error('refresh error', r)
    }
    // Stay anonymous
    if (auth.anonymous) {
      return auth.anonymous
    }
  }
}

// Global > Auth > Token > User

export function useUser(): DecodedJWT | null {
  const jwt = useToken()
  return useMemo(() => (jwt ? unsafeDecode(jwt) : null), [jwt])
}

// Global > Auth > Token > Role

export function useUserRole(): '' | 'user' | 'moderator' | 'owner' {
  return (
    useUser()?.['https://hasura.io/jwt/claims']['x-hasura-default-role'] || ''
  )
}

/** Consider refreshing  */
export function evaluateToken(token: string) {
  const now = Date.now() / 1000
  const decoded = token ? unsafeDecode(token) : { exp: 0, iat: now + 1 }
  return {
    expired: decoded.exp < now,
    age: now - decoded.iat,
    period: decoded.exp - decoded.iat,
  }
}

// Storage transform

/** Returns a valid AuthState from a stringified Partial<> */
function stateFromStorage(json: string | null): AuthState | null {
  if (!json || !json.startsWith('{')) {
    return null
  }
  const { anonymous, id_token, refresh_token, signature }: AuthState =
    JSON.parse(json)
  if (!id_token) {
    return null
  }

  const a = evaluateToken(id_token)
  const b = evaluateToken(refresh_token)
  if (a.expired && b.expired) return null

  return {
    anonymous,
    id_token,
    refresh_token,
    signature,
  }
}

// Helpers

export function unsafeDecode(token: string) {
  const [, data] = token.split('.')
  return JSON.parse(atob(data))
}

const chars =
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/='
export function atob(input: string = '') {
  const str = input.replace(/=+$/, '')
  let output = ''

  if (str.length % 4 == 1) {
    throw new Error(
      "'atob' failed: The string to be decoded is not correctly encoded."
    )
  }
  for (
    let bc = 0, bs = 0, buffer, i = 0;
    (buffer = str.charAt(i++));
    ~buffer && ((bs = bc % 4 ? bs * 64 + buffer : buffer), bc++ % 4)
      ? (output += String.fromCharCode(255 & (bs >> ((-2 * bc) & 6))))
      : 0
  ) {
    buffer = chars.indexOf(buffer)
  }

  return output
}
