import React, { createContext, ReactNode, useContext, useMemo } from 'react'
import type { Theme as ThemeType } from './types'

export function ThemeProvider({
  children,
  overrides,
  transform,
}: {
  children: ReactNode
  overrides?: Partial<ThemeType>
  transform?: (t: ThemeType) => ThemeType
}) {
  let value = useContext(Theme)
  return (
    <Theme.Provider
      value={useMemo(() => {
        if (overrides) value = { ...defaultTheme, ...overrides } as ThemeType
        if (transform) value = transform(value)
        return value
      }, [value, overrides, transform])}
      children={children}
    />
  )
}

export function OnlineThemeProvider(props: { children: ReactNode }) {
  console.warn('core/theme.OnlineThemeProvider TODO')
  // const project = useProject()
  // const color = project.config['theme.primary.color']
  // // TODO support more tokens
  // if (color) {
  //   return (
  //     <ThemeProvider
  //       {...props}
  //       overrides={{ primary: { ...defaultTheme.primary, color } }}
  //     />
  //   )
  // }

  return <ThemeProvider {...props} />
}

export function useTheme() {
  return useContext(Theme)
}

export function useStyled<T>(func: (ThemeType) => T): T {
  return func(useTheme())
}

// Default theme

const color = {
  bg: 'white',
  text: '#4B4C4D',
  darkText: '#1E1F20',
  grayBackground: '#F7F7F7',
  grayIconColor: '#A5A8B4',
}

export const defaultTheme = {
  spacing: 8,
  bodyFont: 'bodyFont',
  h1Font: 'h1Font',
  primary: {
    color: '#0659fd',
    disabledColor: '#D2D3D9',
    activeColor: '#003BB0',
  },
  secondary: {
    color: '#0659fd',
    disabledColor: '#E9E9EC',
    activeColor: '#0659FD',
  },
  tertiary: {
    color: '#A5A8B4',
    disabledColor: '#B7B7B7',
    activeColor: '#F9B853',
    light: '#FEF8EE',
  },
  error: {
    color: '#EE6542',
    disabledColor: '#E9E9EC',
    activeColor: '#FF4E4E',
  },
  success: {
    color: '#01CD77',
    disabledColor: '#E9E9EC',
    activeColor: '#53D769',
  },
  button: {
    radius: 8,
  },
  card: {
    radius: 8,
  },
  label: {
    fontFamily: 'buttonFont',
    fontSize: 14,
    lineHeight: 22,
    marginTop: 16,
    marginBottom: 8,
    color: '#4B4C4D',
  },
  inputText: {
    fontFamily: 'bodyFont',
    fontSize: 14,
    lineHeight: 28,
  },
  input: {
    fontFamily: 'bodyFont',
    fontSize: 14,
    height: 50,
    paddingLeft: 16,
    borderRadius: 10,
    backgroundColor: color.grayBackground,
  },
  h1: {
    fontFamily: 'h1Font',
    marginTop: 64,
    marginBottom: 32,
    fontSize: 30,
    lineHeight: 36,
  },
  h2: {
    fontFamily: 'h2Font',
    fontSize: 22,
    lineHeight: 30,
    marginTop: 16,
    marginBottom: 8,
  },
  h3: {
    fontFamily: 'h1Font',
    fontSize: 16,
    lineHeight: 24,
    marginTop: 16,
    marginBottom: 8,
  },
  body: {
    fontFamily: 'bodyFont',
    fontSize: 14,
    lineHeight: 1.5 * 14,
  },
  paragraph: {
    marginVertical: 16,
    fontFamily: 'bodyFont',
    fontSize: 14,
    lineHeight: 1.5 * 14,
  },
  colors: {
    white: '#FFFFFF',
    grey: '#8F92A1',
    dark: '#1E1F20',
    grayBackground: color.grayBackground,
    grayIconColor: color.grayIconColor,
    t1: '#000000',
    t2: '#444444',
    t3: '#AAAAAA',
    t4: '#D4D3D4',
    dark100: '#1E1F20',
    dark80: '#4B4C4D',
    dark60: '#787979',
  },
  markdownStyle: {
    heading: {
      fontFamily: 'bodyFont',
      lineHeight: 30,
      color: color.darkText,
      marginBottom: 10,
    },
    paragraph: {
      fontFamily: 'bodyFont',
      paddingBottom: 20,
    },
    text: {
      fontSize: 14,
      fontFamily: 'bodyFont',
      color: color.text,
      lineHeight: 20,
    },
    strong: {
      // TODO: check if this makes sense
      fontFamily: 'h1Font',
    },
  },
}

export const Theme = createContext<ThemeType>(
  // @ts-ignore This type is wrong when theme is extended
  defaultTheme
)

/**
 * Returns a color that's mixed with white
 * Use 1 for original color
 * Use 0 for white
 */
export function lighten(color = '#004488', opacity = 0.8) {
  // Convert to color channels
  const num = parseInt(color.slice(1), 16)
  let R = num >> 16,
    G = (num >> 8) & 0x00ff,
    B = num & 0x0000ff

  // Interpolate channel
  opacity = Math.min(Math.max(opacity, 0), 1)
  R = Math.round(R + (1 - opacity) * (255 - R))
  G = Math.round(G + (1 - opacity) * (255 - G))
  B = Math.round(B + (1 - opacity) * (255 - B))

  // Encode as hex
  return (
    '#' +
    (
      0x1000000 +
      (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
      (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 +
      (B < 255 ? (B < 1 ? 0 : B) : 255)
    )
      .toString(16)
      .slice(1)
  )
}

/**
 * Returns a transparent color
 * Consider using lighten if this element overlaps other elements
 */
export function opacity(color: string, opacity: number): string {
  if (color[0] === '#' && color.length === 7) {
    return color + opacity.toString(16).slice(2, 4).padEnd(2, '0') || '00'
  }

  return color
}
