import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { ContainerHorizontal } from '@duik/container-horizontal'
import { ContainerVertical } from '@duik/container-vertical'
import { Toggle } from '@duik/toggle'
import { ls } from '@bothrs/util/ls'
import { uniq } from '@bothrs/util/uniq'
import format from 'date-fns/format'

import { useLocal } from '@healthblocks-io/core/local'
import { useProjectContext } from '@healthblocks-io/core/project'
import { useAuth } from '@healthblocks-io/core/auth'

import HealthblocksTopBar from './modules/HealthblocksTopBar'
import Navigation from './modules/Navigation'
import { theme, toggleDarkMode } from 'App/BtnSwitch'
import { unsafeDecode } from 'lib/jwt'

const Layout = ({ children }) => (
  <ContainerVertical>
    <ContainerHorizontal>
      <Navigation />
      <ContainerVertical>{children}</ContainerVertical>
    </ContainerHorizontal>
  </ContainerVertical>
)

// Does not work properly out of project context because of navigation & top bar
export default function Debug() {
  const { pid } = useParams<{ pid: string }>()
  const [{ debug, obviousTranslations }, setLocal] = useLocal()

  const keys = Object.entries(localStorage)
    .filter(([key]) => key.endsWith('/project') || key.endsWith('/auth'))
    .sort((a, b) => a[0].localeCompare(b[0]))
  const pids = Object.keys(localStorage)
    .filter(key => key.endsWith('/project') || key.endsWith('/auth'))
    .filter(key => key.split('/').length > 3)
    .map(p => p.split('/').slice(0, -1).join('/'))
    .filter(uniq)
    .sort((a, b) => a.localeCompare(b))

  if (!keys.length) {
    keys.push(['default/project', '{"pid":"default"}'])
  }
  return (
    <Layout>
      <HealthblocksTopBar>Debug {pid}</HealthblocksTopBar>
      <div className="scroll-area-main">
        <p>
          <Link to="/">Switch to another project</Link>
        </p>
        <p>
          <Toggle
            checked={theme === 'dark'}
            onChange={() => toggleDarkMode(true)}
            label={'Enable dark mode'}
          />
        </p>
        <p>
          <Toggle
            checked={debug}
            onChange={() => setLocal({ debug: !debug })}
            label={'Enable debugging tools'}
          />
        </p>
        <p>
          <Toggle
            checked={obviousTranslations}
            onChange={() =>
              setLocal({ obviousTranslations: !obviousTranslations })
            }
            label={'Enable obvious translations'}
          />
        </p>
        <table className="tbl tbl--wide">
          <thead>
            <tr>
              <td>Project</td>
              <td>Auth</td>
            </tr>
          </thead>
          <tbody>
            {pid && pid !== 'debug' && <DebugProject />}
            {pids.map(pid => (
              <tr key={pid}>
                <td>
                  <h4>{pid}</h4>
                  <Links pid={pid} />
                  <Project project={ls(pid + '/project')} />
                </td>
                <td>
                  <br />
                  <Auth auth={ls(pid + '/auth')} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Layout>
  )
}

function DebugProject() {
  const { pid, data: project, refetch } = useProjectContext()
  const auth = useAuth()
  return (
    <tr>
      <td>
        <h4>Current</h4>
        <Links pid={pid} />
        <Project project={project} refetch={refetch} />
      </td>
      <td>
        <br />
        <Auth auth={auth.data} current />
      </td>
    </tr>
  )
}

function Links({ pid }: { pid: string }) {
  return (
    <span>
      <Link to={'/' + pid + '/debug'}>/debug</Link>
      {'   '}
      <Link to={'/' + pid + '/admin'}>/admin</Link>
      {'   '}
      <Link to={'/' + pid + '/graphiql'}>GraphiQL</Link>
    </span>
  )
}

function Project({ project, refetch = null }) {
  if (!project) return null
  return (
    <div style={{ fontSize: 12 }}>
      {/* prettier-ignore */}
      <div>
        {typeof project.fallback==='boolean' && <pre>fallback:  {String(project.fallback)}</pre>}
        {typeof project.loading==='boolean' && <pre>loading:   {String(project.loading)}</pre>}
        <pre>appName:   {project.config?.appName || '?'}</pre>
        <pre>config:    {Object.keys(project.config || {}).length} keys</pre>
        <pre>fetchedAt: {date(project.fetchedAt)}</pre>
      </div>
      <div>
        {refetch && (
          <button
            onClick={() => (refetch ? refetch() : alert('to be implemented'))}
            className="btn btn-sm btn-success"
          >
            refresh
          </button>
        )}
        {refetch && (
          <button
            onClick={() => forget(project.pid + '/project', refetch)}
            className="btn btn-sm btn-danger"
          >
            forget
          </button>
        )}
      </div>
    </div>
  )
}

function forget(key: string, refetch) {
  localStorage.removeItem(key)
  refetch(Math.random())
}

function Auth({ auth, current = false }) {
  const { refresh } = useAuth()
  if (!auth) return null
  const id_token = (auth.id_token && unsafeDecode(auth.id_token)) || {}
  const refresh_token =
    (auth.refresh_token && unsafeDecode(auth.refresh_token)) || {}
  return (
    // prettier-ignore
    <div style={{ fontSize:12 }}>
      <pre>role:        {claims(id_token).role}</pre>
      <pre>allowed:     {claims(id_token).roles?.join(', ')}</pre>
      <pre>name:        {id_token.name}</pre>
      <pre>email:       {id_token.email} {
        id_token.email_verified ? (
          '✓'
        ) : (
          <span style={{ color: 'red' }}>not verified</span>
        )}</pre>
      <pre>phone:       {id_token.phone}</pre>
      <pre>sub:         {id_token.sub}</pre>
      <pre>auth exp:    {exp(id_token)}</pre>
      <pre>refresh exp: {exp(refresh_token)}</pre>
      {current && (
        <button onClick={refresh} className="btn btn-sm btn-success">
          refresh auth
        </button>
      )}
    </div>
  )
}

function exp(token) {
  return date(new Date(token.exp * 1000))
}
function claims(token) {
  const claims = token['https://hasura.io/jwt/claims'] || {}
  return {
    ...claims,
    uid: claims['x-hasura-uid'],
    role: claims['x-hasura-default-role'],
    roles: claims['x-hasura-allowed-roles'],
    pid: claims['x-hasura-pid'],
  }
}

function date(value: Date | string) {
  if (typeof value === 'string') {
    value = new Date(value)
  }
  if (!value) {
    return ''
  }
  if (value.getFullYear() === new Date().getFullYear()) {
    return format(value, 'MMMM do, HH:mm:ss')
  }
  return format(value, 'PPP, HH:mm:ss')
}
