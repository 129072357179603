import React, { useMemo, useState } from 'react'
import { Select } from '@duik/select'

import { DropdownInput } from 'pages/users/FormFields'
import { useCareTeam } from '@healthblocks-io/core/careteam'
import { Reference } from '@healthblocks-io/core/types'
import { useSubject } from '@healthblocks-io/core/subject'
import { useTranslation } from 'lib/i18n'

export default function SelectParticipant({
  value,
  onChange,
  placeholder = 'Select a user...',
}: {
  value: Reference | null
  onChange: (u: Reference) => void
  placeholder?: string
}) {
  const { t } = useTranslation()
  const subject = useSubject().reference
  const { team } = useCareTeam()
  const [name, setName] = useState('')
  const [focus, setFocus] = useState(false)

  const options = useMemo(
    () =>
      [
        { value: subject.reference, label: subject.display },
        { value: null, label: t('Full care team') },
      ].concat(
        team.participant.map(p => ({
          value: p.user_inline?.email || p.uid || p.user?.uid,
          label:
            (p.role_title ? `(${p.role_title}) ` : '') +
            (p.user_inline?.name || p.user?.name || '?'),
        }))
      ),
    [subject, team, t]
  )

  return (
    <Select
      ButtonComponent={DropdownInput}
      activeOption={{
        value: value ? value.reference || '?' : null,
        label: value ? value.display || '?' : placeholder,
      }}
      onOptionClick={({ label, value }) =>
        onChange({ display: String(label), reference: value })
      }
      options={options}
      // "searchable" introduces a rendering glitch, below is a workaround
      // that only enables it after the input was focused
      searchable={focus}
      onFocus={({ target }) => {
        setFocus(true)
        if (target.classList.contains('dropdown-item')) {
          return
        }
        setTimeout(
          () =>
            (
              target.parentNode.querySelector(
                '.select-search-input'
              ) as HTMLElement
            )?.focus(),
          200
        )
      }}
      inputSearchProps={{
        value: name,
        onChange: e => setName(e.target.value),
      }}
    />
  )
}
