import React, { useEffect } from 'react'
import { TabItem, Tabs } from '@duik/tabs'
import { ScrollArea } from '@duik/scroll-area'
import { AvatarImageSingle } from '@duik/avatar'
import { ContainerVertical } from '@duik/container-vertical'
import { NavLink } from 'react-router-dom'

import { Thread } from '@healthblocks-io/core/types'

import cls from './ThreadList.module.scss'
import { userName } from 'lib/user'

import Button from 'pages/atoms/Button'
import { usePid } from '@healthblocks-io/core/project'
import inlineAvatar from 'lib/avatar'

interface Props {
  threads: Thread[]
  loading?: boolean
  tid?: string
  nextPage?: () => void
  setTab: (tab: string) => void
  tab: string
}

const ThreadList = ({
  threads,
  tid,
  loading = false,
  nextPage,
  tab,
  setTab,
}: Props) => {
  const pid = usePid()
  const HITL_ENABLED = pid !== 'biosil'
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => entry.isIntersecting && nextPage?.(),
      {
        root: document.querySelector('#scrollArea'),
        rootMargin: '0px',
        threshold: 0.5,
      }
    )
    observer.observe(document.querySelector('.' + cls.nextPageContainer))
    return () => observer.disconnect()
  }, [nextPage])

  // console.log(threads);
  return (
    <ContainerVertical className={cls.conversations}>
      <Tabs style={{ justifyContent: 'flex-start' }}>
        {HITL_ENABLED && (
          <TabItem
            className={tab === 'hitl' ? 'active' : ''}
            onClick={() => setTab('hitl')}
          >
            HITL
          </TabItem>
        )}
        <TabItem
          className={tab === 'all' ? 'active' : ''}
          onClick={() => setTab('all')}
        >
          All Conversations
        </TabItem>
      </Tabs>
      <ScrollArea className={cls['conversation-list']}>
        <div className={cls.flex1}>
          {threads.map(thread => (
            <NavLink
              key={thread.tid}
              className={
                cls['conversation-list-item'] +
                ' ' +
                (thread.tid === tid
                  ? cls['conversation-list-item-active']
                  : '') +
                ' ' +
                (thread.sent_at < thread.archived_at
                  ? ''
                  : cls['conversation-list-item-unread'])
              }
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
              to={`/${pid}/assess/conversations/${thread.tid}`}
            >
              <div style={{ width: 38, height: 38, flexShrink: 0 }}>
                <AvatarImageSingle imgUrl={inlineAvatar(thread.user)} />
              </div>
              <div style={{ marginLeft: 18, fontSize: 12 }}>
                <strong className={cls['thread-user-name']}>
                  {userName(
                    thread.userByUser,
                    thread.user.replace('anonymous:', '')
                  )}
                </strong>
                <span className={cls['last-message']}>
                  {thread?.messages[0]?.body}
                </span>
              </div>
              {/* <span
                className={"generated-avatar"}
                dangerouslySetInnerHTML={{
                  __html: generateFromString(user.uid),
                }}
              ></span> */}
              {/* {user.uid.replace("anonymous:", "")} */}
            </NavLink>
          ))}
        </div>
        <div className={cls.nextPageContainer}>
          <Button
            secondary
            disabled={loading}
            onClick={nextPage}
            style={{ visibility: loading || !nextPage ? 'hidden' : null }}
          >
            Load more...
          </Button>
        </div>
      </ScrollArea>
    </ContainerVertical>
  )
}

export default ThreadList
