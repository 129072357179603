import { useState } from 'react'

export interface Loadable<T = any> {
  data: T | null
  error: Error | null
  loading: boolean
}

/** This loadable starts in loading state */
export function emptyAutoLoadable<T = any>(): Loadable<T> {
  return { data: null, error: null, loading: true }
}

export function emptyLoadable<T = any>(): Loadable<T> {
  return { data: null, error: null, loading: false }
}

export function useLoadable<T>(initialData?: Loadable<T>) {
  return useState<Loadable>(initialData || emptyLoadable)
}
