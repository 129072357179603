import React, { ReactNode } from 'react'
import { useParams } from 'react-router-dom'
import format from 'date-fns/format'

import { gql, useQuery } from '@healthblocks-io/apollo'

import { TopRightUser } from '../modules/HealthblocksTopBar'
import Breadcrumb from 'pages/atoms/Breadcrumb'

export default function ActivityDetail() {
  const { uid, id } = useParams<{ uid: string; id: string }>()
  const { data } = useQuery(
    gql`
      query ActivityDetailQuery($id: uuid!) {
        activity_by_pk(id: $id) {
          id
          uid
          completed_at
          created_at
          reminder_at
          started_at
          kind
          doc
          planned_at
          questionnaire_response {
            answers {
              answer
              linkId
              text
              question {
                linkId
                index
                type
                text
                config
              }
            }
          }
        }
      }
    `,
    { variables: { id } }
  )

  return (
    <div>
      <TopRightUser />

      <div className="scroll-area-main">
        <Breadcrumb
          items={[
            { title: 'Users', href: '/users' },
            { title: 'Marine', href: '/users/' + uid },
            { title: 'Care plan', href: '/users/' + uid + '?tab=careplan' },
          ]}
        />
        <div className="mb-2" />

        {data?.activity_by_pk ? (
          <PureActivityDetail activity={data?.activity_by_pk} />
        ) : (
          <div>
            <h1>Activity detail</h1>
          </div>
        )}
      </div>
    </div>
  )
}

function PureActivityDetail({ activity }) {
  const completed_at = new Date(activity.completed_at)
  return (
    <div>
      <h1>Activity detail</h1>

      <h2>
        {activity.doc.title || 'Activity'}{' '}
        <small className="ml-4 text-muted">
          {format(completed_at, 'LLL d')}
        </small>
      </h2>
      {activity.doc.description && <p>{activity.doc.description}</p>}
      <Stats>
        <Stat label="Planned at">
          {format(new Date(activity.planned_at), 'LLL d')}
        </Stat>
        <Stat label="Completed at">
          {format(new Date(activity.completed_at), 'LLL d')}
        </Stat>
      </Stats>

      {activity.questionnaire_response && (
        <QuestionnaireResponse response={activity.questionnaire_response} />
      )}
    </div>
  )
}

function Stats({ children }: { children: ReactNode }) {
  return <div className="d-flex flex-row">{children}</div>
}
function Stat({ label, children }: { label: string; children: ReactNode }) {
  return (
    <div className="mr-5">
      <small>{label}</small>
      <div style={{ fontSize: 24 }}>{children}</div>
    </div>
  )
}

interface QuestionnaireResponse {
  questionnaire_id: string
  answers: Answer[]
  completed_at?: string
}
interface Answer {
  question_id: string
  question: Question
  linkId?: string
  text: string
  answer: object
}
interface Question {
  id: string
  linkId?: string
  text: string
  index: number
}

function QuestionnaireResponse({
  response,
}: {
  response: QuestionnaireResponse
}) {
  return (
    <div className="mt-5">
      <h2>Answers</h2>
      <Answers>
        {response.answers
          .sort((a, b) => a.question.index - b.question.index)
          .map(answer => (
            <Answer label={mdtext(answer.question.text)}>{answer.text}</Answer>
          ))}
      </Answers>
    </div>
  )
}

function mdtext(markdown: string) {
  return markdown.replace('# ', '')
}

function Answers({ children }: { children: ReactNode }) {
  const length = Array.isArray(children) ? children.length : 1
  return (
    <div
      className={(length < 4 ? '' : 'd-flex flex-row flex-wrap') + ' py-1 px-3'}
      style={{
        background: '#f5f5f5',
        borderRadius: 20,
        maxWidth: length < 4 ? '25em' : '45em',
      }}
    >
      {children}
    </div>
  )
}
function Answer({ label, children }: { label: string; children: ReactNode }) {
  return (
    <div className="mr-5 my-3 flex-grow-1">
      <small className="white-space-pre">{label}</small>
      <div style={{ fontSize: 24 }}>{children}</div>
    </div>
  )
}
