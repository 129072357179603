import React from 'react'
import classnames from 'classnames'

import cls from './badge.module.scss'

export default function Badge({
  airtable = false,
  muted = false,
  primary = false,
  error = false,
  tag = false,
  sm = false,
  ...props
}) {
  return (
    <span
      {...props}
      className={classnames(
        cls.badge,
        airtable && cls.airtable,
        muted && cls.muted,
        primary && cls.primary,
        error && cls.error,
        tag && cls.tag,
        sm && cls.sm
      )}
    />
  )
}
