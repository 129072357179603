import { useMemo } from 'react'
import {
  ApolloClient,
  InMemoryCache,
  defaultDataIdFromObject,
  HttpLink,
} from '@apollo/client'
import { WebSocketLink } from '@apollo/client/link/ws'

import { AuthState, useAuthState } from '@healthblocks-io/core/auth'
import { useGraphURL } from '@healthblocks-io/core/project'

export function useHealthblocksApolloClient() {
  const graph = useGraphURL()
  const auth = useAuthState()
  if (!auth) {
    throw new Error('Auth required for apollo')
  }

  return useMemo(() => createHttpClient(graph, auth), [graph, auth])
}

/** With subscription */
export function useHealthblocksApolloClientWs() {
  const graph = useGraphURL()
  const auth = useAuthState()
  if (!auth) {
    throw new Error('Auth required for apollo')
  }

  return useMemo(() => createWebsocketClient(graph, auth), [graph, auth])
}

/** Excludes subscription support */
export function createHttpClient(graph: string, auth: AuthState) {
  return new ApolloClient({
    link: new HttpLink({
      uri: graph.replace('/v1/graphql', '') + '/v1/graphql',
      headers: { authorization: 'Bearer ' + auth.id_token },
    }),
    cache: new InMemoryCache({ dataIdFromObject }),
  })
}

/** Includes subscription support */
export function createWebsocketClient(graph: string, auth: AuthState) {
  return new ApolloClient({
    link: new WebSocketLink({
      uri:
        graph.replace('http', 'ws').replace('/v1/graphql', '') + '/v1/graphql',
      options: {
        reconnect: true,
        timeout: 10000,
        connectionParams: {
          headers: { authorization: 'Bearer ' + auth.id_token },
        },
      },
    }),
    cache: new InMemoryCache({ dataIdFromObject }),
  })
}

function dataIdFromObject(object: any, ctx: any) {
  switch (object.__typename) {
    case 'messages':
      if (!object.mid) console.warn('no mid', object, ctx)
      return 'm_' + object.mid
    case 'users':
      if ('group' in object) {
        return 'ug_' + object.group
      }
      if (!object.uid) console.warn('no uid', object, ctx)
      return 'u_' + object.uid
    case 'threads':
      if (!object.tid) console.warn('no tid', object, ctx)
      return 't_' + object.tid
    case 'projects':
      if (!object.pid) console.warn('no pid', object, ctx)
      return 'p_' + object.pid
    case 'observations':
      if (!object.oid) console.warn('no oid', object, ctx)
      return 'o_' + object.oid
    case 'fields':
      if (!object.fid) console.warn('no fid', object, ctx)
      return 'f_' + object.fid + object.pid
    case 'checkouts':
      if (!object.id) console.warn('no checkout id', object, ctx)
      return 'ch_' + object.id
  }
  if (
    object.__typename?.endsWith('mutation_response') ||
    object.__typename?.endsWith('_fields') ||
    object.__typename?.endsWith('_aggregate') ||
    object.__typename?.endsWith('_aggregate_fields')
  ) {
    return
  }
  if (object.users_by_pk) {
    return 'object.users_by_pk.' + object.users_by_pk.uid
  }
  if (object.questionnaires) {
    return 'object.questionnaires.' + object.questionnaires
  }
  if (object.threads) {
    return 'object.threads.' + object.threads
  }
  if (object.id) {
    return defaultDataIdFromObject(object, ctx)
  }
  // console.warn('id', object, defaultDataIdFromObject(object, ctx))
  return defaultDataIdFromObject(object, ctx)
}
