import React, { HTMLAttributes } from 'react'
import classnames from 'classnames'

import cls from './TableButton.module.scss'

interface TableButtonProps {
  primary?: boolean
  subtle?: boolean
}

export default function TableButton({
  className,
  primary,
  subtle,
  ...rest
}: HTMLAttributes<HTMLButtonElement> & TableButtonProps) {
  return (
    <button
      {...rest}
      className={classnames(
        className,
        cls.btn,
        primary && cls.primary,
        subtle && cls.subtle
      )}
    />
  )
}
