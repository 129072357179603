import React, { useEffect, useMemo } from 'react'
import { useLocalStorage } from '@utils'
import { NavPanel } from '@duik/nav-panel'
import { ScrollArea } from '@duik/scroll-area'
import { ContainerVertical } from '@duik/container-vertical'
import classnames from 'classnames'
import { Link, useHistory, useLocation } from 'react-router-dom'

import { useUser } from '@healthblocks-io/core/auth'

import cls from './Navigation.module.scss'

import { MenuLink, useMenu } from 'lib/menu'
import { useApi, useConfig, usePid } from '@healthblocks-io/core/project'
import { useTranslation } from 'lib/i18n'
import { useLocal } from '@healthblocks-io/core/local'

// prettier-ignore
const icons = {
  home: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 1 24 24" width="24" height="24" style={{display:'block'}}><path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 9l9-7 9 7v11a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"/><path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 22V12h6v10"/></svg>,
  users: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 22" width="24" height="22" style={{display:'block'}}><path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16.1 17.36v-1.63a3.27 3.27 0 00-3.28-3.27H6.27A3.27 3.27 0 003 15.73v1.63M9.55 9.18a3.27 3.27 0 100-6.54 3.27 3.27 0 000 6.54zM21 17.36v-1.63a3.27 3.27 0 00-2.45-3.17M15.27 2.74a3.27 3.27 0 010 6.34"/></svg>,
  git_branch: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 22" width="24" height="22" style={{display:'block'}}><path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6.667 2v10.667M17.334 7.333a2.667 2.667 0 100-5.333 2.667 2.667 0 000 5.333zM6.667 18a2.667 2.667 0 100-5.334 2.667 2.667 0 000 5.333zM17.333 7.334a8 8 0 01-8 8"/></svg>,
  message_circle: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 22" width="24" height="22" style={{display:'block'}}><path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20 9.6a7.5 7.5 0 01-3.6 6.4 7.6 7.6 0 01-7.3.3L4 18l1.7-5A7.4 7.4 0 016 5.5 7.6 7.6 0 0112.4 2h.5A7.5 7.5 0 0120 9.1v.5z"/></svg>,
  target: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 22" width="24" height="22" style={{display:'block'}}><path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 18a8 8 0 100-16 8 8 0 000 16z"/><path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 14.8a4.8 4.8 0 100-9.6 4.8 4.8 0 000 9.6z"/><path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 11.6a1.6 1.6 0 100-3.2 1.6 1.6 0 000 3.2z"/></svg>,
  settings: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 -2 24 24" width="24" height="24" style={{display:'block'}}><path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 12.18a2.18 2.18 0 100-4.36 2.18 2.18 0 000 4.36z"/><path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17.38 12.18a1.2 1.2 0 00.24 1.33l.05.04a1.46 1.46 0 11-2.06 2.06l-.05-.05a1.2 1.2 0 00-1.32-.24 1.2 1.2 0 00-.73 1.1v.13a1.45 1.45 0 11-2.9 0v-.07a1.2 1.2 0 00-.8-1.1 1.2 1.2 0 00-1.32.24l-.04.05A1.46 1.46 0 116.4 13.6l.05-.05a1.2 1.2 0 00.24-1.32 1.2 1.2 0 00-1.1-.73h-.13a1.45 1.45 0 110-2.9h.07a1.2 1.2 0 001.1-.8 1.2 1.2 0 00-.24-1.32l-.05-.04A1.45 1.45 0 118.4 4.4l.05.05a1.2 1.2 0 001.32.24h.06a1.2 1.2 0 00.73-1.1v-.13a1.45 1.45 0 112.9 0v.07a1.2 1.2 0 00.73 1.1 1.2 1.2 0 001.33-.24l.04-.05A1.45 1.45 0 1117.6 6.4l-.05.05a1.2 1.2 0 00-.24 1.32v.06a1.2 1.2 0 001.1.73h.13a1.45 1.45 0 010 2.9h-.07a1.2 1.2 0 00-1.1.73v0z"/></svg>
}

const NavLinkAnalytics = ({
  rightEl = null,
  children,
  className = '',
  highlighted = false,
  icon = null,
  ...rest
}) => (
  <Link
    to={rest.href}
    className={classnames(cls['nav-link'], className)}
    {...rest}
  >
    <span className={cls['nav-link-text']}>
      <span className={cls['nav-link-icon']}>{icon}</span>
      {children}
    </span>
    {rightEl && <span className={cls['nav-link-right-el']}>{rightEl}</span>}
  </Link>
)

function useCollapsed() {
  const location = useLocation()
  return location.pathname?.includes('/questionnaires/') ||
    location.pathname?.includes('/sendbird') ||
    location.pathname?.includes('/users/')
    ? cls.collapsed + ' '
    : ''
}

const AnalyticsNavigation = props => {
  const user = useUser()
  const history = useHistory()
  const collapsed = useCollapsed()
  const menu = useMenu(user)
  const local = useLocal()
  const logo = useConfig(
    p => p.config.appLogo?.thumbnails?.large?.url || p.config.appLogo?.url || ''
  )

  // TODO: support for dashboard-devapi.healthblocks.io
  const debug = !!local?.[0].debug
  const devDashboard = !window.location.host.includes('dashboard.healthblocks')
  const api = useApi()

  const filteredMenu = useMemo(
    () =>
      debug
        ? menu.concat({
            text: 'Care plan templates',
            href: '/plandefinitions',
            allow: [],
            icon: 'settings',
          })
        : menu,
    [menu, debug]
  )

  useEffect(() => {
    if (!collapsed) {
      return
    }
    const selector = window.$('.' + cls['nav-link'])
    selector.tooltip({ boundary: 'window' })
    return () => {
      selector.tooltip('dispose')
    }
  }, [collapsed, filteredMenu])

  return (
    <NavPanel className={collapsed + cls.panel}>
      <ContainerVertical>
        <div className={classnames(cls['analytics-nav-user'])}>
          <div>
            <img
              alt="hb"
              className={cls['analytics-nav-user-avatar']}
              src={logo || '/images/healthblocks-logo-white.png'}
              onClick={() => {
                history.push('/' + window.location.pathname.split('/')[1])
              }}
              onDoubleClick={() => {
                history.push(
                  '/' +
                    [window.location.pathname.split('/')[1], 'debug']
                      .filter(Boolean)
                      .join('/')
                )
              }}
            />
            {devDashboard && <span className={cls.dev}>dev</span>}
            {debug && !collapsed && (
              <select
                className={cls.devSelect}
                style={{ border: 0, background: 'none', appearance: 'none' }}
                value={api}
                onChange={evt => {
                  window.location.href =
                    (evt.target.value === 'https://api.dev.healthblocks.io'
                      ? 'https://dashboard-dev.v1.healthblocks.io/'
                      : 'https://dashboard-prdapi-dev.v1.healthblocks.io/') +
                    window.location.href.split('/').slice(3).join('/')
                }}
              >
                <option disabled>Choose...</option>
                <option value="https://api.healthblocks.io">Data: prd</option>
                <option value="https://api.dev.healthblocks.io">
                  Data: dev
                </option>
                {![
                  'https://api.healthblocks.io',
                  'https://api.dev.healthblocks.io',
                ].includes(api) && (
                  <option value={api}>
                    {api
                      .replace('https://', '')
                      .replace('http://', '')
                      .replace('api.', '')
                      .replace('.healthblocks.io', '')}
                  </option>
                )}
              </select>
            )}
          </div>
        </div>
        <ScrollArea style={{ overflowX: 'hidden' }}>
          {filteredMenu.map((link, key) =>
            link ? (
              <NavItem key={key} link={link} expandedSidebar={!collapsed} />
            ) : (
              <div
                key={key}
                style={{
                  borderBottom: '1px solid #E9E9EC',
                  margin: '10px 15px 10px 0',
                }}
              />
            )
          )}
        </ScrollArea>
      </ContainerVertical>
    </NavPanel>
  )
}

interface NavItemProps {
  link: MenuLink
  expandedSidebar: boolean
}

function NavItem({ link, expandedSidebar }: NavItemProps) {
  const pid = usePid()
  const location = useLocation()
  const { t } = useTranslation()
  const [collapsed, toggle] = useLocalStorage(link.href, true)
  const prefix = '/' + pid
  const rootActive = link.items?.[0]
    ? link.items.find(item => subActive(item.href))
    : link.exact
    ? location.pathname === '/' + pid + link.href
    : location.pathname.startsWith('/' + pid + link.href)

  function subActive(href) {
    return location.pathname.startsWith('/' + pid + href) ? 'active' : ''
  }

  return (
    <div>
      <NavLinkAnalytics
        className={
          (rootActive ? 'active' : '') +
          (link.items ? ' has-sublink' : '') +
          (collapsed ? '' : ' nav-link--expanded')
        }
        icon={icons[link.icon]}
        rightEl={link.items && <ChevronDown />}
        href={prefix + (link.items?.[0] ? link.items?.[0].href : link.href)}
        onClick={
          link.items && expandedSidebar
            ? e => {
                !collapsed && e.preventDefault()
                toggle(!collapsed)
              }
            : null
        }
        data-toggle="tooltip"
        data-placement="right"
        title={t(link.text)}
      >
        <strong>{t(link.text)}</strong>
      </NavLinkAnalytics>
      {collapsed
        ? null
        : link.items?.map(subItem => (
            <NavLinkAnalytics
              key={subItem.text}
              className={subActive(subItem.href) + ' ' + cls.sublink}
              href={prefix + subItem.href}
            >
              <strong>{t(subItem.text)}</strong>
            </NavLinkAnalytics>
          ))}
    </div>
  )
}

export default AnalyticsNavigation

function ChevronDown() {
  return (
    <svg
      width={24}
      height={22}
      viewBox="0 -2 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 7l6 6 6-6"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
