import React from 'react'
import Icon from '@duik/icon'

import cls from './CloseButton.module.scss'

const CloseButton = ({ onClose }) => {
  return (
    <span onClick={onClose} className={cls.closeButton}>
      <Icon>close</Icon>
    </span>
  )
}

export default CloseButton
