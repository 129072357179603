import React from 'react'

const privacy = () => {
  return (
    <div>
      <h1>Privacy</h1>

      <p>
        George McFly? Oh, he's kinda cute and all, but, well, I think a man
        should be strong, so he could stand up for himself, and protect the
        woman he loves. Don't you? Just say anything, George, say what ever's
        natural, the first thing that comes to your mind. Quiet. Ho ho ho, look
        at it roll. Now we could watch Jackie Gleason while we eat. Well, Marty,
        I'm almost eighteen-years-old, it's not like I've never parked before.
      </p>
      <p>
        That ain't no airplane, look. Well, Biff. Yeah, he's right here.
        Re-elect Mayor Goldie Wilson. Progress is his middle name. In that case,
        I'll tell you strait out.
      </p>
      <p>
        No, get out of town, my mom thinks I'm going camping with the guys.
        Well, Jennifer, my mother would freak out if she knew I was going up
        there with you. And I get this standard lecture about how she never did
        that kind of stuff when she was a kid. Now look, I think she was born a
        nun. Great good, good, Lorraine, I had a feeling about you two.
        Children. Perfect, just perfect. Oh hey, Biff, hey, guys, how are you
        doing?
      </p>
    </div>
  )
}

export default privacy
