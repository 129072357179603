import React, { FC, useLayoutEffect, useRef } from 'react'
import { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'

// Probably a day
export interface IndexListItem<T> {
  /** Sorted key */
  index: number
  data: T
}

// Should show data based on props
// Should trigger callback when top/bottom
// Should be scrolled initially to specific item
export default function IndexList<T>({
  data,
  renderItem: RenderItem = FallbackItem,
  onStartReached,
  onEndReached,
}: {
  data: IndexListItem<T>[]
  renderItem?: FC<{ data: T; index: number }>
  onStartReached?: () => void
  onEndReached?: () => void
  onStartReachedThreshold?: number
  onEndReachedThreshold?: number
}) {
  const before = data.filter(c => c.index < 0)
  const after = data.filter(c => c.index >= 0)

  const scroll = useRef<number | null>(null)
  const pivot = useRef<HTMLDivElement>(null)

  // Measure scroll position before render
  if (pivot.current) scroll.current = pivot.current.getBoundingClientRect().top
  useLayoutEffect(() => {
    // Initial scroll
    if (scroll.current === null) {
      if (pivot.current) {
        pivot.current.scrollIntoView({ block: 'start' })
        pivot.current.parentElement.scrollTop -= 30
      }
      return
    }

    // Reset scroll position after render
    const top = pivot.current.getBoundingClientRect().top - scroll.current
    if (top) {
      pivot.current.parentElement.scrollBy({ top })
    }
  }, [data])

  return (
    <div style={{ position: 'relative', height: '100%', overflow: 'hidden' }}>
      <div
        style={{
          zIndex: 2,
          boxShadow: '0 0 20px rgba(0, 0, 0, .05)',
          position: 'absolute',
          left: -10,
          top: -20,
          height: 20,
          right: -10,
        }}
      />
      <div
        style={{
          zIndex: 2,
          boxShadow: '0 0 20px rgba(0, 0, 0, .1)',
          position: 'absolute',
          left: -10,
          bottom: -20,
          height: 20,
          right: -10,
        }}
      />
      <div style={{ height: '100%', overflow: 'auto' }}>
        <LoadMore top onClick={onStartReached} />
        <div>
          {before.map(item => (
            <RenderItem key={item.index} data={item.data} index={item.index} />
          ))}
        </div>
        <div ref={pivot} style={{ minHeight: '100%' }}>
          {after.map(item => (
            <RenderItem key={item.index} data={item.data} index={item.index} />
          ))}
        </div>
        <LoadMore onClick={onEndReached} />
      </div>
    </div>
  )
}

function LoadMore({ top, onClick }: { top?: boolean; onClick: () => void }) {
  const { ref, inView } = useInView()
  useEffect(() => {
    if (inView) {
      onClick()
    }
    // eslint-disable-next-line
  }, [inView])
  return (
    <div
      ref={ref}
      style={{
        height: 300,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: top ? 'flex-start' : 'flex-end',
      }}
    >
      <button onClick={onClick} className="btn--unstyled">
        Load more
      </button>
    </div>
  )
}

function FallbackItem({ data, index }) {
  return (
    <div>
      {index}:<pre>{JSON.stringify(data)}</pre>
    </div>
  )
}
