import React from 'react'
import { Link } from 'react-router-dom'
import { Select } from '@duik/select'
import { DropdownMenuPosition } from '@duik/dropdown'
import { Avatar } from '@duik/avatar'
import Icon from '@duik/icon'

import { useApolloClient } from '@healthblocks-io/apollo'
import { usePid } from '@healthblocks-io/core/project'

import { useLocalStorage } from '@utils'
import Button from 'pages/atoms/Button'

import cls from './settings.module.scss'
import HealthblocksTopBar from '../modules/HealthblocksTopBar'
import { useAccess, revoke, setRole } from '../../lib/project'
import { UsersIcon } from 'pages/atoms/Icon'
import inlineAvatar from 'lib/avatar'

const headings = [
  { displayName: 'Name', sortBy: 'name' },
  { displayName: 'Email', sortBy: 'email' },
  { displayName: 'Status' },
  { displayName: 'Role' },
]

export default function Access() {
  const { access, loading } = useAccess()
  return (
    <div>
      <HealthblocksTopBar>Teams &amp; Roles</HealthblocksTopBar>
      <div className="scroll-area-main">
        <p>
          <span className={cls.memberCount}>
            {loading ? '' : access.length} Teammates
          </span>
          <Link to="invite">
            <Button primary type="button">
              Add new teammates
              <UsersIcon style={{ margin: '-2px -4px 0 10px' }} />
            </Button>
          </Link>
        </p>
        <UserTableOrFallback {...{ access, loading }} />
      </div>
    </div>
  )
}

const UserTableOrFallback = ({ access, loading }) => {
  if (loading) {
    return <div>Loading access</div>
  }

  if (!access.length) {
    return <div>No team members have been defined, let's add some!</div>
  }

  return <UserTable access={access} />
}

const UserTable = ({ access }) => {
  const [order, setOrder] = useLocalStorage('accessOrder', 'email')
  const toggleOrder = key =>
    setOrder(order === key ? '-' + key : order === '-' + key ? '' : key)
  const sortedUsers = sortBy(access, order)
  return (
    <table
      className="tbl tbl--sticky tbl--avatar tbl--wide"
      style={{ fontSize: 14 }}
    >
      <thead>
        <tr>
          <th />
          {headings.map((heading, key) => {
            return (
              <th
                key={key}
                onClick={() => heading.sortBy && toggleOrder(heading.sortBy)}
                style={{ top: -26 }}
                className={
                  (heading.sortBy ? 'tbl__sort ' : '') +
                  (order === heading.sortBy
                    ? `selected asc`
                    : order === '-' + heading.sortBy
                    ? `selected des`
                    : '')
                }
              >
                {heading.displayName}{' '}
                <span
                  style={{
                    transform: 'rotate(90deg)',
                    display: 'inline-block',
                  }}
                >
                  {order === heading.sortBy ? (
                    <Icon className={cls.arrow}>arrow_right</Icon>
                  ) : order === '-' + heading.sortBy ? (
                    <Icon className={cls.arrow}>arrow_left</Icon>
                  ) : (
                    ''
                  )}
                </span>
              </th>
            )
          })}
          <th style={{ top: -26 }} />
        </tr>
      </thead>
      <tbody>
        {sortedUsers.map((user, key) => (
          <UserRow key={user.uid || key} user={user} />
        ))}
      </tbody>
    </table>
  )
}

const UserRow = ({ user }) => {
  const pid = usePid()
  const client = useApolloClient()
  const handleOptionSelect = option => {
    setRole(client, pid, user, option.value)
  }
  return (
    <tr>
      <td className="td-1">
        <Avatar className={cls.avatarUsers} imgUrl={inlineAvatar(user.uid)} />
      </td>
      <td>
        <span className={cls.darker} style={{ opacity: user.name ? 1 : 0.3 }}>
          {user.name || '(invite)'}
        </span>
      </td>
      <td>{user.email || <span style={{ opacity: 0.5 }}>n/a</span>}</td>
      <td>
        <span>{user.uid ? 'Active' : 'Invite'}</span>
      </td>
      <td>
        <span>
          <Select
            activeOption={user.role}
            options={['moderator', 'owner'].map(value => ({
              value,
              label: value,
            }))}
            onOptionClick={handleOptionSelect}
            placeholder={user.role}
            menuPosition={DropdownMenuPosition['bottom-right']}
          />
        </span>
      </td>
      <td>
        <Button
          secondary
          onClick={() => confirmRevoke(client, pid, user)}
          style={{ margin: 0 }}
        >
          revoke
        </Button>
      </td>
    </tr>
  )
}

function confirmRevoke(client: any, pid: string, user) {
  if (window.confirm('Revoke access?')) {
    revoke(client, pid, user)
  }
}

function sortBy(array, order) {
  const reverse = order.startsWith('-') ? -1 : 1
  if (reverse < 0) {
    order = order.slice(1)
  }
  return array
    .slice()
    .sort((a, b) => reverse * (a[order] || '').localeCompare(b[order] || ''))
}
