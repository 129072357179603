import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  BundleState,
  CareTeam as CareTeamType,
  CareTeamParticipant,
  Profile,
  Reference,
} from './types'
import { FHIRProvider, useFHIR } from './fhir'
import debug from './log'
import { useUserRole } from './auth'
import { checkPolicy } from './policy'

const { log, warn } = debug('core/careteam')

export interface CareTeamContext {
  uid?: string
  me: Reference | null
  team?: CareTeamType

  // Policy check
  participant?: CareTeamParticipant
  can: (input: Parameters<typeof checkPolicy>[1]) => boolean

  // Loading
  loading: boolean
  refetch: () => Promise<void>
}

// @ts-ignore
export const CareTeam = createContext<CareTeamContext>(null)

export function useCareTeam() {
  return useContext(CareTeam)
}

function ok(a: any) {
  return true
}
function nok(a: any) {
  return false
}
export function empty() {
  /** @todo check if loading should be false by default */
  return { data: null, loading: true }
}
export function CareTeamProvider({
  children,
  subject,
  profile,
}: {
  children: ReactNode
  /** uid of the subject */
  subject: string
  /** My own user profile */
  profile: Profile
}) {
  const role = useUserRole()
  const { search, events } = useFHIR()
  const [bundle, setBundle] = useState<BundleState<CareTeamType>>(empty)
  const refetch = useCallback(
    () => {
      setBundle(v => ({ ...v, loading: true }))
      return search<CareTeamType>('CareTeam', { subject })
        .then(data => {
          if (data.entry) {
            setBundle({ data, error: null, loading: false })
          } else if (
            // @ts-ignore legacy API response => backwards compatible
            data.data
          ) {
            setBundle({
              // @ts-ignore legacy API response => backwards compatible
              data: { type: 'searchset', entry: data.data.careteam },
              error: null,
              loading: false,
            })
          } else {
            setBundle({
              data: null,
              error: new Error('Unexpected error'),
              loading: false,
            })
          }
        })
        .catch(error => {
          setBundle(v => ({ ...v, loading: false, error }))
        })
    },
    // eslint-disable-next-line
    [search, subject]
  )

  useEffect(() => {
    refetch()
  }, [refetch])

  events.on(
    'change',
    a => {
      refetch()
    },
    []
  )

  const value = useMemo(() => {
    const team =
      bundle.data?.entry.find(c =>
        c.participant.find(p => p.uid === profile.uid)
      ) ||
      bundle.data?.entry.find(c =>
        c.participant.find(p => p.user?.uid === profile.uid)
      ) ||
      bundle.data?.entry[0]
    const participant =
      team?.participant.find(p => p.uid === profile.uid) ||
      team?.participant.find(p => p.user?.uid === profile.uid)
    const role_title = participant?.role_title
    return {
      refetch,
      loading: bundle.loading,
      team,
      participant,
      can:
        role === 'owner' || role === 'moderator' || subject === profile.uid
          ? ok
          : !participant
          ? nok
          : (input: Parameters<typeof checkPolicy>[1]) =>
              checkPolicy(
                participant.policies.map(p => p.policy!).filter(Boolean),
                { uid: profile.uid, ...input }
              ),
      me:
        subject === profile.uid
          ? { type: 'Patient', reference: profile.uid, display: profile.name }
          : participant
          ? {
              type: 'Practitioner',
              reference: profile.uid,
              display: (role_title ? `(${role_title}) ` : '') + profile.name,
            }
          : role === 'owner' || role === 'moderator'
          ? {
              type: 'Practitioner',
              reference: profile.uid,
              display: '(Administrator) ' + profile.name,
            }
          : null,
    }
  }, [refetch, bundle, profile.uid, profile.name])

  return <CareTeam.Provider value={value} children={children} />
}

export function PatientCareTeamProvider({
  children,
  subject,
}: {
  children: ReactNode
  subject: string
}) {
  const [first, setFirst] = useState(true)
  const { search, create } = useFHIR()
  const [bundle, setBundle] = useState<BundleState<CareTeamType>>(empty)
  const refetch = useCallback(
    async () => {
      if (!subject) {
        return warn('careteam.refetch subject', subject)
      }
      setBundle(v => ({ ...v, loading: true }))
      return search<CareTeamType>('CareTeam', { subject })
        .then(data => {
          if (data.entry) {
            setBundle({ data, error: null, loading: false })
          } else if (
            // @ts-ignore legacy API response => backwards compatible
            data.data
          ) {
            setBundle({
              // @ts-ignore legacy API response => backwards compatible
              data: { type: 'searchset', entry: data.data.careteam },
              error: null,
              loading: false,
            })
          } else {
            setBundle({
              data: null,
              error: new Error('Unexpected error'),
              loading: false,
            })
          }
        })
        .catch(error => {
          setBundle(v => ({ ...v, loading: false, error }))
        })
    },
    // eslint-disable-next-line
    [search, subject]
  )

  useEffect(() => {
    refetch()
  }, [refetch])

  const care = useMemo(() => {
    const team =
      bundle.data?.entry.find(c => c.subject_id?.endsWith(subject)) ||
      bundle.data?.entry[0]
    return {
      refetch,
      loading: bundle.loading,
      can: () => true,
      team,
      me: null,
    }
  }, [refetch, bundle, subject])

  // Create careteam if missing
  useEffect(() => {
    if (bundle.data?.entry.length === 0 && !bundle.error && first) {
      setFirst(false)
      create({
        resourceType: 'CareTeam',
        // @ts-ignore
        subject_id: subject,
      }).then(ok => {
        log('created', ok)
        refetch()
      })
    }
    // eslint-disable-next-line
  }, [first, bundle.data?.entry.length])

  if (!care.team) {
    return <CareTeam.Provider value={care}>{children}</CareTeam.Provider>
  }

  return (
    <CareTeam.Provider value={care}>
      <FHIRProvider ctx={{ careteam_id: care.team.id }}>
        {children}
      </FHIRProvider>
    </CareTeam.Provider>
  )
}
