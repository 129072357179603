import React, {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
} from 'react'

import debug from './log'
import { Hook, useHook } from './hook'
import { useFetch } from './fetch'
import { deviceId } from './device'

const { warn } = debug('analytics')

// @ts-ignore
export const Analytics = createContext<AnalyticsClient>(null)

export function AnalyticsProvider({
  children,
  properties,
  windowEvents = false,
}: {
  children: ReactNode
  properties?: Record<string, any>
  windowEvents?: boolean
}) {
  const hook = useContext(Hook)
  const { postJSON } = useFetch()

  const client = useMemo(
    () => ({
      track: (data: Partial<TrackData>) => {
        if (!data.anonymousId) data.anonymousId = deviceId
        if (properties) data.properties = { ...properties, ...data.properties }
        postJSON('/api/proxy', data).catch(e => warn('track error', data, e))
      },
    }),
    [postJSON]
  )

  hook.on('track', client.track, [client])

  useEffect(() => {
    if (!windowEvents) return

    // To be reviewed, is focus/blur useful or superfluous?
    // const focus = () => hook.emit('track', { event: 'Window Focused' })
    // const blur = () => hook.emit('track', { event: 'Window Blurred' })
    const close = () => hook.emit('track', { event: 'Window Closed' })
    const visibilitychange = () =>
      document.visibilityState === 'visible'
        ? hook.emit('track', { event: 'Window Visible' })
        : document.visibilityState === 'hidden'
        ? hook.emit('track', { event: 'Window Hidden' })
        : null

    // window.addEventListener('focus', focus)
    // window.addEventListener('blur', blur)
    window.addEventListener('beforeunload', close)
    document.addEventListener('visibilitychange', visibilitychange)
    return () => {
      // window.removeEventListener('focus', focus)
      // window.removeEventListener('blur', blur)
      window.removeEventListener('beforeunload', close)
      document.removeEventListener('visibilitychange', visibilitychange)
    }
  }, [windowEvents])

  return <Analytics.Provider value={client} children={children} />
}

export function AnalyticsUserProvider({
  children,
  userId,
}: {
  children: ReactElement
  userId?: string
}) {
  const hook = useContext(Hook)
  hook.before('track', (data: TrackData) => ({ ...data, userId }), [userId])

  return children
}

export function AnalyticsDataProvider({
  children,
  properties: extra,
}: {
  children: ReactElement
  properties?: Record<string, any>
}) {
  const parent = useContext(Hook)
  const client = useMemo(
    () => ({
      emit: (type: string, data: any) => ({
        ...parent,
        emit: parent.emit(type, {
          ...data,
          properties: { ...extra, ...data.properties },
        }),
      }),
    }),
    [JSON.stringify(extra)]
  )

  return <Hook.Provider value={client as any} children={children} />
}

/** This hook reduces the need to refactor */
export function useAnalytics() {
  const hook = useHook()
  return useMemo(
    () => ({
      track: (event: string, properties?: Props, userId?: string) =>
        hook.emit('track', { event, properties, userId }),
    }),
    [hook]
  )
}

export function useTrack(
  event: string,
  properties: Props = {},
  deps: any[] = []
) {
  const hook = useHook()
  useEffect(() => {
    hook.emit('track', { event, properties })
    if (event.includes('Opened')) {
      const start = Date.now()
      return () => {
        hook.emit('track', {
          event: event.replace('Opened', 'Closed'),
          properties: { ...properties, duration: (Date.now() - start) / 1000 },
        })
      }
    }
  }, deps)
}

export function usePeopleIncrement(properties: Props = {}, deps: any[] = []) {
  const hook = useHook()
  useEffect(() => {
    hook.emit('people_increment', { properties })
  }, deps)
}

export function useNavTracker() {
  const hook = useHook()
  return ({ data }) => {
    const current = getCurrentRoute(data)
    if (current.name === 'TabbedPages') {
      return // console.warn('navigate tabbed', data, current)
    }
    const properties = { route: current.name }
    // if (properties.route === 'Questionnaire') {
    //   properties['Questionnaire Name'] = current.params.name
    // }
    // console.log('cucc', current)
    hook.emit('track', { event: 'Navigate', properties })
  }
}

function getCurrentRoute(state: any) {
  if (state.routes) {
    return getCurrentRoute(state.routes[state.index])
  }
  if (state.state) {
    return getCurrentRoute(state.state)
  }
  return state
}

// Types

export interface AnalyticsClient {
  track: (t: TrackData) => void
}

export interface TrackData {
  /** Event name, e.g. "Article Opened", "Profile Updated" */
  event: string
  /** Custom data */
  properties: Props
  anonymousId: string
  /** User time when event happened */
  sentAt: string
  userId?: string
  pid: string
}

export type Props = Record<string, any>
