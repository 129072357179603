import React from 'react'

import { useProject } from '@healthblocks-io/core/project'

import Button from 'pages/atoms/Button'

import HealthblocksTopBar from './modules/HealthblocksTopBar'

const SelfAssessmentsPage = () => {
  const { config } = useProject()
  return (
    <div>
      <HealthblocksTopBar>Engage</HealthblocksTopBar>
      <div className="scroll-area-main">
        <h3 className="title">Suggestion overview</h3>

        {config.airtableSuggestions ? (
          <>
            <p>Suggestions are managed through Airtable</p>
            <p>
              <a
                href={config.airtableSuggestions}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button primary>Manage suggestions</Button>
              </a>
            </p>
          </>
        ) : null}
      </div>
    </div>
  )
}

export default SelfAssessmentsPage
