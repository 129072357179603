import React, { useState } from 'react'

import { loadScript } from '@bothrs/util/load'

import { usePid } from '@healthblocks-io/core/project'
import { useFetch } from '@healthblocks-io/core/fetch'
import { useToken } from '@healthblocks-io/core/auth'

import Button from '../../pages/atoms/Button'
import { format } from 'date-fns'
import ImportConfigurationModal from './ImportConfigurationModal'

export default function ImportExportConfiguration() {
  const pid = usePid()
  const { getJSON } = useFetch()

  const [exported, setExported] = useState(false)
  const [message, setMessage] = useState('')

  const modal = useModal()

  const exportConfiguration = async (
    pid: string,
    setMessage: (m: string) => void,
    getJSON: (url: string) => void
  ) => {
    try {
      await loadScript(
        'https://unpkg.com/file-saver@2.0.2/dist/FileSaver.min.js',
        'FileSaverSDK'
      )
      setMessage('Downloading')
      const content = await getJSON(
        '/api/projects/' + pid + '?include=questionnaires,secret_auth0'
      )
      setMessage('')
      const date = format(new Date(), 'yyyy-MM-dd')
      const fileName = date + '_export_' + pid + '.json'

      // Create a blob of the data
      const fileToSave = new Blob([JSON.stringify(content, null, 4)], {
        type: 'application/json',
      })
      // Save the file
      ;(window as any).saveAs(fileToSave, fileName)
      return true
    } catch (e) {
      console.log('export err', e)
      setMessage('Failed to export questionnaires: ' + e.message)
    }
  }

  return (
    <>
      <Button
        secondary
        inline
        className="mr-2"
        onClick={async () => {
          const process = await exportConfiguration(pid, setMessage, getJSON)
          if (process) setExported(process)
        }}
      >
        {exported ? 'Done!' : 'Export configuration'}
      </Button>
      <Button secondary inline className="mr-2" onClick={modal.open}>
        Import configuration
      </Button>
      {message}

      <ImportConfigurationModal close={modal.close} isOpen={modal.isOpen} />
    </>
  )
}

function useModal() {
  const [state, setState] = useState(false)
  return {
    isOpen: state,
    open: () => setState(true),
    close: () => setState(false),
  }
}
