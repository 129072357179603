import React from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'

import cls from './ReviewAssessmentBlock.module.scss'
import Pill from 'pages/atoms/Pill'
import { usePid } from '@healthblocks-io/core/project'

const ReviewAssessmentBlock = ({
  users,
  totalReviews,
  smallView,
}: {
  users
  totalReviews?: number | undefined
  smallView?: boolean
}) => {
  const pid = usePid()
  const prefix = `/${pid}/assess/conversations/`

  return (
    <div>
      <h4 className={`subTitle ${cls.reviewsTitle}`}>
        <span>Assessment reviews {users && `(${totalReviews})`}</span>
        <span>Date last assessment</span>
        <span>Type</span>
        <span>Status</span>
      </h4>
      {users?.map((user, i) => (
        <Link
          key={i}
          to={`${prefix}${user.tid}/${user.mid}`}
          className={`${cls.row} ${cls.reviewsRow}`}
        >
          <span className={cls.reviewRowCol}>{user.name}</span>
          <span className={cls.reviewRowCol}>
            {moment(user.lastAssessment).format('LL')}
          </span>
          <span className={cls.reviewRowCol}>
            <span className="capitalize">{user.bodypart}</span>
          </span>
          <span className={cls.reviewRowCol}>
            <Pill pillType={user.validated ? `validated` : `review`}>
              {user.validated ? 'validated' : 'review'}
            </Pill>
          </span>
        </Link>
      ))}
      {smallView && (
        <Link
          to={`/${pid}/assess/review/`}
          className={`${cls.row} ${cls.reviewsRow} ${cls.hyperlink}`}
        >
          all users
        </Link>
      )}
    </div>
  )
}

export default ReviewAssessmentBlock
