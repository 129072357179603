import { useMemo } from 'react'
import {
  deleteJSON,
  getJSON,
  patchJSON,
  postJSON,
  putJSON,
} from '@bothrs/util/fetch'
import { serialize } from '@bothrs/util/url'

import { useToken } from './auth'
import { useApi, usePid } from './project'

export function useFetch(ctx?: any) {
  const pid = usePid()
  const api = useApi()
  const auth = useToken()

  const join = (url: string) =>
    api + url + (url.includes('?') ? '&' : '?') + serialize({ pid, ...ctx })

  return useMemo(
    () => ({
      postJSON: (url: string, data: any, options?: any) =>
        postJSON(join(url), data, { auth, ...options }),
      getJSON: (url: string) => getJSON(join(url), { auth }),
      putJSON: (url: string, data: any) => putJSON(join(url), data, { auth }),
      patchJSON: (url: string, data: any) =>
        patchJSON(join(url), data, { auth }),
      deleteJSON: (url: string) => deleteJSON(join(url), { auth }),
      fetcher: async (url: string) => {
        const response = await fetch(join(url), {
          headers: {
            accept: 'application/json',
            authorization: 'Bearer ' + auth,
          },
        })
        const body = await response.json().catch(() => ({}))
        if (body.error) {
          throw new Error(body.error.message)
        }
        if (response.status >= 400) {
          throw new Error('Response status ' + response.status)
        }
        return body
      },
    }),
    [serialize({ pid, ...ctx }), api, auth]
  )
}
