import { Auth0Provider } from '@auth0/auth0-react'
import { useProjectContext } from '@healthblocks-io/core/project'
import React, { ReactNode } from 'react'

export function HBAuth0Provider({ children }: { children: ReactNode }) {
  const { pid, data, error } = useProjectContext()

  const config = data?.config.auth0?.dashboard || data?.config.auth0

  if (error) {
    return <pre>{JSON.stringify({ error, message: error.message })}</pre>
  }

  if (!config?.domain) {
    if (data)
      window.Sentry?.captureMessage('Unexpected auth0 config', {
        extra: { data },
      })
    return null
  }

  return (
    <Auth0Provider
      domain={config.domain}
      clientId={config.clientId}
      redirectUri={window.location.origin + '/' + pid}
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  )
}
