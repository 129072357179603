import React from 'react'
import { Link } from 'react-router-dom'

import { usePid } from '@healthblocks-io/core/project'

interface Crumb {
  title: string
  href: string
}

export default function Breadcrumb({ items }: { items: Crumb[] }) {
  return (
    <div className="d-flex flex-row" style={{ marginLeft: '-.5rem' }}>
      <BreadcrumbItem item={{ title: 'Home', href: '/' }} />
      {items.map((item, key) => (
        <BreadcrumbItem key={key} item={item} />
      ))}
    </div>
  )
}

export function BreadcrumbItem({ item }: { item: Crumb }) {
  const pid = usePid()
  return (
    <div>
      <Link to={'/' + pid + item.href} className="px-2">
        {/* eslint-disable jsx-a11y/anchor-is-valid */}
        <a>{item.title}</a>
      </Link>
      &gt;
    </div>
  )
}
