import React from 'react'

import { useDistinctUsers, useEvent } from 'lib/reporting'

import cls from '../Dashboard.module.scss'

import Card from '../atoms/Card'

export default function Numbers({ basis, title }) {
  // Active users
  const active = useDistinctUsers({ [basis]: 0, period: 30 })
  const fromLast =
    basis === 'week'
      ? 'from the last 7 days'
      : basis === 'month'
      ? 'from the last month'
      : 'from yesterday'

  // Compare with monthly users
  const dailyActive = useDistinctUsers({ day: -1 })
  const monthlyActive = useDistinctUsers({ month: 0 })
  const mauRatio = monthlyActive
    ? Math.round((dailyActive / monthlyActive) * 1000) / 10 + '%'
    : '-'

  // Retention: E-R/S
  const endTotal = useDistinctUsers({ [basis]: 0 })
  const registrations = useEvent('User Created', { [basis]: -1 })
  const startTotal = useDistinctUsers({ [basis]: -1 })
  const retention = startTotal
    ? Math.round(((endTotal - registrations) / startTotal) * 100) + '%'
    : '-'

  return (
    <div>
      <h3 className="title">{title || 'Numbers'}</h3>
      <div className="row">
        <div className="col-md-4 mb-4">
          <Card className={cls.darkCard}>
            <h4 className="subTitle">Active users</h4>
            <span className={cls.value}>{active}</span>
            <span className={cls.infoText}>{fromLast}</span>
          </Card>
        </div>
        <div className="col-md-4 mb-4">
          <Card className={cls.darkCard}>
            <h4 className="subTitle">DAU/MAU ratio</h4>
            <span className={cls.value}>{mauRatio}</span>
            <span className={cls.infoText}>from the last 7 days</span>
          </Card>
        </div>
        <div className="col-md-4 mb-4">
          <Card className={cls.darkCard}>
            <h4 className="subTitle">Retention rate</h4>
            <span className={cls.value}>{retention}</span>
            <span className={cls.infoText}>{fromLast}</span>
          </Card>
        </div>
      </div>
    </div>
  )
}
