import React from 'react'
import format from 'date-fns-tz/format'
import utcToZonedTime from 'date-fns-tz/utcToZonedTime'

export default function Medication({ medication: med, user }) {
  return (
    <p className="mb-2">
      <b>{med.name}</b>
      {med.moments?.map((moment, key) => (
        <div key={key}>
          {moment.name}: {moment.time} ({triggerAt(moment.time, user.timezone)})
        </div>
      ))}
      <small style={{ display: 'block' }}>
        {med.type} - {med.period}
      </small>
    </p>
  )
}

function triggerAt(time, timezone) {
  const d = new Date()
  d.setHours(parseFloat(time.slice(0, 2)), parseFloat(time.slice(3, 5)), 0, 0)

  return format(utcToZonedTime(d, timezone), 'HH:mm zzz', {
    timeZone: 'Etc/UTC',
  })
}
