import React, { useState } from 'react'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { Dropdown, DropdownItem } from '@duik/dropdown'

import { gql, useApolloClient, useSubscription } from '@healthblocks-io/apollo'
import { useTrack } from '@healthblocks-io/core/analytics'
import { usePid } from '@healthblocks-io/core/project'
import { DashboardConfig } from '@healthblocks-io/core/types'

import { useLocalStorage } from '@utils'
import Button from 'pages/atoms/Button'

import cls from './Dashboard.module.scss'

import AssessmentsReview from './dashboard/AssessmentsReview'
import Numbers from './dashboard/Numbers'
import Performance from './dashboard/Performance'
import SuggestionsClicks from './dashboard/SuggestionsClicks'
import HealthblocksTopBar from './modules/HealthblocksTopBar'

const blocks = {
  AssessmentsReview,
  Numbers,
  Performance,
  SuggestionsClicks,
}
const blockIds = Object.keys(blocks)

export default function Dashboard() {
  // List of dashboard blocks
  const pid = usePid()
  const { data, loading, error } = useSubscription(
    gql`
      subscription ProjectDashboard($pid: String!) {
        projects_by_pk(pid: $pid) {
          pid
          dashboard
        }
      }
    `,
    { variables: { pid } }
  )

  if (data?.projects_by_pk?.dashboard) {
    return <DashboardBlocks dashboard={data.projects_by_pk.dashboard} />
  }

  if (loading) {
    return (
      <div>
        <HealthblocksTopBar>Dashboard</HealthblocksTopBar>
        <div>loading</div>
      </div>
    )
  }

  return (
    <div>
      <HealthblocksTopBar>Dashboard</HealthblocksTopBar>
      <div>{JSON.stringify({ data, error, loading })}</div>
    </div>
  )
}

function DashboardBlocks({ dashboard }: { dashboard: DashboardConfig }) {
  // General scope
  const [basis, setBasis] = useLocalStorage('reportingBasis', 'month')

  useTrack('Dashboard Opened')

  return (
    <div>
      <HealthblocksTopBar>Dashboard</HealthblocksTopBar>

      <section className="scroll-area-main">
        <div style={{ float: 'right', clear: 'both' }}>
          <Dropdown className={cls.dropdown} buttonText={basis}>
            {['day', 'week', 'month'].map(drop => {
              return (
                <DropdownItem key={drop} onClick={() => setBasis(drop)}>
                  {drop}
                </DropdownItem>
              )
            })}
          </Dropdown>
        </div>
        <div
          style={{
            minHeight: 'calc(100vh - 70px - 25px)',
            boxSizing: 'border-box',
          }}
        >
          {dashboard.blocks
            .map((block, key) => {
              const Block = blocks[block.id]
              return (
                Block && <Block key={key} basis={basis} title={block.title} />
              )
            })
            .filter(Boolean)}
        </div>
        <div>
          <h3 className="title">Configure dashboard</h3>

          <BlockConfiguration dashboard={dashboard} />
        </div>
      </section>
    </div>
  )
}

const SortableItem = SortableElement(({ block, remove }) => (
  <div className={cls.draggable}>
    {block.id}
    <Button inline type="button" subtle onClick={() => remove(block)}>
      Remove
    </Button>
  </div>
))

const MySortableContainer = SortableContainer(({ children }) => {
  return <div>{children}</div>
})

function BlockConfiguration({ dashboard }) {
  const pid = usePid()
  const client = useApolloClient()
  const [id, setId] = useLocalStorage('dashboardAddWidget', blockIds[0])
  const [temp, setTemp] = useState()
  const add = evt => {
    evt.preventDefault()
    updateDashboard(client, pid, setTemp, dashboard, {
      blocks: dashboard.blocks.concat({
        id,
        title: id,
        key: Math.random(),
      }),
    })
  }
  const remove = block => {
    updateDashboard(client, pid, setTemp, dashboard, {
      blocks: dashboard.blocks.filter(b =>
        block.key ? b.key !== block.key : b.id !== block.id
      ),
    })
  }
  const onSortEnd = ({ oldIndex, newIndex }) => {
    updateDashboard(client, pid, setTemp, dashboard, {
      blocks: arrayMove(dashboard.blocks, oldIndex, newIndex),
    })
  }

  // Assign unique ids to all blocks if not there yet
  dashboard.blocks.forEach((b, key) => {
    if (!b.key) b.key = Math.random()
  })

  //
  const ids = dashboard.blocks.map(b => b.id)
  const present = blockIds.filter(id => ids.includes(id))
  const absent = blockIds.filter(id => !ids.includes(id))

  return (
    <div>
      <MySortableContainer onSortEnd={onSortEnd}>
        {(temp || dashboard).blocks.map((block, index) => (
          <SortableItem
            key={`item-${block.key}`}
            index={index}
            block={block}
            remove={remove}
          />
        ))}
      </MySortableContainer>
      <form onSubmit={add} className={cls.draggable}>
        <select value={id} onChange={evt => setId(evt.target.value)}>
          <optgroup label="Unused:">
            {absent.map(name => (
              <option key={name}>{name}</option>
            ))}
          </optgroup>
          <optgroup label="Already used:">
            {present.map(name => (
              <option key={name}>{name}</option>
            ))}
          </optgroup>
        </select>
        <Button inline secondary type="submit">
          Add
        </Button>
      </form>
    </div>
  )
}

function updateDashboard(client: any, pid: string, setTemp, existing, updates) {
  const dashboard = {
    ...existing,
    ...updates,
  }
  // Update UI
  setTemp(dashboard)

  // Update backend
  client.mutate({
    mutation: gql`
      mutation UpdateDashboard($dashboard: jsonb!, $pid: String!) {
        update_projects_by_pk(
          pk_columns: { pid: $pid }
          _append: { dashboard: $dashboard }
        ) {
          pid
          dashboard
        }
      }
    `,
    variables: {
      pid,
      dashboard,
    },
    update() {
      clearTimeout(window.updateDashboardTimeout)
      window.updateDashboardTimeout = setTimeout(() => setTemp(null), 2000)
    },
  })
}

// array-move

function arrayMoveMutate(array, from, to) {
  const startIndex = from < 0 ? array.length + from : from

  if (startIndex >= 0 && startIndex < array.length) {
    const endIndex = to < 0 ? array.length + to : to

    const [item] = array.splice(from, 1)
    array.splice(endIndex, 0, item)
  }
}

function arrayMove(array, from, to) {
  array = [...array]
  arrayMoveMutate(array, from, to)
  return array
}
