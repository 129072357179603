import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { DropdownMenuPosition } from '@duik/dropdown'
import { Select } from '@duik/select'
import HealthblocksTopBar from '../modules/HealthblocksTopBar'
import Input from 'pages/atoms/Input'
import Button from 'pages/atoms/Button'

import { useApolloClient } from '@healthblocks-io/apollo'
import { useFetch } from '@healthblocks-io/core/fetch'
import { usePid } from '@healthblocks-io/core/project'

import { invite, sendInviteEmail } from '../../lib/project'

const Invite = ({ history }) => {
  const pid = usePid()
  const client = useApolloClient()
  const { postJSON } = useFetch()
  const [members, setMembers] = useState([])
  const [email, setEmail] = useState('')

  return (
    <div>
      <HealthblocksTopBar>Invite team members</HealthblocksTopBar>
      <form
        onSubmit={e => {
          e.preventDefault()
          if (members.find(m => m.email === email)) {
            return
          }
          setEmail('')
          setMembers(members.concat({ email, role: 'moderator' }))
        }}
        className="scroll-area-main"
      >
        <h1>Invite new teammates</h1>
        <p>You can invite multiple teammates.</p>
        <div style={{ position: 'relative' }}>
          <Input
            label="Enter your teammates’ email address"
            placeholder="me@healthblocks.io"
            type="email"
            id="email"
            name="email"
            value={email}
            required
            onChange={e => setEmail(e.target.value)}
          />
          <div style={{ position: 'absolute', bottom: -16, right: 0 }}>
            <Button
              type="submit"
              primary
              inline
              style={{ borderBottomLeftRadius: 0, borderTopLeftRadius: 0 }}
            >
              Add
            </Button>
          </div>
        </div>
        {members.length ? (
          <table className="tbl">
            <thead>
              <tr>
                <th>Email</th>
                <th>Role</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {members.map(member => (
                <Member
                  key={member.email}
                  member={member}
                  setRole={role => {
                    member.role = role
                    setMembers(members.slice())
                  }}
                  onRemove={() => setMembers(members.filter(m => m !== member))}
                />
              ))}
            </tbody>
          </table>
        ) : null}

        {members.length ? (
          <p style={{ marginTop: 16 }}>
            <Button
              type="button"
              primary={!!members.length}
              inline
              onClick={() => {
                members.forEach(({ email, role }) =>
                  invite(client, pid, email, role)
                )
                members.forEach(({ email, role }) =>
                  sendInviteEmail(postJSON, email, role)
                )

                history.push('access')
              }}
            >
              Send invite{members.length > 1 ? 's' : ''}
            </Button>
            &nbsp;
            <Link to="access">
              <Button type="reset" secondary inline>
                Cancel
              </Button>
            </Link>
          </p>
        ) : null}
      </form>
    </div>
  )
}

const Member = ({ member, setRole, onRemove }) => {
  return (
    <tr>
      <td>{member.email}</td>
      <td>
        <Select
          activeOption={member.role}
          placeholder={member.role}
          options={['moderator', 'owner'].map(value => ({
            value,
            label: value,
          }))}
          onOptionClick={opt => setRole(opt.value)}
          menuPosition={DropdownMenuPosition['bottom-right']}
        />
      </td>
      <td>
        <Button
          type="button"
          onClick={onRemove}
          secondary
          inline
          style={{ margin: 0 }}
        >
          Remove
        </Button>
      </td>
    </tr>
  )
}

export default Invite
