import React, { useContext, useState } from 'react'
import Modal from '@duik/modal'

import { useAnalytics, useTrack } from '@healthblocks-io/core/analytics'
import { useCareTeam } from '@healthblocks-io/core/careteam'
import {
  Bundle,
  BundleLoader,
  useBundle,
  useFHIR,
} from '@healthblocks-io/core/fhir'
import { SubjectContext, useSubject } from '@healthblocks-io/core/subject'

import { CareTeam, emptyParticipant, Participant } from '../../lib/careteam'
import { useTranslation } from '../../lib/i18n'
import Button from '../atoms/Button'
import EmptyState from '../atoms/EmptyState'
import TableButton from '../atoms/TableButton'
import CareTeamParticipantEditor from './CareTeamParticipantEditor'

export default function CareTeams() {
  const subject = useSubject()
  return (
    <BundleLoader type="CareTeam" params={{ subject: subject.uid }}>
      <CareTeamsList />
    </BundleLoader>
  )
}

export function CareTeamsList() {
  const subject = useSubject()
  const { loading, error } = useContext(Bundle)
  const teams = useBundle('CareTeam') as unknown as CareTeam[]

  useTrack('Care Teams Opened', subject.track)

  if (error) {
    return <pre>error? {JSON.stringify(error, null, 2)}</pre>
  }
  if (loading) {
    return <div />
  }
  if (!teams.length && !loading) {
    return <Empty subject={subject} />
  }

  if (!teams.find(c => c.participant.length) && !loading) {
    return <Empty subject={subject} careteam={teams[0]} />
  }

  if (teams.length > 0) {
    return (
      <>
        {teams.map(c => (
          <CareTeamView key={c.id} careteam={c} multiple={teams.length > 1} />
        ))}
      </>
    )
  }

  return <pre>{JSON.stringify(teams, null, 2)}</pre>
}

function Empty({
  subject,
  careteam,
}: {
  subject: SubjectContext
  careteam?: CareTeam
}) {
  const { t } = useTranslation()
  const [editing, setEditing] = useState<Participant | null>(null)
  const { create, patch } = useFHIR()
  return (
    <EmptyState>
      <h1>{t('Care team')}</h1>
      <p>{t('Care team p')}</p>
      <Button
        onClick={async () => {
          setEditing(emptyParticipant({}))
          // await postJSON(
          //   app().api + '/api/fhir/CareTeam',
          //   { subject_id: subject },
          //   { auth: await token() }
          // )
          // refetch()
        }}
        inline
      >
        {t('Add a first member')}
      </Button>
      <Modal
        className="modal-top"
        isOpen={!!editing}
        handleClose={() => setEditing(null)}
        closeOnOuterClick
        sm
      >
        <CareTeamParticipantEditor
          participant={editing}
          save={async p => {
            if (!careteam) {
              await create({
                resourceType: 'CareTeam',
                // @ts-ignore This is already solved in backend actually
                subject_id: subject.uid,
                subject: subject.reference,
                participant: [p],
              })
            } else {
              await patch({ resourceType: 'CareTeam', id: careteam.id }, [
                {
                  op: 'add',
                  path: '/participant',
                  value: {
                    careteam_id: careteam.id,
                    uid: p.user?.uid || p.uid,
                    ...p,
                  },
                },
              ])
            }
            setEditing(null)
          }}
          remove={console.log}
        />
      </Modal>
    </EmptyState>
  )
}

export function CareTeamView({
  careteam,
  multiple = false,
}: {
  careteam: CareTeam
  multiple?: boolean
}) {
  const { t } = useTranslation()
  const [editing, setEditing] = useState<Participant | null>(null)
  const { patch, remove } = useFHIR()
  const { track } = useAnalytics()

  const care = useCareTeam()
  const allowDelete = care.can({ action: 'delete', resource: 'fhir:CareTeam' })
  const allowUpdate = care.can({ action: 'update', resource: 'fhir:CareTeam' })

  const removeParticipant = async (p: Participant) => {
    if (
      !window.confirm(
        t('Do you want to remove [name] from this careteam?', {
          name: p.user?.name || p.user_inline?.name || 'this user',
        })
      )
    ) {
      return
    }
    await patch({ resourceType: 'CareTeam', id: careteam.id }, [
      { op: 'remove', path: '/participant/' + p.id },
    ])
    setEditing(null)
    track('Care Team Participant Removed')
  }

  return (
    <div>
      <h2>{careteam.name || 'Members'}</h2>
      <p style={{ maxWidth: 600 }}>{t('Care team p')}</p>
      <table className="tbl tbl--wide">
        <thead>
          <tr>
            <th />
            <th>{t('Name')}</th>
            <th className="td-1 text-nowrap">{t('Contact')}</th>
            <th className="td-1">{t('Care team role')}</th>
            <th>{t('Permissions')}</th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          {careteam.participant.map((p, key) => (
            <tr key={key} onClick={() => allowUpdate && setEditing(p)}>
              <td
                className="td-1 text-nowrap"
                style={{ padding: 0, paddingLeft: 16 }}
              >
                <div
                  style={{
                    width: 38,
                    height: 38,
                    backgroundColor: '#ddd',
                    borderRadius: 38,
                  }}
                />
              </td>

              <td className="td-1 text-nowrap">
                <b>{p.user?.name || p.user_inline?.name || '?'}</b>
              </td>
              <td
                className="td-1 text-nowrap"
                onClick={evt => evt.stopPropagation()}
              >
                {p.user?.email || p.user_inline?.email ? (
                  <div>
                    {t('Email')}:{' '}
                    <a
                      href={'mailto:' + (p.user?.email || p.user_inline?.email)}
                    >
                      {p.user?.email || p.user_inline?.email}
                    </a>
                  </div>
                ) : null}
                {p.user?.phone || p.user_inline?.phone ? (
                  <div>
                    {t('Phone')}:{' '}
                    <a href={'tel:' + (p.user?.phone || p.user_inline?.phone)}>
                      {p.user?.phone || p.user_inline?.phone}
                    </a>
                  </div>
                ) : null}
                {p.user?.doc?.organisation || p.user_inline?.organisation ? (
                  <div>
                    {t('Organisation')}:{' '}
                    {p.user?.doc?.organisation || p.user_inline?.organisation}
                  </div>
                ) : null}
              </td>
              <td className="td-1 text-nowrap">{t(p.role_title) || '?'}</td>
              <td>
                {p.policies
                  .slice(0, p.policies.length > 3 ? 2 : 3)
                  .map(({ policy }) => t(policy.title))
                  .join(', ')}
                {p.policies.length > 3 && (
                  <b className="ml-2">
                    {t('and [count] more', {
                      count: p.policies.length - 2,
                    })}
                  </b>
                )}
              </td>
              {allowDelete && (
                <td
                  className="table-button-td"
                  onClick={evt => {
                    evt.stopPropagation()
                    removeParticipant(p)
                  }}
                >
                  <TableButton subtle>{t('remove')}</TableButton>
                </td>
              )}
              {allowUpdate && (
                <td className="table-button-td">
                  <TableButton primary>{t('edit')}</TableButton>
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>
      <p>
        <Button
          className="mt-3"
          onClick={() => setEditing(emptyParticipant({}))}
          inline
        >
          {t('Add participant')}
        </Button>

        {multiple && (
          <Button
            error
            className="mt-3 ml-3 mb-5"
            onClick={() => {
              remove({ resourceType: 'CareTeam', id: careteam.id })
              track('Care Team Removed')
            }}
            inline
          >
            {t('Remove care team')}
          </Button>
        )}
      </p>

      <Modal
        className="modal-top"
        isOpen={!!editing}
        handleClose={() => setEditing(null)}
        closeOnOuterClick
        sm
      >
        <CareTeamParticipantEditor
          participant={editing}
          save={async p => {
            if (!p.user?.uid && !p.user_inline?.name) {
              return alert(t('Select a user before saving!'))
            }
            await patch({ resourceType: 'CareTeam', id: careteam.id }, [
              {
                op: p.id ? 'replace' : 'add',
                path: '/participant',
                value: {
                  careteam_id: careteam.id,
                  uid: p.user?.uid,
                  ...p,
                },
              },
            ])
            setEditing(null)
            track('Care Team Participant ' + (p.id ? 'Updated' : 'Created'))
          }}
          remove={() => removeParticipant(editing)}
        />
      </Modal>
    </div>
  )
}
