import { createContext, useContext, useEffect, useRef } from 'react'
// initFocus?: (callback: () => void) => void | (() => void)

/** @deprecated To be reviewed */
export const Focus = createContext(focusListener)

/** @deprecated To be reviewed */
export function useFocusListener() {
  return useContext(Focus)
}

/** @deprecated Just log the raw events, analyze later */
export function focusListener(callback: () => void) {
  if (typeof document !== 'undefined') {
    document.addEventListener('visibilitychange', callback)
  }
  if (typeof window !== 'undefined') {
    window.addEventListener('focus', callback)
  }
  return () => {
    if (typeof document !== 'undefined') {
      document.removeEventListener('visibilitychange', callback)
    }
    if (typeof window !== 'undefined') {
      window.removeEventListener('focus', callback)
    }
  }
}

/** @deprecated Just log the raw events, analyze later */
export class FocusActivity {
  started = 0
  stopped = 0

  // navigator.sendBeacon('/log', analyticsData)
  config = { report: ms => console.log('Time spent', ms) }

  constructor(config?: { report: (ms: number) => void }) {
    this.started = Date.now()
    if (config) this.config = config

    this.change = this.change.bind(this)
    document.addEventListener('visibilitychange', this.change)
  }
  destroy() {
    document.removeEventListener('visibilitychange', this.change)
    this.report(this.stop())
  }
  change() {
    if (document.visibilityState === 'hidden') {
      this.report(this.stop())
    }
    if (document.visibilityState === 'visible') {
      this.start()
    }
  }
  start() {
    // Only start if not already started
    if (this.stopped > this.started) {
      this.started = Date.now()
    }
  }
  stop() {
    // Only stop if not already stopped
    if (this.started > this.stopped) {
      const now = Date.now()
      this.stopped = now
      return now - this.started
    }
    return 0
  }
  report(ms: number) {
    if (!ms) return
    if (this.config.report) {
      this.config.report(ms)
    }
  }
}

export function useFocusEffect(report: (ms: number) => void) {
  // Workaround to make sure reports are sent to latest callback
  const ref = useRef({ report })
  ref.current.report = report
  useEffect(() => {
    const activity = new FocusActivity(ref.current)
    return () => activity.destroy()
  }, [])
}
