import { useReducer } from 'react'
import addDays from 'date-fns/addDays'
import addHours from 'date-fns/addHours'
import addMinutes from 'date-fns/addMinutes'
import addMonths from 'date-fns/addMonths'
import addWeeks from 'date-fns/addWeeks'
import addYears from 'date-fns/addYears'
import differenceInDays from 'date-fns/differenceInDays'
import endOfDay from 'date-fns/endOfDay'
import format from 'date-fns/format'
import startOfDay from 'date-fns/startOfDay'

import { useConfig } from './project'
import { deepSet } from './set'
import type { Activity } from './types'

export function useActivityKindOptions() {
  return (
    useConfig<('Appointment' | 'Article' | 'Questionnaire' | 'Task')[]>(
      p => p.config.activityKindOptions
    ) || ['Appointment', 'Article', 'Questionnaire', 'Task']
  )
}

export function useActivityEditor(activity: Activity) {
  return useReducer(careplanReducer, activity, initialPatch)
}

function careplanReducer(prev: PatchState, action) {
  if (action.type === 'discard') {
    return initialPatch(null)
  }
  if (action.type === 'save') {
    return { ...prev, saving: true }
  }
  if (!action.id) {
    // console.warn('sure no id')
  }

  let { data } = prev
  for (const key in action) {
    if (key !== 'id' && key !== 'type') {
      data = deepSet(data, key, action[key])
    }
  }

  return { ...prev, data }
}

interface PatchState {
  saving: boolean
  data: Activity | null
}

function initialPatch(activity: PatchState['data']): PatchState {
  return {
    saving: false,
    data: activity,
  }
}

export interface DayChunk {
  key: string
  date: Date
  month?: string
  activities: Activity[]
}

export function groupByDayInterval(
  activities: Activity[],
  start: Date,
  end: Date
) {
  const istart = startOfDay(start).valueOf()
  const iend = endOfDay(end).valueOf()
  const diff = differenceInDays(iend, istart)
  if (diff < 1) {
    throw new Error('Invalid start and end date')
  }
  if (diff > 3650) {
    throw new Error('Max render distance is 10 years')
  }
  return range(diff)
    .map(
      i =>
        ({
          key: format(addDays(start, i), 'yyyy-MM-dd'),
          date: addDays(istart, i),
          activities: [],
        } as DayChunk)
    )
    .flatMap(insertMonth)
}

export function groupByDay(activities: Activity[]) {
  const days: DayChunk[] = []
  let last: DayChunk = { key: '', date: new Date(), activities: [] }
  for (const a of activities) {
    const date = new Date(a.planned_at)
    const key = format(date, 'yyyy-MM-dd')
    if (last.key === key) {
      last.activities.push(a)
    } else {
      // Fill up with empty days
      if (last.key) {
        let x = 1
        let fillDate = addDays(last.date, x)
        let fill = format(fillDate, 'yyyy-MM-dd')
        while (fill < key) {
          days.push({ key: fill, date: fillDate, activities: [] })

          x++
          fillDate = addDays(last.date, x)
          fill = format(fillDate, 'yyyy-MM-dd')
        }
      }
      last = { key, date, activities: [a] }
      days.push(last)
    }
  }
  return days.flatMap(insertMonth)
}

function insertMonth(d: DayChunk, i: number, a: DayChunk[]): DayChunk[] {
  if (d.date.getDate() === 1) {
    return [
      {
        month: format(d.date, 'LLLL'),
        key: d.key + '-mon',
        activities: [],
        date: d.date,
      },
      d,
    ]
  }

  return [d]
}

export function withRepetitions(
  activities: Activity[],
  until: Date,
  now?: Date
) {
  return activities
    .flatMap(a => withRepetitionsOne(a, until, now))
    .sort((a, b) =>
      a.planned_at ? a.planned_at.localeCompare(b.planned_at) : -1
    )
}

export function withRepetitionsOne(a: Activity, until: Date, now?: Date) {
  if (!a.planned_at || !a.doc.repeatPeriod || !a.doc.repeatPeriodUnit) {
    return [a]
  }
  const exdate = a.doc.exdate || []
  const out = exdate.includes(a.planned_at.slice(0, 10)) ? [] : [a]
  const duration =
    new Date(a.doc.end_at!).valueOf() - new Date(a.planned_at).valueOf()

  // Limits
  // TODO: is count total count incl/excl original?
  let count = a.doc.repeatCount || Infinity

  // Fill in between time with due assessments
  let cloneStart = addTime(
    new Date(a.planned_at),
    a.doc.repeatPeriod,
    a.doc.repeatPeriodUnit
  )
  while (cloneStart < until && count > 1) {
    const added = {
      ...clone(a),
      parent_id: a.id,
      planned_at: cloneStart.toJSON(),
    }
    if (now && a.reminder_at) {
      added.reminder_at = getNextReminder(added, now)
    }
    if (added.doc.end_at) {
      added.doc.end_at = new Date(cloneStart.valueOf() + duration).toJSON()
    }
    if (!exdate.includes(added.planned_at.slice(0, 10))) {
      out.push(added)
    }

    // Update limits
    count--

    // Prep next clone
    cloneStart = addTime(cloneStart, a.doc.repeatPeriod, a.doc.repeatPeriodUnit)
  }

  return out
}

function clone(a: Activity) {
  const cloned = JSON.parse(JSON.stringify(a))
  return cloned
}

function addTime(date: Date, value: number, unit: string) {
  if (unit.startsWith('min')) {
    return addMinutes(date, value)
  }
  if (unit.startsWith('h')) {
    return addHours(date, value)
  }
  if (unit.startsWith('d')) {
    return agnostic(addDays, date, value)
  }
  if (unit.startsWith('w')) {
    return agnostic(addWeeks, date, value)
  }
  if (unit.startsWith('mo')) {
    return agnostic(addMonths, date, value)
  }
  if (unit.startsWith('md')) {
    return agnostic(addMonths, date, value)
  }
  if (unit !== 'a') {
    console.warn('unexpected addTime unit', unit)
  }
  return addYears(date, value)
}

function agnostic(f: (a: Date, b: number) => Date, date: Date, value: number) {
  const original = date.getTimezoneOffset()
  const output = f(date, value)
  const final = output.getTimezoneOffset()
  const diff = original - final
  return diff ? addMinutes(output, diff) : output
}

function range(count: number) {
  return Array(count)
    .fill(1)
    .map((_, i) => i)
}

export function getNextReminder(activity: Activity, at: Date) {
  if (!Array.isArray(activity.doc.reminders)) {
    return null
  }
  const planned = activity.planned_at
  if (!planned) {
    return null
  }

  // How far do reminders go back in time?
  // This allows to minimize how far we have to calculate repetitions
  const backInTime = Math.max(
    ...activity.doc.reminders.map(
      r =>
        new Date(planned).valueOf() -
        addTime(new Date(planned), r.offset, r.offsetUnit).valueOf()
    )
  )

  // Map out all reminders
  const x = withRepetitionsOne(
    activity,
    new Date(at.valueOf() + backInTime + 36e5 * 24)
  )
  const all = x
    .map(a =>
      a.doc
        .reminders!.map(r =>
          addTime(new Date(a.planned_at!), r.offset, r.offsetUnit).valueOf()
        )
        .filter(r => r > at.valueOf())
    )
    .flat()

  if (!all.length) {
    return null
  }

  const min = Math.min(...all)
  return new Date(min)
}

// Patient calendar

export function renderDays(activities: Activity[], start: Date, end?: Date) {
  if (!end) end = addDays(start, 30)
  const now = Date.now()
  activities = activities.map(a =>
    a.doc.title
      ? a
      : // Set missing activity title
        deepSet(
          a,
          'doc.title',
          a.questionnaire_response?.questionnaire?.title ||
            a.questionnaire?.title ||
            ''
        )
  )

  const groups = groupByDay(withRepetitions(activities, end))
  const daysRendered = groupByDayInterval([], start, end)

  daysRendered.forEach(day => {
    const group = groups.find(g => g.key === day.key)?.activities
    if (group) {
      day.activities = group.map(a => {
        // Mark past appointments as completed
        if (a.kind === 'Appointment' && Date.parse(a.planned_at) < now)
          return { ...a, status: 'completed' }

        // withRepetitions may have planned activities in the past that are due
        // so let's mark them as due (not-started)
        if (
          a.status === 'scheduled' &&
          endOfDay(smartEnd(a)).valueOf() < Date.now()
        )
          return { ...a, status: 'not-started' }

        return a
      })
    }
  })

  return daysRendered
}

function smartEnd(a: Activity) {
  return new Date(
    !a.doc.end_at || a.doc.end_at < a.planned_at ? a.planned_at : a.doc.end_at
  )
}
