import { gql, useQuery } from '@healthblocks-io/apollo'

import { usePid } from '@healthblocks-io/core/project'

export default function useBlocks(): string[] {
  const pid = usePid()
  const { data } = useQuery(
    gql`
      query BlocksQuery($pid: String!) {
        projects_by_pk(pid: $pid) {
          pid
          blocks
        }
      }
    `,
    { variables: { pid }, fetchPolicy: 'cache-first' }
  )
  return data?.projects_by_pk?.blocks || []
}
