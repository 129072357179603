import { useEffect, useState } from 'react'
import { getJSON } from '@bothrs/util/fetch'
import { serialize } from '@bothrs/util/url'

import type { AirtableRecord } from './types'
import { useApi, usePid } from './project'

const cache = {} as any
const fetching = {} as any
const fallback = []
// @ts-ignore
fallback.loading = true

export function usePrefetch(table = 'Config', timeout = 2000) {
  const api = useApi()
  const pid = usePid()
  useEffect(() => {
    const t = setTimeout(() => {
      optimisticFetchContent(api, pid, table)
    }, timeout)
    return () => clearTimeout(t)
  }, [pid, table])
}

export function useContent<T = AirtableRecord>(table = 'Config'): T[] {
  const api = useApi()
  const pid = usePid()
  const key = [pid, table].join()
  const [content, setContent] = useState<T[]>(cache[key] || fallback)
  useEffect(() => {
    let mounted = true
    const set = () => mounted && cache[key] && setContent(cache[key])
    if (cache[key]) {
      console.log('core/content cached', key)
      return set()
    }
    if (fetching[key]) {
      console.log('core/content ? just checking if this ever happens')
      fetching[key].then(set)
      return
    }
    fetching[key] = getJSON(
      api + '/api/projects/' + pid + '/tables/' + table
    ).then(data => {
      if (Array.isArray(data)) {
        cache[key] = data
        set()
      } else {
        console.warn('core/content Unexpected content', data)
      }
    })
    return () => {
      mounted = false
    }
  }, [])
  return content
}

// Consider cached or in-flight fetches
async function optimisticFetchContent(api: string, pid: string, table: string) {
  const key = [pid, table].join()
  return (
    cache[key] ||
    fetching[key] ||
    (fetching[key] = fetchContent(api, pid, table).then(data => {
      if (data) cache[key] = data
      return data
    }))
  )
}

// Simply fetch content
async function fetchContent(api: string, pid: string, table: string) {
  return getJSON(api + '/api/projects/' + pid + '/tables/' + table).then(
    data => {
      if (Array.isArray(data)) {
        return data
      } else {
        console.warn('core/content Unexpected content', data)
      }
    }
  )
}

interface Questionnaire {
  id: string
  name: string
  title: string
  description: string
  date: string
  status: 'draft' | 'active' | 'retired' | 'unknown'
  url: string
  questions: Question[]
}

interface Question {
  id: string
  linkId?: string
  text: string
  type: string
  config: any
}

/** Fetch all active questionnaires anonymously */
export function useQuestionnaires() {
  return useContent<Questionnaire>('questionnaires')
}

/** Fetch questionnaire by name anonymously */
export function useQuestionnaireByName(name: string) {
  return useContent<Questionnaire>('questionnaires?' + serialize({ name }))
}
