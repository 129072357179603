import { useReducer, Reducer, useCallback, useMemo, Dispatch } from 'react'
import { timeout } from '@bothrs/util/async'

import { gql, useApolloClient, useQuery } from '@healthblocks-io/apollo'
import { usePid, useProjectContext } from '@healthblocks-io/core/project'
import type {
  DashboardConfig,
  PublicProject,
} from '@healthblocks-io/core/types'

function createEditor(initial?: any) {
  return {
    project: {},
    config: {},
    saving: 0,
    version: 0,
    ref: { latest: 0 },
  }
}

export default function useProjectEditor() {
  const pid = usePid()
  const context = useProjectContext()
  const client = useApolloClient()
  const { data, refetch } = useQuery<{ projects_by_pk: AdminProject }>(
    AdminConfigQuery,
    { variables: { pid } }
  )

  const [editor, dispatch] = useReducer<
    Reducer<EditorState<AdminProject>, EditorAction<AdminProject>>
  >(projectEditorReducer, createEditor())

  const updateProject = useCallback(
    (project: Partial<AdminProject>) => dispatch({ type: 'project', project }),
    []
  )
  const updateConfig = useCallback(
    (config: Partial<AdminProject['config']>) =>
      dispatch({ type: 'config', config }),
    []
  )
  const save = useCallback(async () => {
    dispatch({ type: 'save', save: 1 })
    const variables = {
      pid,
      config: editor.config,
      _set: editor.project,
    }
    console.log('persist', variables)
    await client.mutate({
      mutation: UpdateConfigMutation,
      variables,
    })
    dispatch({ type: 'save', save: 2 })

    // Refresh state
    context.refetch({ maxAge: 0 })
    await refetch()
    await timeout(2000)
    dispatch({ type: 'discard' })
  }, [client, context, pid, editor, refetch])
  const project = useMemo(
    () =>
      data && {
        ...data.projects_by_pk,
        ...editor.project,
        config: { ...data.projects_by_pk.config, ...editor.config },
      },
    [data, editor.project, editor.config]
  )

  const dirtyKeys = useMemo(
    () =>
      Object.keys(editor.project).concat(
        Object.keys(editor.config).map(k => 'config.' + k)
      ),
    [editor]
  )
  return {
    project,
    save,
    saving: editor.saving,
    dirtyKeys,
    updateProject,
    updateConfig,
    dispatch,
  }
}

export function projectEditorReducer<T extends { config: any }>(
  prev: EditorState<T>,
  action: EditorAction<T>
) {
  if (action.type === 'discard') {
    return createEditor()
  }
  if (action.type === 'save') {
    return { ...prev, saving: action.save }
  }
  if (action.type === 'project') {
    let { project, config } = prev
    for (const key in action.project) {
      if (key.startsWith('config.')) {
        config = { ...config, [key.slice(7)]: action.project[key] }
      } else {
        project = { ...project, [key]: action.project[key] }
      }
    }
    return {
      ...prev,
      project,
      config,
      version: (prev.ref.latest = prev.version + 1),
    }
  }
  if (action.type === 'config') {
    return {
      ...prev,
      config: { ...prev.config, ...action.config },
      version: (prev.ref.latest = prev.version + 1),
    }
  }

  console.warn('Unexpected action type', action.type)
  return prev
}

export interface AdminProject {
  pid: string
  name: string
  plan: {}
  blocks: string[]
  config: PublicProject['config']
  auth0: PublicProject['auth0']
  dashboard: DashboardConfig
  shopify: {}
  secret_airtable: {}
  secret_auth0: {}
  secret_twilio: {}
  secret_google: {}
}

export interface EditorState<T extends { config: any }> {
  project: Partial<T>
  config: Partial<T['config']>
  saving: number
  /** Actual version */
  version: number
  /** This ref is mutable */
  ref: {
    /** Latest local version */
    latest: number
  }
}

export type EditorAction<T extends { config: any }> =
  | { type: 'discard' }
  | { type: 'save'; save: number }
  | { type: 'original'; original: T }
  | { type: 'project'; project: Partial<T> }
  | { type: 'config'; config: Partial<T['config']> }
  | { type: 'online'; online: number }

export interface ProjectEditor {
  project: AdminProject
  dispatch: Dispatch<EditorAction<AdminProject>>
  dirtyKeys: string[]
  saving: number
  save: () => void
}

const AdminConfigQuery = gql`
  query AdminConfigQuery($pid: String!) {
    projects_by_pk(pid: $pid) {
      pid
      name
      plan
      blocks
      config
      auth0
      dashboard
      shopify
      secret_airtable
      secret_auth0
      secret_twilio
      secret_google
    }
  }
`

const UpdateConfigMutation = gql`
  mutation UpdateConfigMutation(
    $pid: String!
    $config: jsonb = ""
    $_set: projects_set_input = {}
  ) {
    update_projects_by_pk(
      pk_columns: { pid: $pid }
      _append: { config: $config }
      _set: $_set
    ) {
      pid
    }
  }
`
