import React, { useMemo, useState } from 'react'
import AceEditor from 'react-ace'
import { Checkbox } from '@duik/checkbox'
import { Dropdown, DropdownButton, DropdownItem } from '@duik/dropdown'
import _get from 'lodash/get'

import 'ace-builds/src-min-noconflict/ext-searchbox'
import 'ace-builds/src-min-noconflict/mode-json'
import 'ace-builds/src-min-noconflict/theme-github'

import { useUser } from '@healthblocks-io/core/auth'
import { usePid } from '@healthblocks-io/core/project'

import Badge from '@components/Badge'
import { Block, blocks, ConfigItem, ConfigSection, sections } from 'lib/config'
import useProjectEditor, { ProjectEditor } from 'lib/useProjectEditor'

import Button from './atoms/Button'
import Input from './atoms/Input'
import Textarea from './atoms/Textarea'
import HealthblocksTopBar from './modules/HealthblocksTopBar'

export default function Admin() {
  const pid = usePid()
  return (
    <>
      <HealthblocksTopBar>Debug {pid}</HealthblocksTopBar>
      <div className="scroll-area-main">
        <AdminConfig />
      </div>
    </>
  )
}

export function AdminConfig() {
  const editor = useProjectEditor()

  if (!editor.project) {
    return null
  }

  return <AdminConfigActive editor={editor} />
}

export function AdminConfigActive({ editor }: { editor: ProjectEditor }) {
  const { email } = useUser()

  const visualKeys = useMemo(
    () =>
      sections
        .flatMap(s => Object.keys(s.properties))
        .map(p => p.replace('config.', '').split('.')[0])
        .concat(
          '__typename',
          'blocks',
          'pid',
          'plan',
          'config',
          'questions',
          'replies'
        ),
    []
  )
  const otherKeys = ([key, _]: [string, any]) =>
    !visualKeys.includes(key) && !visualKeys.includes('secret_' + key)

  return (
    <>
      {sections.map(section => (
        <SectionEditor key={section.title} section={section} editor={editor} />
      ))}
      <h2>All config</h2>
      <table className="tbl tbl--wide">
        <tbody>
          {Object.entries({ ...editor.project.config })
            .filter(otherKeys)
            .filter(([, value]) => value !== null)
            .sort(([a], [b]) => a.localeCompare(b))
            .map(([key, value]) => (
              <tr key={key}>
                <td width="120">
                  {key}{' '}
                  <button
                    style={{ border: 'none', background: 'none' }}
                    onClick={() =>
                      editor.dispatch({
                        type: 'config',
                        config: { [key]: null },
                      })
                    }
                    type="button"
                  >
                    &times;
                  </button>
                </td>
                <td id={'editor_' + key}>
                  <Editor
                    k={key}
                    value={value}
                    onChange={value =>
                      editor.dispatch({
                        type: 'config',
                        config: { [key]: value },
                      })
                    }
                  />
                </td>
              </tr>
            ))}
          {Object.entries({ ...editor.project, config: null })
            .filter(otherKeys)
            .map(([key, value]) => (
              <tr key={key}>
                <td width="120">{key}</td>
                <td id={'editor_' + key}>
                  <Editor
                    k={key}
                    value={value}
                    onChange={value =>
                      editor.dispatch({
                        type: 'project',
                        project: { [key]: value },
                      })
                    }
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <p>
        <Button
          inline
          onClick={() => {
            const key = prompt('Key?')
            if (key) {
              editor.dispatch({ type: 'config', config: { [key]: '' } })
              setTimeout(() => {
                document
                  .querySelector('#editor_' + key)
                  ?.scrollIntoView({ behavior: 'smooth' })
              }, 300)
            }
          }}
        >
          Add config key: string
        </Button>
        <Button
          inline
          onClick={() => {
            const key = prompt('Key?')
            if (key) {
              editor.dispatch({
                type: 'config',
                config: { [key]: { key: 'example' } },
              })
              setTimeout(() => {
                document
                  .querySelector('#editor_' + key)
                  ?.scrollIntoView({ behavior: 'smooth' })
              }, 300)
            }
          }}
        >
          Add config key: object
        </Button>
      </p>
      <p>&nbsp;</p>
      {email?.endsWith('@healthblocks.io') ? (
        <BlocksEditor editor={editor} />
      ) : null}
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      {editor.dirtyKeys.length || editor.saving ? (
        <div
          style={{
            position: 'absolute',
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
            bottom: 0,
            right: 8,
            padding: 24,
            paddingBottom: 8,
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
            background: 'white',
          }}
        >
          <b className="mb-3">Are you sure you want to change this?</b>
          {editor.dirtyKeys.map(p => (
            <div key={p}>{p}</div>
          ))}
          <Button
            className="mt-3 mb-0"
            onClick={editor.save}
            disabled={editor.saving}
            success={editor.saving === 2}
          >
            {editor.saving === 2 ? 'Saved successfully!' : 'Apply changes'}
          </Button>
          <Button
            className="mt-2 mb-1"
            subtle
            onClick={() => editor.dispatch({ type: 'discard' })}
            disabled={editor.saving}
          >
            Cancel
          </Button>
        </div>
      ) : null}
    </>
  )
}

function SectionEditor({
  section,
  editor: { project, dispatch },
}: {
  section: ConfigSection
  editor: ProjectEditor
}) {
  // Config section check
  if (section.blocks && !project.blocks.includes(section.blocks[0])) {
    return null
  }

  // Config item check
  const properties = Object.entries(section.properties).filter(
    ([, item]) => !item.blocks || project.blocks.includes(item.blocks[0])
  )
  if (!properties.length) return null

  return (
    <div className="mb-5">
      <h2>{section.title}</h2>
      {properties.map(([key, item]) => (
        <div key={key} className="mt-1 mb-3">
          <b>{item.title || key}</b>
          {item.type === 'boolean' && item.description ? null : (
            <div>{item.description}</div>
          )}
          <ItemEditor
            item={item}
            value={_get(project, key, '')}
            onChange={(value: any) =>
              dispatch({ type: 'project', project: { [key]: value } })
            }
          />
        </div>
      ))}
    </div>
  )
}

interface ItemEditorProps {
  item: ConfigItem
  value: any
  onChange: (d: any) => void
}

function ItemEditor({ item, value, onChange }: ItemEditorProps) {
  if (item.enum) {
    return (
      <Dropdown
        ButtonComponent={() => (
          // @ts-ignore
          <DropdownButton>{value || item.default}</DropdownButton>
        )}
      >
        {item.enum.map((value, index) => (
          <DropdownItem key={index} onClick={() => onChange(value)}>
            {value}
          </DropdownItem>
        ))}
      </Dropdown>
    )
  }
  if (item.type === 'string') {
    return (
      <Input
        className="mb-0"
        value={typeof value === 'object' ? JSON.stringify(value) : value}
        placeholder={item.placeholder || item.default}
        onChangeText={text => onChange(text)}
      />
    )
  }
  if (item.type === 'boolean') {
    return (
      <div className="mt-1">
        <Checkbox
          label={item.description || 'Yes'}
          checked={value}
          onChange={() => onChange(!value)}
        />
      </div>
    )
  }
  if (item.type === 'array' && item.items.enum) {
    // The selected items are displayed first, then the other options.
    const selected = Array.isArray(value) ? value : item.default || []
    return (
      <div className="mt-1">
        {selected.map(key => (
          <div className="d-inline-block mr-4 mb-2" key={key + 'incl'}>
            <Checkbox
              label={key}
              checked
              onChange={() => onChange(selected.filter(b => b !== key))}
            />
          </div>
        ))}
        <br />
        {item.items.enum
          .filter(key => !selected.includes(key))
          .map(key => (
            <div className="d-inline-block mr-4 mb-2" key={key + 'excl'}>
              <Checkbox
                label={key}
                checked={false}
                onChange={() => onChange(selected.concat(key))}
              />
            </div>
          ))}
      </div>
    )
  }
  return (
    <div>
      item editor {JSON.stringify(item)}
      {JSON.stringify(value)}
    </div>
  )
}

function BlocksEditor({ editor }: { editor: ProjectEditor }) {
  const selected = editor.project.blocks
  return (
    <>
      <h2>Blocks</h2>
      <p>Feature flags, restricted to Healthblocks team</p>
      <div>
        {blocks
          .filter(b => !b.stage)
          .map(block => (
            <BlockToggle
              key={block.id}
              block={block}
              selected={selected}
              onChange={blocks =>
                editor.dispatch({ type: 'project', project: { blocks } })
              }
            />
          ))}
      </div>
      <p>Unstable</p>
      <div>
        {blocks
          .filter(b => b.stage)
          .map(block => (
            <BlockToggle
              key={block.id}
              block={block}
              selected={selected}
              onChange={blocks =>
                editor.dispatch({ type: 'project', project: { blocks } })
              }
            />
          ))}
      </div>
    </>
  )
}

function BlockToggle({
  block,
  selected,
  onChange,
}: {
  block: Block
  selected: string[]
  onChange: (selection: string[]) => void
}) {
  return (
    <div
      className="d-inline-block mr-4 mb-2"
      style={{ width: 192 }}
      key={block.id}
    >
      <Checkbox
        label={
          block.stage ? (
            <span>
              {block.id + ' '}
              <Badge muted sm>
                {block.stage}
              </Badge>
            </span>
          ) : (
            block.id
          )
        }
        checked={selected.includes(block.id)}
        onChange={() =>
          onChange(
            selected.includes(block.id)
              ? selected.filter(b => b !== block.id)
              : selected.concat(block.id).sort((a, b) => a.localeCompare(b))
          )
        }
      />
    </div>
  )
}

function Editor({ k, value, onChange }) {
  const [show, setShow] = useState(false)
  if (typeof value === 'string') {
    if (
      k.startsWith('email') ||
      k.startsWith('template') ||
      value.includes('\n')
    ) {
      return <Textarea value={value} onChangeText={onChange} />
    }
    return <Input className="mb-0" value={value} onChangeText={onChange} />
  }
  const json = JSON.stringify(value, null, 2)
  if (!show && json.split('\n').length > 7) {
    return (
      <>
        <button onClick={() => setShow(true)} style={{ float: 'left' }}>
          show
        </button>
        <pre style={{ fontSize: 1, lineHeight: 1 }}>{json}</pre>
      </>
    )
  }

  return (
    <AceEditor
      mode="json"
      theme="github"
      name={'jsontoggler' + k}
      setOptions={{ useWorker: false }}
      minLines={1}
      maxLines={40}
      width="100%"
      value={json}
      showPrintMargin={false}
      onChange={json => {
        try {
          const value = JSON.parse(json)
          onChange(value)
        } catch (e) {}
      }}
      showGutter={false}
    />
  )
}
