import Users from '../pages/users'
import UserDetail from '../pages/UserDetail'
import Conversations from '../pages/conversations'
import Questionnaires from '../pages/Questionnaires'
import AssessmentsReview from '../pages/assessmentReview'
import QuestionnaireDetail from '../pages/QuestionnaireDetail'
import ActivityDetail from '../pages/activities/ActivityDetail'

import Acquire from '../pages/acquire'
import Dashboard from '../pages/Dashboard'
import Engage from '../pages/engage'
import Profile from '../pages/profile'

import GeneralSettings from '../pages/settings/general'
import AccessSettings from '../pages/settings/access'
import Policies from '../pages/settings/policies'
import Invite from '../pages/settings/invite'
import Health from '../pages/Health'
import GraphiQL from '../pages/GraphiQL'
import PlanDefinitions from 'pages/plandefinition/PlanDefinitions'

interface Route {
  path: string
  exact?: boolean
  user?: boolean
  owner?: boolean
  component: (props?: any) => JSX.Element
}

export const projectRoutes: Route[] = [
  { path: '/:pid/', exact: true, component: Dashboard },
  { path: '/:pid/profile', user: true, exact: true, component: Profile },
  {
    path: '/:pid/users/:uid/activities/:id',
    owner: true,
    component: ActivityDetail,
  },
  { path: '/:pid/users/:uid', user: true, owner: true, component: UserDetail },
  { path: '/:pid/users', user: true, owner: true, component: Users },
  { path: '/:pid/assess/conversations', exact: true, component: Conversations },
  {
    path: '/:pid/assess/conversations/:tid',
    exact: true,
    component: Conversations,
  },
  {
    path: '/:pid/assess/conversations/:tid/:messageId',
    exact: true,
    component: Conversations,
  },
  {
    path: '/:pid/assess/questionnaires',
    exact: true,
    component: Questionnaires,
  },
  {
    path: '/:pid/assess/questionnaires/:id/:tab',
    component: QuestionnaireDetail,
  },
  { path: '/:pid/assess/review', component: AssessmentsReview },
  { path: '/:pid/acquire', owner: true, component: Acquire },
  { path: '/:pid/engage/suggestions', owner: true, component: Engage },
  { path: '/:pid/settings/general', owner: true, component: GeneralSettings },
  { path: '/:pid/settings/access', owner: true, component: AccessSettings },
  { path: '/:pid/settings/invite', owner: true, component: Invite },
  { path: '/:pid/health', exact: true, component: Health },
  { path: '/:pid/graphiql', exact: true, component: GraphiQL },

  // Shorter routes
  { path: '/:pid/conversations/:tid/:messageId', component: Conversations },
  { path: '/:pid/conversations/:tid', component: Conversations },
  { path: '/:pid/conversations', component: Conversations },
  { path: '/:pid/questionnaires/:id/:tab', component: QuestionnaireDetail },
  { path: '/:pid/questionnaires', component: Questionnaires },
  { path: '/:pid/plandefinitions/:name', component: PlanDefinitions },
  { path: '/:pid/plandefinitions', component: PlanDefinitions },
  { path: '/:pid/suggestions', owner: true, component: Engage },
  { path: '/:pid/general', owner: true, component: GeneralSettings },
  { path: '/:pid/access', owner: true, component: AccessSettings },
  { path: '/:pid/policies', owner: true, component: Policies },
  { path: '/:pid/invite', owner: true, component: Invite },
  { path: '/:pid/reviews', component: AssessmentsReview },
]
