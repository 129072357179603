import React, { createContext, ReactNode, useContext } from 'react'
import { Reference, Profile } from './types'

export interface SubjectContext {
  uid: string
  reference: Reference
  profile: Profile
  track: { subjectId: string; subjectName?: string }
}

export const Subject = createContext<SubjectContext>(
  // @ts-ignore
  null
)

export function useSubject() {
  return useContext(Subject)
}

export function SubjectProvider({
  children,
  uid,
  profile,
}: {
  children?: ReactNode
  uid: string
  profile: Profile
}) {
  if (!profile) {
    return null
  }

  return (
    <Subject.Provider
      value={{
        uid,
        reference: {
          type: 'Patient',
          reference: 'Patient/' + uid,
          display: profile.name,
        },
        profile,
        track: {
          subjectId: uid,
          subjectName: profile.name,
        },
      }}
      children={children}
    />
  )
}
