import { useConfig } from './project'
import { CodeableConcept } from './types'

const categories = [{ coding: [{ code: 'example' }], text: 'Example' }]

export function useCommunicationCategories(): CodeableConcept[] {
  return (
    useConfig<CodeableConcept[]>(p => p.config.communicationCategories) ||
    categories
  )
}

export interface CodeableConceptColor {
  text: string
  color?: string
  background: string
}

const fallback = [{ text: 'Example', color: '#ffffff', background: '#990000' }]

export function useCommunicationCategoryColors(): CodeableConceptColor[] {
  return useConfig<CodeableConceptColor[]>(
    p => p.config.communicationCategoryColors || fallback
  )
}
