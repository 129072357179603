import React, { useState } from 'react'
import Icon from '@duik/icon'
import Modal from '@duik/modal'
import { useApolloClient } from '@healthblocks-io/apollo'

import { useLocalStorage } from '@utils'
import Button from 'pages/atoms/Button'

import cls from './settings.module.scss'
import HealthblocksTopBar from '../modules/HealthblocksTopBar'
import {
  createPolicy,
  emptyPolicy,
  Policy,
  removePolicy,
  updatePolicy,
  usePolicies,
} from 'lib/policy'
import { sortBy } from 'lib/sort'
import PolicyEditor from './PolicyEditor'
import TableButton from 'pages/atoms/TableButton'

const headings = [
  { displayName: 'Title', sortBy: 'title' },
  { displayName: 'Statements' },
  { displayName: '' },
]

export default function Policies() {
  const client = useApolloClient()
  const { data, loading, error, refetch } = usePolicies()
  const [editing, setEditing] = useState<Policy | null>(null)

  const remove = async p => {
    if (!window.confirm(`Do you want to remove ${p.title || 'this policy'}?`)) {
      return
    }
    try {
      await removePolicy(client, p)
      refetch()
      setEditing(null)
    } catch (e) {
      errorAlert(e)
    }
  }
  return (
    <div>
      <HealthblocksTopBar>Care team Policies</HealthblocksTopBar>
      <div className="scroll-area-main">
        <div style={{ overflow: 'auto' }}>
          <div style={{ float: 'right' }}>
            <Button
              primary
              type="button"
              onClick={() => setEditing(emptyPolicy({}))}
            >
              Add new policy
            </Button>
          </div>
        </div>
        {data?.policy ? (
          <PolicyTable
            policies={data.policy}
            setEditing={setEditing}
            remove={remove}
          />
        ) : loading ? (
          'Loading...'
        ) : error ? (
          error.message
        ) : (
          'unexpected state'
        )}
      </div>

      <Modal
        className="modal-top"
        isOpen={!!editing}
        handleClose={() => setEditing(null)}
        closeOnOuterClick
        sm
      >
        <PolicyEditor
          policy={editing}
          save={async p => {
            try {
              if (p.id) {
                await updatePolicy(client, p)
              } else {
                await createPolicy(client, p)
              }
              refetch()
              setEditing(null)
            } catch (e) {
              errorAlert(e)
            }
          }}
          remove={() => remove(editing)}
        />
      </Modal>
    </div>
  )
}

function errorAlert({ message }: { message: string }) {
  alert(message)
}

function PolicyTable({
  policies,
  setEditing,
  remove,
}: {
  policies: Policy[]
  setEditing: (p: Policy | null) => void
  remove: (p: Policy) => void
}) {
  const [order, setOrder] = useLocalStorage('careteamPoliciesOrder', 'title')
  const toggleOrder = key =>
    setOrder(order === key ? '-' + key : order === '-' + key ? '' : key)
  const sortedPolicies = sortBy(policies, order)
  return (
    <table className="tbl tbl--sticky" style={{ fontSize: 14 }}>
      <thead>
        <tr>
          {headings.map((heading, key) => {
            return (
              <th
                key={key}
                onClick={() => heading.sortBy && toggleOrder(heading.sortBy)}
                style={{ top: -26 }}
                className={
                  order === heading.sortBy
                    ? `selected asc`
                    : order === '-' + heading.sortBy
                    ? `selected des`
                    : ''
                }
              >
                {heading.displayName}{' '}
                <span
                  style={{
                    transform: 'rotate(90deg)',
                    display: 'inline-block',
                  }}
                >
                  {order === heading.sortBy ? (
                    <Icon className={cls.arrow}>arrow_right</Icon>
                  ) : order === '-' + heading.sortBy ? (
                    <Icon className={cls.arrow}>arrow_left</Icon>
                  ) : (
                    ''
                  )}
                </span>
              </th>
            )
          })}
          <th style={{ top: -26 }} />
        </tr>
      </thead>
      <tbody>
        {sortedPolicies.map((policy, key) => (
          <PolicyRow
            key={policy.id || key}
            policy={policy}
            setEditing={setEditing}
            remove={remove}
          />
        ))}
      </tbody>
    </table>
  )
}

function PolicyRow({
  policy,
  setEditing,
  remove,
}: {
  policy: Policy
  setEditing: (p: Policy | null) => void
  remove: (p: Policy) => void
}) {
  const statements = collect(policy.statement)
  return (
    <tr onClick={() => setEditing(policy)}>
      <td className="text-nowrap">
        {policy.title || <span style={{ opacity: 0.5 }}>n/a</span>}
      </td>
      <td>{statements.map(s => JSON.stringify(s)).join('\n')}</td>
      <td
        className="table-button-td"
        onClick={evt => {
          evt.stopPropagation()
          remove(policy)
        }}
      >
        <TableButton subtle>remove</TableButton>
      </td>
      <td className="table-button-td">
        <TableButton primary>edit</TableButton>
      </td>
    </tr>
  )
}

function collect<T>(obj: T | T[]) {
  return Array.isArray(obj) ? obj : [obj]
}
