import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useInView } from 'react-intersection-observer'

import { uniq } from '@bothrs/util/uniq'
import { serialize } from '@bothrs/util/url'
import moment from 'moment'
import useSWR from 'swr'
import { Checkbox } from '@duik/checkbox'
import { Avatar } from '@duik/avatar'
import Icon from '@duik/icon'
import { Select } from '@duik/select'

import { gql, useApolloClient } from '@healthblocks-io/apollo'
import { useFetch } from '@healthblocks-io/core/fetch'
import { usePid } from '@healthblocks-io/core/project'
import { Profile } from '@healthblocks-io/core/types'

import cls from './users.module.scss'
import HealthblocksTopBar from './modules/HealthblocksTopBar'
import Input from './atoms/Input'
import { useLocalStorage } from '@utils'
import inlineAvatar from 'lib/avatar'
import useUserFilters from 'lib/useUserFilters'
import { DropdownInput } from './users/FormFields'
import { useTranslation } from 'lib/i18n'

interface UserRowType extends Profile {
  search: string
  stats: any
  biosil: any
  interactions: any
  lastUpdate: any
  created_at: string
}

const headings = [
  { displayName: 'Name', sortBy: 'name' },
  { displayName: 'Email', sortBy: 'email' },
  { displayName: 'Phone', sortBy: 'phone' },
  {
    displayName: '# of assessments',
    sortBy: 'assessments',
    biosil: true,
    advanced: true,
  },
  { displayName: '# of interactions', sortBy: 'interactions', advanced: true },
  { displayName: 'Last update', sortBy: 'lastUpdate' },
  { displayName: 'Group', sortBy: 'group' },
]

function getRowHeight() {
  return Math.ceil(window.innerHeight / 63)
}
let historyDebounce
let refetchDebounce

export default function UserPage() {
  const pid = usePid()
  const history = useHistory()
  const { t } = useTranslation()
  const [advanced, setAdvanced] = useLocalStorage('usersAdvanced', false)
  const allFilters = useUserFilters()
  const [filter, setFilter] = useLocalStorage('usersFilter', {
    q: '',
    role_title: '',
  })

  const [sortBy, setSortBy] = useLocalStorage('usersSortBy', 'email')
  const [sortAsc, setSortAsc] = useLocalStorage('usersSortAsc', true)

  // Ctrl F
  useEffect(() => {
    const listener = function (e) {
      if (e.keyCode === 114 || ((e.ctrlKey || e.metaKey) && e.keyCode === 70)) {
        const elem = document.getElementById('UserOverviewSearchInput')
        if (elem) {
          e.preventDefault()
          elem.focus()
        }
      }
    }
    window.addEventListener('keydown', listener)
    return () => {
      window.removeEventListener('keydown', listener)
    }
  }, [])

  // Fetch data
  const { getJSON } = useFetch()
  const { data, error, revalidate } = useSWR<UserRowType[]>(
    '/api/users?' + serialize({ advanced, role_title: filter.role_title }),
    getJSON
  )
  const allUsers = useMemo(() => (Array.isArray(data) ? data : []), [data])

  // Data => Mapped
  const groups = useMemo(
    () =>
      allUsers
        .map(u => u.group)
        .filter(uniq)
        .sort(),
    [allUsers]
  )

  // Mapped => Filtered
  const filteredUsers = useMemo(
    () =>
      filter.q
        ? allUsers.filter(u => u.search.includes(filter.q.toLowerCase()))
        : allUsers,
    [allUsers, filter.q]
  )

  const handleSort = sortByClick => {
    if (sortBy === sortByClick) {
      setSortAsc(!sortAsc)
    } else {
      setSortAsc(true)
    }
    setSortBy(sortByClick)
  }

  const sortUsers = useCallback(
    users => {
      const reverse = sortAsc ? 1 : -1

      switch (sortBy) {
        case 'name':
          return users
            .slice()
            .sort((a, b) =>
              !a.name
                ? 1
                : !b.name
                ? -1
                : reverse * a.name.localeCompare(b.name)
            )

        case 'email':
          return users
            .slice()
            .sort((a, b) =>
              !a.email
                ? 1
                : !b.email
                ? -1
                : reverse * a.email.localeCompare(b.email)
            )

        case 'phone':
          return users
            .slice()
            .sort((a, b) =>
              !a.phone
                ? 1
                : !b.phone
                ? -1
                : reverse * a.phone.localeCompare(b.phone)
            )

        case 'assessments':
          return users.slice().sort((a, b) => {
            return b.assessments > a.assessments
              ? reverse === -1
                ? -1
                : 1
              : a.assessments > b.assessments
              ? reverse === -1
                ? 1
                : -1
              : 0
          })
        case 'interactions':
          return users.slice().sort((a, b) => {
            return b.interactions > a.interactions
              ? reverse === -1
                ? -1
                : 1
              : a.interactions > b.interactions
              ? reverse === -1
                ? 1
                : -1
              : 0
          })
        case 'group':
          return users
            .slice()
            .sort((a, b) =>
              !a.group
                ? 1
                : !b.group
                ? -1
                : reverse * a.group.localeCompare(b.group)
            )
        case 'lastUpdate':
          return users
            .slice()
            .sort((a, b) =>
              !a.lastUpdate
                ? 1
                : !b.lastUpdate
                ? -1
                : reverse * b.lastUpdate.localeCompare(a.lastUpdate)
            )
        default:
      }
      return users
    },
    [sortBy, sortAsc]
  )
  // Filtered => Sorted
  const sortedUsers = useMemo(
    () => sortUsers(filteredUsers),
    [filteredUsers, sortUsers]
  )

  // Limit optimization, only show more when scrolled down
  const [rowHeight, setRowHeight] = useState(getRowHeight)
  const [limit, setLimit] = useState(rowHeight)
  const { ref, inView } = useInView()
  useEffect(() => {
    if (inView && filteredUsers.length > limit) {
      setLimit(lim => lim * 2)
    }
    // eslint-disable-next-line
  }, [inView])

  // Sorted => Optimized
  const optimizedUsers = useMemo(
    () => sortedUsers.slice(0, limit),
    [sortedUsers, limit]
  )

  return (
    <div>
      <HealthblocksTopBar>
        {t('User overview')}
        {data && (
          <span style={{ fontWeight: 'normal', marginLeft: 15 }}>
            {' ' + filteredUsers.length} {t('users')}
          </span>
        )}
      </HealthblocksTopBar>

      <div className="scroll-area-main">
        <div className="d-flex flex-row justify-content-start align-items-center">
          {allFilters.map((f, key) => (
            <div key={key + f.type} className="mr-3 mb-2">
              <UserFilter
                filter={f}
                value={filter[f.field]}
                onChange={value => {
                  if (limit > rowHeight) {
                    setRowHeight(getRowHeight)
                    setLimit(rowHeight)
                  }
                  setFilter(filter => ({ ...filter, [f.field]: value }))

                  clearTimeout(historyDebounce)
                  historyDebounce = setTimeout(() => {
                    history.replace(
                      '?' + serialize({ ...filter, [f.field]: value })
                    )
                  }, 500)
                }}
              />
            </div>
          ))}
          <Checkbox
            checked={advanced}
            className="ml-4"
            label={t('Advanced')}
            onChange={() => setAdvanced(!advanced)}
          />
        </div>
        <div className={cls.pushHeight}>
          <table className="tbl tbl--sticky tbl--avatar tbl--wide">
            <thead>
              <tr>
                <th />
                {headings.map(heading => {
                  if (heading.advanced && !advanced) return null
                  if (heading.biosil && pid !== 'biosil') return null
                  return (
                    <th
                      key={heading.sortBy}
                      onClick={() => handleSort(heading.sortBy)}
                      className={
                        'tbl__sort ' +
                        (sortBy === heading.sortBy
                          ? `selected ${sortAsc ? 'asc' : 'des'}`
                          : '')
                      }
                    >
                      {t(heading.displayName)}{' '}
                      {sortBy === heading.sortBy ? (
                        <Icon
                          className={
                            cls.arrow +
                            ' ' +
                            (sortAsc ? cls.sortDown : cls.sortUp)
                          }
                        >
                          arrow_right
                        </Icon>
                      ) : (
                        <></>
                      )}
                    </th>
                  )
                })}
              </tr>
            </thead>
            <tbody>
              {optimizedUsers.map((user, i) => (
                <UserRow
                  key={user.uid}
                  user={user}
                  advanced={advanced}
                  refetch={revalidate}
                />
              ))}
            </tbody>
          </table>
          {error && <p className="mt-5">An error occurred: {error.message}</p>}
        </div>
        <div ref={ref} style={{ height: 500 }} />
      </div>
      <datalist id="user_groups">
        {groups.map(g => (
          <option key={g} value={g} />
        ))}
      </datalist>
    </div>
  )
}

const UpdateUserGroup = gql`
  mutation UpdateUserGroup($uid: String!, $group: String) {
    update_users(where: { uid: { _eq: $uid } }, _set: { group: $group }) {
      affected_rows
    }
  }
`

const UserRow = memo(({ user, advanced = false, refetch }: any) => {
  const pid = usePid()
  const history = useHistory()
  const { t } = useTranslation()
  const client = useApolloClient()
  const handleGroup = evt => {
    const { value: group } = evt.target
    if (group !== user.group) {
      clearTimeout(refetchDebounce)
      client
        .mutate({
          mutation: UpdateUserGroup,
          variables: { uid: user.uid, group },
        })
        .then(() => {
          clearTimeout(refetchDebounce)
          refetchDebounce = setTimeout(refetch, 1000)
        })
        .catch(() => {
          alert('Group not saved! ' + group)
        })
    }
  }

  const url = `/${pid}/users/${user.uid}/overview`
  return (
    <tr
      className={cls.clickableRow}
      onClick={evt =>
        evt.metaKey || evt.shiftKey || evt.ctrlKey
          ? window.open(url)
          : history.push(url)
      }
    >
      <td className="td-1">
        <Avatar className={cls.avatarUsers} imgUrl={inlineAvatar(user.uid)} />
      </td>
      <td>
        {user.name ? (
          <span
            className={
              cls.darker +
              ' ' +
              cls.name +
              ' ' +
              (user.uid.startsWith('anonymous:') ? cls.anonymous : '')
            }
          >
            {user.name}
          </span>
        ) : (
          <span className={cls.name} style={{ opacity: 0.5 }}>
            {t('n/a')}
          </span>
        )}
      </td>
      <td>{user.email || <span style={{ opacity: 0.5 }}>{t('n/a')}</span>}</td>
      <td>{user.phone || <span style={{ opacity: 0.5 }}>{t('n/a')}</span>}</td>
      {advanced && pid === 'biosil' && (
        <td>
          <span className={cls.darker}>{user.assessments}</span>
        </td>
      )}
      {advanced && (
        <td>
          <span className={cls.darker}>{user.interactions}</span>
        </td>
      )}
      <td className={cls.nowrap} title={new Date(user.lastUpdate).toString()}>
        <span>{user.lastUpdate && moment(user.lastUpdate).fromNow()}</span>
      </td>
      <td
        className={cls.nowrap + ' ' + cls.tdInput}
        onClick={evt => evt.stopPropagation()}
      >
        <Input
          defaultValue={user.group || ''}
          onChange={handleGroup}
          // style={user.group ? { border: '1px inset #ccc',borderRadius: 2 } : null}
          list="user_groups"
        />
      </td>
    </tr>
  )
})

function UserFilter({ filter, value, onChange }) {
  const { t } = useTranslation()
  if (filter.type === 'search') {
    return (
      <Input
        id="UserOverviewSearchInput"
        value={value}
        onChange={evt => {
          console.log('ev chan', evt.target.value)
          onChange(evt.target.value)
          return
          // if (limit > rowHeight) {
          //   setRowHeight(getRowHeight)
          //   setLimit(rowHeight)
          // }
          // const value = evt.target.value
          // setQ(value)
          // clearTimeout(historyDebounce)
          // historyDebounce = setTimeout(() => {
          //   history.replace('?q=' + value)
          // }, 500)
        }}
        placeholder={t(filter.placeholder || 'Search_placeholder')}
        className="mb-0 flex-grow-0"
        style={{ width: 250 }}
      />
    )
  }
  if (filter.type === 'select') {
    const options = (filter.options || []).map(opt => ({
      ...opt,
      label: t(opt.label),
    }))
    return (
      <Select
        ButtonComponent={DropdownInput}
        activeOption={options.find(v => v.value === value) || options[0]}
        options={options}
        onOptionClick={({ value }) => onChange(value)}
        placeholder={filter.placeholder}
      />
    )
  }
  return <input />
}
