export interface Policy {
  id: string
  title: string
  statement: Statement | Statement[]
}
export interface Statement {
  Effect?: 'Allow' | 'Block'
  Action: string | string[]
  Resource: string | string[]
  Condition?: Condition | Condition[]
}

export interface Condition {
  author?: 'hb:self' | string
  receiver?: 'hb:self' | string
  sender?: 'hb:self' | string
  subject?: 'hb:self' | string
}

/** Returns true if allowed */
export function checkPolicy(
  policies: Pick<Policy, 'statement'>[],
  input: {
    action: string
    resource: string
    uid?: string
    getter?: (property: 'author' | 'sender' | 'subject') => string | undefined
  }
) {
  for (const policy of policies) {
    for (const statement of collect(policy.statement)) {
      for (const Action of collect(statement.Action)) {
        if (match(Action, input.action)) {
          for (const Resource of collect(statement.Resource)) {
            if (match(Resource, input.resource)) {
              if (statement.Condition) {
                for (const Condition of collect(statement.Condition)) {
                  if (Condition.author) {
                    if (!input.getter) return false
                    if (Condition.author === 'hb:self') {
                      if (!input.uid) return false

                      const author = input.getter('author')
                      return (
                        input.uid === author && statement.Effect !== 'Block'
                      )
                    } else if (Condition.author === input.getter('author')) {
                      return statement.Effect !== 'Block'
                    } else {
                      return false
                    }
                  }
                  if (Condition.sender) {
                    if (!input.getter) return false
                    if (Condition.sender === 'hb:self') {
                      if (!input.uid) return false

                      const sender = input.getter('sender')
                      return (
                        input.uid === sender && statement.Effect !== 'Block'
                      )
                    } else if (Condition.sender === input.getter('sender')) {
                      return statement.Effect !== 'Block'
                    } else {
                      return false
                    }
                  }

                  // More conditions...

                  // Unknown condition?
                  return false

                  // if (match(Condition, input.author)) {
                  //   return statement.Effect !== 'Block'
                  // }
                }
              } else {
                return statement.Effect !== 'Block'
              }
            }
          }
        }
      }
    }
  }
  return false
}

function match(pattern, input) {
  if (pattern === '*') {
    return true
  }
  if (pattern === input) {
    return true
  }
  // Consider replacing "+" by "*"
  const regex = new RegExp('^' + pattern.replace('*', '[a-zA-Z0-9_-|%]+') + '$')
  return regex.test(input)
}

export function collect<T>(obj: T | T[]) {
  return Array.isArray(obj) ? obj : [obj]
}
