import React, { useRef, useState } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { uniq } from '@bothrs/util/uniq'
import format from 'date-fns-tz/format'
import utcToZonedTime from 'date-fns-tz/utcToZonedTime'

import { gql, useSubscription } from '@healthblocks-io/apollo'
import { useTrack } from '@healthblocks-io/core/analytics'
import { useSubject } from '@healthblocks-io/core/subject'
import type { Message, Profile } from '@healthblocks-io/core/types'

import Badge from '@components/Badge'
import { useLocalStorage } from '@utils'

import cls from '../users.module.scss'

import Button from '../atoms/Button'

interface UidProp {
  uid: string
}

interface EventType {
  sentAt: string
  receivedAt: string
  properties: any
  event: string
  id: number
  time: string
}

type UserType = Profile & { events: EventType[]; messages: Message[] }

export function EventsToggle({ uid }: UidProp) {
  return (
    <div>
      <Events uid={uid} />
    </div>
  )
}

const defaultFilter = ['App Started', 'Flow Closed', 'Flow Opened']

function Events({ uid }: UidProp) {
  const stale = useRef<UserType>()
  const [filter, setFilter] = useLocalStorage('eventFilter', defaultFilter)
  const [limit, setLimit] = useState(100)
  const clear = () => setFilter([])
  const toggle = event => {
    setFilter(filter =>
      filter.includes(event)
        ? filter.filter(e => e !== event)
        : filter.concat(event)
    )
  }

  const subject = useSubject()
  useTrack('Events Opened', subject.track, [subject.uid])

  const { data, error } = useSubscription(UserEventsQuery, {
    variables: { uid, filter, limit },
  })
  const user: UserType = data?.users_by_pk || stale.current
  if (error) {
    return <pre>{JSON.stringify(error)}</pre>
  }
  if (!user) {
    return null
  }
  stale.current = user

  user.events.forEach(event => {
    if (!event.time) {
      event.time = user.timezone
        ? format(
            utcToZonedTime(new Date(event.sentAt), user.timezone),
            'yyyy-MM-dd HH:mm:ss zzz',
            {
              timeZone: user.timezone,
            }
          )
        : event.sentAt
    }
  })
  const items = user.events.flatMap((event, index) => {
    const prev = user.events[index - 1]
    if (!prev || prev.time.slice(0, 10) !== event.time.slice(0, 10)) {
      return [{ id: event.time.slice(0, 10), time: '' }, event]
    }
    return event
  })

  return (
    <div className="mt-4" style={{ minHeight: '100vh' }}>
      <h2>Events</h2>
      <p>This is a list of analytics events.</p>
      <p>
        <Badge onClick={clear} muted>
          Clear
        </Badge>
        {(filter || [])
          .concat(user.events.map(e => e.event))
          .filter(uniq)
          .map(f => (
            <Badge
              key={f}
              onClick={() => toggle(f)}
              primary={!filter.includes(f)}
              muted={filter.includes(f)}
            >
              {f}
            </Badge>
          ))}
      </p>
      <TransitionGroup className="events-list">
        {items.map(event => (
          <CSSTransition key={event.id} timeout={500} classNames="events-item">
            {event.time ? (
              <Event key={event.id} event={event} />
            ) : (
              <h3>{event.id}</h3>
            )}
          </CSSTransition>
        ))}
      </TransitionGroup>
      {user.events.length === limit ? (
        <div style={{ height: '50vh' }}>
          <Button inline onClick={() => setLimit(l => l * 2)}>
            Load more
          </Button>
        </div>
      ) : null}
    </div>
  )
}

function Event({ event }) {
  return (
    <div className={cls.event}>
      <time className={cls.eventSentAt}>{event.time.slice(11)}</time>
      <div className={cls.eventName}>{event.event}</div>
      {/* <div className={cls.eventProperties}> */}
      {Object.entries(event.properties).map(([key, value]) => (
        <div key={key} className={cls.eventProperty}>
          {key}: {typeof value === 'object' ? JSON.stringify(value) : value}
        </div>
      ))}
      {/* </div> */}
    </div>
  )
}

const UserEventsQuery = gql`
  subscription UserEventsQuery(
    $uid: String!
    $filter: [String!]!
    $limit: Int!
  ) {
    users_by_pk(uid: $uid) {
      uid
      timezone
      events(
        order_by: { receivedAt: desc }
        limit: $limit
        where: { event: { _nin: $filter } }
      ) {
        id: messageId
        event
        sentAt
        receivedAt
        properties
      }
    }
  }
`
