import React, { useEffect, useState, useRef } from 'react'
import { Dropdown, DropdownItem } from '@duik/dropdown'

import { usePid } from '@healthblocks-io/core/project'
import { resized, useUploadWeb } from '@healthblocks-io/core/upload'

import cls from './Annotate.module.scss'
import moment from 'moment'
import Button from 'pages/atoms/Button'
import CloseButton from 'pages/atoms/CloseButton'
import circleLogo from '../../assets/circle.svg'
import undoLogo from '../../assets/undo.svg'
import arrowLogo from '../../assets/arrow.svg'

const resizeCoordinateX = (point, smallCan, bigCan) => {
  return (point / smallCan.width) * bigCan.width
}
const resizeCoordinateY = (point, smallCan, bigCan) => {
  return (point / smallCan.height) * bigCan.height
}

const drawCircles = (
  canvas,
  circles,
  savingImage = false,
  smallCanvas = null
) => {
  const ctx = canvas.getContext('2d')
  if (!savingImage) {
    ctx.clearRect(0, 0, canvas.width, canvas.height)
  }
  if (smallCanvas) {
    circles.forEach(c => {
      ctx.beginPath()

      ctx.lineWidth = 3
      ctx.strokeStyle = c.c
      const cxR = resizeCoordinateX(c.x, smallCanvas, canvas)
      const cyR = resizeCoordinateY(c.y, smallCanvas, canvas)
      const cexR = resizeCoordinateX(c.endX, smallCanvas, canvas)
      const ceyR = resizeCoordinateY(c.endY, smallCanvas, canvas)

      const radiusR = Math.sqrt(
        (cexR - cxR) * (cexR - cxR) + (ceyR - cyR) * (ceyR - cyR)
      )
      if (c.t === 'circle') {
        ctx.arc(c.x, c.y, c.r, 0, 2 * Math.PI, false)
      }
      if (c.t === 'arrow') {
        ctx.moveTo(cxR, cyR)
        ctx.lineTo(cexR, ceyR)

        const udx = (cxR - cexR) / radiusR
        const udy = (cyR - ceyR) / radiusR
        const ax = (udx * Math.sqrt(3)) / 2 - (udy * 1) / 2
        const ay = (udx * 1) / 2 + (udy * Math.sqrt(3)) / 2
        const bx = (udx * Math.sqrt(3)) / 2 + (udy * 1) / 2
        const by = (-udx * 1) / 2 + (udy * Math.sqrt(3)) / 2

        ctx.moveTo(cexR, ceyR)
        ctx.lineTo(cexR + 20 * ax, ceyR + 20 * ay)

        ctx.moveTo(cexR, ceyR)
        ctx.lineTo(cexR + 20 * bx, ceyR + 20 * by)

        ctx.lineWidth = 2
      }

      if (c.t === 'oval') {
        ctx.ellipse(
          (cxR + cexR) / 2,
          (cyR + ceyR) / 2,
          Math.abs((cexR - cxR) / 2),
          Math.abs((ceyR - cyR) / 2),
          0,
          0,
          Math.PI * 2
        )
        // ctx.strokeRect(c.x, c.y, c.endX - c.x, c.endY - c.y);
      }
      ctx.stroke()
    })
  } else {
    circles.forEach(c => {
      ctx.beginPath()

      ctx.lineWidth = 3
      ctx.strokeStyle = c.c
      if (c.t === 'circle') {
        ctx.arc(c.x, c.y, c.r, 0, 2 * Math.PI, false)
      }
      if (c.t === 'arrow') {
        ctx.moveTo(c.x, c.y)
        ctx.lineTo(c.endX, c.endY)

        const udx = (c.x - c.endX) / c.r
        const udy = (c.y - c.endY) / c.r
        const ax = (udx * Math.sqrt(3)) / 2 - (udy * 1) / 2
        const ay = (udx * 1) / 2 + (udy * Math.sqrt(3)) / 2
        const bx = (udx * Math.sqrt(3)) / 2 + (udy * 1) / 2
        const by = (-udx * 1) / 2 + (udy * Math.sqrt(3)) / 2

        ctx.moveTo(c.endX, c.endY)
        ctx.lineTo(c.endX + 20 * ax, c.endY + 20 * ay)

        ctx.moveTo(c.endX, c.endY)
        ctx.lineTo(c.endX + 20 * bx, c.endY + 20 * by)

        ctx.lineWidth = 2
      }

      if (c.t === 'oval') {
        ctx.ellipse(
          (c.x + c.endX) / 2,
          (c.y + c.endY) / 2,
          Math.abs((c.endX - c.x) / 2),
          Math.abs((c.endY - c.y) / 2),
          0,
          0,
          Math.PI * 2
        )
        // ctx.strokeRect(c.x, c.y, c.endX - c.x, c.endY - c.y);
      }
      ctx.stroke()
    })
  }
}
const colors = ['red', '#000000', 'green']

const Annotate = ({ image2Src, onClose, send, imageList, bodypart }) => {
  const pid = usePid()
  const upload = useUploadWeb()
  const [color, setColor] = useState(0)
  const [drawType, setDrawType] = useState('oval')
  const [message, setMessage] = useState('')
  const [, setHeightCanvas] = useState(800)

  const [circles, setCircles] = useState([])
  const canvas2 = useRef(null)
  const canvasCircles = useRef(null)
  const canvasPhotos = useRef(null)

  const [bigCanvas] = useState(document.createElement('canvas'))
  console.log(image2Src)
  const [image1Url, setImage1Url] = useState(imageList[0].url)
  const [image2Url, setImage2Url] = useState(
    imageList[0].url === image2Src
      ? imageList[imageList.length - 1].url
      : image2Src
  )

  const [savingImage, setSavingImage] = useState(false)
  const [savingImageForRealz, setSavingImageForRealz] = useState(false)
  useEffect(() => {
    const drawImagesWithCorrectRatio = () => {
      console.log('DRAWING CIRCLES')
      if (!canvasPhotos.current) return
      const ctxPhotos = canvasPhotos.current.getContext('2d')

      bigCanvas.width = 1500
      // TODO calculate height using width of canvasPhotos and ratio
      bigCanvas.height = 1500
      const ctxBig = bigCanvas.getContext('2d')

      // draw the images on the back context
      const image = new Image()
      image.crossOrigin = 'anonymous'

      image.addEventListener('load', () => {
        ctxPhotos.drawImage(
          image,
          0,
          0,
          canvasPhotos.current.clientWidth / 2,
          (canvasPhotos.current.clientWidth / 2 / image.width) * image.height
        )

        ctxBig.drawImage(
          image,
          0,
          0,
          1500 / 2,
          (1500 / 2 / image.width) * image.height
        )
        setHeightCanvas(
          (canvasPhotos.current.clientWidth / 2 / image.width) * image.height
        )

        // bigCanvas.height =
        //   (canvasPhotos.current.clientWidth / 2 / image.width) * image.height
      })

      const image2 = new Image()
      image2.crossOrigin = 'anonymous'

      image2.addEventListener('load', () => {
        ctxPhotos.drawImage(
          image2,
          canvasPhotos.current.clientWidth / 2,
          0,
          canvasPhotos.current.clientWidth / 2,
          (canvasPhotos.current.clientWidth / 2 / image2.width) * image2.height
        )

        ctxBig.drawImage(
          image2,
          1500 / 2,
          0,
          1500 / 2,
          (1500 / 2 / image2.width) * image2.height
        )
      })
      image.src = image1Url
      image2.src = image2Url

      // normalize the width of the canvasses
      canvas2.current.width = canvasPhotos.current.clientWidth
      canvas2.current.height = canvasPhotos.current.clientHeight

      canvasCircles.current.width = canvasPhotos.current.clientWidth
      canvasCircles.current.height = canvasPhotos.current.clientHeight

      canvasPhotos.current.width = canvasCircles.current.width
      canvasPhotos.current.height = canvasCircles.current.height

      bigCanvas.height =
        (1500 / canvasPhotos.current.width) * canvasPhotos.current.height
    }
    window.addEventListener('resize', () => drawImagesWithCorrectRatio())
    drawImagesWithCorrectRatio()
    return () => {}
  }, [image1Url, image2Url, canvasPhotos, bigCanvas])

  useEffect(() => {
    const ctx2 = canvas2.current.getContext('2d')

    // helper variables
    let startX
    let startY
    let drawing = false
    // let paint = false;
    const tempCircles = circles
    const canvasCirclesCopy = canvasCircles.current
    drawCircles(canvasCirclesCopy, circles)

    const mouseDownEvent = e => {
      startX = e.offsetX
      startY = e.offsetY
      drawing = true
    }
    const mouseUpEvent = e => {
      if (drawing) {
        const endX = e.offsetX
        const endY = e.offsetY
        const radius = Math.sqrt(
          (endX - startX) * (endX - startX) + (endY - startY) * (endY - startY)
        )
        // clear the helper circles
        ctx2.clearRect(0, 0, canvas2.current.width, canvas2.current.height)
        tempCircles.push({
          x: startX,
          y: startY,
          endX,
          endY,
          r: radius,
          c: colors[color],
          t: drawType,
        })

        setCircles([...tempCircles])
      }
      drawing = false
    }
    const mouseMoveEvent = e => {
      const moveX = e.offsetX
      const moveY = e.offsetY

      // draw helper circles on the second canvas
      if (drawing) {
        ctx2.clearRect(0, 0, canvas2.current.width, canvas2.current.height)
        ctx2.beginPath()
        ctx2.lineWidth = 5
        ctx2.strokeStyle = 'rgba(3, 3, 3, 0.4)'
        if (drawType === 'circle') {
          const radiusMove = Math.sqrt(
            (moveX - startX) * (moveX - startX) +
              (moveY - startY) * (moveY - startY)
          )
          ctx2.arc(startX, startY, radiusMove, 0, 2 * Math.PI, false)
        }

        if (drawType === 'arrow') {
          ctx2.moveTo(startX, startY)
          ctx2.lineTo(moveX, moveY)
        }

        if (drawType === 'oval') {
          ctx2.ellipse(
            (startX + moveX) / 2,
            (startY + moveY) / 2,
            Math.abs((moveX - startX) / 2),
            Math.abs((moveY - startY) / 2),
            0,
            0,
            Math.PI * 2
          )
        }
        ctx2.stroke()
      }
    }
    // start drawing and save beginposition
    canvasCirclesCopy.addEventListener('mousedown', mouseDownEvent)
    // add this listener to the document so we detect mouse-up if its not on the canvas
    document.addEventListener('mouseup', mouseUpEvent)
    // do the drawing
    canvasCirclesCopy.addEventListener('mousemove', mouseMoveEvent)

    return () => {
      document.removeEventListener('mouseup', mouseUpEvent)
      canvasCirclesCopy.removeEventListener('mousemove', mouseMoveEvent)
      canvasCirclesCopy.removeEventListener('mousedown', mouseDownEvent)
    }
  }, [circles, color, canvas2, canvasCircles, drawType])

  const saveForRealz = async e => {
    setSavingImageForRealz(true)

    drawCircles(bigCanvas, circles, true, canvasPhotos.current)

    try {
      const uploaded = await uploadCanvas(pid, upload, bigCanvas)
      console.log('uploaded', uploaded)
      send(e, [uploaded], message, image2Src, bodypart)
      onClose()
    } catch (e) {
      alert('Failed to upload image: ' + e.message)
    }
  }
  const saveImage = async () => {
    setSavingImage(true)
    // drawCircles(canvasPhotos.current, circles, true)

    // const fileFromCanvas = canvasPhotos.current.toDataURL('image/png')

    // const formattedImage = await savePictureToCloud(e, fileFromCanvas)

    // send(e, formattedImage, message, image2Src, bodypart)
    // onClose()
  }

  return (
    <div className={cls.modal}>
      {savingImage && (
        <div className={cls.modalInModal}>
          <div className={cls.aboutToSave}>
            <h4>You're about to send a message</h4>
            {savingImageForRealz ? (
              <p>
                Busy sending the picture...{' '}
                <span
                  style={{ fontSize: '1.5rem' }}
                  role="img"
                  aria-label="camera-emoji"
                >
                  📸
                </span>
              </p>
            ) : (
              <p>
                Do you want to send message with a comparisson of the pictures?
              </p>
            )}
            <div className={cls.twoButtons}>
              <Button onClick={() => setSavingImage(false)} error>
                No, cancel
              </Button>
              <Button
                disabled={savingImageForRealz}
                onClick={saveForRealz}
                success
              >
                Yes, send message
              </Button>
            </div>
          </div>
        </div>
      )}
      <CloseButton onClose={onClose} />

      <div className={cls.left}>
        <div className={cls.header}>
          <div className={cls.halfHeader}>
            Baseline picture
            <Dropdown
              // @ts-ignore
              menuPosition="bottom-center"
              buttonText={
                <strong>
                  {moment
                    .utc(imageList.find(x => x.url === image1Url)?.date)
                    .format('LL')}
                </strong>
              }
            >
              {imageList.map((image, key) => {
                return (
                  <DropdownItem
                    key={key}
                    className={cls.dropdownItem}
                    onClick={() => setImage1Url(image.url)}
                  >
                    {moment(image.date).format('LL')}
                  </DropdownItem>
                )
              })}
            </Dropdown>
          </div>
          <div className={cls.halfHeader}>
            Second picture
            <Dropdown
              // @ts-ignore
              menuPosition="bottom-center"
              buttonText={
                <strong>
                  {moment
                    .utc(imageList.find(x => x.url === image2Url)?.date)
                    .format('LL')}
                </strong>
              }
            >
              {imageList.map((image, key) => {
                return (
                  <DropdownItem
                    key={key}
                    onClick={() => setImage2Url(image.url)}
                  >
                    {moment(image.date).format('LL')}
                  </DropdownItem>
                )
              })}
            </Dropdown>
          </div>
        </div>
        <div className={cls.wrap}>
          <canvas
            className={cls.imagecanvas}
            ref={canvasPhotos}
            id="imagecanvas"
            height="533"
            width="800"
          />
          <canvas
            className={cls.canvas2}
            id="canvas2"
            ref={canvas2}
            height="533"
            width="800"
          />
          <canvas
            className={cls.canvas2}
            ref={canvasCircles}
            id="canvas"
            height="533"
            width="800"
          />
        </div>
      </div>
      <div className={cls.right}>
        <h4>Tools</h4>
        <div className={cls.toolButtonWrap}>
          {/* <button onClick={() => setDrawType("circle")}>circle</button> */}
          <button
            className={`${cls.toolButton} ${drawType === 'oval' && cls.active}`}
            onClick={() => setDrawType('oval')}
          >
            <img src={circleLogo} alt="circleLogo" />
          </button>
          <button
            className={`${cls.toolButton} ${
              drawType === 'arrow' && cls.active
            }`}
            onClick={() => setDrawType('arrow')}
          >
            <img src={arrowLogo} alt="arrowLogo" />
          </button>
          <button
            className={cls.toolButton}
            onClick={() => {
              setCircles([...circles.slice(0, -1)])
            }}
          >
            <img src={undoLogo} alt="undoLogo" />
          </button>
          <button
            onClick={() => {
              setCircles([])
            }}
          >
            Clear all
          </button>
        </div>
        <h4>Color</h4>
        {colors.map((c, i) => {
          return (
            <button
              key={i}
              className={cls.colorButton}
              style={{
                backgroundColor: c,
                borderColor: i === color && 'var(--primary)',
              }}
              onClick={() => setColor(i)}
            />
          )
        })}

        <h4>Message</h4>

        <textarea
          className={cls.textarea}
          name=""
          id=""
          cols={30}
          rows={10}
          value={message}
          onChange={e => setMessage(e.target.value)}
        />

        <Button onClick={onClose} secondary>
          Cancel
        </Button>
        <Button disabled={!message || savingImage} onClick={() => saveImage()}>
          Send comparison
        </Button>
      </div>
    </div>
  )
}

export default Annotate

function uploadCanvas(pid: string, upload, canvas) {
  return (
    new Promise(resolve =>
      canvas.toBlob(blob => resolve(new File([blob], createFilename())))
    )
      .then(upload)
      // @todo get type from core/types
      .then((uploaded: { url: string; size: number }) => {
        // Temporary workaround for BioSil app & dashboard
        if (['biosil', 'pillar'].includes(pid)) {
          const { url } = uploaded
          const { width, height } = canvas
          console.log('wid', width, height)
          return {
            ...uploaded,
            height,
            width,
            // @ts-ignore
            thumbnails: {
              small: {
                url: resized(url, 100),
                width: 100,
                height: Math.ceil((100 * height) / width),
              },
              large: {
                url: resized(url, 600),
                width: 600,
                height: Math.ceil((600 * height) / width),
              },
              full: {
                url,
                width,
                height,
              },
            },
          }
        }

        return uploaded
      })
  )
}

function createFilename() {
  return new Date().toJSON().slice(0, 10) + ' picture.png'
}
