import React from 'react'

import { useUserEditor } from '@healthblocks-io/core/user'

import Button from 'pages/atoms/Button'
import Input from 'pages/atoms/Input'
import { useTranslation } from 'lib/i18n'
import cls from './Mandatory.module.scss'

export default function MandatoryName({ onChange }: { onChange?: () => void }) {
  const { t } = useTranslation()
  const { patchUserDoc, save, user } = useUserEditor({ lazy: true })

  return (
    <section className={cls['mandatory-page']}>
      <div className={cls['mandatory-wrap']}>
        <img
          className={cls.roundedAvatar}
          src="/images/healthblocks-logo-white.png"
          alt="logo"
        />

        <h2>{t('Welcome ask name')}</h2>
        <p>
          <strong>{t('Welcome ask name p')}</strong>
        </p>

        <form
          onSubmit={async evt => {
            evt.preventDefault()
            await save()
            onChange?.()
          }}
        >
          <Input
            label={t('Given name')}
            value={user.doc.givenName || ''}
            onChange={evt =>
              patchUserDoc({
                givenName: evt.target.value,
              })
            }
          />

          <Input
            label={t('Family name')}
            value={user.doc.familyName || ''}
            onChange={evt =>
              patchUserDoc({
                familyName: evt.target.value,
              })
            }
          />
          <Button disabled={!user.doc.givenName || !user.doc.familyName}>
            {t('Save')}
          </Button>
        </form>
      </div>
    </section>
  )
}
