import React from 'react'

import { gql, useQuery } from '@healthblocks-io/apollo'
import { usePid } from '@healthblocks-io/core/project'
import { Message } from '@healthblocks-io/core/types'

import Badge from '@components/Badge'
import { userName } from '../lib/user'

export default function PidCheck() {
  return usePid() === 'biosil' ? <Reviews /> : <div>disabled</div>
}

function Reviews() {
  const { data, loading } = useQuery(ReviewsQuery, { ssr: false })

  return (
    <div>
      {loading ? (
        'loading'
      ) : data?.users.length ? (
        <ReviewsTable data={data} />
      ) : (
        'no data'
      )}
    </div>
  )
}

function ReviewsTable({ data }) {
  const reviews: ReviewCheck[] = data?.users.map(reviewsNeeded)

  if (!reviews) {
    return <div>no reviews{JSON.stringify(data)}</div>
  }
  const count = reviews.reduce((acc, r) => acc + r.checkinCount, 0)
  const atleast1 = reviews.reduce(
    (acc, r) => acc + (r.checkinCount > 0 ? r.checkinCount : 0),
    0
  )
  const atleast2partusers = reviews.reduce(
    (acc, r) => acc + (r.reviewable > 1 ? 1 : 0),
    0
  )
  const atleast2parts = reviews.reduce(
    (acc, r) => acc + (r.reviewable > 1 ? r.reviewable : 0),
    0
  )

  return (
    <div>
      Reviews
      <div>{reviews.length} users</div>
      <div>{count} checkins</div>
      <div>{atleast1} users with at least 1</div>
      <div>{atleast2partusers} reviewable users</div>
      <div>{atleast2parts} reviewable checkins</div>
      <table className="tbl tbl--sticky tbl--wide">
        <tbody>
          <tr>
            <td>Example user name</td>
            <td>
              <div className="text-success">
                Reviewable goal:{' '}
                <span className="text-muted ml-3 mr-3">3 days ago</span>
                <Badge primary>01-01</Badge>
                <Badge primary>12-01</Badge>
              </div>
              <div>
                Not reviewable:{' '}
                <span className="text-muted ml-3 mr-3">none</span>
              </div>
            </td>
            <td />
            <td />
          </tr>
          {reviews
            .filter(r => r.checkinCount)
            .map((user, key) => (
              <tr key={key}>
                <td>{user.name}</td>
                <td>
                  {user.goals.map((goal: ReviewGoal, key) => (
                    <div
                      key={key}
                      className={goal.checkins.length > 1 ? 'text-success' : ''}
                    >
                      {goal.bodypart}:
                      <span
                        className={
                          'ml-3 mr-3 ' +
                          (goal.expected < 28
                            ? 'text-muted'
                            : goal.expected >= 30
                            ? 'text-danger'
                            : '')
                        }
                      >
                        {goal.expected > 0
                          ? Math.floor(goal.expected) + ' days ago'
                          : 'none'}
                      </span>
                      {goal.checkins.map(c => (
                        <Badge key={c.mid} primary>
                          {c.sent_at.slice(5, 10)}
                        </Badge>
                      ))}
                    </div>
                  ))}
                </td>
                <td />
                <td />
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  )
}

const ReviewsQuery = gql`
  query MyQuery {
    messages(where: { doc: { _has_key: "reviewForImageUrl" } }) {
      doc
    }
    users {
      uid
      doc
      email
      name
      group
      created_at
      messages(order_by: { sent_at: desc }) {
        sent_at
        doc
        mid
        tid
      }
    }
  }
`

interface ReviewCheck {
  name: string
  goals: ReviewGoal[]
  checkinCount: number
  reviewable: number
}
interface ReviewGoal {
  expected: number
  bodypart: string
  checkins: Message[]
}
function reviewsNeeded(user): ReviewCheck {
  const now = Date.now()
  const goals: ReviewGoal[] = (
    user.doc.goals ||
    user.messages
      .find(x => x.doc.replyTo === 'goals')
      ?.doc.reply.map(x => x.id) || ['hair', 'nails', 'skin']
  ).map((bodypart: string) => {
    // Message are sorted DESC => recent first
    const checkins = user.messages.filter(
      x => x.doc.bodypart === bodypart && !x.doc.hitl
    )

    if (checkins.length < 1) {
      return { bodypart, f: checkins.length, checkins, expected: -1 }
    }
    const expected = (now - new Date(checkins[0].sent_at).valueOf()) / 24 / 36e5
    if (checkins.length < 2) {
      return { bodypart, f: checkins.length, checkins, expected }
    }

    const message = checkins.find(x => x.doc.images.length)
    return {
      checkins,
      expected,
      bodypart,
      lastAssessment: message.sent_at,
      reviewed: message.doc.images
        .map(y => y.url)
        .filter(v =>
          []
            .map(z => z.doc.reviewForImageUrl)
            .filter(Boolean)
            .includes(v)
        ).length,
      // messages are returned newest first
      mid: message.mid,
      tid: message.tid,
      search:
        bodypart +
        // moment(message.sent_at).format('LL') +
        (message.doc.images
          .map(y => y.url)
          .filter(v =>
            []
              .map(z => z.doc.reviewForImageUrl)
              .filter(Boolean)
              .includes(v)
          ).length
          ? 'validated'
          : 'review'),
    }
  })
  return {
    name: userName(user, user.uid.replace('anonymous', '').replace('sms|', '')),
    goals,
    checkinCount: goals.reduce((acc, g) => acc + g.checkins.length, 0),
    reviewable: goals.reduce(
      (acc, g) => acc + (g.checkins.length > 1 ? 1 : 0),
      0
    ),
  }
}
