import useSWR from 'swr'
import { useUser } from './auth'
import { useFetch } from './fetch'
import { Profile } from './types'

const placeholder: Profile = {
  doc: {},
  pid: '',
  uid: '',
  role: '',
  checkouts: [],
}

export function useProfile() {
  const user = useUser()
  const { fetcher } = useFetch()
  return useSWR<Profile>('/api/users/' + user!.sub, fetcher)
}

export function useProfileData() {
  return useProfile().data || placeholder
}
