import React from 'react'
import { useFetch } from '@healthblocks-io/core/fetch'

import HealthblocksTopBar from './modules/HealthblocksTopBar'
import useAsync from 'lib/useAsync'
import { useWindowFocus } from 'lib/useWindowFocus'

export default function Health() {
  const focus = useWindowFocus()
  const { getJSON } = useFetch()
  const health = useAsync(() => getJSON('/api/health'), [focus]) || []

  return (
    <>
      <HealthblocksTopBar>System</HealthblocksTopBar>
      <div className="scroll-area-main">
        <h2>Health</h2>
        <table className="tbl tbl--wide">
          <tbody>
            {health.map(h => (
              <tr key={h.pid}>
                <td style={{ fontSize: 16 }}>
                  <a href={'/' + h.pid + '/admin'}>{h.pid}</a>
                </td>
                <td>
                  <pre>{JSON.stringify(h.error || h.errors, null, 2)}</pre>
                </td>
                <td>
                  <pre>{JSON.stringify(h.ok, null, 2)}</pre>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}
