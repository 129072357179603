import React, { useState } from 'react'

import { useToken, useUser } from '@healthblocks-io/core/auth'
import { useProject, useApi, usePid } from '@healthblocks-io/core/project'

import CsvExport from '@components/CsvExport/CsvExport'
import ImportExportConfiguration from '@components/ProjectConfiguration/ImportExportConfiguration'
import cls from './settings.module.scss'
import HealthblocksTopBar from '../modules/HealthblocksTopBar'
import { copyTextToClipboard } from 'lib/clipboard'
import Button from 'pages/atoms/Button'
import useBlocks from 'lib/useBlocks'
import { AdminConfig } from 'pages/Admin'

const Settings = () => {
  const { config } = useProject()
  const api = useApi()
  const pid = usePid()
  const id_token = useToken()
  const blocks = useBlocks()

  const user = useUser()

  const admin =
    user.email.endsWith('@healthblocks.io') ||
    user.email.endsWith('@bothrs.com')

  // const airtable = config.airtable || {}
  // const airtableApp = useState(airtable.app)
  // const airtableKey = useState(airtable.key)

  // const save = console.log

  return (
    <div>
      <HealthblocksTopBar>General settings</HealthblocksTopBar>
      <div className="scroll-area-main">
        {config.airtableConfig && (
          <>
            <p>Configuration is managed through Airtable</p>
            <p>
              <Button primary inline href={config.airtableConfig}>
                Manage config
              </Button>
            </p>
          </>
        )}
        <h3 className="title">Help &amp; support</h3>
        <Button
          primary
          inline
          href="mailto:support@healthblocks.io?subject=Admin support"
        >
          Contact Healthblocks support
        </Button>
        <hr />
        <h3 className="title">Project and user data</h3>
        <CsvExport />
        <hr />
        <h3 className="title">Manage project configuration</h3>
        <ImportExportConfiguration />
        <hr />
        {blocks.includes('cumul1') && (
          <>
            <h3 className="title">Cumul.io plugin</h3>
            <ol style={{ paddingLeft: 20 }}>
              <li>
                Open{' '}
                <a
                  href="https://app.cumul.io/start/profile/plugins"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  cumul.io plugins
                </a>
              </li>
              <li>Click "Create new plugin"</li>
              <li>
                Name: <SelectAll text="Healthblocks" />
              </li>
              <li>
                Slug: <SelectAll text={`healthblocks2${pid}`} />
              </li>
              <li>
                Base URL: <SelectAll text={api + '/api/cumul'} />
              </li>
              <li>Authentication: "Key / Token"</li>
              <li>Click "Create"</li>
              <li>Open your dashboard and add a new dataset</li>
              <li>Choose "Healthblocks"</li>
              <li>
                Key: <SelectAll text={pid} />
              </li>
              <li>
                Token: <SelectAll text={id_token} />
              </li>
              <li>Click "Connect"</li>
            </ol>
          </>
        )}
        {admin && <AdminConfig />}
      </div>
    </div>
  )
}
export default Settings

function SelectAll({ text }) {
  const [copied, setCopied] = useState(null)
  return (
    <span className={cls.selectAll}>
      <span className={cls.selectText}>{text}</span>
      <button
        className={cls.copyText}
        type="button"
        onClick={() => copyTextToClipboard(text).then(setCopied)}
      >
        {copied ? 'copied' : 'copy'}
      </button>
    </span>
  )
}
