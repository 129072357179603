import React, { useEffect, useState } from 'react'
import marked from 'marked'
import QRCode from 'qrcode'

import { useProject } from '@healthblocks-io/core/project'

import cls from './acquire.module.scss'

import { copyTextToClipboard } from '../lib/clipboard'
import Button from './atoms/Button'
import Loading from './atoms/Loading'
import HealthblocksTopBar from './modules/HealthblocksTopBar'
// import Input from './atoms/Input'

const AcquirePage = () => {
  const [qrCodeExpo, setQrCodeExpo] = useState('')
  const { config } = useProject()

  useEffect(() => {
    if (!config?.expoQR) return
    QRCode.toDataURL(config.expoQR)
      .then(url => {
        console.log(url)
        setQrCodeExpo(url)
      })
      .catch(err => {
        console.error(err)
      })
  }, [config])

  const copyText = txt => {
    copyTextToClipboard(txt)
  }

  // const requestLink = e => {
  //   e.preventDefault()
  // }

  return (
    <div>
      <HealthblocksTopBar>Acquire</HealthblocksTopBar>
      {config ? (
        <div className="scroll-area-main">
          <div className={cls.logoHeader}>
            {config.appLogo?.url && (
              <img height="80" width="80" src={config.appLogo.url} alt="" />
            )}
            <h2>{config.appName}</h2>
          </div>

          <div className={cls.grid}>
            <div className={cls.cell}>
              <span
                dangerouslySetInnerHTML={{
                  __html: marked(config.appDescription || ''),
                }}
              ></span>
              {config.mockup && <img src={config.mockup.url} alt="mockup" />}
            </div>
            <div className={cls.cell}>
              {/* THIS IS DOES NOT WORK YET */}
              {/* <div className={cls.verticalCell}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: marked(config.appLinkDescription),
                  }}
                ></span>

                <form
                  onSubmit={e => requestLink(e)}
                  className={cls.inputButton}
                >
                  <Input placeholder="Email"></Input>
                  <Button>Request Link</Button>
                </form>
              </div> */}
            </div>
            <div className={cls.cell}>
              <span
                dangerouslySetInnerHTML={{
                  __html: marked(config.appExpoDescription || ''),
                }}
              ></span>
              {qrCodeExpo && (
                <img src={qrCodeExpo} alt="qrcode for the expo app" />
              )}
            </div>
            <div className={cls.cell}>
              <div className={cls.verticalCell}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: marked(config.appLandingspageDescription || ''),
                  }}
                ></span>
                <div className={cls.buttons}>
                  <Button
                    onClick={() => copyText(config.appLandingspage)}
                    secondary
                  >
                    Copy link
                  </Button>
                  <Button href={config.appLandingspage}>
                    View Landingspage
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loading></Loading>
      )}
    </div>
  )
}

export default AcquirePage
