import React from 'react'
import cls from './Textarea.module.scss'

export default function Textarea({
  className,
  value,
  placeholder,
  onChangeText,
  lineBreaks = true,
}: React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
  onChangeText: (s: string) => void
  lineBreaks?: boolean
}) {
  return (
    <span className={cls.input}>
      <span className={cls.wrapper + ' ' + className}>
        <span className={cls.div} data-value={value || placeholder || ''} />
        <textarea
          className={cls.textarea}
          value={value || ''}
          placeholder={placeholder}
          onChange={evt =>
            onChangeText(
              lineBreaks
                ? evt.target.value
                : evt.target.value.replaceAll('\n', '')
            )
          }
        />
      </span>
    </span>
  )
}
