export default function debug(mod = '') {
  return {
    log: (...args) =>
      console.log.apply(null, ['%c' + mod, 'color:cyan', ...args]),
    warn: (...args) =>
      console.warn.apply(null, ['%c' + mod, 'color:cyan', ...args]),
    error: (...args) =>
      console.error.apply(null, ['%c' + mod, 'color:cyan', ...args]),
  }
}
