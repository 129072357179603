import React, { ReactNode, useCallback, useState } from 'react'
import { useEffect } from 'react'
import Dropdown, { DropdownItem } from '@duik/dropdown'
import Icon from '@duik/icon'
import Modal from '@duik/modal'
import format from 'date-fns/format'

import { useAnalytics, useTrack } from '@healthblocks-io/core/analytics'
import { answerString } from '@healthblocks-io/core/fhir'
import { useLocalState } from '@healthblocks-io/core/local'
import {
  ActivityEditor,
  useActivityEditor,
} from '@healthblocks-io/core/occurrence'
import { Activity, Reminder } from '@healthblocks-io/core/types'

import { useTranslation } from 'lib/i18n'
import Button from 'pages/atoms/Button'
import Input from 'pages/atoms/Input'
import Label from 'pages/atoms/Label'
import Tabs from 'pages/atoms/Tabs'
import Textarea from 'pages/atoms/Textarea'

import cls2 from './Calendar.module.scss'
import cls from './OccurrenceEditorModal.module.scss'

import {
  ArticleDropdown,
  DropdownInput,
  JSONDateEditor,
  QuestionnaireDropdown,
  RepeatEditor,
} from './FormFields'

export function OccurrenceEditorModal({
  activity,
  onClose,
  onProtect,
}: {
  activity: Activity
  onClose: () => void
  /** Make sure modal doesn't close on accident */
  onProtect: (b: boolean) => void
}) {
  const { t } = useTranslation()
  const { debug } = useLocalState()
  const editor = useActivityEditor(activity)
  const { activity: a, dirty, repeating } = editor
  const [rescheduling, setRescheduling] = useState(false)
  const [tab, setTab] = useState(
    a.kind === 'Questionnaire' && a.status === 'completed' ? 'response' : ''
  )

  const protect = rescheduling || !!dirty
  useEffect(() => onProtect(protect), [protect, onProtect])

  const { track } = useAnalytics()
  useTrack('Activity Editor Opened', {
    activityId: activity.id,
    activityKind: activity.kind,
    title: activity.doc.title,
  })

  return (
    <div style={{ padding: 25 }}>
      <h3 style={{ fontWeight: 'bold', margin: '24px 0' }}>
        {a.kind === 'Questionnaire' && a.status === 'completed'
          ? a.doc.title
          : t([a.id ? 'Edit' : 'Add', a.kind.toLowerCase()].join(' '))}

        {a.id && (
          <Status status={a.status}>
            {a.completed_at
              ? t('Completed at') +
                ' ' +
                format(new Date(a.completed_at), 'MMMM do, HH:mm')
              : a.status === 'scheduled'
              ? t('Scheduled')
              : t('Incomplete')}
          </Status>
        )}
      </h3>

      <Tabs
        options={[
          'General',
          'Reminders',
          a.kind === 'Questionnaire' && a.id ? 'Response' : '',
          debug ? 'Debug' : '',
        ]
          .filter(Boolean)
          .map(tab => ({ value: tab.toLowerCase(), label: t(tab) }))}
        value={tab}
        onChange={setTab}
      />

      {tab === 'reminders' ? (
        <Reminders {...editor} />
      ) : tab === 'response' ? (
        <QuestionnaireResponse {...editor} />
      ) : tab === 'debug' ? (
        <div>
          <pre>{JSON.stringify(a, null, 2)}</pre>
        </div>
      ) : (
        <General {...editor} />
      )}

      {tab !== 'response' && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: a.id ? 'space-between' : 'flex-end',
            marginTop: a.id ? 24 : 0,
          }}
        >
          {a.id ? (
            <Button
              id="delete-activity"
              data-kind={a.kind}
              data-title={a.doc.title}
              className="mb-0"
              inline
              error
              onClick={() => editor.remove().then(() => onClose?.())}
            >
              {t('Remove activity')}
            </Button>
          ) : null}
          <Button
            id={a.id ? 'update-activity' : 'create-activity'}
            data-kind={a.kind}
            data-title={a.doc.title}
            className="mb-0"
            inline
            disabled={
              (['Article', 'Questionnaire'].includes(a.kind) &&
                !a.doc.definitionUri) ||
              !a.doc.title
            }
            onClick={async () => {
              if (a.id && repeating) {
                return setRescheduling(true)
              } else if (a.id) {
                await editor.reschedule()
                track('Activity Updated', { activityKind: a.kind })
              } else {
                await editor.create()
                track('Activity Created', { activityKind: a.kind })
              }
              onClose?.()
            }}
          >
            {t(
              a.id && repeating
                ? 'Save_ellipsis'
                : a.id
                ? 'Save activity'
                : 'Add activity'
            )}
          </Button>
        </div>
      )}

      {a.id && repeating && (
        <Modal
          style={{ maxWidth: 320, padding: '4px 24px 12px', height: 'auto' }}
          isOpen={rescheduling}
          closeOnOuterClick
          handleClose={() => setRescheduling(false)}
        >
          <h3 style={{ fontWeight: 'bold', margin: '24px 0' }}>
            {t('Reschedule activity')}
          </h3>
          <p>{t('Reschedule activity p')}</p>
          <p>
            <Button
              onClick={async () => {
                await editor.reschedule('single')
                onClose?.()
              }}
            >
              {t('Only this event')}
            </Button>
            <Button
              onClick={async () => {
                await editor.reschedule('all')
                onClose?.()
              }}
            >
              {t('All past and future events')}
            </Button>
          </p>
        </Modal>
      )}
    </div>
  )
}

function Status({ status, children }: { status: string; children: ReactNode }) {
  return (
    <div className={cls.status}>
      <div
        style={{}}
        className={cls.statusCircle + ' ' + cls2['status_' + status]}
      />
      <div className={cls.statusLabel}>{children}</div>
    </div>
  )
}

function General({
  activity: a,
  patchActivity,
  patchActivityDoc,
}: ActivityEditor) {
  const { t } = useTranslation()
  return (
    <div>
      {'Questionnaire' === a.kind ? (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Label>{t('Questionnaire')}</Label>
          <QuestionnaireDropdown
            label={t(a.doc.title + '')}
            value={a.doc.definitionUri?.split('/')[1]}
            onChange={(name, title) =>
              patchActivityDoc({
                definitionUri: 'Questionnaire/' + name,
                title: t(title),
              })
            }
          />
        </div>
      ) : 'Article' === a.kind ? (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Label>{t('Article')}</Label>
          <ArticleDropdown
            label={t(a.doc.title + '')}
            value={a.doc.definitionUri?.split('/')[1]}
            onChange={(name, title) =>
              patchActivityDoc({
                definitionUri: 'Article/' + name,
                title: t(title),
              })
            }
          />
        </div>
      ) : (
        <div>
          <Input
            label={t('Title')}
            type="text"
            value={a.doc.title || ''}
            onChangeText={title => patchActivityDoc({ title })}
            placeholder={t('Add title_placeholder')}
          />
          <Label>{t('Description')}</Label>
          <Textarea
            value={a.doc.description || ''}
            onChangeText={description => patchActivityDoc({ description })}
            placeholder={t('Add description_placeholder')}
          />
        </div>
      )}

      {a.id && (
        <div style={{ margin: '20px 0' }}>
          <Label>{t('Status')}</Label>
          <div
            style={{
              color: a.completed_at ? '#52E8A9' : undefined,
              marginTop: 6,
              fontWeight: 'bold',
              fontSize: 14,
              lineHeight: '23px',
            }}
          >
            {a.completed_at
              ? t('Completed at') +
                ' ' +
                format(new Date(a.completed_at), 'MMMM do, HH:mm')
              : a.status === 'scheduled'
              ? t('Scheduled')
              : t('Incomplete')}
          </div>
        </div>
      )}

      <div style={{ display: 'flex', flexDirection: 'row', margin: '20px 0' }}>
        <div style={{ flexGrow: 1, flexBasis: 0 }}>
          <div>
            <Label>{t('Start')}</Label>
            <JSONDateEditor
              cta={t('Set date_placeholder')}
              value={a.planned_at}
              onChange={planned_at => {
                if (!a.doc.end_at) {
                  return patchActivity({ planned_at })
                }
                const shift = Date.parse(planned_at) - Date.parse(a.planned_at)
                patchActivity({ planned_at })
                patchActivityDoc({
                  end_at: new Date(Date.parse(a.doc.end_at) + shift).toJSON(),
                })
              }}
            />
          </div>
        </div>
        <div style={{ padding: '42px 10px 0 10px' }}>-</div>
        <div style={{ flexGrow: 1, flexBasis: 0 }}>
          <div>
            <Label>{t('End')}</Label>
            <div>
              <JSONDateEditor
                cta={t('Set date_placeholder')}
                value={a.doc.end_at}
                onChange={useCallback(
                  e => patchActivityDoc({ end_at: e }),
                  [patchActivityDoc]
                )}
              />
            </div>
          </div>
        </div>
      </div>

      <RepeatEditor
        value={{
          repeatPeriod: a.doc.repeatPeriod,
          repeatPeriodUnit: a.doc.repeatPeriodUnit,
          repeatBoundsPeriodEnd: a.doc.repeatBoundsPeriodEnd,
          repeatCount: a.doc.repeatCount,
        }}
        onChange={doc => patchActivityDoc(doc)}
      />
    </div>
  )
}

function Reminders({ activity: a, patchActivityDoc }: ActivityEditor) {
  const { t } = useTranslation()
  const reminders = a.doc.reminders || []
  return (
    <div>
      <p>{t('Reminders p')}</p>

      {reminders.length ? (
        <table className={cls.tblSubtle}>
          <tbody>
            <tr>
              <th>
                <Label>{t('Time')}</Label>
              </th>
              <th />
              <th>
                <Label>{t('Before or after the start date')}</Label>
              </th>
            </tr>
            {reminders.map((reminder, key) => (
              <tr key={key}>
                <td>
                  <Dropdown
                    style={{ width: 190 }}
                    buttonText={t(
                      reminderKey(reminder),
                      reminderCount(reminder)
                    )}
                    ButtonComponent={DropdownInput}
                    closeOnOptionClick
                  >
                    {[
                      {
                        offset: reminderSign(reminder) * 1,
                        offsetUnit: 'h' as const,
                      },
                      {
                        offset: reminderSign(reminder) * 4,
                        offsetUnit: 'h' as const,
                      },
                      {
                        offset: reminderSign(reminder) * 1,
                        offsetUnit: 'd' as const,
                      },
                      {
                        offset: reminderSign(reminder) * 3,
                        offsetUnit: 'd' as const,
                      },
                      {
                        offset: reminderSign(reminder) * 1,
                        offsetUnit: 'wk' as const,
                      },
                    ].map((reminder, index) => (
                      <DropdownItem
                        key={index}
                        onClick={() => {
                          const clone = reminders.slice()
                          clone.splice(key, 1, reminder)
                          patchActivityDoc({
                            reminders: clone,
                          })
                        }}
                      >
                        {t(reminderKey(reminder), reminderCount(reminder))}
                      </DropdownItem>
                    ))}
                  </Dropdown>
                </td>
                <td width="8" />
                <td>
                  <Dropdown
                    style={{ width: 190 }}
                    buttonText={t(reminderAbs(reminder))}
                    ButtonComponent={DropdownInput}
                    closeOnOptionClick
                  >
                    {[
                      { ...reminder, offset: -Math.abs(reminder.offset) },
                      { ...reminder, offset: Math.abs(reminder.offset) },
                    ].map((reminder, index) => (
                      <DropdownItem
                        key={index}
                        onClick={() => {
                          const clone = reminders.slice()
                          clone.splice(key, 1, reminder)
                          patchActivityDoc({
                            reminders: clone,
                          })
                        }}
                      >
                        {t(reminderAbs(reminder))}
                      </DropdownItem>
                    ))}
                  </Dropdown>
                </td>
                <td width="8" />
                <td>
                  <Button
                    className="mb-0 p-2"
                    subtle
                    onClick={() =>
                      patchActivityDoc({
                        reminders: reminders.filter(r => r !== reminder),
                      })
                    }
                  >
                    <CrossIcon />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : null}

      <Button
        secondary
        inline
        onClick={() =>
          patchActivityDoc({
            reminders: reminders.concat({
              offset: -1,
              offsetUnit: 'h',
            }),
          })
        }
      >
        <Icon className="mr-2">add</Icon>
        {t('Add a reminder')}
      </Button>
    </div>
  )
}

function QuestionnaireResponse({ activity: a, patchActivity }: ActivityEditor) {
  const { t } = useTranslation()
  const answers = a.questionnaire_response?.answers
  if (!answers) {
    return (
      <p style={{ height: 200, padding: '15%', textAlign: 'center' }}>
        {t(
          'Once the questionnaire is complete, the patient’s answers will be visualised here'
        )}
        .
      </p>
    )
  }
  if (!answers.length) {
    return <div>{t('The questionnaire response is not available')}.</div>
  }
  return (
    <div>
      {answers.map((a, key) => (
        <Answer key={key} a={a} />
      ))}
    </div>
  )
}

function Answer({
  a,
}: {
  a: Activity['questionnaire_response']['answers'][0]
}) {
  const [title, description] = splitText(a.question.text)
  return (
    <div className={cls.answer}>
      <div style={{ flexBasis: 28, flexShrink: 0 }}>
        <QuestionIcon />
      </div>
      <div style={{ flex: 1 }}>
        {title && (
          <div
            style={{
              fontWeight: 600,
              fontSize: 14,
              lineHeight: '18px',
              marginBottom: 10,
              color: '#4B4C4D',
            }}
          >
            {title}
          </div>
        )}
        {description && (
          <div
            style={{
              fontSize: 12,
              lineHeight: 1.33,
              marginBottom: 6,
              color: '#4B4C4D',
            }}
          >
            {description}
          </div>
        )}
        <p style={{ fontSize: 12, lineHeight: 1.3, color: '#4B4C4D' }}>
          {answerString(a.answer || a.text)}
        </p>
      </div>
    </div>
  )
}

function splitText(text: string) {
  return text.startsWith('#') ? text.slice(2).split('\n', 2) : ['', text]
}

function QuestionIcon() {
  return (
    <svg
      width={16}
      height={17}
      viewBox="0 0 16 17"
      fill="none"
      style={{ display: 'block' }}
    >
      <path
        d="M8 1.833A6.67 6.67 0 001.333 8.5 6.67 6.67 0 008 15.166 6.67 6.67 0 0014.667 8.5 6.67 6.67 0 008 1.833zm.667 11.333H7.333v-1.333h1.334v1.333zM10.047 8l-.6.613c-.334.34-.574.647-.694 1.127a3.059 3.059 0 00-.086.76H7.333v-.334a2.665 2.665 0 01.78-1.887l.827-.84c.307-.293.453-.733.367-1.2a1.327 1.327 0 00-.927-1.02 1.344 1.344 0 00-1.647.847c-.08.247-.286.434-.546.434h-.2a.577.577 0 01-.547-.747 2.672 2.672 0 012.153-1.887c1.014-.16 1.98.367 2.58 1.2.787 1.087.554 2.254-.126 2.934z"
        fill="#DAE6FF"
      />
    </svg>
  )
}

function reminderSign(reminder: Reminder) {
  return reminder.offset > 0 ? 1 : -1
}
function reminderAbs(reminder: Reminder) {
  return reminder.offset > 0 ? 'After' : 'Before'
}

function reminderKey(reminder: Reminder) {
  if (reminder.label) {
    return reminder.label
  }
  return 'duration ' + reminder.offsetUnit
}

function reminderCount(reminder: Reminder) {
  return { count: Math.abs(reminder.offset) }
}

function CrossIcon(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.536 9.61a1 1 0 10-1.415-1.415L12 10.316l-2.121-2.12a1 1 0 00-1.415 1.413l2.122 2.122-2.122 2.121a1 1 0 101.415 1.414L12 13.145l2.121 2.121a1 1 0 001.415-1.414l-2.122-2.121 2.122-2.122z"
        fill="#0659FD"
      />
    </svg>
  )
}
