import React, { useState } from 'react'
import Icon from '@duik/icon'
import moment from 'moment'

import { useSignedURL } from '@healthblocks-io/core/upload'

import CloseButton from 'pages/atoms/CloseButton'

import cls from './ImageModal.module.scss'

import Button from '../atoms/Button'
import Annotate from './Annotate'

const ImageModal = ({
  currentImage,
  bodypart,
  overlay,

  allImages,
  sentAt,
  children,
  send,
  overlays,
  messages,
}) => {
  const fullScreenUrl = currentImage.url
  const [currentURL, setCurrentURL] = useState(fullScreenUrl)
  const [openModal, setOpenModal] = useState(false)
  const [showAnnotate, setShowAnnotate] = useState(false)
  const sign = useSignedURL()

  const allImageURLs = allImages.map(img => img.url)
  const currentImageIndex = allImageURLs.indexOf(currentURL)

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes'
    if (!bytes) return '? Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
  }

  return (
    <>
      <div
        style={{ cursor: 'pointer' }}
        onClick={() => {
          setOpenModal(true)
        }}
      >
        {children}
      </div>
      {openModal && (
        <div className={cls.pictureModal} onClick={() => setOpenModal(false)}>
          <div className={cls.imgWrap} onClick={e => e.stopPropagation()}>
            {currentImageIndex !== 0 && (
              <Icon
                onClick={() =>
                  setCurrentURL(allImages[currentImageIndex - 1].url)
                }
                style={{
                  color: 'white',
                  fontSize: 20,
                  position: 'absolute',
                  left: '-35px',
                  top: '50%',
                  cursor: 'pointer',
                }}
              >
                arrow_left
              </Icon>
            )}
            {currentImageIndex !== allImages.length - 1 && (
              <Icon
                onClick={() =>
                  setCurrentURL(allImages[currentImageIndex + 1].url)
                }
                style={{
                  color: 'white',
                  fontSize: 20,
                  position: 'absolute',
                  right: '-35px',
                  top: '50%',
                  cursor: 'pointer',
                }}
              >
                arrow_right
              </Icon>
            )}
            <img className={cls.image} src={sign(currentURL)} alt="" />
            <div className={cls.downloadContainer}>
              <CloseButton
                onClose={() => {
                  setCurrentURL(fullScreenUrl)
                  setOpenModal(false)
                }}
              ></CloseButton>
              <h3>
                {bodypart} {overlays?.[currentImageIndex]?.name}
              </h3>
              <div className={cls.details}>
                <div className={cls['details-row']}>
                  <span>File size</span>
                  <strong>{formatBytes(currentImage?.size)}</strong>
                </div>
                <div className={cls['details-row']}>
                  <span>Uploaded</span>{' '}
                  <strong>{moment(sentAt).fromNow()}</strong>
                </div>
              </div>

              <Button
                href={sign(currentURL)}
                download="foto"
                className={cls['conversation-download'] + ' d-block'}
                primary
              >
                Download picture
                <Icon ml>cloud_up</Icon>
              </Button>

              {/* Only allow pictures taken from the app to be compared, these will allways have an overlay */}
              {overlays && (
                <Button
                  onClick={() => setShowAnnotate(true)}
                  className={cls['conversation-download']}
                  primary
                >
                  Compare pictures
                </Button>
              )}
              {/* )} */}
            </div>
          </div>
        </div>
      )}
      {showAnnotate && (
        <Annotate
          onClose={() => {
            setOpenModal(false)
            setShowAnnotate(false)
          }}
          image2Src={currentURL}
          imageList={messages
            .filter(
              m =>
                m.doc.bodypart === bodypart &&
                m.doc.images?.length &&
                !m.doc.hitl
            )
            .map(x => {
              const index = x.doc.overlays.findIndex(
                o => o.name === overlay.name
              )
              return {
                url: x.doc.images[index]?.url,
                date: x.sent_at,
              }
            })}
          send={send}
          bodypart={bodypart}
        ></Annotate>
      )}
    </>
  )
}

export default ImageModal
