import { createSimpleComponent } from '@duik/create-simple-component'

import cls from './messages.module.scss'

export const MessageImageContainer = createSimpleComponent({
  displayName: 'MessageImageContainer',
  Component: 'div',
  className: cls['message-image-container'],
})

export default MessageImageContainer
