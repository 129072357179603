import { useReducer } from 'react'
import _set from 'lodash/fp/set'

import { Policy } from 'lib/policy'

export function useParticipantEditor(participant: Participant) {
  return useReducer(careplanReducer, participant, initialPatch)
}

function careplanReducer(prev: PatchState, action) {
  if (action.type === 'discard') {
    return initialPatch(null)
  }
  if (action.type === 'save') {
    return { ...prev, saving: true }
  }
  if (!action.id) {
    // console.warn('sure no id')
  }

  let { data } = prev
  for (const key in action) {
    if (key !== 'id' && key !== 'type') {
      data = _set(key, action[key], data)
    }
  }

  return { ...prev, data }
}

interface PatchState {
  saving: boolean
  data: Participant | null
}

function initialPatch(participant: PatchState['data']): PatchState {
  return {
    saving: false,
    data: participant,
  }
}

export function emptyParticipant(p: Partial<Participant>): Participant {
  return {
    id: 0,
    role_title: '',
    preset_id: null,
    period_start: '',
    period_end: '',
    user: null,
    user_inline: null,
    policies: [],
    ...p,
  }
}

export interface Result<T> {
  data?: T
  errors?: { message: string }[]
}

export interface CareTeam {
  id: string
  name: string
  subject_id: string
  // /** @todo update backend */
  // subject?: Reference
  participant: Participant[]
}

export interface Participant {
  id: number
  role_title: string
  preset_id: string
  period_start: string
  period_end: string
  uid?: string
  user: {
    uid?: string
    name: string
    email: string
    phone?: string
    doc?: { organisation?: string }
  }
  user_inline: {
    name: string
    email: string
    phone?: string
    organisation?: string
  }
  // roles: { role: { name: string; title: string; permissions: string[] } }[]
  policies: { inline: Policy | null; policy: Policy | null }[]
}

export interface Preset {
  preset_id: string
  role_title: string
  policies: string[]
}
