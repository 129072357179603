import { useConfig } from './project'
import {
  CodeableConcept,
  Observation,
  ObservationComponent,
  ObservationConstraints,
} from './types'

export interface ObservationPreset {
  code: Observation['code']
  valueQuantity?: Observation['valueQuantity']
  component?: Observation['component']
}

export function useObservationPresets(): ObservationPreset[] {
  return (
    useConfig<ObservationPreset[]>(p => p.config.observationPresets) || [
      {
        code: { coding: [{ code: '8867-4' }], text: 'Heart rate' },
        valueQuantity: { value: 70, unit: 'beats/s' },
      },
      {
        code: { coding: [{ code: '8459-0' }], text: 'Blood pressure' },
        valueQuantity: { value: 110, unit: 'mm Hg' },
      },
      {
        code: { coding: [{ code: '41950-7' }], text: 'Steps' },
        valueQuantity: { value: 5000, unit: 'steps/day' },
      },
    ]
  )
}

export function useConstraints(code: CodeableConcept) {
  const constraints = useConfig<ObservationConstraints[]>(
    p => p.config.observationConstraints
  ) || [{ code: '8867-4', text: 'Heart rate', min: 30, max: 300, step: 1 }]
  return (
    constraints.find(c => c.code && c.code === code.coding?.[0].code) ||
    constraints.find(c => c.text && c.text === code.text)
  )
}

export function validate(
  observation: Observation,
  constraints: ObservationConstraints
): boolean {
  if (!constraints) return true

  if (
    observation.valueQuantity &&
    !validateComponent(observation.valueQuantity, constraints)
  ) {
    return false
  }

  if (
    observation.component?.find(
      c => c.valueQuantity && !validateComponent(c.valueQuantity, constraints)
    )
  ) {
    return false
  }

  return true
}

export function validateComponent(
  quantity: Observation['valueQuantity'],
  { min, max, step }: ObservationConstraints = {}
): boolean {
  if (
    quantity &&
    (typeof min !== 'number' || quantity.value >= min) &&
    (typeof max !== 'number' || quantity.value <= max) &&
    (!step || step <= 0 || remainder(quantity.value, step) === 0)
  ) {
    return true
  }
  return false
}

// Returns object, value should not be translated, label should be translated
export function observationValue(
  observation: Observation,
  options?: { label: string; value: number }[]
): { value: string | number; label?: string; unit?: string } {
  if (Array.isArray(options)) {
    const option = options.find(option => {
      return option.value === observation.valueQuantity?.value
    })
    if (option) return option
  }
  if (observation.valueQuantity) {
    return {
      value: observation.valueQuantity.value,
      unit: observation.valueQuantity.unit,
    }
  }
  if (observation.component?.length === 2) {
    return {
      value: observation.component
        .map(c => c.valueQuantity?.value || '?')
        .join('/'),
      unit: observation.component[0].valueQuantity?.unit,
    }
  }
  return { value: '?' }
}

export function toTimeChart(observations: Observation[]) {
  if (!observations || !observations[0]) {
    return
  }
  const table: any[] = []
  const codes = new Map()
  for (const obs of observations) {
    const summary: any = {}
    const add = (a: Observation | ObservationComponent) => {
      const code = a.code.coding?.[0]?.code
      if (code) {
        codes.set(code, a.code.text)
        summary[code] = a.valueQuantity?.value
      }
    }

    if (obs.component) {
      obs.component.forEach(add)
    }
    if (obs.valueQuantity) {
      add(obs)
    }
    if (Object.keys(summary).length && obs.effectiveInstant) {
      summary.effectiveInstant = new Date(obs.effectiveInstant)
      summary.author = obs.author?.type
      summary.note = obs.note?.[0]
      table.push(summary)
    }
  }

  table.sort((a, b) => a.effectiveInstant - b.effectiveInstant)

  return {
    labels: table.map(obs => obs.effectiveInstant),
    datasets: Array.from(codes.entries()).map(([code, codeText]) => ({
      label: codeText,
      data: table.map(obs => obs[code]),
      author: table.map(obs => obs.author),
      note: table.map(obs => obs.note),
    })),
  }
}

export function numericValue(obj: Partial<Observation>) {
  if (!obj) return
  if (obj.valueString) return parseFloat(obj.valueString)
  if (obj.valueQuantity) return obj.valueQuantity.value
  if (obj.component) return obj.component[0].valueQuantity?.value
}

function remainder(val: number, step: number) {
  const valDecCount = (val.toString().split('.')[1] || '').length
  const stepDecCount = (step.toString().split('.')[1] || '').length
  const decCount = valDecCount > stepDecCount ? valDecCount : stepDecCount
  const valInt = parseInt(val.toFixed(decCount).replace('.', ''))
  const stepInt = parseInt(step.toFixed(decCount).replace('.', ''))
  return (valInt % stepInt) / Math.pow(10, decCount)
}
