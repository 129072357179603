import React, { ReactNode, useCallback, useState } from 'react'
import format from 'date-fns/format'
import formatDuration from 'date-fns/formatDuration'

import { useFHIRActivity } from '@healthblocks-io/core/fhir'
import { useTheme } from '@healthblocks-io/core/theme'
import { Activity } from '@healthblocks-io/core/types'

import Modal from '@duik/modal'
import { useTranslation } from 'lib/i18n'
import Icon from 'pages/atoms/ActivityIcon'
import { usePatientCalendar } from './PatientCalendarContext'
import { IndexListItem } from './IndexList'
import { OccurrenceEditorModal } from 'pages/users/OccurrenceEditorModal'
import cls from 'pages/users/OccurrenceEditorModal.module.scss'

export type ActivityDay<T = Activity> = IndexListItem<Day<T> | MonthStart>

export interface Day<T> {
  date: Date
  activities: T[]
}

export interface MonthStart {
  date: Date
  month: string
}

export function PatientCalendarItem({ data }: ActivityDay) {
  const calendar = usePatientCalendar()
  const monthStart = data as MonthStart
  const [editing, _setEditing] = useState<Activity | null>(null)
  const [isProtected, setProtected] = useState(false)

  const setEditing = useCallback(
    (x: Activity | null) => {
      _setEditing(x)
      // Refetch after closing the editor
      if (!x) calendar.refetch()
    },
    [calendar]
  )

  if (monthStart.month) {
    return (
      <div
        style={{
          paddingTop: 50,
          paddingBottom: 24,
          paddingLeft: 82,
        }}
      >
        <div
          style={{
            fontWeight: 600,
            fontSize: 24,
            lineHeight: '29px',
            color: '#B3B3B3',
          }}
        >
          {monthStart.month}
        </div>
      </div>
    )
  }
  const d = data as Day<Activity> // Hide empty days
  if (!d.activities) {
    return <div style={{ paddingTop: 1 }}>no activ? {JSON.stringify(data)}</div>
  }
  if (!d.activities.length) {
    return <div style={{ paddingTop: 1 }} />
  }
  // @ts-ignore
  const style =
    format(d.date, 'yyyy-MM-dd') === format(new Date(), 'yyyy-MM-dd')
      ? styles.today
      : d.activities.length
      ? styles.normal
      : styles.empty
  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          paddingLeft: 16,
          width: 52 + 16,
        }}
      >
        <div
          // circle & line
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: 16,
          }}
        >
          <div
            style={{
              marginLeft: 15 - style.circleSize / 2,
              marginRight: 15 - style.circleSize / 2,
              width: style.circleSize,
              height: style.circleSize,
              flexGrow: 0,
              flexShrink: 0,
              flexBasis: style.circleSize,
              borderRadius: style.circleSize,
              backgroundColor: style.circle,
            }}
          />
          <div
            // line
            style={{
              flexGrow: 1,
              flexBasis: 12,
              flexShrink: 0,
              width: 2,
              height: 12,
              backgroundColor: style.line,
              borderRadius: 2,
            }}
          />
        </div>
        <div style={{ flex: 1, marginTop: 2 }}>
          <div
            style={{
              fontSize: 16,
              fontWeight: 'bold',
              lineHeight: '19px',
              textAlign: 'center',
              color: style.date,
            }}
          >
            {d.date.getDate()}
          </div>
          <div
            style={{
              fontSize: 13,
              lineHeight: '16px',
              color: style.day,
              textAlign: 'center',
              textTransform: 'uppercase',
            }}
          >
            {format(d.date, 'E')}
          </div>
        </div>
      </div>
      <div style={{ flex: 1, padding: '0 16px 0' }}>
        {d.activities.map((a, key) => (
          <Card key={key} a={a} setEditing={setEditing} />
        ))}
      </div>
      <Modal
        className="modal-top"
        sm
        isOpen={!!editing}
        closeOnOuterClick={!isProtected}
        handleClose={() => setEditing(null)}
      >
        {editing && (
          <OccurrenceEditorModal
            onProtect={setProtected}
            activity={editing}
            onClose={() => setEditing(null)}
          />
        )}
      </Modal>
    </div>
  )
}
const card = { padding: 14 }

export function Card({
  a,
  setEditing,
}: {
  a: Activity
  setEditing: (c: Activity) => void
}) {
  const { t } = useTranslation()
  const calendar = usePatientCalendar()
  const { markCompleted } = useFHIRActivity()
  const hidden = a.hidden!

  if (hidden) {
    return null
  }

  return (
    <div className={cls.card} onClick={() => setEditing(a)}>
      <div
        style={{
          borderRadius: 16,
          opacity: hidden ? 0.3 : 1,
        }}
      >
        <CardTopBar>
          <div style={{ margin: '0 -14px' }}>
            <ActivityKindIndicator kind={a.kind} />
          </div>
          {a.status !== 'completed' && a.doc.repeatDuration && (
            <CardDuration>
              &middot;{' '}
              {formatDuration({ minutes: a.doc.repeatDuration }).replace(
                'utes',
                '.'
              )}
            </CardDuration>
          )}
          <div style={{ flex: 1 }} />
          {(a.kind === 'Task' || a.kind === 'Appointment') &&
            a.status !== 'completed' && (
              <CardLabel icon="clock">
                {format(new Date(a.planned_at), 'HH:mm')}
              </CardLabel>
            )}
          {a.kind === 'Task' || a.status === 'completed' ? (
            <div
              style={{ marginLeft: 6 }}
              onClick={event => {
                event.stopPropagation()
              }}
            >
              <Checkbox2
                value={a.status === 'completed'}
                onValueChange={newValue => {
                  if (newValue) {
                    markCompleted(a)
                      .catch(e => {
                        alert(e.message || 'Something went wrong')
                      })
                      .then(() => {
                        calendar.refetch()
                      })
                  }
                }}
              />
            </div>
          ) : ['Task', 'Questionnaire'].includes(a.kind) &&
            a.status === 'not-started' ? (
            <CardDuration>{t('due')}</CardDuration>
          ) : null}
        </CardTopBar>
        <CardTitle>{a.doc.title}</CardTitle>
        {/* {doc.definitionUri?.startsWith('Questionnaire/') &&
        isBefore(new Date(a.planned_at), endOfDay(new Date())) ? (
          <QuestionnaireAction
            name={doc.definitionUri.slice(14)}
            activity={a}
          />
        ) : doc.definitionUri?.startsWith('Article/') ? (
          <ArticleAction name={doc.definitionUri.slice(8)} />
        ) : a.kind === 'Task' ? (
          <DetailsAction id={a.id} />
        ) : a.kind === 'Appointment' ? (
          <DetailsAction id={a.id} />
        ) : (
          <div style={{ height: 16 }} />
        )} */}
        <div style={{ height: 16 }} />
      </div>
    </div>
  )
}

export function CardTopBar({ children }) {
  return (
    <div
      style={{
        marginLeft: -card.padding,
        marginRight: -card.padding,
        paddingLeft: card.padding,
        paddingRight: card.padding,
        paddingTop: 16,
        paddingBottom: 12,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      {children}
    </div>
  )
}

export function CardKind({ children }: { children: ReactNode }) {
  return (
    <div style={{ marginLeft: 8 }}>
      <div
        style={{
          fontSize: 13,
          fontWeight: 'bold',
          lineHeight: '16px',
          color: '#B3B3B3',
        }}
      >
        {children}
      </div>
    </div>
  )
}

export function CardDuration({ children }: { children: ReactNode }) {
  return (
    <div style={{ marginLeft: 4 }}>
      <div
        style={{
          fontSize: 13,
          lineHeight: '16px',
          color: '#B3B3B3',
        }}
      >
        {children}
      </div>
    </div>
  )
}

export function CardLabel({
  children,
  icon,
}: {
  children: ReactNode
  icon: string
}) {
  // const theme = useTheme()
  const color = '#0697FF'
  return (
    <div
      style={{
        paddingLeft: 6,
        paddingRight: 8,
        backgroundColor: '#DAEFFF',
        borderRadius: 6,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      {icon && <Icon icon={icon} color={color} size={16} />}
      <div
        style={{
          fontSize: 11,
          lineHeight: '20px',
          fontWeight: 'bold',
          color,
          marginLeft: 4,
        }}
      >
        {children}
      </div>
    </div>
  )
}

export function Checkbox2({
  value,
  onValueChange,
}: {
  value: boolean
  onValueChange: (b: boolean) => void
}) {
  const [temporary, setTemporary] = useState(undefined)
  if (temporary ?? value) {
    return <Icon icon="checkmark2" color="#53D769" />
  }
  return (
    <div
      className={cls.checkbox}
      onClick={() => {
        onValueChange(true)
        setTemporary(true)
      }}
    />
  )
}

export function CardTitle({ children }: { children: ReactNode }) {
  return (
    <div>
      <div
        style={{
          fontSize: 14,
          lineHeight: '18px',
          fontWeight: 'bold',
        }}
      >
        {children}
      </div>
    </div>
  )
}
export function CardAvatar({ kind }: { kind: string }) {
  const t = useTheme()
  return (
    <div
      style={{
        width: 33,
        height: 33,
        borderRadius: 33,
        paddingTop: 7,
        paddingLeft: 7,
        backgroundColor: '#CDDEFF',
      }}
    >
      <Icon icon={kind} color={t.primary.color} size={20} />
    </div>
  )
}

export function ActivityKindIndicator({ kind }: { kind: string }) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '0 14px 0',
      }}
    >
      <CardAvatar kind={kind} />
      <CardKind>{kind}</CardKind>
    </div>
  )
}

// function CardAction({
//   onClick,
//   ...props
// }: HTMLAttributes<HTMLSpanElement> & { children: ReactNode }) {
//   const theme = useTheme()
//   return (
//     <div
//       style={{
//         paddingTop: 10,
//         paddingBottom: 20,
//         display: 'flex',
//         flexDirection: 'row',
//         // justifyContent: 'center',
//         alignItems: 'center',
//       }}
//       onClick={onClick}
//     >
//       <div
//         style={{
//           color: theme.primary.color,
//           fontWeight: 'bold',
//           fontSize: 13,
//           lineHeight: '16px',
//           // borderBottomWidth: 2,
//           // borderBottomColor: useCard().focus ? d.typography.t1 : d.typography.t3,
//         }}
//         {...props}
//       />
//     </div>
//   )
// }

// function ArticleAction({ name }) {
//   const calendar = usePatientCalendar()
//   return (
//     <CardAction onClick={() => calendar.navigate('Article', { name })}>
//       Read article
//     </CardAction>
//   )
// }

// function QuestionnaireAction({
//   name,
//   activity,
// }: {
//   name: string
//   activity: Activity
// }) {
//   const calendar = usePatientCalendar()
//   if (activity.status === 'completed') {
//     return <div style={{ height: 16 }} />
//   }
//   return (
//     <CardAction
//       onClick={() =>
//         calendar.navigate('Questionnaire', { name, activityId: activity.id })
//       }
//     >
//       Start questionnaire
//     </CardAction>
//   )
// }

// function DetailsAction({ id }) {
//   const calendar = usePatientCalendar()
//   return (
//     <CardAction onClick={() => calendar.navigate('Activity', { id })}>
//       Show details
//     </CardAction>
//   )
// }

// function Timestamp({ date }: { date: string }) {
//   const theme = useTheme()
//   const distance = formatDistance(new Date(date), new Date(), {
//     addSuffix: true,
//   })
//   return (
//     <div
//       style={{
//         position: 'absolute',
//         left: 24,
//         top: -26,
//         color: theme.secondary.disabledColor,
//         fontSize: 14,
//         fontWeight: 'normal',
//         lineHeight: '18px',
//         paddingBottom: 8,
//       }}
//     >
//       {distance}
//     </div>
//   )
// }

const styles = {
  today: {
    date: '#0659FD',
    circle: '#0659FD',
    circleSize: 10,
    line: '#82ACFE',
    day: '#0659FD',
  },
  normal: {
    date: '#000',
    circle: '#CDDEFF',
    circleSize: 10,
    line: '#eee',
    day: '#B3B3B3',
  },
  empty: {
    date: '#000',
    circle: '#eee',
    circleSize: 6,
    line: '#eee',
    day: '#B3B3B3',
  },
}
