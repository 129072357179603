import { gql } from '@healthblocks-io/apollo'

/** @deprecated Too resource intensive */
export const ThreadsWithLastMessage = gql`
  subscription getThreadsWithLastMessage(
    $limit: Int = 10
    $where: threads_bool_exp = {}
  ) {
    threads(
      order_by: { sent_at: desc_nulls_last }
      limit: $limit
      where: $where
    ) {
      tid
      name
      user
      flow_name
      sent_at
      archived_at
      userByUser {
        uid
        doc
        name
        email
        phone
      }
      messages(limit: 1, order_by: { sent_at: desc_nulls_last }) {
        mid
        body
        sent_at
        sender
      }
    }
  }
`
