import React from 'react'

//
export default function Reminder({ reminder }) {
  return (
    <p className="mb-2">
      {reminder.name || reminder.id} at {reminder.time}
      {reminder.enabled ? (
        <small style={{ color: '#090', display: 'block' }}>enabled</small>
      ) : reminder.enabled === false ? (
        <small style={{ color: 'red', display: 'block' }}>disabled</small>
      ) : null}
    </p>
  )
}
