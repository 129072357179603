import { useEffect, useRef, useState } from 'react'

export default function useAsync<T>(func: () => Promise<T>, deps: any[] = []) {
  const [data, setData] = useState<T>()

  useEffect(() => {
    let mounted = true
    func().then(data => {
      mounted && setData(data)
    })
    return () => (mounted = false)
    // eslint-disable-next-line
  }, deps)

  return data
}

export function useSafeAsync<T>(func: () => Promise<T>, deps: any[] = []) {
  const [data, setData] = useState<T>()
  const version = useRef(0)
  const latest = useRef(0)

  useEffect(() => {
    let mounted = true
    const v = ++latest.current
    func().then(data => {
      if (mounted && v > version.current) {
        version.current = v
        setData(data)
      }
    })
    return () => (mounted = false)
    // eslint-disable-next-line
  }, deps)

  return data
}
