import React, { useCallback, useMemo, useState } from 'react'
import { Line } from 'react-chartjs-2'
import { Link, useLocation } from 'react-router-dom'
import { useFilters, usePagination, useSortBy, useTable } from 'react-table'
import { serialize } from '@bothrs/util/url'
import Modal from '@duik/modal'
import { Select } from '@duik/select'
import type { ChartConfiguration } from 'chart.js'
import maxBy from 'lodash/maxBy'

import 'chartjs-adapter-date-fns'

import { useProfile } from '@healthblocks-io/apollo'
import { useAnalytics, useTrack } from '@healthblocks-io/core/analytics'
import { useUser } from '@healthblocks-io/core/auth'
import {
  BundleLoader,
  useBundle,
  useFHIR,
  useSearch,
} from '@healthblocks-io/core/fhir'
import {
  numericValue,
  observationValue,
  toTimeChart,
  useConstraints,
  useObservationPresets,
  validate,
} from '@healthblocks-io/core/observation'
import { useConfig } from '@healthblocks-io/core/project'
import { useSubject } from '@healthblocks-io/core/subject'
import { useTheme } from '@healthblocks-io/core/theme'
import { Goal, Observation } from '@healthblocks-io/core/types'

import { humanDate, humanDatetime } from 'lib/date-fns'
import { useResourceEditor } from 'lib/editor'
import { useTranslation } from 'lib/i18n'
import { dateOptions, filterTypes, numberOptions } from 'lib/table'
import { toastError } from 'lib/toast'
import { ButtonFeatherIcon, FeatherIcon } from 'pages/atoms/ActivityIcon'
import Button from 'pages/atoms/Button'
import Card, { CardGrid } from 'pages/atoms/Card'
import Input from 'pages/atoms/Input'
import Label from 'pages/atoms/Label'
import MarkdownInput from 'pages/atoms/MarkdownInput'

import { groupBy } from './Calendar'
import { DateEditor, DropdownInput } from './FormFields'

export default function ObservationsPage() {
  const [editing, setEditing] = useState<Observation>()
  const { upsert, remove } = useFHIR()
  const { t } = useTranslation()

  const query = new URLSearchParams(useLocation().search)
  const code = query.get('code')
  const reportName = query.get('report')
  const profile = useProfile()
  const author = {
    reference: profile.uid,
    display: profile.name,
    type: 'Practitioner',
  }
  const subject = useSubject()

  // Every preset has a detail page
  const presets = useObservationPresets()
  const preset =
    presets.find(byCode(code)) || presets.find(p => p.code.text === code)

  // Every report has a detail page
  const diagnosticReports = useConfig(p => p.config.diagnosticReports || [])
  const report =
    diagnosticReports.find(r => r.name === reportName) ||
    diagnosticReports.find(r => r.title === reportName)
  const goals = useSearch<Goal>({ type: 'Goal' })
  const related = goals.data?.entry.filter(g =>
    g.target?.find(t => t.measure?.coding?.find(c => c.code === code))
  )

  const { track } = useAnalytics()
  useTrack('Observations Overview Opened', subject.track)

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        {code ? (
          <div>
            <Link to="?" style={{ fontSize: 13, color: '#818182' }}>
              {t('Observations')}
            </Link>
            <h2 className="mt-0">
              {t(preset ? preset.code.text : code)}
              {preset && (
                <span style={{ color: '#7D7D7D', fontSize: 18 }}>
                  {' '}
                  ({t(preset.valueQuantity?.unit)})
                </span>
              )}
            </h2>

            {related?.map((goal, key) => (
              <p key={key}>{goal.text}</p>
            ))}
          </div>
        ) : report ? (
          <div>
            <Link to="?" style={{ fontSize: 13, color: '#818182' }}>
              {t('Observations')}
            </Link>
            <h2 className="mt-0">{t(report.title)}</h2>
          </div>
        ) : (
          <h2>{t('Observations')}</h2>
        )}
        <Button
          className="m-0"
          primary
          inline
          onClick={() =>
            setEditing(
              emptyObservation({
                author,
                subject: subject.reference,
                ...(preset || presets[0]),
              })
            )
          }
        >
          {t('Add observation')}
        </Button>
      </div>

      <BundleLoader type="Observation">
        {code || report ? (
          <PureObservationsTable2
            setEditing={setEditing}
            code={code}
            report={report}
          />
        ) : (
          <LatestValues />
        )}
        <Modal
          className="modal-top"
          isOpen={!!editing}
          handleClose={() => setEditing(null)}
          closeOnOuterClick
          sm
        >
          <ObservationEditor
            observation={editing}
            save={async resource => {
              try {
                await upsert(resource)
                setEditing(null)
                track(
                  resource.id ? 'Observation Updated' : 'Observation Created'
                )
              } catch (e) {
                alert(t(e.message))
              }
            }}
            remove={() => remove(editing).then(() => setEditing(null))}
          />
        </Modal>
      </BundleLoader>
    </div>
  )
}

function LatestValues() {
  const { t } = useTranslation()
  const data = useBundle<Observation>('Observation').filter(
    o => o.effectiveInstant
  )

  // Prepare report data
  const diagnosticReports = useConfig(p => p.config.diagnosticReports || [])
  const reports = diagnosticReports
    .map(d => {
      const flat = data.filter(o => d.codes.includes(o.code?.coding[0]?.code))
      if (!flat.length) return { ...d, date: '', observations: [] }

      const recent = Math.max(...flat.map(o => Date.parse(o.effectiveInstant)))
      const date = humanDate(new Date(recent))
      const observations = flat.filter(
        o => humanDate(new Date(o.effectiveInstant)) === date
      )
      return { ...d, date, observations }
    })
    .filter(d => d.observations.length)

  // Remove report data from the rest
  const reported = diagnosticReports.flatMap(d => d.codes || [])
  const otherData = data.filter(
    o => !reported.includes(o.code?.coding[0]?.code)
  )

  // Group by code and keep only the most recent
  const codes = Object.values(
    groupBy(otherData, o => o.code?.coding?.[0]?.code)
  ).map(list => maxBy(list, o => new Date(o.effectiveInstant)))

  return (
    <div>
      {codes.length || reports.length ? (
        <CardGrid>
          {codes.map(observation => (
            <Link
              key={observation.id}
              to={
                '?' + serialize({ code: observation.code?.coding?.[0]?.code })
              }
            >
              <ObservationCard
                observation={observation}
                // refresh={refresh}
                // edit={`/${pid}/assess/questionnaires/${q.id}/edit`}
                // onDelete={() => deleteQuestionnaire(q.id).then(refresh)}
              />
            </Link>
          ))}
          {reports.map((report, key) => (
            <Link
              key={key}
              to={'?' + serialize({ report: report.name || report.title })}
            >
              <Card>
                <h2 style={{ fontWeight: 500, fontSize: 20 }}>
                  {t(report.title || 'Diagnostic report')}
                </h2>
                <div style={{ fontSize: 13 }}>{report.date}</div>
                <div style={{ margin: '8px 0' }}>
                  <table className="tbl tbl--mini tbl--compact tbl--card">
                    <tbody>
                      {report.observations.map((obs, key) => {
                        const quantity = observationValue(obs, obs.options)
                        return (
                          <tr key={key}>
                            <td className="th--relevant">
                              {t(obs.code.text) || '?'}
                            </td>
                            <td style={{ textAlign: 'right' }}>
                              {quantity.label
                                ? t(quantity.label)
                                : quantity.value}
                            </td>
                            <td>{t(obs.valueQuantity?.unit)}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="card__action">{t('View history')}</div>
              </Card>
            </Link>
          ))}
        </CardGrid>
      ) : (
        <p>{t('No observations yet')}</p>
      )}
    </div>
  )
}

function ObservationCard({ observation }: { observation: Observation }) {
  const { t } = useTranslation()
  const quantity = observationValue(observation, observation.options)
  return (
    <Card>
      <h2 style={{ fontWeight: 500, fontSize: 20 }}>
        {t(observation.code?.text || 'Observation')}
      </h2>
      <div style={{ marginTop: 10 }}>
        <span style={{ fontSize: 40, lineHeight: 1.2, fontWeight: 600 }}>
          {quantity.label ? t(quantity.label) : quantity.value}{' '}
        </span>
        <span style={{ fontSize: 16, fontWeight: 600, color: '#A5A5A6' }}>
          {t(quantity.unit)}
        </span>
      </div>
      <div style={{ fontSize: 12 }}>
        {humanDatetime(observation.effectiveInstant)}
      </div>
    </Card>
  )
}

function PureObservationsTable2({
  code,
  report,
  setEditing,
}: {
  code: string
  report: { codes: string[] }
  setEditing: (r: Observation) => void
}) {
  const { t } = useTranslation()
  const a = useBundle<Observation>('Observation')
  const theme = useTheme()
  const { sub } = useUser()
  const relevant = useCallback(
    (row: Observation) => row.author?.reference?.endsWith(sub),
    [sub]
  )
  const data = useMemo(
    () =>
      code
        ? a.filter(byCode(code))
        : report
        ? a.filter(o => report.codes.includes(o.code?.coding?.[0]?.code))
        : [],
    [a, code, report]
  )

  const columns = React.useMemo(
    () => [
      {
        id: 'code',
        Header: t('Type'),
        accessor: 'code.text',
        Cell: ({ value }) => t(value),
      },
      {
        id: 'effectiveInstant',
        Header: t('Date and time'),
        accessor: observation => Date.parse(observation.effectiveInstant),
        Cell: ({ value }) => humanDatetime(new Date(value)),
        filter: 'period',
        initialFilterValue: { unit: 'd', value: 14 },
      },
      {
        id: 'value',
        Header: t('Value'),
        accessor: numericValue,
        Cell: ({ row }) => {
          const quantity = observationValue(row.original, row.original.options)
          return quantity.label ? t(quantity.label) : quantity.value
        },
        filter: 'number',
        initialFilterValue: { type: 'gt', value: 0 },
      },
      {
        id: 'author',
        Header: t('Author'),
        accessor: observation => observation.author?.display || '?',
        Cell: ({ row, value }) => (
          <div className={relevant(row.original) ? ' th--relevant' : ''}>
            {value}
          </div>
        ),
        filter: 'fuzzyText',
        initialFilterValue: '',
      },
      {
        id: 'note',
        Header: t('Remark'),
        accessor: observation => observation.note?.map(p => p.text).join('\n'),
        filter: 'fuzzyText',
        initialFilterValue: '',
      },
    ],
    [relevant, t]
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    rows,

    // Filter
    setFilter,
    setAllFilters,

    // Pagination
    page, // Instead of using 'rows', we'll use page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 50,
        sortBy: [{ id: 'effectiveInstant', desc: true }],
      },
      filterTypes,
      // autoResetFilters: false,
      autoResetSortBy: false,
    },
    useFilters,
    useSortBy,
    usePagination
  )

  const allColumnOptions = columns
    .filter(col => col.filter)
    .map(col => ({
      value: col.id,
      label: col.Header,
      col,
    }))
  const columnOptions = allColumnOptions.filter(
    col => !state.filters.find(f => f.id === col.value)
  )

  // Workaround to only rerender when rows actually change
  const chartData = rows.map(obs => numericValue(obs.original)).join()
  const chart = useMemo(() => {
    if (!code) return null
    const timeChart = toTimeChart(rows.map(obs => obs.original))
    const options = rows.find(obs => obs.original.options)?.original.options

    if (!timeChart) return null

    return {
      type: 'line',
      data: {
        labels: timeChart.labels,
        datasets: timeChart.datasets.map(dataset => ({
          label: dataset.label,
          data: dataset.data,
          note: dataset.note,
          tension: 0.2,
          borderWidth: 4,
          borderColor: 'rgba(6, 89, 253, .2)',
          pointBorderColor: theme.primary.color,
          pointStyle: row =>
            dataset.author[row.index] === 'Practitioner' ? 'rectRot' : 'circle',
        })),
      },
      options: {
        elements: {
          point: {
            radius: 7.5,
            borderWidth: 5,
            borderColor: theme.primary.color,
            hoverBorderWidth: 9,
            hoverRadius: 7,
            backgroundColor: '#fff',
          },
        },

        plugins: {
          tooltip: {
            intersect: false,
            displayColors: false,
            callbacks: {
              // Add 'any' so that it does not complain about added note key
              afterLabel(tooltipItems: any) {
                const note = tooltipItems.dataset.note[tooltipItems.dataIndex]
                if (!note) return
                const { text } = note
                return text.length > 13 ? text.slice(0, 10) + '...' : text
              },
            },
          },
          legend: { display: false },
        },
        scales: {
          x: {
            type: 'time',
            grace: '10%',
            offset: true,
            time: {
              unit: 'day',
            },
            adapters: { date: {} },
          },
          y: {
            ticks: options
              ? {
                  callback(value, index, values) {
                    return options.find(opt => opt.value === value)?.label
                  },
                }
              : {},
            grace: '10%',
          },
        },
      },
    } as ChartConfiguration
    // eslint-disable-next-line
  }, [chartData, theme.primary.color, code])

  const chartComponent = useMemo(
    () =>
      chart ? (
        <>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              fontSize: 13,
              color: '#787979',
            }}
          >
            <div
              style={{
                borderRadius: 20,
                border: '4px solid ' + theme.primary.color,
                width: 20,
                height: 20,
                marginRight: 8,
              }}
            />
            {t('Added by patient')}
            <div
              style={{
                border: '4px solid ' + theme.primary.color,
                width: 14,
                height: 14,
                transform: 'rotate(45deg)',
                marginLeft: 24,
                marginRight: 10,
              }}
            />
            {t('Added by care team')}
          </div>
          <Line type="line" width={1200} height={300} {...chart} />
        </>
      ) : null,
    [chart, t, theme.primary.color]
  )

  return (
    <>
      <div>
        {state.filters.map((filter, key) => {
          const column = columns.find(c => filter.id === c.id)
          if (!column || !column.filter) {
            return <span key={key}>Unexpected filter {filter.id}</span>
          }
          return (
            <div
              key={key}
              style={{
                display: 'flex',
                alignItems: 'center',
                margin: '12px 0',
              }}
            >
              <FeatherIcon d="M22 3H2l8 9.46V19l4 2v-8.54L22 3z" size={18} />
              <Select
                style={{ width: 210, margin: '0 8px' }}
                className="select--pretty"
                ButtonComponent={DropdownInput}
                activeOption={allColumnOptions.find(
                  opt => opt.value === filter.id
                )}
                options={columnOptions}
                onOptionClick={({ value }) => {
                  if (value === filter.id) {
                    return // no-op
                  }
                  const opt = columnOptions.find(opt => opt.value === value)
                  if (!value) {
                    return
                  }
                  setAllFilters(v =>
                    v
                      // Remove existing filter because doubles are not allowed
                      .filter(f => f.id !== value)
                      // Replace this filter with newly chosen filter
                      .map(f =>
                        f.id === filter.id
                          ? { id: value, value: opt.col.initialFilterValue }
                          : f
                      )
                  )
                }}
                placeholder="Choose..."
              />
              {column.filter === 'number' ? (
                <>
                  <Select
                    style={{ width: 150, marginRight: 8 }}
                    className="select--pretty"
                    ButtonComponent={DropdownInput}
                    activeOption={numberOptions.find(
                      opt => opt.value === filter.value.type
                    )}
                    options={numberOptions}
                    onOptionClick={({ value }) => {
                      setFilter(filter.id, v => ({ ...v, type: value }))
                    }}
                    placeholder="Choose..."
                  />
                  <Input
                    type="number"
                    style={{ width: 150, marginBottom: 0 }}
                    value={filter.value.value}
                    onChangeText={value => {
                      setFilter(filter.id, v => ({ ...v, value }))
                    }}
                  />
                </>
              ) : column.filter === 'period' ? (
                <>
                  <Select
                    style={{ width: 150, marginRight: 8 }}
                    className="select--pretty"
                    ButtonComponent={DropdownInput}
                    activeOption={
                      dateOptions.find(
                        opt =>
                          opt.value === filter.value.value &&
                          opt.unit === filter.value.unit
                      ) || dateOptions[0]
                    }
                    options={dateOptions}
                    // @ts-ignore
                    onOptionClick={({ value, unit }) => {
                      setFilter(filter.id, { value, unit })
                    }}
                    placeholder="Choose..."
                  />
                  {/* <Input
                    type="number"
                    style={{ width: 150, marginBottom: 0 }}
                    value={filter.value.value}
                    onChangeText={value => {
                      setFilter(filter.id, v => ({ ...v, value }))
                    }}
                  /> */}
                </>
              ) : (
                <Input
                  style={{ width: 150, marginBottom: 0 }}
                  value={String(filter.value)}
                  onChangeText={text => {
                    setFilter(filter.id, text)
                  }}
                />
              )}
              <button
                className="btn--unstyled btn--icon-only"
                onClick={() => {
                  setAllFilters(filters =>
                    filters.filter(f => f.id !== filter.id)
                  )
                }}
              >
                <FeatherIcon d="M15 9L9 15M9 9l6 6" />
              </button>
            </div>
          )
        })}
        {columnOptions.length ? (
          <div style={{ marginLeft: -20 }}>
            <Button
              id="filter-observations"
              data-filter1={state.filters[0]?.id}
              data-filter2={state.filters[1]?.id}
              data-filter3={state.filters[2]?.id}
              primary
              subtle
              inline
              onClick={() => {
                const next = columnOptions[0].col
                if (!next.initialFilterValue) {
                  alert('Must configure column.initialFilterValue')
                }
                setFilter(next.id, next.initialFilterValue)
              }}
            >
              <ButtonFeatherIcon d="M12 5v14M5 12h14" size={18} mr />
              {t('Add filter')}
            </Button>
          </div>
        ) : null}
      </div>

      {chartComponent}

      <div className="tbl__border mt-3">
        <table
          className="tbl tbl--mini tbl--sticky tbl--wide"
          {...getTableProps()}
        >
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th
                    className="th--sort"
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render('Header')}
                    <span className="th__sort">
                      {column.isSorted
                        ? column.isSortedDesc
                          ? ' 🔽'
                          : ' 🔼'
                        : ''}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row)
              return (
                <tr
                  className="tr--clickable"
                  onClick={() => setEditing(row.original)}
                  {...row.getRowProps()}
                >
                  {row.cells.map(cell => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      <div className="pagination mt-2">
        <button
          onClick={() => gotoPage(0)}
          className="btn"
          disabled={!canPreviousPage}
        >
          {'<<'}
        </button>{' '}
        <button
          onClick={() => previousPage()}
          className="btn"
          disabled={!canPreviousPage}
        >
          {'<'}
        </button>{' '}
        <button
          onClick={() => nextPage()}
          className="btn"
          disabled={!canNextPage}
        >
          {'>'}
        </button>{' '}
        <button
          onClick={() => gotoPage(pageCount - 1)}
          className="btn"
          disabled={!canNextPage}
        >
          {'>>'}
        </button>{' '}
        <span className="mx-3">
          {t('Page')}{' '}
          <strong>
            {pageIndex + 1} {t('of')} {pageOptions.length}
          </strong>{' '}
        </span>
        <select
          className="btn"
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value))
          }}
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              {t('Show')} {pageSize}
            </option>
          ))}
        </select>
      </div>
    </>
  )
}

export function ObservationEditor({
  observation,
  save,
  remove,
}: {
  observation: Observation
  save: (p: Observation) => void
  remove: () => void
}) {
  const { t } = useTranslation()
  const [{ data: o }, dispatch] = useResourceEditor<Observation>(observation)
  const constraints = useConstraints(o.code)
  if (!o) {
    return <div>?</div>
  }
  const valid = validate(o, constraints)

  return (
    <form
      style={{ padding: 25 }}
      onSubmit={e => {
        e.preventDefault()
        console.log('observation', o, valid)
        if (valid) {
          save(o)
        } else {
          toastError(t('Invalid observation value'))
        }
      }}
    >
      <h3 style={{ fontWeight: 'bold', margin: '24px 0' }}>
        {t(o.id ? 'Edit an observation' : 'Add an observation')}
      </h3>

      <General o={o} dispatch={dispatch} />

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: o.id ? 'space-between' : 'flex-end',
          marginTop: 24,
        }}
      >
        {o.id ? (
          <Button
            id="delete-observation"
            className="mb-0"
            inline
            error
            type="button"
            onClick={remove}
          >
            {t('Remove observation')}
          </Button>
        ) : null}
        <Button
          id={o.id ? 'update-observation' : 'create-observation'}
          className="mb-0"
          inline
          type="submit"
        >
          {t(o.id ? 'Apply changes' : 'Add observation')}
        </Button>
      </div>
    </form>
  )
}

function General({
  o,
  dispatch,
}: {
  o: Observation
  dispatch: (o: any) => void
}) {
  const { t } = useTranslation()
  const presets = useObservationPresets()

  return (
    <div>
      <div style={{ marginBottom: 20 }}>
        <Label>{t('Type of observation')}</Label>
        <Select
          className="select--pretty"
          ButtonComponent={DropdownInput}
          activeOption={
            o.code ? { label: t(o.code.text) || '?', value: '' } : null
          }
          options={presets.map(preset => ({
            value: JSON.stringify(preset),
            label: t(preset.code?.text) || '?',
          }))}
          onOptionClick={({ value }) => {
            const { code, valueQuantity, component, options } =
              JSON.parse(value)
            if (options) {
              dispatch({
                code,
                options,
                valueQuantity,
                component,
              })
            } else if (component) {
              dispatch({
                code,
                component,
                valueQuantity: undefined,
                options: undefined,
              })
            } else if (!o.valueQuantity?.value) {
              dispatch({
                code,
                valueQuantity,
                component: undefined,
                options: undefined,
              })
            } else {
              dispatch({
                code,
                'valueQuantity.unit': valueQuantity.unit,
                component: undefined,
                options: undefined,
              })
            }
          }}
          placeholder={t('Choose_placeholder')}
        />
      </div>
      <ValueEditor observation={o} dispatch={dispatch} />

      <Label>{t('Date')}</Label>
      <div style={{ maxWidth: 250, marginBottom: 20 }}>
        <DateEditor
          max="now"
          cta={t('Choose_placeholder')}
          value={new Date(o.effectiveInstant)}
          onChange={date => dispatch({ effectiveInstant: date?.toJSON() })}
        />
      </div>
      <Label>{t('Remark')}</Label>
      <MarkdownInput
        value={o.note?.[0]?.text}
        placeholder={t('Your remarks here')}
        onChangeText={text => dispatch({ 'note[0].text': text })}
      />
    </div>
  )
}

function ValueEditor({
  observation,
  dispatch,
}: {
  observation: Observation
  dispatch: (o: any) => void
}) {
  const { t } = useTranslation()
  const quantity = observationValue(observation, observation.options)
  const constraints = useConstraints(observation.code)

  if (observation.options) {
    return (
      <div style={{ marginBottom: 20 }}>
        <Label>{t('Value')}</Label>
        <Select
          className="select--pretty"
          activeOption={
            quantity.label
              ? {
                  label: t(quantity.label) || '?',
                  value: quantity.value,
                }
              : null
          }
          ButtonComponent={DropdownInput}
          placeholder={t(observation.valueQuantity?.unit)}
          options={observation.options}
          onOptionClick={opt => {
            dispatch({ 'valueQuantity.value': opt.value })
          }}
        />
      </div>
    )
  }
  if (observation.valueQuantity) {
    return (
      <SingleNumberEditor
        required
        label={t('Value')}
        unit={t(observation.valueQuantity?.unit)}
        {...constraints}
        value={observation.valueQuantity?.value}
        onChange={value => dispatch({ 'valueQuantity.value': value })}
      />
    )
  }
  if (observation.component) {
    return (
      <>
        {observation.component.map((component, key) => (
          <SingleNumberEditor
            required
            label={component.code?.text || 'Component ' + (key + 1)}
            unit={t(component.valueQuantity?.unit)}
            {...constraints}
            value={component.valueQuantity?.value}
            onChange={value =>
              dispatch({ ['component.' + key + '.valueQuantity.value']: value })
            }
          />
        ))}
      </>
    )
  }
  return null
}

function SingleNumberEditor({
  label = 'Value',
  value,
  unit,
  onChange,
  ...rest
}: {
  value: number
  label?: string
  unit: string
  onChange: (num: number) => void
} & React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>) {
  return (
    <div style={{ position: 'relative' }}>
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          right: 24,
          opacity: 0.5,
          lineHeight: '40px',
          fontSize: 14,
          fontWeight: 500,
        }}
      >
        {unit}
      </div>
      <Input
        label={label}
        type="number"
        {...rest}
        value={typeof value === 'number' ? value : value || ''}
        onChangeText={value => {
          onChange(parseFloat(value))
        }}
      />
    </div>
  )
}

//

function emptyObservation(
  obs: Pick<Observation, 'subject' | 'author' | 'code'>
): Observation {
  return {
    id: '',
    resourceType: 'Observation',
    effectiveInstant: new Date().toJSON(),
    ...obs,
  }
}

// Helpers
function byCode(code: string) {
  return (observation: Observation) =>
    observation.code?.coding?.[0]?.code === code
}
