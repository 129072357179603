import React, { Dispatch, useEffect, useReducer, useState } from 'react'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import { timeout } from '@bothrs/util/async'
import { Dropdown, DropdownItem, DropdownMenuPosition } from '@duik/dropdown'
import Icon from '@duik/icon'
import { Modal } from '@duik/modal'
import type { History } from 'history'
import _set from 'lodash/fp/set'
import { v4 as uuid } from 'uuid'

import { gql, useApolloClient, useQuery } from '@healthblocks-io/apollo'
import { useProject } from '@healthblocks-io/core/project'

import Badge from '@components/Badge'
import { languageLabels } from 'lib/i18n'

import cls from './QuestionnaireDetail.module.scss'

import Button from './atoms/Button'
import Card from './atoms/Card'
import { AlertCircleIcon, PlusIcon, QuestionIcon } from './atoms/Icon'
import Input, { BadgeInput, LimitedInput } from './atoms/Input'
import Label from './atoms/Label'
import SubtleTextarea from './atoms/SubtleTextarea'
import Textarea from './atoms/Textarea'
import TextareaJSON from './atoms/TextareaJSON'
import HealthblocksTopBar from './modules/HealthblocksTopBar'

interface Questionnaire {
  id: string
  name: string
  title: string
  description: string
  date: string
  status: 'draft' | 'active' | 'retired' | 'unknown'
  url: string
  questions: Question[]
}

interface Question {
  id: string
  linkId?: string
  text: string
  type: string
  config?: QuestionConfig
  index: number
}

interface Reply {
  id: string
  title: string
  input?: string
}

type ConditionOperator = '==' | '!=' | '>' | '<' | '>=' | '<='

type ConditionConjunction = 'and' | 'or'

interface Condition {
  linkId?: string
  formula?: string
  operator: ConditionOperator
  value: string | number
}

interface FieldCondition extends Condition {
  linkId: string
}

interface CalculatedCondition extends Condition {
  formula: string
}

interface ConditionGroup {
  conjunction: ConditionConjunction
  conditionSet: (FieldCondition | ConditionGroup)[]
}

interface QuestionConfig {
  t?: {
    [language: string]: {
      text?: string
      fallback?: string
      replies?: Reply[]
    }
  }
  replies?: Reply[]
  optional?: boolean
  fallback?: string
  condition?: ConditionGroup
}

const initialState = {
  questions: null,
  editing: null,
  original: null,
  orderAlert: false,
  deletes: [],
  save: {
    loading: false,
  },
}
const newQuestion = () => ({
  id: uuid(),
  text: '',
  type: 'QuickReplies',
  config: {},
  index: -1,
})
const MemoTopbar = React.memo(() => (
  <HealthblocksTopBar back="../../questionnaires">
    Questionnaires
  </HealthblocksTopBar>
))

// TODO: derive from project config
export default function QuestionnaireDetail() {
  const client = useApolloClient()
  const history = useHistory()
  const { id, tab } = useParams<{ id: string; tab: string }>()
  const toParam = useSearchParams().get('to')
  const { languages: l, defaultLanguage: d } = useProject().config
  const languages = Array.isArray(l) ? l : ['en']
  const defaultLanguage = typeof d === 'string' ? d : 'en'
  const to = languages?.filter(l => l !== defaultLanguage).includes(toParam)
    ? toParam
    : ''
  const result = useQuestionnaire(id)
  const { data, loading, error, refetch } = result
  const [patch, dispatch] = useReducer(patchReducer, initialState, init)

  // TODO how to initialize
  useEffect(() => dispatch({ type: 'result', result }), [result])
  useEffect(() => dispatch({ type: 'history', history }), [history])

  if (loading && !data) {
    return (
      <div>
        <MemoTopbar />
        <div>loading</div>
      </div>
    )
  }

  if (error) {
    return (
      <div>
        <MemoTopbar />
        <div>{JSON.stringify(error)}</div>
      </div>
    )
  }

  const questionnaire: Questionnaire = {
    ...data.questionnaires_by_pk,
    ...patch.editing,
  }
  // patch.questions or patch.editing.questions
  const questions: Question[] =
    patch.questions || data.questionnaires_by_pk?.questions || []

  if (!data || !questions.length) {
    return (
      <div>
        <MemoTopbar />
        <pre>{JSON.stringify(data, null, 2)}</pre>
        <pre>{JSON.stringify(patch.questions, null, 2)}</pre>
        <pre>{JSON.stringify(questionnaire.questions, null, 2)}</pre>
      </div>
    )
  }

  return (
    <div className={cls.full}>
      <MemoTopbar />

      <section className={cls.panes}>
        <section className={cls.questions}>
          <Pills items={['Edit', 'Preview', 'Translate', 'Analytics']} />
          {tab === 'preview' ? (
            <Preview questions={questions} />
          ) : tab === 'analytics' ? (
            <Analytics />
          ) : (
            <Editor {...{ questions, dispatch, to }} />
          )}
          {/* <div>{JSON.stringify(questions)}</div> */}
          {/* <pre>{JSON.stringify(patch, null, 2)}</pre> */}
        </section>
        <section className={cls.main}>
          <div className={cls.saves}>
            <Button
              secondary
              onClick={() => save(client, dispatch, patch, 'draft', refetch)}
            >
              Save as draft
            </Button>
            <Button
              onClick={() => save(client, dispatch, patch, 'active', refetch)}
              disabled={patch.save.loading}
            >
              {patch.save.loading ? 'Publishing...' : 'Publish'}
            </Button>
          </div>
          {patch.orderAlert ? (
            <p>
              The order of the questions in this questionnaire is not
              garantueed. After saving this, it will be fixed.
            </p>
          ) : null}
          <p className="subTitle" style={{ textTransform: 'none' }}>
            {questionnaire.name ? 'ID: ' + questionnaire.name : ''}
          </p>
          <h2 style={{ fontWeight: 700, lineHeight: 1.1 }}>
            <SubtleTextarea
              value={questionnaire.title}
              placeholder="Add title..."
              onChangeText={title => dispatch({ type: 'title', title })}
              lineBreaks={false}
            />
          </h2>
          <p style={{ marginTop: 10 }}>
            <SubtleTextarea
              value={questionnaire.description}
              placeholder="Add description..."
              onChangeText={description =>
                dispatch({ type: 'description', description })
              }
            />
          </p>
          <p className="subTitle mt-4">Languages</p>
          <div className="mt-2">
            <Link to={'?to=' + defaultLanguage} className="mr-2 mb-2">
              <Badge
                tag
                muted={to && to !== defaultLanguage}
                primary={!to || to === defaultLanguage}
              >
                {languageLabels[defaultLanguage]}
              </Badge>
            </Link>
            {languages
              .filter(l => l !== defaultLanguage)
              .map(lang => (
                <Link key={lang} to={'?to=' + lang} className="mr-2 mb-2">
                  <Badge tag muted={to !== lang} primary={to === lang}>
                    {languageLabels[lang]}
                  </Badge>
                </Link>
              ))}
          </div>
          <p className="subTitle mt-4">Status</p>
          <p className="mt-2">
            {patch.original?.status === 'retired' ? (
              <Badge muted>retired</Badge>
            ) : (
              ''
            )}
            {patch.original?.status === 'active' ? (
              <Badge primary>active</Badge>
            ) : (
              ''
            )}
            {patch.original?.status === 'draft' ? (
              <Badge muted>draft</Badge>
            ) : (
              ''
            )}
          </p>
          {/* {JSON.stringify(patch.original?.status)} */}
          {/* {JSON.stringify(questionnaire)} */}
        </section>
      </section>

      {patch.original && !patch.original.name && (
        <TitleNameEditor {...{ patch, dispatch }} />
      )}
    </div>
  )
}

function TitleNameEditor({
  patch,
  dispatch,
}: {
  patch: PatchState
  dispatch: (a: Action) => void
}) {
  const [closed, setClosed] = useState(false)
  const titleState = useState(patch.original.title || '')
  const [title] = titleState
  const nameState = useState('')
  const [name] = nameState
  const save = () => {
    if (title) {
      dispatch({ type: 'name', name: name || slugify(title) })
      dispatch({ type: 'title', title })
      setClosed(true)
    } else {
      alert('Choose a title and identifier first!')
    }
  }
  return (
    <Modal
      isOpen={!patch.original.name && !closed}
      handleClose={save}
      closeOnOuterClick
      style={{ height: 'auto', maxWidth: 400 }}
    >
      <Modal.Body>
        <Modal.Title className="font-weight-bold text-center">
          Choose a title
        </Modal.Title>
        <div className="row mt-5 mb-5">
          <div className="col">
            <Input label="Title" placeholder="" state={titleState} />
            <Input
              label="Identifier"
              placeholder={slugify(title) || ''}
              state={nameState}
            />
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            primary
            className="mb-0"
            style={{ width: 'auto' }}
            onClick={save}
          >
            Save
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

function slugify(text: string) {
  return text
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '_')
    .replace(/[^\w-_]+/g, '')
    .replace(/_+/g, '_')
    .replace(/--+/g, '-')
}

function Analytics() {
  return <div />
}
// function Translate() {
//   return <div></div>
// }

interface EditorProps {
  questions: Question[]
  dispatch: (a: Action) => void
  to: string
}

const REPLY_TYPES = [
  'QuickReplies',
  'MultiSelect',
  'Rating',
  'Number',
  'Text',
  'LongText',
  'BirthDate',
  'Date',
  'Time',
  'Custom1',
  'Custom2',
  'Custom3',
  'Custom4',
  'Custom5',
]

function Editor({ questions, dispatch, to }: EditorProps) {
  return (
    <>
      {questions.map((question: Question, q: number) => (
        <QuestionEditor
          key={question.id || q}
          q={q}
          question={question}
          dispatch={dispatch}
          to={to}
          last={q === questions.length - 1}
        />
      ))}
      <div className={cls.another}>
        <Button inline secondary onClick={() => dispatch({ type: 'append' })}>
          Add item
        </Button>
      </div>
    </>
  )
}

interface QuestionEditorProps {
  question: Question
  q: number
  dispatch: (a: Action) => void
  to: string
  last: boolean
}
function QuestionEditor({
  question,
  dispatch,
  q,
  to,
  last,
}: QuestionEditorProps) {
  const text = to ? question.config.t?.[to]?.text || '' : question.text
  const [title, description] = splitText(text)
  const [title1, description1] = splitText(question.text)
  const fallback = to
    ? question.config.t?.[to]?.fallback || ''
    : question.config.fallback
  const [collapsed, setCollapsed] = useState(!!title)

  const ReplyType = ({ handleToggle }) => (
    <BadgeInput className="mb-0" onClick={handleToggle}>
      <Badge tag primary>
        {question.type}
      </Badge>
      <ChevronDown />
    </BadgeInput>
  )
  const OptionalBadge = ({ handleToggle }) => (
    <BadgeInput className="mb-0" onClick={handleToggle}>
      <Badge tag primary>
        {question.config.optional ? 'Yes' : 'No'}
      </Badge>
      <ChevronDown />
    </BadgeInput>
  )
  const update = (key: string, value: string, to = '') =>
    dispatch({ type: 'update', key, value, to, q })
  const toggle = (key: string, value: boolean, to = '') =>
    dispatch({ type: 'toggle', key, value, to, q })

  const replies = question.config.replies || []

  return (
    <div>
      <div onClick={() => setCollapsed(c => !c)}>
        {collapsed ? (
          <QuestionPreview question={question} q={q} />
        ) : (
          <Card
            className={
              cls.question +
              ' ' +
              (collapsed ? cls.collapsed : cls.expanded) +
              ' ' +
              (question.linkId ? cls.linked : '')
            }
          >
            <div className={cls.questionTitle}>
              <QuestionIcon />
              <h4 className={cls.title + ' mt-1 ml-3'}>
                {(collapsed && title) || 'Question ' + (q + 1)}
              </h4>
              <h4
                className={cls.title + ' mt-1 ml-4 title--muted ' + cls.linkId}
                onClick={stop}
              >
                {collapsed ? (
                  question.linkId
                ) : (
                  <SubtleTextarea
                    value={question.linkId}
                    onChangeText={id => update('linkId', id)}
                    placeholder="Set ID..."
                    lineBreaks={false}
                  />
                )}
              </h4>
              <div style={{ flexGrow: 1 }} />
              <div onClick={stop}>
                <Dropdown
                  onClick={stop}
                  className="d-block"
                  ButtonComponent={ActionsIcon}
                  menuPosition={DropdownMenuPosition['bottom-left']}
                >
                  {!question.config.condition && (
                    <DropdownItem
                      onClick={() =>
                        update('config.condition', {
                          conjunction: 'and',
                          conditionSet: [],
                        } as any)
                      }
                    >
                      <ConditionIcon style={{ margin: '0 18px 0 -7px' }} />
                      Add condition
                    </DropdownItem>
                  )}
                  {q > 0 && (
                    <DropdownItem
                      onClick={() => dispatch({ type: 'move', q, to: q - 1 })}
                    >
                      <ArrowUp className="mr-3" style={{ marginLeft: -6 }} />
                      Move up
                    </DropdownItem>
                  )}
                  {!last && (
                    <DropdownItem
                      onClick={() => dispatch({ type: 'move', q, to: q + 1 })}
                    >
                      <ArrowDown className="mr-3" style={{ marginLeft: -6 }} />
                      Move down
                    </DropdownItem>
                  )}
                  <DropdownItem onClick={() => dispatch({ type: 'delete', q })}>
                    <Icon className="mr-3">close</Icon>Delete
                  </DropdownItem>
                </Dropdown>
              </div>
            </div>

            {!collapsed && (
              <form className="mt-3" onClick={stop}>
                <div className="row">
                  <div className="col col-8">
                    <LimitedInput
                      limit={70}
                      label="Question title"
                      type="text"
                      id={'title' + q}
                      placeholder={to ? title1 : ''}
                      state={[
                        title,
                        title =>
                          update(
                            'text',
                            (title ? '# ' + title + '\n' : '') + description,
                            to
                          ),
                      ]}
                    />
                    {to && <Original text={title1} />}
                  </div>
                  <div className="col col-4">
                    <Label>Reply type</Label>
                    <div>
                      <Dropdown
                        className="d-block"
                        ButtonComponent={ReplyType}
                        closeOnOptionClick
                      >
                        {REPLY_TYPES.map(type => (
                          <DropdownItem
                            key={type}
                            onClick={() => update('type', type)}
                            children={type}
                          />
                        ))}
                      </Dropdown>
                    </div>
                  </div>
                </div>
                <LimitedInput
                  limit={200}
                  label="Question description"
                  type="text"
                  id={'description' + q}
                  placeholder={to ? description1 : ''}
                  state={[
                    description,
                    description =>
                      update(
                        'text',
                        (title ? '# ' + title + '\n' : '') + description,
                        to
                      ),
                  ]}
                />
                {to && <Original text={description1} />}
                {hasReplies(question.type) && (
                  <div className="mt-3">
                    <Label>Replies</Label>
                    <div className="row">
                      {replies.concat(textToReply('')).map((reply, r) => (
                        <div className="col col-4" key={r}>
                          <ReplyEditor {...{ reply, dispatch, q, r, to }} />
                          {to && <Original text={replyToText(reply)} />}
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                <div className="row">
                  <div className="col col-4">
                    <Label>Optional</Label>
                    <Dropdown
                      style={{ marginBottom: 20 }}
                      className="d-block"
                      ButtonComponent={OptionalBadge}
                      closeOnOptionClick
                    >
                      {[true, false].map(type => (
                        <DropdownItem
                          key={type + ''}
                          onClick={() => toggle('config.optional', type)}
                          children={type ? 'Yes' : 'No'}
                        />
                      ))}
                    </Dropdown>
                  </div>
                  {question.config.optional && (
                    <div className="col col-8">
                      <Input
                        label="Data value when no answer provided"
                        type="text"
                        id={'fallback' + q}
                        placeholder={to ? question.config.fallback : ''}
                        state={[
                          fallback,
                          fallback => update('config.fallback', fallback, to),
                        ]}
                      />
                      {to && <Original text={question.config.fallback} />}
                    </div>
                  )}
                </div>
                {question.config.condition && (
                  <div className=" mb-4">
                    <Label>Condition</Label>
                    <TextareaJSON
                      value={question.config.condition}
                      onChangeValue={condition =>
                        update('config.condition', condition as any)
                      }
                    />
                  </div>
                )}
              </form>
            )}
          </Card>
        )}
      </div>
      {last ? (
        <div className={cls.line} />
      ) : (
        <button
          className={cls.insert}
          onClick={evt =>
            growAnimation(evt).then(() => dispatch({ type: 'insert', q }))
          }
        >
          <div className={cls.insertBorder}>
            <div className={cls.insertLabel}>
              <PlusIcon />
            </div>
          </div>
        </button>
      )}
    </div>
  )
}

function ChevronDown() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke="#3B3E45"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M5 7.5l5 5 5-5"
      />
    </svg>
  )
}

function ArrowDown(props) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="-2 -2 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.707 11.113c.39-.392.39-1.025 0-1.416a.995.995 0 00-1.411 0l-6.438 6.446a.504.504 0 01-.86-.353V.984a.979.979 0 00-.99-.984h-.004a.989.989 0 00-1.002.984V15.79c0 .445-.53.668-.845.353L1.7 9.666a.993.993 0 00-1.41 0h.002c-.39.39-.39 1.024 0 1.416l8.311 8.332c.78.781 2.045.781 2.825 0 .18-.181 8.46-8.483 8.28-8.301z"
        fill="currentColor"
      />
    </svg>
  )
}

function ArrowUp(props) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="-2 -2 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.22.94L.292 8.88a1 1 0 101.413 1.414l6.429-6.44a.499.499 0 01.851.354V19c0 .552.456 1 1.008 1h.003a.989.989 0 00.986-1V4.208a.5.5 0 01.853-.354l6.46 6.471a1 1 0 001.412-1.414L11.397.586a1.994 1.994 0 00-2.824 0L8.22.94z"
        fill="#000"
      />
    </svg>
  )
}

function ConditionIcon(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <circle cx={18} cy={18} r={3} />
      <circle cx={6} cy={6} r={3} />
      <path d="M13 6h3a2 2 0 012 2v7" />
      <path d="M6 9L6 21" />
    </svg>
  )
}

function stop(evt: React.MouseEvent<any, MouseEvent>) {
  evt.stopPropagation()
}

async function growAnimation(
  evt: React.MouseEvent<HTMLButtonElement, MouseEvent>
) {
  const duration = 400
  const g = evt.currentTarget
  g.style.transition = 'min-height ' + duration + 'ms'
  g.classList.add(cls.growing)
  evt.currentTarget.scrollIntoView({ behavior: 'smooth', block: 'start' })
  await timeout(500)
  if (g) {
    g.style.minHeight = '500px'
    await timeout(duration + 100)
    g.style.transition = ''
  }
  g.style.minHeight = null
}

function ReplyEditor({ reply, dispatch, q, r, to }) {
  const text = to ? replyToText(reply.t?.[to]) : replyToText(reply)
  return (
    <Input
      className="mb-3"
      type="text"
      id={'reply_' + q + '_' + r}
      placeholder={to ? replyToText(reply) : ''}
      state={[text, text => dispatch({ type: 'replies', q, r, text, to })]}
    />
  )
}

function Original({ text }: { text: string }) {
  if (!text) return null
  return (
    <div className={cls.original} data-language="Original">
      {text}
    </div>
  )
}

function splitText(text: string) {
  return text.startsWith('#') ? text.slice(2).split('\n', 2) : ['', text]
}

function replyToText({ id = '', title = '', input = '' } = {}) {
  if (input) {
    return input
  }
  if (id) {
    return id + ': ' + title
  }
  return title
}

function textToReply(input: string) {
  const [first, last] = input.split(':', 2)
  return last
    ? { input, id: first.trim(), title: last.trim() }
    : { input, id: first.trim(), title: first.trim() }
}

function ActionsIcon({ handleToggle }) {
  return (
    <Icon className={cls.actionsIcon} onClick={handleToggle}>
      more
    </Icon>
  )
}

function hasReplies(type: string) {
  return type === 'QuickReplies' || type === 'MultiSelect'
}

// QuestionEditor > Reducer

type Action =
  | { type: 'name'; name: string }
  | { type: 'title'; title: string; name?: string }
  | { type: 'description'; description: string }
  | { type: 'append' }
  | { type: 'insert'; q: number }
  | { type: 'delete'; q: number }
  | { type: 'move'; q: number; to: number }
  | { type: 'update'; q: number; to: string; key: string; value: string }
  | { type: 'toggle'; q: number; to: string; key: string; value: boolean }
  | { type: 'replies'; q: number; to: string; r: number; text: string }
  | { type: 'history'; history: History }
  | { type: 'reset' }
  | {
      type: 'result'
      result: { data: { questionnaires_by_pk: Questionnaire } }
    }
  | {
      type: 'save'
      status?: string
      result?: { error?: Error; data?: any; loading?: boolean }
    }

interface PatchState {
  history?: History
  questions: null | Question[]
  editing: null | Questionnaire
  original: null | Questionnaire
  // updates: Partial<Question>[]
  // creates: Partial<Question>[]
  deletes: string[]
  orderAlert: boolean
  save: {
    loading?: boolean
    error?: Error
    data?: any
  }
}

function init(state: PatchState) {
  return state
}
function patchReducer(state: PatchState, action: Action): PatchState {
  switch (action.type) {
    // Questionnaire
    case 'name': {
      return _set(`editing.name`, action.name, state)
    }
    case 'title': {
      return _set(`editing.title`, action.title, state)
    }
    case 'description': {
      return _set(`editing.description`, action.description, state)
    }

    // Question
    case 'update': {
      if (action.to) {
        const key = action.key.replace('config.', '')
        return _set(
          `questions[${action.q}].config.t[${action.to}].${key}`,
          action.value,
          state
        )
      }
      return _set(`questions[${action.q}].${action.key}`, action.value, state)
    }
    case 'toggle': {
      return _set(`questions[${action.q}].${action.key}`, action.value, state)
    }
    case 'replies':
      if (action.to) {
        return _set(
          `questions[${action.q}].config.replies[${action.r}].t[${action.to}]`,
          textToReply(action.text),
          state
        )
      }
      return _mergeSet(
        `questions[${action.q}].config.replies[${action.r}]`,
        textToReply(action.text),
        state
      )

    case 'reset':
      return { ...state, ...initialState }
    case 'history':
      return { ...state, history: action.history }
    case 'result':
      if (!action.result.data) {
        console.log('still loading', action)
        return state
      }
      const original = action.result.data.questionnaires_by_pk
      console.log('existing questionnaire', action, original)
      return {
        ...state,
        orderAlert: !!original.questions.find(q => q.index < 0),
        questions: !original?.questions.length
          ? reindex([newQuestion()])
          : reindex(
              JSON.parse(JSON.stringify(original.questions)).sort(
                (a, b) => a.index - b.index
              )
            ),
        original,
      }
    case 'insert':
      const questions = state.questions!.slice()
      questions!.splice(action.q + 1, 0, newQuestion())
      return { ...state, questions: reindex(questions) }
    case 'delete': {
      const questions = state.questions!.slice()
      if (!questions.length) {
        window.alert('Cannot remove last question')
        return state
      }
      const [deleted] = questions!.splice(action.q, 1)
      return { ...state, questions, deletes: state.deletes.concat(deleted.id) }
    }
    case 'append':
      return {
        ...state,
        questions: reindex(state.questions!.concat(newQuestion())),
      }

    case 'move':
      if (action.to < 0) return state
      if (action.to >= state.questions.length) return state
      const moved = state.questions!.slice()
      const item = moved!.splice(action.q, 1)
      moved!.splice(action.to, 0, ...item)
      return { ...state, questions: reindex(moved) }

    case 'save':
      return { ...state, save: action.result || { loading: true } }

    default:
      break
  }
  return state
}

/** Merge deep object */
function _mergeSet(path: string, updates: object, object: any) {
  return Object.entries(updates).reduce(
    (acc, [key, value]) => _set(path + '.' + key, value, acc),
    object
  )
}

function reindex(questions: Question[]) {
  questions.forEach((q, index) => {
    q.index = index
  })
  return questions
}

// QuestionEditor > Actions

async function save(
  client: any,
  dispatch: Dispatch<Action>,
  patch: PatchState,
  status = 'draft',
  refetch: () => void
) {
  const { original } = patch
  const minimum = timeout(500)
  dispatch({ type: 'save', status })
  try {
    // TODO: don't mutate patch
    normalizePatch(patch)
    // pub + draf => Clone to draft
    // draft + draf => Overwrite draft
    // pub + pub => Clone to pub
    // draft + pub => Overwrite draft
    const action = original.status === 'active' ? saveClone : applyPatch
    const result = await action(client, patch, status)

    if (original.status === 'active') {
      const id = result.data?.insert_questionnaires_one.id
      if (id && patch.history) {
        dispatch({ type: 'reset' })
        patch.history?.push('../' + id + '/edit')
        return
      }
      alert('Something went wrong while saving...')
    }

    await refetch()
    await minimum
    dispatch({ type: 'save', result })
  } catch (error) {
    console.log('status, error', original.status, error)
    window.alert('Failed to save: ' + error.message)
    dispatch({ type: 'save', result: { error } })
  }
}

async function saveClone(client: any, patch: PatchState, status: string) {
  const object = {
    ...patch.original,
    ...patch.editing,
    derivedFrom: patch.original.id,
    status,
    ...(patch.questions
      ? {
          questions: {
            data: patch.questions.map(cloneQuestion),
          },
        }
      : null),

    // exclude
    id: undefined,
    __typename: undefined,
    updated_at: undefined,
    created_at: undefined,
    pid: undefined,
    date: undefined,
    stats: undefined,
  }
  return client
    .mutate({
      mutation: gql`
        mutation SaveClone(
          $object: questionnaires_insert_input!
          $name: [String!]
        ) {
          update_questionnaires(
            where: {
              status: { _eq: "active" }
              name: { _in: $name, _is_null: false }
            }
            _set: { status: "retired" }
          ) {
            affected_rows
          }
          insert_questionnaires_one(object: $object) {
            id
            pid
            date
            name
          }
        }
      `,
      variables: {
        name: status === 'active' ? [object.name] : [],
        object,
      },
    })
    .then(ok => {
      console.log('saveclone ok', ok)
      return ok
    })
}

async function applyPatch(client: any, patch: PatchState, status: string) {
  const object = { ...patch.original, ...patch.editing }
  return client
    .mutate({
      mutation: gql`
        mutation ApplyPatch($deletes: [uuid!]!, $object: questionnaires_insert_input!,$name: [String!]) {
          update_questionnaires(
            where: {
              status: { _eq: "active" }
              name: { _in: $name, _is_null: false }
            }
            _set: { status: "retired" }
          ) {
            affected_rows
          }
          delete_questions(where: { id: { _in: $deletes } }) {
            affected_rows
          }
          insert_questionnaires_one(
            object: $object
            on_conflict: {
              constraint: questionnaires_pkey
              update_columns: [${Object.keys(patch.editing || {})
                .concat('status')
                .join(',')}]
            }
          ) {
            id
            pid
            date
          }
        }
      `,
      variables: {
        name: status === 'active' ? [object.name] : [],
        deletes: patch.deletes,
        object: {
          ...patch.editing,
          id: patch.original.id,
          status,
          ...(patch.questions
            ? {
                questions: {
                  on_conflict: {
                    constraint: 'questions_pkey',
                    update_columns: [
                      'text',
                      'type',
                      'config',
                      'linkId',
                      'index',
                    ],
                  },
                  data: patch.questions.map(patchQuestion),
                },
              }
            : null),
        },
      },
    })
    .then(ok => {
      console.log('applypatch ok', ok)
      return ok
    })
}

function patchQuestion({ id, config, text, linkId, index, type }) {
  return {
    id,
    config,
    text,
    linkId,
    type,
    index,
  }
}
function cloneQuestion({ id, config, text, linkId, index, type }) {
  return {
    config,
    text,
    linkId,
    type,
    index,
  }
}

function normalizePatch(patch: PatchState) {
  patch.questions.forEach(q => {
    filterReplies(q.config)
    if (q.config?.t) {
      for (const config of Object.values(q.config.t)) {
        filterReplies(config)
      }
    }
  })
}
function filterReplies(config: { replies?: Reply[] }) {
  if (config?.replies) {
    config.replies = config.replies.filter(r => r.title || r.id)
  }
}

// Preview

interface PreviewProps {
  questions: Question[]
}

function Preview({ questions }: PreviewProps) {
  return (
    <div>
      {questions.map((question: Question, q: number) => (
        <QuestionPreview key={question.id || q} q={q} question={question} />
      ))}
    </div>
  )
}
interface QuestionPreviewProps {
  question: Question
  q: number
}
function QuestionPreview({ question, q }: QuestionPreviewProps) {
  const [title] = question.text.startsWith('#')
    ? question.text.slice(2).split('\n', 2)
    : ['', question.text]

  return (
    <div>
      <Card className={cls.question + ' ' + cls.collapsed}>
        <div className={cls.questionTitle}>
          {title ? <QuestionIcon /> : <AlertCircleIcon />}
          <span className={cls.title + ' mt-1 ml-3'}>
            {title || 'No question title...'}
          </span>
          <span className={cls.title + ' mt-1 ml-4 title--muted ' + cls.linkId}>
            {question.linkId}
          </span>
          <div style={{ flexGrow: 1 }} />
          <Badge tag primary>
            {question.type}
          </Badge>
        </div>
      </Card>
    </div>
  )
}

// Translate

// Analytics

// GraphQL

const QuestionnaireQuery = gql`
  query QuestionnaireDetail($id: uuid!) {
    questionnaires_by_pk(id: $id) {
      id
      name
      title
      description
      date
      status
      url
      updated_at
      created_at
      questions(order_by: { index: asc }) {
        config
        id
        linkId
        text
        type
        index
      }
    }
  }
`

function useQuestionnaire(id: string) {
  return useQuery(QuestionnaireQuery, {
    variables: { id },
    client: useApolloClient(),
    fetchPolicy: 'cache-and-network',
  })
}

// unused
// export function fetchQuestionnaire(client:any,id: string) {
//   return client.query({
//     query: QuestionnaireQuery,
//     variables: { id },
//   })
// }

// Pills

interface PillsProps {
  items: string[]
}

function Pills({ items }: PillsProps) {
  const tab = useParams<{ tab: string }>().tab || 'edit'
  const active = items.find(t => t.toLowerCase() === tab) || items[0]
  return (
    <div className={cls.pills}>
      <div className={cls.inner}>
        {items.map(tab => (
          <Pill key={tab} tab={tab} active={tab === active} />
        ))}
      </div>
    </div>
  )
}

interface PillProps {
  tab: string
  active: boolean
}

function Pill({ tab, active }: PillProps) {
  return (
    <Link
      to={tab.toLowerCase()}
      className={cls.pill + (active ? ' ' + cls.active : '')}
    >
      {tab}
    </Link>
  )
}

// Helpers
function useSearchParams() {
  return new URLSearchParams(useLocation().search)
}
