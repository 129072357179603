import React, { ReactNode, Suspense, useEffect } from 'react'
// import LanguageDetector from 'i18next-browser-languagedetector'
import { I18nextProvider, useTranslation as useI18next } from 'react-i18next'
import { useLocalState } from '@healthblocks-io/core/local'
import { useApi, usePid } from '@healthblocks-io/core/project'
import i18n from 'i18next'
import ChainedBackend from 'i18next-chained-backend'
import HttpApi from 'i18next-http-backend'
import LocalStorageBackend from 'i18next-localstorage-backend' // primary use cache
// import Fetch from 'i18next-fetch-backend'
import MultiLoad from 'i18next-multiload-backend-adapter'

import resources from './translations.json'

export function useTranslation(): {
  t: (key: string, context?: any) => string
  i18n: typeof i18n
} {
  const [t, i18n] = useI18next() as any
  return { t, i18n }
}

function createHandler() {
  const set = {}
  let timeout
  return (tr, ns, key) => {
    set[key] = key
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      console.log(JSON.stringify(set, null, 2))
    }, 1000)
  }
}

export function TranslationProvider({ children }: { children: ReactNode }) {
  const pid = usePid()
  const api = useApi()
  const { debug, obviousTranslations } = useLocalState()

  useEffect(() => {
    if (obviousTranslations)
      i18n.use({
        type: 'postProcessor',
        name: 'obviousTranslations',
        process: () => '✅',
      })
    i18n.use(ChainedBackend).init({
      saveMissing: true,
      missingKeyHandler: createHandler(),
      fallbackLng: 'en',
      keySeparator: false,
      nsSeparator: false,
      parseMissingKeyHandler: debug && ((key: string) => '👉' + key + '👈'),
      postProcess: obviousTranslations && 'obviousTranslations',
      interpolation: {
        prefix: '[',
        suffix: ']',
      },
      backend: {
        backends: [LocalStorageBackend, MultiLoad, LocalStorageBackend],
        backendOptions: [
          {
            prefix: 'i18n_',
            expirationTime: 60 * 60 * 1000,
          },
          {
            backend: HttpApi,
            backendOption: {
              loadPath: api + '/api/projects/' + pid + '/tables/translations',
              allowMultiLoading: true,
              parse(data) {
                console.log('src/hooks/useTranslations parse', data.length)
                const keys = resources
                const langs = Object.keys(resources)

                JSON.parse(data).forEach(row => {
                  langs.forEach(lang => process(row, lang))
                })

                return keys

                function process(row, lang) {
                  const translation = (row[lang] || '').trim()
                  if (translation) {
                    keys[lang].translation[row.key] = translation
                  }
                }
              },
            },
          },
          {
            prefix: 'i18n_',
            expirationTime: 9e9,
          },
        ],
      },
    })
  }, [api, pid, debug, obviousTranslations])
  return (
    <Suspense fallback="loading">
      <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
    </Suspense>
  )
}

export const languageLabels = {
  de: 'German',
  en: 'English',
  es: 'Spanish',
  fr: 'French',
  nl: 'Dutch',
}

export const languageNames = {
  de: 'Deutsch',
  en: 'English',
  es: 'Español',
  fr: 'Français',
  nl: 'Nederlands',
}
