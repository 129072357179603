import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { serialize } from '@bothrs/util/url'

import { useFetch } from './fetch'
import type {
  Activity,
  BundleContext,
  BundleState,
  FHIRClient,
  Resource,
} from './types'
import useMounted from './useMounted'

export const FHIR = createContext<FHIRClient>(
  // @ts-ignore
  null
)

export function FHIRProvider({
  children,
  ctx,
}: {
  children?: ReactNode
  ctx?: any
}) {
  const { getJSON, postJSON, putJSON, patchJSON, deleteJSON } = useFetch(ctx)
  const [events] = useState(emitter)

  const change = (a: any) => {
    events.emit('change', a)
    return a
  }

  const value = useMemo(
    () =>
      ({
        events,
        async create(resource) {
          if (!resource.resourceType) {
            throw new Error('resource resourceType required')
          }
          const a = await postJSON(
            '/api/fhir/' + resource.resourceType,
            resource
          )
          change(a)
          if (a.error) {
            throw new Error(a.error.message)
          }
          return a
        },
        read<T extends Resource>(resource): Promise<T> {
          if (!resource.id) {
            throw new Error('resource id required')
          }
          if (!resource.resourceType) {
            throw new Error('resource resourceType required')
          }
          return getJSON(
            '/api/fhir/' + resource.resourceType + '/' + resource.id
          )
        },
        async update(resource) {
          if (!resource.id) {
            throw new Error('resource id required')
          }
          if (!resource.resourceType) {
            throw new Error('resource resourceType required')
          }
          const a = await putJSON(
            '/api/fhir/' + resource.resourceType + '/' + resource.id,
            resource
          )
          change(a)
          if (a.error) {
            throw new Error(a.error.message)
          }
          return a
        },
        async patch(resource, patch) {
          if (!resource.id) {
            throw new Error('resource id required')
          }
          if (!resource.resourceType) {
            throw new Error('resource resourceType required')
          }
          const a = await patchJSON(
            '/api/fhir/' + resource.resourceType + '/' + resource.id,
            patch
          )
          change(a)
          if (a.error) {
            throw new Error(a.error.message)
          }
          return a
        },
        async upsert(resource) {
          if (!resource.resourceType) {
            throw new Error('resource resourceType required')
          }
          if (!(resource as Resource).id) {
            const a = await postJSON(
              '/api/fhir/' + resource.resourceType,
              resource
            )
            change(a)
            if (a.error) {
              throw new Error(a.error.message)
            }
            return a
          }
          const a = await putJSON(
            '/api/fhir/' +
              resource.resourceType +
              '/' +
              (resource as Resource).id,
            resource
          )
          change(a)
          if (a.error) {
            throw new Error(a.error.message)
          }
          return a
        },
        async remove(resource) {
          if (!resource.id) {
            throw new Error('resource id required')
          }
          if (!resource.resourceType) {
            throw new Error('resource resourceType required')
          }
          const a = await deleteJSON(
            '/api/fhir/' + resource.resourceType + '/' + resource.id
          )
          change(a)
          if (a.error) {
            throw new Error(a.error.message)
          }
          return a
        },
        async search(query = '', params = {}) {
          const bundle = await getJSON(
            '/api/fhir' +
              (query && !query.startsWith('/') ? '/' : '') +
              query +
              '?' +
              serialize(params)
          )
          if (!bundle.entry) {
            console.warn('unexpected search result', query, params, bundle)
          }
          if (bundle.error) {
            throw new Error(bundle.error.message)
          }
          return bundle
        },
        async operation(resource, operation, params) {
          const response = await postJSON(
            '/api/fhir' +
              (resource.resourceType ? '/' + resource.resourceType : '') +
              (resource.id ? '/' + resource.id : '') +
              '/$' +
              operation,
            params
          )
          if (!response.success) {
            console.warn('unexpected operation result', response)
          }
          if (response.error) {
            throw new Error(response.error.message)
          }
          return response
        },
      } as FHIRClient),
    // eslint-disable-next-line
    [getJSON]
  )

  return <FHIR.Provider value={value} children={children} />
}

export function useFHIR() {
  return useContext(FHIR)
}

export function useFHIRActivity() {
  const fhir = useFHIR()
  return {
    upsert(activity: Activity) {
      if (!activity.id) {
        return fhir.create({ ...activity, resourceType: 'CarePlan.Activity' })
      }
      return fhir.update({ ...activity, resourceType: 'CarePlan.Activity' })
    },
    update(activity: Activity) {
      return fhir.update({ ...activity, resourceType: 'CarePlan.Activity' })
    },
    remove(id: string) {
      return fhir.remove({ resourceType: 'CarePlan.Activity', id })
    },
    markCompleted(
      activity: Pick<Activity, 'id'> & Partial<Activity>,
      context?: { questionnaire_id; answers } | object
    ) {
      return fhir.operation(
        {
          id: activity.parent_id || activity.id,
          resourceType: 'CarePlan.Activity',
        },
        'complete',
        {
          planned_at: activity.planned_at,
          context,
        }
      )
    },
  }
}

// Helpers

function emitter() {
  return {
    callbacks: {},
    emit(event: string, data: any) {
      this.callbacks[event]?.forEach(cb => cb(data))
      // this.callbacks['*']?.forEach(cb => cb(event, data))
    },
    on(event: string, cb: Function, deps: any[]) {
      // eslint-disable-next-line
      return useEffect(() => {
        if (this.callbacks[event]) {
          this.callbacks[event].push(cb)
        } else {
          this.callbacks[event] = [cb]
        }
        return () => {
          const before = this.callbacks[event].length
          this.callbacks[event] = this.callbacks[event].filter(i => i !== cb)
          if (this.callbacks[event].length !== before - 1) {
            console.warn('Possible hook bug detected', event, before)
          }
        }
        // eslint-disable-next-line
      }, deps)
    },
  }
}

// Load a fhir bundle

export function empty() {
  return {
    data: null,
    loading: false,
    refetch: () => {
      throw new Error('Cannot use bundle outside of BundleContext')
    },
  }
}

export const defaultParams = {}

export const Bundle = createContext<BundleContext>(empty())

export function BundleLoader({
  children,
  type = '',
  params = defaultParams,
}: {
  type?: string
  params?: object
  children: ReactNode
}) {
  const value = useSearch({ type, params })
  return <Bundle.Provider value={value} children={children} />
}

function isFalse() {
  return false
}

export function useSearch<T extends Resource>({
  type = '',
  params = defaultParams,
  isPaused = isFalse,
}: {
  type?: string
  params?: object
  isPaused?: () => boolean
}) {
  const mounted = useMounted()
  const { search, events } = useFHIR()
  const [_value, setValue] = useState<BundleState<T>>(empty)
  const refetch = useCallback(
    () => {
      if (isPaused()) {
        return Promise.resolve()
      }
      setValue(v => ({ ...v, loading: true }))
      return search<T>(type, params)
        .then(data => {
          if (!mounted.current) return
          if (data.entry) {
            setValue({ data, loading: false })
          } else {
            setValue({
              data: null,
              loading: false,
              error: new Error('Unexpected error'),
            })
          }
        })
        .catch(error => {
          setValue(v => ({ ...v, loading: false, error }))
        })
    },
    // eslint-disable-next-line
    [search, type, serialize(params), isPaused()]
  )

  useEffect(() => {
    refetch()
  }, [refetch])

  events.on(
    'change',
    a => {
      if (!a?.type || a.type === type) {
        refetch()
      }
    },
    []
  )

  return useMemo(() => ({ ..._value, refetch }), [_value, refetch])
}

export function useBundle<T extends Resource>(type: string): T[] {
  const bundle = useContext(Bundle).data
  return useMemo(
    () => bundle?.entry.filter(e => e.resourceType === type) || [],
    [bundle, type]
  ) as T[]
}

// Questionnaire helper

export function answerString(
  answer:
    | string
    | { valueInteger?: number; valueString?: string; reply?: string }[]
) {
  if (!answer) {
    return '?'
  }
  if (typeof answer === 'string') {
    return answer
  }
  if (Array.isArray(answer)) {
    return answer.map(a => a.valueString || a.reply).join('\n')
  }
  return JSON.stringify(answer).slice(0, 100)
}

/**
 * Question.text has this structure:
 *
 *     # Title
 *
 *     description
 *
 */
export function questionTitleString(question: { text: string }) {
  return (question?.text || '').split('\n')[0].slice(2)
}

const allowed = ['author', 'sender', 'subject']

/** Resolve the value of the policy condition */
export function fhirGet<T>(property: string, resource: T) {
  if (!allowed.includes(property) || !resource) return
  return (
    resource[property]?.reference
      /** @todo Remove this workaround */
      .replace('Patient/', '')
      .replace('Practitioner/', '')
  )
}

/** Resolve the value of the policy condition conditionally */
export function fhirGetter<T>(property: string, fetcher: () => T) {
  if (!allowed.includes(property)) return
  return fhirGet(property, fetcher())
}

/** Resolve the value of the policy condition conditionally asynchronously */
export async function fhirGetterAsync<T>(property: string, fetcher: () => T) {
  if (!allowed.includes(property)) return
  return fhirGet(property, await fetcher())
}
