import React, { useState } from 'react'
import cls from './UserInfo.module.scss'
import moment from 'moment'

import Avatar from '@duik/avatar'
import Checkbox from '@duik/checkbox'
import Button from 'pages/atoms/Button'

import { gql, useApolloClient } from '@healthblocks-io/apollo'
import Input from 'pages/atoms/Input'
import inlineAvatar from 'lib/avatar'

const UpdateUserGroup = gql`
  mutation UpdateUserGroup($uid: String!, $group: String) {
    update_users(where: { uid: { _eq: $uid } }, _set: { group: $group }) {
      affected_rows
    }
  }
`

const UserInfo = ({ user, groups, resetAssessment }) => {
  const client = useApolloClient()
  const [flowName, setFlowName] = useState([])

  if (!user) return <div className={cls.userInfoSidebar} />

  const handleGroup = evt => {
    const { value: group } = evt.target
    if (group !== user.group) {
      client.mutate({
        mutation: UpdateUserGroup,
        variables: {
          uid: user.uid,
          group,
        },
      })
    }
  }

  return (
    <div className={cls.userInfoSidebar}>
      <Avatar
        className={cls.userName}
        imgUrl={inlineAvatar(user.uid)}
        name={user.name || user.email || user.doc.name || user.doc.email}
      />

      <h4>Key insights</h4>
      <dl>
        {/* <dt>App spent time</dt>
        <dd>1h 35min</dd> */}
        <dt># Assessments</dt>
        <dd>{user.messages.filter(m => m.doc.startFlow).length}</dd>
        <dt># Interaction</dt>
        <dd>{user.messages_aggregate.aggregate.count}</dd>
        <dt>Last update</dt>
        <dd>
          {moment(user.messages.length && user.messages[0].sent_at).fromNow()}
        </dd>
      </dl>

      <h4>General information</h4>
      <datalist id="user_groups">
        {groups.map(g => (
          <option key={g} value={g} />
        ))}
      </datalist>
      <dl>
        {!user.doc.pushTokens ? (
          <dt style={{ color: '#a00' }}>No push notifications</dt>
        ) : null}
        <dt>Group</dt>
        <dd>
          <Input
            className="pr-2"
            defaultValue={user.group || ''}
            onChange={handleGroup}
            list="user_groups"
          />
        </dd>
        <dt>Email address</dt>
        <dd>
          {user.email || user.doc.email ? (
            <a href={`mailto:${user.email || user.doc.email}`}>
              {user.email || user.doc.email}
            </a>
          ) : (
            'no email could be retrieved'
          )}
        </dd>
        <dt>Age</dt>
        <dd>{user.doc.age} years old</dd>
        <dt>Gender</dt>
        <dd>{user.doc.gender ? user.doc.gender[0]?.reply : '/'}</dd>
        <dt>User since</dt>
        <dd>{moment(user.created_at).format('LL')}</dd>
      </dl>

      <h4>Additional data</h4>
      <dl>
        <dt>Main goals</dt>
        <dd>
          {user.messages.find(x => x.doc.replyTo === 'goals')
            ? user.messages
                .find(x => x.doc.replyTo === 'goals')
                .doc.reply.map(x => <div key={x.reply}>{x.reply}</div>)
            : '/'}
        </dd>
      </dl>

      {resetAssessment && (
        <>
          <h3>Start assessment</h3>
          <p>Suggests the user to redo these assessments.</p>
          {['hair', 'nails', 'skin', 'joints'].map((f, key) => (
            <div key={key} className="mb-2">
              <Checkbox
                label={f}
                value={f}
                checked={flowName.includes(f)}
                name="flowName[]"
                onChange={evt => {
                  const { value } = evt.nativeEvent.target as any
                  setFlowName(flows =>
                    flows.includes(value)
                      ? flows.filter(f => f !== value)
                      : flows.concat(value)
                  )
                }}
              />
            </div>
          ))}
          <p className="mt-4">
            <Button onClick={() => resetAssessment(flowName)}>
              {flowName.length ? 'Send' : 'Reset'}
            </Button>
          </p>
        </>
      )}
    </div>
  )
}

export default UserInfo
