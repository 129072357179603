import React, { useState } from 'react'
import useSWR from 'swr'

import { useFetch } from '@healthblocks-io/core/fetch'
import cls from './assessmentReview.module.scss'

import HealthblocksTopBar from './modules/HealthblocksTopBar'
import ReviewAssessmentBlock from './modules/ReviewAssessmentBlock'
import Reviews from './Reviews'

interface Reviewable {
  name: string
  uid: string
  bodypart: string
  lastAssessment: string
  validated: boolean
  mid: string
  tid: string
  search: string
}

const AssessmentReviewPage = () => {
  const { getJSON } = useFetch()
  const { data: users } = useSWR<Reviewable[]>(
    '/api/custom/biosil_assessment_review',
    getJSON
  )
  const [fff, setFilter] = useState('')

  return (
    <div>
      <HealthblocksTopBar>
        <div className={cls.header}>
          User overview
          <span>
            <i className="fa fa-filter" aria-hidden="true" />
            <input onChange={e => setFilter(e.target.value)} />
          </span>
        </div>
      </HealthblocksTopBar>

      <section className="scroll-area-main">
        <ReviewAssessmentBlock
          totalReviews={users?.length}
          users={users?.filter(u => u.search.includes(fff.toLowerCase()))}
        />
        <Reviews />
      </section>
    </div>
  )
}

export default AssessmentReviewPage
