import React, { useEffect, useMemo, useState } from 'react'

import { uniq } from '@bothrs/util/uniq'
import { ls } from '@bothrs/util/ls'

// Why are atoms inside pages directory?
import Input from 'pages/atoms/Input'
import { Link } from 'react-router-dom'
import { unsafeDecode } from 'lib/jwt'

export default function ProjectPicker() {
  // Load all project data from storage
  const pids = useMemo(
    () =>
      Object.keys(localStorage)
        .map(key => {
          const [pid, type, , pid2, , type2] = key.split('/')
          if (pid2 && ['project', 'auth'].includes(type2)) {
            return pid2
          }
          if (pid && ['project', 'auth'].includes(type)) {
            return pid
          }
          return ''
        })
        .filter(Boolean)
        .filter(uniq)
        .reverse(),
    []
  )

  // Refresh now to improve load time later
  useEffect(() => {
    console.log('todo: reload project config if old')
    // pids.forEach(pid => project.refetch({ maxAge: 3600, maxStale: 9e5 }))
    // eslint-disable-next-line
  }, [])

  // Get all relevant info
  const projects = pids
    .map(pid => ({
      pid,
      project: ls(pid + '/project'),
      auth: ls(pid + '/auth'),
    }))
    .sort((a, b) =>
      ((b.project && b.project.fetchedAt) || '0').localeCompare(
        (a.project && a.project.fetchedAt) || '0'
      )
    )

  if (!projects.length) {
    return <EmptyHistory />
  }

  return (
    <Wrapper>
      <h2>Your projects</h2>
      <div className="card-deck">
        {projects.map(({ pid, project, auth }) => (
          <div
            className="card "
            key={pid}
            style={{ minWidth: 240, marginBottom: '2em' }}
          >
            <div className="card-body">
              <h5 className="card-title">{(project && project.name) || pid}</h5>
              <p className="card-text" style={{ whiteSpace: 'nowrap' }}>
                {auth && auth.id_token
                  ? unsafeDecode(auth.id_token).email
                  : 'not logged in'}
              </p>
              <Link to={'/' + pid} className="btn btn-primary">
                Continue
              </Link>
            </div>
          </div>
        ))}
      </div>
    </Wrapper>
  )
}

function EmptyHistory() {
  const [pid, setPid] = useState('')
  return (
    <Wrapper>
      <div className="card-deck">
        <div className="card " style={{ minWidth: 240, marginBottom: '2em' }}>
          <div className="card-body">
            <h5 className="card-title">What project are you looking for?</h5>
            <Input
              type="text"
              onChange={evt => setPid(evt.target.value)}
              placeholder="project id"
            />

            <a
              href={'/' + pid.toLowerCase().trim()}
              className="btn btn-primary"
            >
              Open
            </a>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

function Wrapper({ children }) {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100%',
        padding: '2em',
      }}
    >
      <div>{children}</div>
    </div>
  )
}
