import React, {
  createContext,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { getJSON } from '@bothrs/util/fetch'
import { serialize } from '@bothrs/util/url'

import debug from './log'
import type {
  AirtableQuestion,
  AirtableReply,
  PublicProject,
  Question,
  Reply,
} from './types'
import { emptyAutoLoadable, Loadable } from './loadable'
import storage from './storage'
import useMounted from './useMounted'

const { log, warn } = debug('core/project')

export interface ProjectState {
  pid: string
  api: string
  app: string
  graph?: string

  data: PublicProject | null
  error: Error | null
  loading: boolean

  /** @todo Consider returning a Promise? */
  refetch: (options?: FetchProjectOptions) => void
}

export const Project = createContext<ProjectState>(
  // @ts-ignore
  null
)

function apiFallback() {
  return (
    // tslint:disable-next-line
    (typeof window !== 'undefined' && window['healthblocksApp']?.api) ||
    'https://api.healthblocks.io'
  )
}

export function ProjectProvider({
  children,
  pid,
  app = 'app',
  api = apiFallback(),
  graph,
  Loading,
}: {
  children: ReactNode
  pid: string
  app?: string
  api: string
  graph?: string
  lazy?: boolean
  Loading?: ReactElement | null
}) {
  const key = api + '/' + pid + '/' + app + '/project'
  const fetching = useRef('')
  const mounted = useMounted()
  const [state, setState] = useState<Loadable<PublicProject>>(initial(key))
  const handleData = useCallback(
    data => {
      if (!mounted.current) return
      if (!data || !data.pid)
        return console.warn('Unexpected project data', data)
      setState({ data, error: null, loading: false })
      storage.setItem(key, JSON.stringify(data))
    },
    [key]
  )
  const refetch = useCallback(
    (options?: FetchProjectOptions) => {
      fetching.current = api + pid
      const start = Date.now()
      findProject(api, pid, options || { maxAge: 0 })
        .then((data: any) => {
          if (fetching.current === api + pid) {
            log('fetchedAt', pid, data.fetchedAt, Date.now() - start)
            handleData(data)
          } else {
            log('misfetch', pid, data.fetchedAt, Date.now() - start)
          }
        })
        .catch(console.error)
    },
    [pid, api]
  )

  useEffect(() => {
    if (state.data && Date.parse(state.data.fetchedAt) > Date.now() - 36e5) {
      return console.log(
        'Already fetched project',
        (Date.now() - Date.parse(state.data.fetchedAt)) / 1000,
        'seconds ago'
      )
    }
    Promise.resolve(storage.getItem(key)).then(json => {
      try {
        const data = JSON.parse(json!)
        data && handleData(data)
      } catch (e) {}
    })

    refetch()
  }, [key, refetch])

  if (typeof Loading !== 'undefined') {
    return null
    // return Loading ? <Loading /> : null
  }

  return (
    <Project.Provider
      value={{
        pid,
        api,
        app,
        graph: state.data?.env.graph || graph,
        data: state.data,
        error: state.error,
        loading: state.loading,
        refetch,
      }}
      children={children}
    />
  )
}

function initial(key: string) {
  return () => {
    if (typeof localStorage === 'undefined') return emptyAutoLoadable()
    try {
      return {
        data: JSON.parse(localStorage[key]),
        error: null,
        loading: false,
      }
    } catch (e) {
      return emptyAutoLoadable()
    }
  }
}

export function useProjectContext() {
  const state = useContext(Project)
  if (!state)
    throw new Error('Wrap your app with <ProjectProvider pid="..." />')
  return state
}

export function useProject(): PublicProject {
  const state = useContext(Project)
  if (!state)
    throw new Error('Wrap your app with <ProjectProvider pid="..." />')
  if (!state.data) throw new Error('Project is not yet loaded')
  return state.data
}

export function useApi() {
  return useContext(Project).api
}

export function useGraphURL() {
  return useContext(Project).graph
}

export function useApp(): string {
  const state = useContext(Project)
  if (!state)
    throw new Error(
      'Wrap your app with <ProjectProvider pid="..." app="..." />'
    )
  return state.app
}

export function usePid(): string {
  const state = useContext(Project)
  if (!state)
    throw new Error('Wrap your app with <ProjectProvider pid="..." />')
  return state.pid
}

export function useConfig<T>(selector: (p: PublicProject) => T): T {
  return selector(useProject())
}

export function useAuth0(app = 'app') {
  const project = useProject()
  if (app === 'app') return project.auth0?.app || project.auth0
  if (app === 'dashboard') return project.auth0?.dashboard || project.auth0
  return project.auth0
}

/** Returns true when the project is in fallback mode */
export function useProjectFallback() {
  return !useContext(Project)?.data
}

type FetchProjectOptions = {
  maxAge?: number
  maxStale?: number
}

/** Fetch project details and validate response */
async function findProject(
  api: string,
  pid: string,
  options: FetchProjectOptions = {}
): Promise<PublicProject> {
  // Validate request
  if (!pid) {
    throw new Error('Invalid pid')
  }

  if (!api) {
    throw new Error('App not initialized')
  }

  // Network request
  const r = await getJSON(
    api + '/api/projects/' + pid + '?' + serialize(options)
  )

  // Validate response
  if (!r) {
    throw new Error('No response from server')
  }
  if (r.error) {
    throw new Error(r.error.message || 'An error occurred')
  }
  if (!r.pid || !r.config) {
    warn('fetchProjectNow invalid', r)
    throw new Error('Invalid response from server')
  }
  if (r.pid !== pid) {
    log('Invalid pid from refresh', r.pid, pid)
    throw new Error('Invalid pid from server')
  }

  // Instrument response
  if (!r.fetchedAt) {
    r.fetchedAt = new Date().toJSON()
  }

  return r
}

// Helpers

export function resolveAssessmentData(data: any): PublicProject {
  const { replies, questions = [], ...rest } = data

  if (!questions?.length) {
    console.error('no questions', data)
  }
  return {
    ...rest,
    questions: questions.map(
      (q: AirtableQuestion): Question => ({
        ...q,
        id: q.id || q._id,
        question: q.question || q.id || q._id,
        images: q.images,
        replyType: q.replyType || (q.replies?.length ? 'QuickReplies' : 'Text'),
        replies: q.replies
          ?.map((id: string) =>
            replies?.find((r: AirtableReply) => r._id === id)
          )
          .filter(Boolean)
          .map(normalizeReply),
      })
    ),
  }
}

function normalizeReply(r: AirtableReply): Reply {
  return {
    ...r,
    id: r.id || r.reply,
    reply: r.reply || r.id,
  }
}
