import React from 'react'

import { useCareTeam } from '@healthblocks-io/core/careteam'
import { useConfig } from '@healthblocks-io/core/project'
import { Component } from '@healthblocks-io/core/types'
import { useUserEditor } from '@healthblocks-io/core/user'

import ProfileComponents from '@components/ProfileComponents'

interface ProfileProps {
  uid: string
}

const fallback: Component[] = [
  {
    slot: 'right',
    props: { title: 'Calendar', border: true, padding: true },
    component: 'hb:Calendar',
  },
]

export default function ProfilePage({ uid }: ProfileProps) {
  const care = useCareTeam()
  const { user, patch, refetch, error } = useUserEditor({ uid })

  let sections = useConfig(p => p.config.userDetailOverview || fallback)
  if (!user && !error) {
    return <div>Loading...</div>
  }
  if (!user) {
    return <div>User not found</div>
  }
  if (!user.doc) {
    return <div>wut{JSON.stringify(user)}</div>
  }

  sections = sections.filter(s => {
    const resource = s.resource
    if (resource) return care.can({ action: 'read', resource })
    const type = (s.props as any).type
    if (type)
      return care.can({
        action: 'read',
        resource: 'fhir:' + type.replace('fhir:', ''),
      })
    return true
  })

  return (
    <ProfileComponents
      {...{
        user,
        update: patch,
        refetch,
        sections,
        pageName: 'Profile Overview',
      }}
    />
  )
}
