import React, {
  ReactNode,
  Reducer,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
} from 'react'
import { gql, useApolloClient, useSubscription } from '@apollo/client'

import { useUser } from '@healthblocks-io/core/auth'
import debug from '@healthblocks-io/core/log'
import { deepSet } from '@healthblocks-io/core/set'
import type {
  Profile as ProfileType,
  UserDoc,
} from '@healthblocks-io/core/types'

const { log, warn } = debug('apollo/questionnaire')

export const userFields = [
  'birthDate',
  'consented_at',
  'doc',
  'email',
  'gender',
  'group',
  'language',
  'name',
  'notes',
  'phone',
  'role_title',
  'role',
  'timezone',
  'uid',
]

const ProfileSubscription = gql`
  subscription Profile($uid: String!) {
    users_by_pk(uid: $uid) {
      birthDate
      consented_at
      doc
      email
      gender
      group
      language
      name
      phone
      role
      role_title
      timezone
      uid
      checkouts {
        id
        doc
      }
    }
  }
`

const ProfileQuery = gql`
  query Profile($uid: String!) {
    users_by_pk(uid: $uid) {
      birthDate
      consented_at
      doc
      email
      gender
      group
      language
      name
      phone
      role
      role_title
      timezone
      uid
      checkouts {
        id
        doc
      }
    }
  }
`

const placeholder: ProfileType = {
  doc: {},
  pid: '',
  uid: '',
  role: '',
  checkouts: [],
}

/** @todo clear after logout (free) */
/** @todo split update fields */
/** @todo signout when profile missing */
/** @todo set timezone on first use */
export function useProfileSubscription() {
  const uid = useUser()!.sub
  return useSubscription<{ users_by_pk: ProfileType }, { uid: string }>(
    ProfileSubscription,
    { variables: { uid } }
  )
}

/** @todo clear after logout (free) */
/** @todo split update fields */
/** @todo signout when profile missing */
/** @todo set timezone on first use */
export function useProfileQuery() {
  const uid = useUser()!.sub
  return useSubscription<{ users_by_pk: ProfileType }, { uid: string }>(
    ProfileQuery,
    { variables: { uid } }
  )
}

export function useProfile() {
  return useProfileQuery().data?.users_by_pk || placeholder
}

export const UpdateProfileMutation = gql`
  mutation UpdateProfile($uid: String!, $top: users_set_input!, $doc: jsonb!) {
    update_users_by_pk(
      pk_columns: { uid: $uid }
      _set: $top
      _append: { doc: $doc }
    ) {
      uid
      __typename
    }
  }
`

/** Immediately apply profile updates */
export function useProfileUpdate() {
  const client = useApolloClient()
  const uid = useUser()!.sub
  const update = useCallback(
    (top: Partial<ProfileType>, doc: Partial<UserDoc>) =>
      client
        .mutate<any, { uid: string; top; doc }>({
          mutation: UpdateProfileMutation,
          variables: { uid, top, doc },
          refetchQueries: [{ query: ProfileQuery }],
        })
        .then((result: any) => {
          if (!result?.data) {
            warn('useProfileUpdate.data', result)
          }
        })
        .catch((e: any) => {
          warn('useProfileUpdate.error', e)
        }),
    [client, uid]
  )
  return useMemo(
    () => ({
      updateProfile: (top: Partial<ProfileType>) => update(top, {}),
      updateDoc: (doc: Partial<UserDoc>) => update({}, doc),
    }),
    [update]
  )
}

function createEditor(initial: any) {
  return {
    original: initial,
    top: {},
    doc: {},
    saving: false,
    data: initial,
    version: 0,
    ref: { latest: 0, online: 0 },
  }
}

export function useProfileEditor({ debounce = 1000 } = {}) {
  const client = useApolloClient()
  const uid = useUser()!.sub

  const initial = useProfile()
  const [editor, dispatch] = useReducer<
    Reducer<EditorState<ProfileType>, EditorAction<ProfileType>>
  >(profileEditorReducer, createEditor(initial))

  const updateProfile = useCallback(
    (top: Partial<ProfileType>) => dispatch({ type: 'top', top }),
    []
  )
  const updateDoc = useCallback(
    (doc: Partial<UserDoc>) => dispatch({ type: 'doc', doc }),
    []
  )
  const flush = useCallback(async (editor: EditorState<ProfileType>) => {
    log('useProfileEditor.persist', editor.top, editor.doc)
    await client.mutate<
      any,
      { uid: string; top: Partial<ProfileType>; doc: Partial<UserDoc> }
    >({
      mutation: UpdateProfileMutation,
      variables: { uid, top: editor.top, doc: editor.doc },
      refetchQueries: [],
    })
    dispatch({ type: 'online', online: editor.version })
  }, [])
  const submit = useCallback(async () => {
    if (editor.ref.online < editor.ref.latest) {
      await flush(editor)
    }
  }, [])

  useEffect(() => {
    if (initial.uid !== editor.data?.uid) {
      log('useProfileEditor.uid changed', initial.uid, editor.data?.uid)
      dispatch({ type: 'original', original: initial })
    }
  }, [initial.uid !== editor.data?.uid])

  useEffect(() => {
    if (editor.ref.latest) {
      const t = setTimeout(() => flush(editor), debounce)
      return () => {
        clearTimeout(t)
      }
    }
  }, [editor.ref.latest])

  return { profile: editor.data, updateProfile, updateDoc, dispatch, submit }
}

export function profileEditorReducer<T extends { doc: any }>(
  prev: EditorState<T>,
  action: EditorAction<T>
) {
  if (action.type === 'discard') {
    return { ...prev, data: prev.original }
  }
  if (action.type === 'save') {
    return { ...prev, saving: true }
  }
  if (action.type === 'unsave') {
    return { ...prev, saving: false }
  }
  if (action.type === 'online') {
    // If we saved version X and the current version is X, let's reset
    return action.online === prev.ref.latest
      ? /** @todo Should .original be reset to .data? */
        { ...prev, top: {}, doc: {}, saving: false }
      : prev
  }
  if (action.type === 'original') {
    return createEditor(action.original!)
  }
  if (action.type === 'top') {
    let { data, top } = prev
    for (const key in action.top) {
      if (key !== 'id' && key !== 'type') {
        data = deepSet(data, key, action.top[key])
        top = deepSet(top, key, action.top[key])
      }
    }
    return { ...prev, top, data, version: (prev.ref.latest = prev.version + 1) }
  }
  if (action.type === 'doc') {
    let { data, doc } = prev
    for (const key in action.doc) {
      if (key !== 'id' && key !== 'type') {
        data = deepSet(data, 'doc.' + key, action.doc[key])
        doc = deepSet(doc, key, action.doc[key])
      }
    }
    return { ...prev, doc, data, version: (prev.ref.latest = prev.version + 1) }
  }

  console.warn('Unexpected action type', action.type)
  return prev
}

export interface EditorState<T extends { doc: any }> {
  original: T
  top: Partial<T>
  doc: Partial<T['doc']>
  saving: boolean
  /** Current live edited data */
  data: T
  /** Actual version */
  version: number
  /** This ref is mutable */
  ref: {
    /** Latest local version */
    latest: number
    /** Online persisted version */
    online: number
  }
}

export type EditorAction<T extends { doc: any }> =
  | { type: 'discard' | 'save' | 'unsave' }
  | { type: 'original'; original: T }
  | { type: 'top'; top: Partial<T> }
  | { type: 'doc'; doc: Partial<T['doc']> }
  | { type: 'online'; online: number }
