import React, { FC, useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import Tabs, { TabItem } from '@duik/tabs'
import { differenceInYears, format } from 'date-fns'
import useSWR from 'swr'

import { useProfile } from '@healthblocks-io/apollo'
import {
  AnalyticsDataProvider,
  useAnalytics,
} from '@healthblocks-io/core/analytics'
import { CareTeamProvider, useCareTeam } from '@healthblocks-io/core/careteam'
import { useFetch } from '@healthblocks-io/core/fetch'
import { useSearch } from '@healthblocks-io/core/fhir'
import { useLocalState } from '@healthblocks-io/core/local'
import { useConfig } from '@healthblocks-io/core/project'
import { SubjectProvider, useSubject } from '@healthblocks-io/core/subject'
import { CarePlanActivity, Message, Profile } from '@healthblocks-io/core/types'

import PatientCalendar from '@components/PatientCalendar'
import { useTranslation } from 'lib/i18n'

import cls from './UserDetail.module.scss'

import { FeatherIcon, MaterialIcon } from './atoms/ActivityIcon'
import Button from './atoms/Button'
import HealthblocksTopBar from './modules/HealthblocksTopBar'
import { Calendar } from './users/Calendar'
import CarePlan from './users/CarePlan'
import CareTeam from './users/CareTeam'
import { CareTeamGate } from './users/CareTeamGate'
import CommunicationsPage from './users/CommunicationsPage'
import { Conversations } from './users/Conversations'
import { EventsToggle } from './users/Events'
import MigrateGoals1 from './users/MigrateGoals1'
import ObservationsPage from './users/ObservationsPage'
import OverviewPage from './users/OverviewPage'
import ProfilePage from './users/ProfilePage'

interface EventType {
  sentAt: string
  receivedAt: string
  properties: any
  event: string
  id: number
  time: string
}

type ProfileExtended = Profile & { events: EventType[]; messages: Message[] }

export default function UserDetailPage() {
  // Patient subject
  const { uid } = useParams<{ uid: string }>()
  const { fetcher } = useFetch()
  const { data } = useSWR<ProfileExtended>('/api/users/' + uid, fetcher)

  // Practitioner
  const profile = useProfile()

  return (
    <SubjectProvider uid={uid} profile={data}>
      <CareTeamProvider subject={uid} profile={profile}>
        <UserDetailPageContent />
      </CareTeamProvider>
    </SubjectProvider>
  )
}

function UserDetailPageContent() {
  const { uid } = useParams<{ uid: string }>()
  const subject = useSubject()
  const { can } = useCareTeam()
  const { t } = useTranslation()
  const { debug } = useLocalState()

  const location = useLocation()
  const urlTab = location.pathname.split('/')[4]
  const tabs = useConfig(p => {
    const tabs = (
      p.config.userDetailTabs ||
      ['info', 'logs'].concat(
        p.blocks.includes('goals1') ? 'migrate_goals1' : []
      )
    )
      .filter(t => typeof t === 'string')
      .filter(t => {
        if (t === 'communications' || t === 'notes')
          return can({ action: 'read', resource: 'fhir:Communication' })
        if (t === 'calendar' || t === 'careplan')
          return can({ action: 'read', resource: 'fhir:CarePlan' })
        if (t === 'careteam')
          return can({ action: 'read', resource: 'fhir:CareTeam' })
        if (t === 'info')
          return can({ action: 'read', resource: 'fhir:Patient' })
        if (t === 'observations')
          return can({ action: 'read', resource: 'fhir:Observation' })
        if (t === 'overview')
          return can({ action: 'read', resource: 'fhir:Patient' })

        return true
      })
    if (debug) {
      tabs.push('logs')
    }
    return tabs
  })
  const tabActive = tabs.includes(urlTab)
    ? urlTab
    : tabs.includes(localStorage.storedTab)
    ? localStorage.storedTab
    : tabs[0]

  return (
    <div className="position-relative">
      <HealthblocksTopBar>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {subject.profile?.name ? (
            <>
              <span className="pl-2 pr-1 d-inline-block">
                <FeatherIcon
                  d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2M8 7a4 4 0 108 0 4 4 0 10-8 0"
                  size={18}
                  color="#BEBEBE"
                />
              </span>
              <span
                style={{
                  color: '#787979',
                  fontWeight: 500,
                  fontSize: 14,
                  fontFamily: 'Inter,sans-serif',
                }}
              >
                {subject.profile.name}
              </span>
            </>
          ) : (
            t('User detail')
          )}

          {subject.profile?.birthDate ? (
            <>
              <span className="pl-4 pr-1 d-inline-block">
                <MaterialIcon
                  d="M12 6a2 2 0 001.7-3L12 0l-1.7 3c-.2.3-.3.6-.3 1a2 2 0 002 2m4.6 10l-1-1-1.2 1a3.6 3.6 0 01-4.8 0l-1.1-1-1.1 1a3.5 3.5 0 01-4.4.4V21a1 1 0 001 1h16a1 1 0 001-1v-4.6a3.5 3.5 0 01-4.4-.4M18 9h-5V7h-2v2H6a3 3 0 00-3 3v1.5A2 2 0 006.3 15l2.2-2.1 2.1 2.1c.8.8 2 .8 2.8 0l2.1-2.1 2.1 2.1c.4.4.9.6 1.4.6 1.1 0 2-.9 2-2V12a3 3 0 00-3-3z"
                  size={18}
                  color="#BEBEBE"
                />
              </span>
              <span
                style={{
                  color: '#787979',
                  fontWeight: 500,
                  fontSize: 14,
                  fontFamily: 'Inter,sans-serif',
                }}
              >
                {format(new Date(subject.profile.birthDate), 'dd/MM/yyyy') +
                  ' ' +
                  '(' +
                  differenceInYears(
                    new Date(),
                    new Date(subject.profile.birthDate)
                  ) +
                  ')'}
              </span>
            </>
          ) : null}
          {subject.profile?.gender ? (
            <>
              <span className="pl-4 pr-1 d-inline-block">
                <MaterialIcon
                  d="M17.58,4H14V2H21V9H19V5.41L15.17,9.24C15.69,10.03 16,11 16,12C16,14.42 14.28,16.44 12,16.9V19H14V21H12V23H10V21H8V19H10V16.9C7.72,16.44 6,14.42 6,12A5,5 0 0,1 11,7C12,7 12.96,7.3 13.75,7.83L17.58,4M11,9A3,3 0 0,0 8,12A3,3 0 0,0 11,15A3,3 0 0,0 14,12A3,3 0 0,0 11,9Z"
                  size={18}
                  color="#BEBEBE"
                />
              </span>
              <span
                style={{
                  color: '#787979',
                  fontWeight: 500,
                  fontSize: 14,
                  fontFamily: 'Inter,sans-serif',
                }}
              >
                {subject.profile.gender}
              </span>
            </>
          ) : null}
        </div>
      </HealthblocksTopBar>

      <UserDetailTabs tabs={tabs} tabActive={tabActive} />

      {tabActive === 'info' ? (
        <ProfilePage uid={uid} />
      ) : tabActive === 'overview' ? (
        <OverviewPage uid={uid} />
      ) : tabActive === 'communications' || tabActive === 'notes' ? (
        <CareTeamGate>
          <div className="scroll-area-tab">
            <CommunicationsPage uid={uid} />
          </div>
        </CareTeamGate>
      ) : tabActive === 'observations' ? (
        <CareTeamGate>
          <div className="scroll-area-tab">
            <ObservationsPage />
          </div>
        </CareTeamGate>
      ) : tabActive === 'careplan' ? (
        <div className="scroll-area-tab">
          <CarePlan uid={uid} />
        </div>
      ) : tabActive === 'careteam' ? (
        <div className="scroll-area-tab">
          <CareTeam />
        </div>
      ) : tabActive === 'logs' ? (
        <div className="scroll-area-tab">
          <EventsToggle uid={uid} />
        </div>
      ) : tabActive === 'conversations' ? (
        <div className="scroll-area-tab">
          <Conversations uid={uid} />
        </div>
      ) : tabActive === 'calendar' ? (
        <Calendar uid={uid} />
      ) : tabActive === 'migrate_goals1' ? (
        <div className="scroll-area-tab">
          <MigrateGoals1 uid={uid} />
        </div>
      ) : (
        <div className="scroll-area-tab pb-0">
          <div>Tab is {tabActive}</div>
        </div>
      )}
    </div>
  )
}

function UserDetailTabs({
  tabs,
  tabActive,
}: {
  tabs: string[]
  tabActive: string
}) {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const { track } = useAnalytics()
  const [previewCareplan, _togglePreviewCareplan] = useState(false)
  const userURL = location.pathname.split('/').slice(0, 4).join('/')

  const subject = useSubject()
  const togglePreviewCareplan = () => {
    _togglePreviewCareplan(prev => !prev)
    track(
      'Care Plan Preview ' + (previewCareplan ? 'Closed' : 'Opened'),
      subject.track
    )
  }
  return (
    <Tabs style={{ alignItems: 'center' }}>
      {tabs.map(tab => (
        <TabItem
          key={tab}
          className={tab === tabActive ? 'active' : ''}
          href={userURL + '/' + tab}
          onClick={evt => {
            evt.preventDefault()
            history.push(userURL + '/' + tab)
          }}
          children={t(tabName(tab))}
        />
      ))}
      <Button
        id="preview-care-plan"
        style={{
          marginLeft: 'auto',
        }}
        inline
        secondary={!previewCareplan}
        onClick={togglePreviewCareplan}
        className=" mb-0"
      >
        <svg
          viewBox="0 0 24 24"
          width={20}
          height={20}
          stroke="currentColor"
          strokeWidth={2}
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="mr-2 mb-0"
        >
          <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />
          <circle cx={12} cy={12} r={3} />
        </svg>
        {t('Preview care plan')}
      </Button>
      <SidebarContainer
        onClose={togglePreviewCareplan}
        visible={previewCareplan}
        component={CalendarSidebar}
      />
    </Tabs>
  )
}

function SidebarContainer({
  component: Component,
  onClose,
  visible,
}: {
  component: FC<{ onClose?: () => void; expanded?: boolean }>
  onClose: () => void
  visible: boolean
}) {
  const [state, setState] = useState<'unmounted' | 'collapsed' | 'expanded'>(
    'unmounted'
  )
  useEffect(() => {
    if (state === 'unmounted' && visible) {
      setState('expanded')
    }
    if (state === 'expanded' && !visible) {
      setState('collapsed')
      setTimeout(() => {
        setState('unmounted')
      }, 500)
    }
  }, [visible, state])

  return state !== 'unmounted' || visible ? (
    <Component expanded={state === 'expanded'} onClose={onClose} />
  ) : null
}

function CalendarSidebar({
  onClose,
  expanded,
}: {
  onClose: () => void
  expanded: boolean
}) {
  const { t } = useTranslation()
  const activities = useSearch<CarePlanActivity>({
    type: 'CarePlan.Activity',
    params: { subject: useSubject().uid },
  })
  return (
    <AnalyticsDataProvider properties={{ area: 'sidebar' }}>
      <div
        className={(expanded ? '' : cls.collapsed + ' ') + cls.calendarSidebar}
      >
        <div
          style={{
            width: 350,
            height: 50,
            backgroundColor: '#f4f4f4',
            display: 'flex',
            alignItems: 'center',
            boxShadow: '0 4px 4px rgba(0, 0, 0, .05)',
          }}
        >
          <h2 style={{ marginLeft: 24, flex: 1, fontSize: 14 }}>
            {t('Care plan')}
          </h2>
          <div
            style={{ marginTop: 0, paddingRight: 24, cursor: 'pointer' }}
            onClick={onClose}
          >
            <svg
              style={{ display: 'block' }}
              xmlns="http://www.w3.org/2000/svg"
              width={18}
              height={18}
              viewBox="0 0 24 24"
              fill="#0659FD"
              stroke="#0659FD"
              strokeWidth={1}
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
            </svg>
          </div>
        </div>

        {activities.data?.entry ? (
          <PatientCalendar
            activities={activities.data.entry}
            refetch={activities.refetch}
          />
        ) : null}
      </div>
    </AnalyticsDataProvider>
  )
}

function tabName(tab: string) {
  if (tab === 'careplan') {
    return 'Care plan'
  }
  if (tab === 'careteam') {
    return 'Care team'
  }
  if (tab === 'info') {
    return 'Patient information'
  }
  if (tab === 'migrate_goals1') {
    return 'Migration'
  }
  if (tab === 'communications') {
    return 'Notes'
  }
  return tab.charAt(0).toUpperCase() + tab.slice(1)
}
