import React, { memo, useCallback, useMemo } from 'react'
import format from 'date-fns/format'
import startOfDay from 'date-fns/startOfDay'
import Checkbox from '@duik/checkbox'
import Radio from '@duik/radio'

import { gql, useQuery } from '@healthblocks-io/apollo'
import { useActivityKindOptions } from '@healthblocks-io/core/activity'
import { useContent } from '@healthblocks-io/core/content'
import { useConfig, useProject } from '@healthblocks-io/core/project'

import cls from '../users.module.scss'
import clsInput from '../atoms/Input.module.scss'
import Dropdown, { DropdownItem } from '@duik/dropdown'
import Icon from '@duik/icon'
import Input, { InputOnly } from 'pages/atoms/Input'
import Button from 'pages/atoms/Button'
import ActivityIcon from 'pages/atoms/ActivityIcon'
import { ActivityDoc } from '@healthblocks-io/core/types'
import Label from 'pages/atoms/Label'
import { useTranslation } from 'lib/i18n'

export function TextAction({ handleToggle, children }) {
  return (
    <button className={cls.btnSubtle} onClick={handleToggle}>
      {children}
      <ChevronDown />
    </button>
  )
}
export function DropdownInput({ handleToggle, children, activeOption }) {
  return (
    <button
      className={clsInput.input + ' mb-0 ' + clsInput.divInput}
      onClick={handleToggle}
      type="button"
    >
      {Array.isArray(activeOption)
        ? activeOption.map(a => a.label).join(', ')
        : activeOption
        ? activeOption.label
        : children}
      &nbsp;&nbsp;&nbsp;
      <ChevronDown />
    </button>
  )
}
export function DropdownButton({ handleToggle, children }) {
  return (
    <Button flex className="mb-0" onClick={handleToggle}>
      {children}&nbsp;&nbsp;&nbsp;
      <Icon>add</Icon>
    </Button>
  )
}

export function KindDropdown({
  value,
  onChange,
}: {
  value: string
  onChange: (kind: string) => void
}) {
  const options = useActivityKindOptions()
  return (
    <Dropdown
      buttonText={value}
      ButtonComponent={DropdownInput}
      closeOnOptionClick
    >
      {options.map((value, index) => (
        <DropdownItem key={index} onClick={() => onChange(value)}>
          {value}
        </DropdownItem>
      ))}
    </Dropdown>
  )
}

export function KindDropdownCreate({
  onChange,
}: {
  onChange: (kind: string) => void
}) {
  const { t } = useTranslation()
  const options = useActivityKindOptions()
  return (
    <Dropdown
      buttonText={t('Create activity')}
      ButtonComponent={DropdownButton}
      closeOnOptionClick
    >
      {options.map((value, index) => (
        <DropdownItem
          key={index}
          onClick={() => onChange(value)}
          className={cls.kindDropdownItem}
        >
          <ActivityIcon icon={value} size={20} color="#0659FD" />
          <b>{t(value)}</b>
        </DropdownItem>
      ))}
    </Dropdown>
  )
}

export function HBDropdown<T>({
  label,
  value,
  options,
  onChange,
}: {
  label?: string
  value: T
  options: Option<T>[]
  onChange: (kind: T) => void
}) {
  return (
    <Dropdown
      buttonText={label || value}
      ButtonComponent={DropdownInput}
      closeOnOptionClick
    >
      {options.map(({ label, value }, index) => (
        <DropdownItem key={index} onClick={() => onChange(value)}>
          {label}
        </DropdownItem>
      ))}
    </Dropdown>
  )
}

interface Option<T = string> {
  label: string
  value: T
}

export function QuestionnaireDropdown({
  label,
  value,
  onChange,
}: {
  label?: string
  value: string
  onChange: (value: string, title: string) => void
}) {
  const { data } = useQuery(QuestionnaireNamesQuery)

  const { config } = useProject()
  const options = data?.questionnaires.concat(
    config.airtableQuestionnairesList || []
  )
  return (
    <Dropdown
      buttonText={value ? label + ' (' + value + ')' : 'Select...'}
      ButtonComponent={DropdownInput}
      closeOnOptionClick
    >
      {options?.map((q, index) => (
        <DropdownItem
          className={cls.highDropdownItem}
          key={index}
          onClick={() => onChange(q.name, q.title)}
        >
          {q.title}
          <small> {q.name}</small>
        </DropdownItem>
      ))}
    </Dropdown>
  )
}

export function ArticleDropdown({
  label,
  value,
  onChange,
}: {
  label: string
  value: string
  onChange: (value: string, title: string) => void
}) {
  const articles = useContent('Suggestions')

  const override = useConfig(p => p.config.airtableArticles || [])
  const options = articles.concat(override)
  return (
    <Dropdown
      block
      buttonText={value ? label + ' (' + value + ')' : 'Select...'}
      ButtonComponent={DropdownInput}
      closeOnOptionClick
    >
      {options?.map((a, index) => (
        <DropdownItem
          className={cls.highDropdownItem}
          key={index}
          onClick={() =>
            onChange((a.name || a._id) as string, a.Title as string)
          }
        >
          {a.Title}
          <small>{a.name || a._id}</small>
        </DropdownItem>
      ))}
    </Dropdown>
  )
}

function ChevronDown() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M5 7.5l5 5 5-5"
      />
    </svg>
  )
}

export function RepeatEditor({
  value,
  onChange,
}: {
  value: Partial<ActivityDoc>
  onChange: (a: Partial<ActivityDoc>) => void
}) {
  const { t } = useTranslation()
  const { repeatPeriod, repeatPeriodUnit } = value
  const checked = !!repeatPeriod && !!repeatPeriodUnit
  const ends = value.repeatBoundsPeriodEnd
    ? 'on'
    : value.repeatCount
    ? 'count'
    : 'never'
  return (
    <div>
      <Checkbox
        label={t('Repeating activity')}
        checked={checked}
        onChange={() =>
          onChange(
            checked
              ? { ...value, repeatPeriod: 0 }
              : {
                  ...value,
                  repeatPeriod: value.repeatPeriod || 1,
                  repeatPeriodUnit: value.repeatPeriodUnit || 'wk',
                }
          )
        }
      />
      {checked && (
        <div style={{ marginLeft: 26 }}>
          <div>
            <Label>{t('Every')}</Label>{' '}
            <Input
              type="number"
              value={value.repeatPeriod}
              onChange={evt =>
                onChange({ ...value, repeatPeriod: parseInt(evt.target.value) })
              }
              className="ml-3 mr-3"
              style={{ display: 'inline-block', width: 60 }}
            />
            <Dropdown
              style={{ width: 190 }}
              buttonText={repeatUnitLabel(value)}
              ButtonComponent={DropdownInput}
              closeOnOptionClick
            >
              {(['d', 'wk', 'mo', 'a'] as const).map((unit, index) => (
                <DropdownItem
                  key={index}
                  onClick={() => onChange({ ...value, repeatPeriodUnit: unit })}
                >
                  {t(
                    repeatUnitLabel({ repeatPeriod: 1, repeatPeriodUnit: unit })
                  )}
                </DropdownItem>
              ))}
            </Dropdown>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <Label>{t('Ends')}</Label>
            <div className="ml-4 mt-0">
              <div>
                <Radio
                  label={t('Never')}
                  radioGroup="ends"
                  value="never"
                  checked={ends === 'never'}
                  onChange={() =>
                    onChange({
                      ...value,
                      repeatBoundsPeriodEnd: undefined,
                      repeatCount: undefined,
                    })
                  }
                />
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Radio
                  label={t('After')}
                  radioGroup="ends"
                  value="count"
                  checked={ends === 'count'}
                  onChange={() => onChange({ ...value, repeatCount: 2 })}
                />
                <Input
                  type="number"
                  value={value.repeatCount || ''}
                  onChange={evt =>
                    onChange({
                      ...value,
                      repeatCount: parseInt(evt.target.value),
                    })
                  }
                  className="ml-3 mr-3 my-0"
                  style={{ display: 'inline-block', width: 60 }}
                />
                {t('occurrences')}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export function CustomRepeatEditor({
  value,
  unit,
  cta,
  onChangeValue,
  onChangeUnit,
}: {
  value: number
  unit: string
  cta: string
  onChangeValue: (a: number) => void
  onChangeUnit: (a: string) => void
}) {
  return value ? (
    <>
      <Input
        type="number"
        className="mb-0"
        style={{ display: 'inline-block', width: 60 }}
        placeholder="0"
        step="1"
        value={value}
        // @ts-ignore
        onChangeText={onChangeValue}
      />
      <Input
        type="text"
        className="mb-0 ml-2"
        style={{ display: 'inline-block', width: 60 }}
        placeholder="md"
        value={unit}
        onChangeText={onChangeUnit}
      />
      <button
        className={cls.btnSubtle + ' ml-3 ' + cls.small}
        onClick={() => {
          onChangeValue(null)
          onChangeUnit(null)
        }}
      >
        clear
      </button>
    </>
  ) : (
    <button
      className={cls.btnSubtle + ' ' + cls.small}
      onClick={() => {
        onChangeValue(value || 1)
        onChangeUnit(unit || 'mo')
      }}
    >
      {cta}
    </button>
  )
}

export const JSONDateEditor = memo(
  ({
    max,
    value,
    cta,
    fallback = false,
    onChange,
  }: {
    max?: number | string
    value: string | null
    cta: string
    fallback?: boolean
    onChange: (d: string) => void
  }) => (
    <DateEditor
      max={max}
      cta={cta}
      fallback={fallback}
      onChange={useCallback(d => onChange(d && d.toJSON()), [onChange])}
      value={useMemo(() => (value ? new Date(value) : null), [value])}
    />
  )
)

export function DateEditor({
  value,
  cta,
  max,
  fallback = false,
  onChange,
}: {
  value: Date
  cta: string
  max?: number | string
  fallback?: boolean
  onChange: (d: Date) => void
}) {
  const isset = !!value?.valueOf()
  const current = isset ? value : startOfDay(new Date())
  return isset || !fallback ? (
    <div style={{ display: 'flex', flexGrow: 1 }}>
      <InputOnly
        style={{ width: 148 }}
        max={max === 'now' ? format(current, 'yyyy-MM-dd') : max}
        type="date"
        value={format(current, 'yyyy-MM-dd')}
        onChange={evt => {
          const date = evt.target.valueAsDate
          if (!date) return
          const moved = new Date(value)
          moved.setFullYear(date.getFullYear())
          moved.setMonth(date.getMonth())
          moved.setDate(date.getDate())
          if (!isset) {
            moved.setHours(12)
          }
          onChange(moved)
        }}
      />
      <Input
        className="ml-2 mb-0"
        style={{ width: 'auto' }}
        type="time"
        value={format(current, 'HH:mm')}
        onChange={evt => {
          const time = evt.target.value
          const moved = new Date(value)
          moved.setHours(parseInt(time.slice(0, 2), 10))
          moved.setMinutes(parseInt(time.slice(3, 5), 10))
          onChange(moved)
        }}
      />
      {fallback && (
        <button
          className={cls.btnSubtle + ' ml-3 ' + cls.small}
          onClick={() => onChange(null)}
        >
          clear
        </button>
      )}
    </div>
  ) : (
    <button
      className={cls.btnSubtle + ' ' + cls.small}
      onClick={() => {
        const d = new Date()
        d.setMinutes(d.getMinutes() + 2)
        onChange(d)
      }}
    >
      {cta}
    </button>
  )
}

// function  TimeToggle({value,onChange, empty, mark}){
//   return <>{a.completed_at ? (
//     formatDistance(new Date(a.completed_at), new Date())
//   ) : (
//     <span style={{ opacity: 0.4 }}>not completed</span>
//   )}
//   {a.completed_at ? (
//     <button
//       className={cls.btnSubtle + ' ml-3'}
//       onClick={() =>
//         dispatch({
//           type: 'completed_at',
//           id: a.id,
//           completed_at: null,
//         })
//       }
//     >
//       clear
//     </button>
//   ) : (
//     <button
//       className={cls.btnSubtle + ' ml-3'}
//       onClick={() =>
//         dispatch({
//           type: 'completed_at',
//           id: a.id,
//           completed_at: new Date().toJSON(),
//         })
//       }
//     >
//       mark as completed
//     </button>
//   )}
//   </>
// }

const QuestionnaireNamesQuery = gql`
  query QuestionnaireNames {
    questionnaires(where: { status: { _eq: "active" } }) {
      id
      name
      title
    }
  }
`

function repeatUnitLabel({
  repeatPeriod,
  repeatPeriodUnit,
}: Partial<ActivityDoc>) {
  const str = unit(repeatPeriodUnit)
  return !str ? '?' : repeatPeriod === 1 ? str : str + 's'
}

function unit(unit: string) {
  switch (unit) {
    case 's':
      return 'second'
    case 'min':
      return 'minute'
    case 'h':
      return 'hour'
    case 'd':
      return 'day'
    case 'wk':
      return 'week'
    case 'mo':
      return 'month'
    case 'a':
      return 'year'
  }
  return unit
}
