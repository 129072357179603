import React, { useState } from 'react'
import { loadScript } from '@bothrs/util/load'

import { useToken } from '@healthblocks-io/core/auth'
import { useApi, usePid } from '@healthblocks-io/core/project'

import Button from 'pages/atoms/Button'

const tables = [
  // "articles",
  // "assessmentResults",
  //  "assessments",
  // "credentials",
  'events',
  // 'events_by_day',
  // 'events_by_month',
  // 'events_by_week',
  'invites',
  'messages',
  'observations',
  'projects',
  // "subscriptions",
  // "thread_user",
  'threads',
  'users',
]

export default function CsvExport() {
  const api = useApi()
  const pid = usePid()
  const token = useToken()
  const [todo, setTodo] = useState(0)
  const [message, setMessage] = useState('')

  const downloadAllCSV = async format => {
    // Lazy load zip + save to file libraries
    setMessage('Preparing...')
    await loadScript(
      'https://unpkg.com/jszip@3.5.0/dist/jszip.min.js',
      'JSZipSDK'
    )
    await loadScript(
      'https://unpkg.com/file-saver@2.0.2/dist/FileSaver.min.js',
      'FileSaverSDK'
    )

    // Download all table contents
    setMessage('Downloading...')
    setTodo(tables.length)
    const exportName = new Date().toJSON().slice(0, 10) + '-' + pid
    const zip = new (window as any).JSZip()
    const folder = zip.folder(exportName)

    for (let i = tables.length - 1; i >= 0; i--) {
      const table = tables[i]
      const contents = await downloadCSV(api, token, table, format)
      setTodo(count => count - 1)
      folder.file(table + '.' + format, contents)
    }

    // Generate zip file with all files
    setMessage('Zipping...')
    const csv = await zip.generateAsync({ type: 'blob' })

    // Force user to download zip file
    setMessage('Done')
    ;(window as any).saveAs(csv, exportName + '.zip')
  }

  return (
    <p>
      <Button
        inline
        onClick={() => downloadAllCSV('csv')}
        className="mr-2"
        secondary
      >
        Export CSV
      </Button>
      <Button
        inline
        onClick={() => downloadAllCSV('tsv')}
        className="mr-4"
        secondary
      >
        Export TSV
      </Button>
      {todo ? `Downloading ${todo} tables` : message}
    </p>
  )
}

function downloadCSV(api, auth, table = 'events', format = 'csv') {
  return fetch(api + '/api/tables/' + table + '/' + format, {
    headers: {
      Authorization: 'Bearer ' + auth,
    },
  }).then(r => r.blob())
}
