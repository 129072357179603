import { useReducer } from 'react'
import { gql, useQuery } from '@healthblocks-io/apollo'

import { EditorReducer, editorReducer } from './editor'

// Project > Careteam Policies

export interface Policy {
  id: string
  title: string
  statement: Statement | Statement[]
}
export interface Statement {
  Effect?: 'Allow' | 'Block'
  Action: string | string[]
  Resource: string | string[]
}

export function usePolicies() {
  const result = useQuery<{ policy: Policy[] }>(
    gql`
      query Policies {
        policy {
          id
          title
          statement
        }
      }
    `
  )
  return result
}

// Policy editor

export function usePolicyEditor(policy: Policy) {
  return useReducer<EditorReducer<Policy>>(editorReducer, {
    saving: false,
    data: policy,
  })
}

export function emptyPolicy(_: any) {
  return {
    id: '',
    title: '',
    statement: [],
  }
}

export async function createPolicy(client: any, { title, statement }: Policy) {
  const { data } = await client.mutate({
    mutation: gql`
      mutation CreatePolicy($policy: policy_insert_input!) {
        insert_policy_one(object: $policy) {
          id
        }
      }
    `,
    variables: { policy: { title, statement } },
  })
  return data?.insert_policy_one
}

export async function updatePolicy(
  client: any,
  { id, title, statement }: Policy
) {
  const { data } = await client.mutate({
    mutation: gql`
      mutation UpdatePolicy($policy: policy_set_input!, $id: uuid!) {
        update_policy_by_pk(pk_columns: { id: $id }, _set: $policy) {
          id
        }
      }
    `,
    variables: { id, policy: { title, statement } },
  })
  return data?.update_policy_by_pk
}

export async function removePolicy(client: any, { id }: { id: string }) {
  const { data } = await client.mutate({
    mutation: gql`
      mutation RemovePolicy($id: uuid!) {
        delete_policy_by_pk(id: $id) {
          id
        }
      }
    `,
    variables: { id },
  })
  return data?.delete_policy_by_pk.id === id
}

// Helpers

export function collect<T>(obj: T | T[]) {
  return Array.isArray(obj) ? obj : [obj]
}
