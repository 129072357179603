import React, { memo } from 'react'

import type { Activity, ActivityDoc } from '@healthblocks-io/core/types'

import cls from '../users.module.scss'
import Card from '../atoms/Card'
import {
  ArticleDropdown,
  DateEditor,
  KindDropdown,
  QuestionnaireDropdown,
  CustomRepeatEditor,
} from './FormFields'
import Input from 'pages/atoms/Input'
import formatDistance from 'date-fns/formatDistance'

export interface ExtraActivityDoc {
  scheduledTiming: { repeat: { period?: string; periodUnit?: string } }[]
  repeatPeriod?: number
  repeatPeriodUnit?: string
}

// Reduce rendering when editing other activities
export const UserActivity = memo(UserActivityComponent)

export function UserActivityComponent({
  activity: a,
  dispatch,
}: {
  activity: Activity & ActivityDoc & ExtraActivityDoc & { id: string }
  dispatch
}) {
  const planned_at = new Date(a.planned_at)
  const reminder_at = new Date(a.reminder_at)
  return (
    <Card className="mb-4">
      {/* <QuestionIcon />
              <h4 className={cls.title + ' mt-1 ml-3'}>
                {(collapsed && title) || 'Question ' + (q + 1)}
              </h4>
         */}
      <dl className="row" id={a.id}>
        <dt className="col-3 col-xl-2" style={{ lineHeight: '40px' }}>
          Kind
        </dt>
        <dd className="col-9 col-xl-10">
          <KindDropdown
            value={a.kind}
            onChange={kind => dispatch({ type: 'kind', id: a.id, kind })}
          />
          {'Questionnaire' === a.kind && (
            <QuestionnaireDropdown
              value={a.definitionUri?.split('/')[1]}
              onChange={(name, title) =>
                dispatch({
                  type: 'definitionUri',
                  id: a.id,
                  definitionUri: 'Questionnaire/' + name,
                  title,
                })
              }
            />
          )}
          {'Article' === a.kind && (
            <ArticleDropdown
              label={a.title}
              value={a.definitionUri?.split('/')[1]}
              onChange={(name, title) =>
                dispatch({
                  type: 'definitionUri',
                  id: a.id,
                  definitionUri: 'Article/' + name,
                  title,
                })
              }
            />
          )}
        </dd>
        <dt className="col-3 col-xl-2" style={{ lineHeight: '40px' }}>
          Title
        </dt>
        <dd className="col-9 col-xl-10">
          <Input
            type="text"
            className="mb-0"
            value={a.title || ''}
            onChangeText={title => dispatch({ type: 'title', id: a.id, title })}
            placeholder="Add title..."
          />
        </dd>

        <dt className="col-3 col-xl-2" style={{ lineHeight: '40px' }}>
          Planned at
        </dt>
        <dd
          className="col-9 col-xl-10"
          style={{ display: 'flex', alignItems: 'center', minHeight: 40 }}
        >
          <DateEditor
            cta="set date"
            value={planned_at}
            onChange={moved =>
              dispatch({
                type: 'planned_at',
                id: a.id,
                planned_at: moved && moved.toJSON(),
              })
            }
          />
        </dd>

        <dt className="col-3 col-xl-2" style={{ lineHeight: '40px' }}>
          Repeats
        </dt>
        <dd
          className="col-9 col-xl-10"
          style={{ display: 'flex', alignItems: 'center', minHeight: 40 }}
        >
          <CustomRepeatEditor
            cta="enable repeat"
            value={a.repeatPeriod}
            unit={a.repeatPeriodUnit}
            onChangeValue={repeatPeriod =>
              dispatch({ type: 'repeatPeriod', id: a.id, repeatPeriod })
            }
            onChangeUnit={repeatPeriodUnit =>
              dispatch({ type: 'repeatPeriodUnit', id: a.id, repeatPeriodUnit })
            }
          />
        </dd>

        <dt className="col-3 col-xl-2" style={{ lineHeight: '40px' }}>
          Reminder at
        </dt>
        <dd
          className="col-9 col-xl-10"
          style={{ display: 'flex', alignItems: 'center', minHeight: 40 }}
        >
          <DateEditor
            cta="set reminder"
            value={reminder_at}
            onChange={moved =>
              dispatch({
                type: 'reminder_at',
                id: a.id,
                reminder_at: moved && moved.toJSON(),
              })
            }
          />
        </dd>

        <dt className="col-3 col-xl-2">Engagement</dt>
        <dd className="col-9 col-xl-10" />
        <dt className={'col-3 col-xl-2 ' + cls.engagement}>Started at</dt>
        <dd className="col-9 col-xl-10">
          {a.started_at ? (
            formatDistance(new Date(a.started_at), new Date())
          ) : (
            <span style={{ opacity: 0.4 }}>not started</span>
          )}
          {a.started_at ? (
            <button
              className={cls.btnSubtle + ' ml-3'}
              onClick={() =>
                dispatch({
                  type: 'started_at',
                  id: a.id,
                  started_at: null,
                })
              }
            >
              clear
            </button>
          ) : (
            <button
              className={cls.btnSubtle + ' ml-3'}
              onClick={() =>
                dispatch({
                  type: 'started_at',
                  id: a.id,
                  started_at: new Date().toJSON(),
                })
              }
            >
              mark as started
            </button>
          )}
        </dd>
        <dt className={'col-3 col-xl-2 ' + cls.engagement}>Completed at</dt>
        <dd className="col-9 col-xl-10">
          {a.completed_at ? (
            formatDistance(new Date(a.completed_at), new Date())
          ) : (
            <span style={{ opacity: 0.4 }}>not completed</span>
          )}
          {a.completed_at ? (
            <button
              className={cls.btnSubtle + ' ml-3'}
              onClick={() =>
                dispatch({
                  type: 'completed_at',
                  id: a.id,
                  completed_at: null,
                })
              }
            >
              clear
            </button>
          ) : (
            <button
              className={cls.btnSubtle + ' ml-3'}
              onClick={() =>
                dispatch({
                  type: 'completed_at',
                  id: a.id,
                  completed_at: new Date().toJSON(),
                })
              }
            >
              mark as completed
            </button>
          )}
        </dd>
        <dt className={'col-3 col-xl-2 ' + cls.engagement}>Expired at</dt>
        <dd className="col-9 col-xl-10">
          {a.expired_at ? (
            formatDistance(new Date(a.expired_at), new Date())
          ) : (
            <span style={{ opacity: 0.4 }}>not expired</span>
          )}
          {a.expired_at ? (
            <button
              className={cls.btnSubtle + ' ml-3'}
              onClick={() =>
                dispatch({
                  type: 'expired_at',
                  id: a.id,
                  expired_at: null,
                })
              }
            >
              clear
            </button>
          ) : (
            <button
              className={cls.btnSubtle + ' ml-3'}
              onClick={() =>
                dispatch({
                  type: 'expired_at',
                  id: a.id,
                  expired_at: new Date().toJSON(),
                })
              }
            >
              mark as expired
            </button>
          )}
        </dd>
      </dl>
    </Card>
  )
}
