import React from 'react'

export default function Tabs({
  options,
  value,
  onChange,
}: {
  options: Option[]
  value: string
  onChange: (s: string) => void
}) {
  const active = options.find(o => o.value === value) || options[0]
  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      {options.map((opt, key) => (
        <button
          key={key}
          style={{
            appearance: 'none',
            border: 0,
            fontSize: 14,
            marginRight: 12,
            marginBottom: 16,
            background: 'none',
            paddingBottom: 8,
            borderBottom: '2px solid',
            borderColor: opt === active ? '#0659FD' : 'transparent',
            fontWeight: 600,
            color: opt === active ? '#000' : '#B3B3B3',
          }}
          onClick={() => onChange(opt.value)}
        >
          {opt.label}
        </button>
      ))}
    </div>
  )
}

interface Option {
  label: string
  value: string
}
