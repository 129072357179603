import { useCallback } from 'react'
import { getJSON } from '@bothrs/util/fetch'
import { serialize, unserialize } from '@bothrs/util/url'

import { atob, useAuthState, useToken } from './auth'
import { useApi } from './project'
import type { Image, ImageData } from './types'

// TODO: these URLs should be dynamic based on useProject()
// For legacy compatibility, these two cloudfront distributions point to the same bucket
const PROTECTED_PROD = 'dwttshmugole.cloudfront'
const UNPROTECTED_PROD = 'd3nvotoi69avok.cloudfront'
const PROTECTED_DEV = 'd3ty4jdwnbwja0.cloudfront'

export type NativeFile = {
  uri: string
  width: number
  height: number
}

export type Dimensions = {
  width: number
  height: number
}

export type FileUpload = {
  url: string
  size: number
  width: number
  height: number
}

/**
 * Upload a file in React Native/Expo
 */
export function useUploadNative() {
  const api = useApi()
  const auth = useToken()!
  return useCallback(
    async (input: NativeFile, folder: string = '') => {
      // const { uri, width, height } = input
      const { uri } = input
      const blob = await fetch(uri).then(r => r.blob())
      const file = new File([blob], uri.slice(uri.lastIndexOf('/') + 1))
      return upload(api, auth, file, folder)
    },
    [api, auth]
  )
}

/**
 * Upload a file on Web
 */
export function useUploadWeb() {
  const api = useApi()
  const auth = useToken()!
  return useCallback(
    async (input: FileList | File, folder: string = '') => {
      const file = input[0] || input
      return upload(api, auth, file, folder)
    },
    [api, auth]
  )
}

/**
 * Upload a file
 */
export function useUpload() {
  const api = useApi()
  const auth = useToken()!
  return useCallback(
    async (file: File, folder: string = '') => upload(api, auth, file, folder),
    []
  )
}

/**
 * Upload a file
 *
 * @returns the URL and size of the uploaded file
 */
export async function upload(
  api: string,
  auth: string,
  file: File,
  folder: string = ''
) {
  if (!file) {
    console.log('core/upload.nofile', file)
    throw new Error('No files selected')
  }

  if (!(file instanceof File)) {
    console.log('core/upload.instanceof')
    throw new Error('Unexpected File object')
  }

  if (!file.name) {
    console.log('core/upload.file.keys', file, file.name, Object.keys(file))
    throw new Error('Platform not supported')
  }

  // Get file type
  const path = file.name
  const ext = path.slice(path.lastIndexOf('.') + 1).toLowerCase()

  // Request permission to upload
  const allowed = await getJSON(
    api + '/api/upload?' + serialize({ ext, folder }),
    { auth }
  )
  if (!allowed?.signature) {
    console.warn('core/upload.allowed', allowed)
    throw new Error('Upload error 154')
  }

  // This URL contains ?Policy=eY...&Key-Pair-Id=abc...&Signature=abc...
  const privateURL = allowed.signature

  // Send the file to S3
  await fetch(privateURL, {
    method: 'PUT',
    body: file,
  })
    .then(r => {
      if (r.status !== 200) {
        throw new Error('Failed to upload ' + r.status)
      }
    })
    .catch(e => {
      console.log('core/upload.error', e, allowed)
      throw e
    })

  // Remove the signature to get a URL that can be shared
  const publicURL = privateURL.split('?')[0]

  // TODO: create Airtable image structure
  return {
    url: publicURL,
    size: file.size,
  }
}

/** Disable signed URLs for compatibility with legacy apps */
export function legacyPublicWorkaround(str: string) {
  return str.replace(PROTECTED_PROD, UNPROTECTED_PROD)
}

/**
 * Loads an image to determine its dimensions
 *
 * @returns the width and height of the image
 */
export async function imageDimensions(file: File): Promise<Dimensions> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function (e) {
      const image = new Image()
      image.onerror = reject
      image.onload = () => {
        const { width, height } = image
        resolve({ width, height })
      }
      if (!e.target?.result) {
        const error = new Error('Could not read file')
        return reject(error)
      }
      image.src = e.target.result as string
    }
  })
}

// const plus = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADMAAAAzCAYAAAA6oTAqAAAAEXRFWHRTb2Z0d2FyZQBwbmdjcnVzaEB1SfMAAABQSURBVGje7dSxCQBACARB+2/ab8BEeQNhFi6WSYzYLYudDQYGBgYGBgYGBgYGBgYGBgZmcvDqYGBgmhivGQYGBgYGBgYGBgYGBgYGBgbmQw+P/eMrC5UTVAAAAABJRU5ErkJggg=='
// const logo1='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAACABAMAAAAxEHz4AAAAG1BMVEX////d3d3h4eH8/Pzq6uru7u74+Pj09PTw8PBusS2iAAAAeUlEQVRo3u3YsQmAQAxGYRFR28MFXMHC3h3keitrR3B04QeV60IaOe+9Ab4qgZCKiIjIWrurzQ3UQY0AAAAAPmCO6kiBLqrVAExBLSnQBDUAAAAUCvRRnU7gDaBs4B6kjHcBAADgA8B66mZxrQMAAPwReN6BRERExi79bXB/wgJwJwAAAABJRU5ErkJggg=='
// const logo2='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAACABAMAAAAxEHz4AAAAJ1BMVEUAAAAwW/UwWvMwWfIwWvUwWO8xWv8wWvMwWvQwWvIwYO8wV/QwW/RgcWw+AAAADHRSTlMA34CgnyAfQHBQEDCZYVQhAAAAf0lEQVRo3u3WsQmAMBRFUcVCxcYZUjqFgziEXVpHcAYncAqrDCU8EEn3SSMx9w5wqv/hVURERNYGr9ZkoA5qBAAAAEgDDqf2GOidmgzAEtQcA01QFwAAQKFA59SWCLwBlA08h5TxLwAAAHwAWKduFmsdAADgj0Dr1VkREREZuwFEbCVs5XnAdAAAAABJRU5ErkJggg=='
const placeholder =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAACAAQMAAAD58POIAAAABlBMVEUAAAAwW/Rik+7FAAAAAXRSTlMAQObYZgAAADRJREFUSMdjGAVDC9T/GxXALSD/v6H+H+P/H6MCJAgwMAwOgcESHkNEYLDkucEsMAoGOQAALA4GBmXOKFgAAAAASUVORK5CYII='

let resizeWarning = true
/**
 * The hook provides a function that signs CloudFront URLs
 *
 * ```tsx
 * const sign = useSignedURL()
 * return <Image source={{ uri: sign(someURL) }} />
 * ```
 */
export function useSignedURL() {
  const { signature } = useAuthState()!
  return (url: string): string => {
    if (url && url.includes('?Policy=ey')) {
      console.log('URL already signed')
      return url
    }

    if (url && url.includes(UNPROTECTED_PROD)) {
      // TODO: Disable this workaround
      url = url.replace(UNPROTECTED_PROD, PROTECTED_PROD)
    }

    if (
      !url ||
      !(url.includes(PROTECTED_PROD) || url.includes(PROTECTED_DEV))
    ) {
      return url // Unprotected URLs
    }
    // TODO: use proper fallback image
    if (!signature) {
      // setTimeout(refresh)
      return placeholder // Missing signature
    }

    // Decode policy
    const [, sig] = signature.split('?')
    const policy = JSON.parse(atob(unserialize(sig).Policy))

    // Check expiry
    if (exp(policy) < Date.now()) {
      // setTimeout(refresh)
      return placeholder // Expired signature
    }

    // Check resource
    // if (!regex(res(policy), url)) {
    //   return placeholder // Invalid resource scope
    // }

    if (url.includes('url=')) {
      if (resizeWarning) {
        console.warn('Consider resizing images AFTER signing')
        resizeWarning = false
      }
      return url + '?' + encodeURIComponent(sig)
    }
    return url + '?' + sig
  }
}

export function useSignedImage() {
  const sign = useSignedURL()
  return (image: Image | ImageData, width = 0): Image | ImageData => {
    if (width) {
      image = thumbnail(image, width)
    }
    return {
      ...image,
      url: sign(image.url),
    }
  }
}

function exp(obj: any): number {
  if (obj.Statement) {
    return exp(obj.Statement)
  }
  if (Array.isArray(obj)) {
    return exp(obj[0])
  }
  if (obj.Condition) {
    return exp(obj.Condition)
  }
  if (obj.DateLessThan) {
    return exp(obj.DateLessThan)
  }
  if (obj['AWS:EpochTime']) {
    return exp(obj['AWS:EpochTime'])
  }
  if (typeof obj !== 'number') {
    throw new Error('Unexpected policy')
  }
  return obj * 1000
}

// For checking resource
// function regex(pattern, code) {
//   if (!pattern.includes('*')) {
//     return false
//   }
//   return new RegExp('^' + pattern.replace('*', '.+') + '$', 'i').test(code)
// }

// function res(obj: any): string {
//   if (obj.Statement) {
//     return res(obj.Statement)
//   }
//   if (Array.isArray(obj)) {
//     return res(obj[0])
//   }
//   if (obj.Resource) {
//     return res(obj.Resource)
//   }
//   if (typeof obj !== 'string') {
//     throw new Error('Unexpected policy ' + JSON.stringify(obj))
//   }
//   return obj
// }

// Resizing helpers

/** Scaled down image URL */
export function resized(url: string, width = 600) {
  if (!url) {
    return placeholder
  }
  if (!url.startsWith('http')) {
    return url
  }
  return 'https://images.weserv.nl?' + serialize({ w: width, url })
}

/** Returns a scaled down image, dimensions may be 0 */
export function thumbnail(image: Image | ImageData, maxwidth = 600): ImageData {
  const { url, thumbnails } = image as Image

  // Airtable thumbnails
  if (thumbnails?.small?.width && maxwidth < 150) {
    return thumbnails?.small
  }
  if (thumbnails?.large?.width && maxwidth < 1000) {
    return thumbnails?.large
  }
  if (thumbnails?.full?.width) {
    return {
      url: resized(thumbnails.full.url, maxwidth),
      width: maxwidth,
      height: (maxwidth * thumbnails.full.height) / thumbnails.full.width,
    }
  }

  // Data URIs
  if (!url?.startsWith('http')) {
    return { url, width: 0, height: 0 }
  }

  // Generate thumbnails on the fly
  const { width, height } = image as ImageData
  if (width && height) {
    return {
      url: resized(url, maxwidth),
      width: maxwidth,
      height: (maxwidth * height) / width,
    }
  }

  // No dimensions...
  return { url: resized(url, maxwidth), width: 0, height: 0 }
}

/** Small thumbnail with cloudinary + cloudfront support */
export function small(image: Image) {
  return thumbnail(image, 100)
}

/** Large thumbnail with cloudinary + cloudfront support */
export function large(image: Image) {
  return thumbnail(image, 600)
}
