import React from 'react'
import { PushToken as PushTokenType } from '@healthblocks-io/core/types'
import Card from '../atoms/Card'

export default function PushToken({ token }: { token: PushTokenType }) {
  return (
    <Card>
      <h2 style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {token.deviceName}
      </h2>
      <small>{token.createdAt}</small>
      <div>Push notifications enabled</div>
    </Card>
  )
}
