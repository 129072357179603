import { createContext, useContext } from 'react'

export const PatientCalendarContext = createContext({
  refetch: () => {
    alert('Context missing')
  },
})

export function usePatientCalendar() {
  return useContext(PatientCalendarContext)
}
