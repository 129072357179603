import React from 'react'
import classnames from 'classnames'

import cls from './Button.module.scss'

const Button = ({
  children,
  className = '',
  flex = false,
  inline = false,
  primary = false,
  secondary = false,
  subtle = false,
  success = false,
  error = false,
  href = '',
  ...rest
}) => {
  const combined = classnames(
    className,
    cls.btn,
    flex && cls.flex,
    inline && cls.inline,
    primary && subtle && cls.primarySubtle,
    secondary && cls.secondary,
    subtle && cls.subtle,
    error && cls.error,
    success && cls.success
  )
  if (href) {
    return (
      <a
        target={href.startsWith('http') ? '_blank' : ''}
        rel={href.startsWith('http') ? 'noopener noreferrer' : ''}
        href={href}
        {...rest}
        className={combined}
      >
        {children}
      </a>
    )
  }

  return (
    <button {...rest} className={combined}>
      {children}
    </button>
  )
}

export default Button
