import React, { ReactNode } from 'react'

export default function EmptyState({ children }: { children: ReactNode }) {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div style={{ maxWidth: 450, margin: 'auto' }}>{children}</div>
    </div>
  )
}
