import React, { ReactNode } from 'react'
import cls from './Input.module.scss'
import cls2 from './Label.module.scss'

interface CustomProps<T = string> {
  onChangeText?: (value: T) => void
  label?: string
  state?: [T, (value: T) => void]
  children?: ReactNode
}

type Props = CustomProps &
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >

export default function Input({
  className,
  label,
  state,
  onChangeText,
  ...rest
}: Props) {
  if (state) {
    rest.value = state[0]
    rest.onChange = evt => state[1](evt.target.value)
  }
  if (onChangeText) {
    rest.onChange = evt => onChangeText(evt.target.value)
  }
  return (
    <>
      {label && (
        <label className={cls2.label} htmlFor={rest.id}>
          {label}
        </label>
      )}
      <input
        {...rest}
        className={cls.input + ' ' + (className || '')}
        type={rest.type || 'text'}
      />
    </>
  )
}

export function LimitedInput(props: { limit: number } & Props) {
  const count =
    typeof props.value === 'string'
      ? props.value.length
      : typeof props.state[0] === 'string'
      ? props.state[0].length
      : 0
  return (
    <div className={cls.limitedContainer} data-count>
      <div
        className={
          cls.characterCount + (count > props.limit ? ' ' + cls.overLimit : '')
        }
      >
        {count}/{props.limit}
      </div>
      <Input {...props} />
    </div>
  )
}

// TODO: weird name
export function InputOnly({ className, state, ...rest }: Props) {
  if (state) {
    rest.value = state[0]
    rest.onChange = evt => state[1](evt.target.value)
  }
  return (
    <input
      {...rest}
      className={cls.input + ' ' + (className || '')}
      type={rest.type || 'text'}
    />
  )
}

// TODO: weird name
export function BadgeInput({ className, ...rest }: Props) {
  return (
    <div
      {...rest}
      className={cls.input + ' ' + cls.badgeInput + ' ' + (className || '')}
    />
  )
}

// TODO: weird name
export function DivInput({ className, ...rest }: Props) {
  return (
    <div
      {...rest}
      className={cls.input + ' ' + cls.divInput + ' ' + (className || '')}
    />
  )
}
