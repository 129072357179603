export default typeof window !== 'undefined'
  ? window.localStorage
  : {
      getItem(key) {
        console.warn('storage:ssr:getItem', key)
        return null
      },
      setItem(key, value) {
        console.warn('storage:ssr:setItem', key, value)
      },
      removeItem(key) {
        console.warn('storage:ssr:removeItem', key)
      },
      key(index) {
        console.warn('storage:ssr:key', index)
      },
      clear() {
        console.warn('storage:ssr:clear')
      },
      length: 0,
    }
