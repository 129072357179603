import React, {
  createContext,
  Fragment,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Link, useLocation } from 'react-router-dom'
import Avatar from '@duik/avatar'
import Icon from '@duik/icon'
import format from 'date-fns/format'
import formatDuration from 'date-fns/formatDuration'

import { useAnalytics, useTrack } from '@healthblocks-io/core/analytics'
import { useCareTeam } from '@healthblocks-io/core/careteam'
import {
  useCommunicationCategories,
  useCommunicationCategoryColors,
} from '@healthblocks-io/core/communication'
import {
  BundleLoader,
  useBundle,
  useFHIR,
  useSearch,
} from '@healthblocks-io/core/fhir'
import { useObservationPresets } from '@healthblocks-io/core/observation'
import { deepGet } from '@healthblocks-io/core/set'
import { useSubject } from '@healthblocks-io/core/subject'
import {
  AvatarProps,
  CalendarProps,
  CarePlanActivity,
  Communication,
  CommunicationComponentProps,
  Component,
  FHIRProps,
  Goal,
  Profile,
  RowProps,
  TextEditorProps,
} from '@healthblocks-io/core/types'
import { useSignedURL } from '@healthblocks-io/core/upload'

import { humanDatetime } from 'lib/date-fns'
import { useTranslation } from 'lib/i18n'
import ActivityIcon, { FeatherIcon } from 'pages/atoms/ActivityIcon'
import Button from 'pages/atoms/Button'
import SubtleTextarea from 'pages/atoms/SubtleTextarea'
import { CareTeamGate } from 'pages/users/CareTeamGate'
import {
  CommunicationModal,
  emptyCommunication,
  fallbackColor,
  Pill,
} from 'pages/users/CommunicationsPage'
import Medication from 'pages/users/Medication'
import Reminder from 'pages/users/Reminder'

import cls from 'pages/users/ProfilePage.module.scss'

import PatientCalendar from './PatientCalendar'

interface DetailContextType {
  user: Profile
  update: (key: string, value: any) => Promise<void>
  refetch: () => void
  sections: Component[]
}
const Detail = createContext<DetailContextType>(null)

export default function ProfileComponents(
  value: DetailContextType & { pageName: string }
) {
  useTrack((value.pageName || 'Profile') + ' Opened', {
    subjectId: value.user.uid,
    subjectName: value.user.name,
  })
  return (
    <Detail.Provider value={value}>
      <ProfileComponentsInner />
    </Detail.Provider>
  )
}

function ProfileComponentsInner() {
  const { sections } = useContext(Detail)
  const left = sections.filter(s => s.slot === 'left')
  const center = sections.filter(s => s.slot !== 'left' && s.slot !== 'right')

  return (
    <div
      style={{
        display: 'flex',
        height: 'calc(100vh - 141px)',
        background: 'white',
      }}
    >
      <div style={{ flex: 1, overflow: 'auto' }}>
        <div style={{ display: 'flex', padding: '25px 0 25px 32px' }}>
          {left.length ? (
            <div style={{ flex: '0 0 300px', paddingRight: '32px' }}>
              <div
                style={{
                  padding: 16,
                  backgroundColor: '#F7F7F7',
                  borderRadius: 10,
                  marginBottom: 100,
                }}
              >
                {left.map(ComponentRender)}
              </div>
            </div>
          ) : null}

          <div
            style={{
              flex: 1,
              paddingRight: '32px',
            }}
          >
            <div
              style={{
                maxWidth: 1200,
                width: '100%',
              }}
            >
              {center.map(ComponentRender)}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

function ComponentRender(component: Component<RowProps>, key?: number) {
  if (component.slot !== 'left') {
    if (component.props.border && component.props.padding) {
      return (
        <div
          style={{
            border: '2px solid #eee',
            borderRadius: 12,
            padding: '16px 16px 0',
            marginBottom: 24,
          }}
          key={key}
        >
          {ComponentRenderTitle(component)}
        </div>
      )
    }
    if (component.props.border) {
      return (
        <div
          style={{ border: '2px solid', borderRadius: 12, marginBottom: 16 }}
          key={key}
        >
          {ComponentRenderTitle(component)}
        </div>
      )
    }
    if (component.props.padding) {
      return (
        <div style={{ padding: 16 }} key={key}>
          {ComponentRenderTitle(component)}
        </div>
      )
    }
  }
  return ComponentRenderTitle(component, key)
}

function ComponentRenderTitle(component: Component, key?: number) {
  const { t } = useTranslation()
  // @ts-ignore
  const title = component.props.title || component.title
  if (title) {
    return (
      <Fragment key={key}>
        <h3>{t(title)}</h3>
        {ComponentRenderOnly(component)}
      </Fragment>
    )
  }
  return ComponentRenderOnly(component, key)
}

function ComponentRenderOnly(component: Component, key?: number) {
  switch (component.component) {
    case 'hb:Avatar':
      return <Avatar2 {...component.props} key={key} />
    case 'hb:FHIR':
      return <FHIR {...component.props} key={key} />
    case 'hb:Goal':
      return (
        <CareTeamGate>
          <BundleLoader type="Goal">
            <Goals {...component.props} key={key} />
          </BundleLoader>
        </CareTeamGate>
      )
    case 'hb:CareTeam':
      return <Row {...component.props} key={key} />
    case 'hb:medications1':
      return <Medications {...component.props} key={key} />
    case 'hb:PushTokens':
      return <PushTokens {...component.props} key={key} />
    case 'hb:reminders1':
      return <Reminders {...component.props} key={key} />
    case 'hb:Row':
      return <Row {...component.props} key={key} />
    case 'hb:Calendar':
      return <Calendar {...component.props} key={key} />
    case 'hb:Communication':
      return (
        <CareTeamGate>
          <CommunicationComponent {...component.props} key={key} />
        </CareTeamGate>
      )
    case 'hb:Date':
    case 'hb:DateTime':
    case 'hb:Duration':
    case 'hb:Email':
    case 'hb:Text':
      return <Text {...component.props} type={component.component} key={key} />
    case 'hb:DateEditor':
    case 'hb:DateTimeEditor':
    case 'hb:DurationEditor':
    case 'hb:EmailEditor':
    case 'hb:TextEditor':
      return <TextEditor {...component.props} key={key} />
  }
  // if (component.component.startsWith('fhir:')) {
  //   return <FHIR {...component.props} item={component} key={key} />
  // }
  return <Debug {...component.props} item={component} key={key} />
}

function Goals({ type }: FHIRProps) {
  const { t } = useTranslation()
  const presets = useObservationPresets()
  const { create, update, remove } = useFHIR()
  const goals = useBundle<Goal>('Goal').sort((a, b) => a.sortKey - b.sortKey)
  const sortKey = Math.max(...goals.map(g => g.sortKey || 0)) + 1
  const allGoals = goals.concat({ id: '', resourceType: 'Goal', text: '' })

  return (
    <div style={{ marginBottom: 18 }}>
      {allGoals.map((item, key) => (
        <div key={key}>
          <PureTextEditor
            label="text"
            value={item.text}
            lines={5}
            placeholder={t(
              item.id ? 'Leave empty to remove' : 'Add_placeholder'
            )}
            save={text => {
              if (!item.id) {
                return create({ resourceType: type, text, sortKey })
              }
              if (text) {
                update({ ...item, text })
              } else {
                remove(item)
              }
            }}
          />
          <label
            style={{
              display: 'flex',
              alignItems: 'center',
              margin: '10px 0 24px',
            }}
          >
            <FeatherIcon
              d="M15 7h3a5 5 0 015 5 5 5 0 01-5 5h-3m-6 0H6a5 5 0 01-5-5 5 5 0 015-5h3M8 12h8"
              size={18}
            />
            <span className="related" style={{ margin: '1px 8px 0' }}>
              {t('Related observation')}:{' '}
            </span>

            <select
              className="select--basic"
              value={item.target?.[0].measure.coding?.[0]?.code || ''}
              onChange={evt => {
                const preset = presets.find(
                  p => p.code?.coding?.[0]?.code === evt.target.value
                )
                if (!item.id) {
                  return create({
                    resourceType: type,
                    text: '',
                    target: preset ? [{ measure: preset.code }] : undefined,
                    sortKey,
                  })
                }
                update({
                  ...item,
                  target: preset ? [{ measure: preset.code }] : undefined,
                })
              }}
            >
              <option value="">{t('None')}</option>
              {presets.map((preset, key) => (
                <option key={key} value={preset.code?.coding?.[0]?.code}>
                  {t(preset.code?.text) || '?'}
                </option>
              ))}
            </select>
          </label>
        </div>
      ))}
    </div>
  )
}

function CommunicationComponent(props: CommunicationComponentProps) {
  const [editing, setEditing] = useState<Communication>()
  const { t } = useTranslation()
  const location = useLocation()

  return (
    <div style={{ position: 'relative' }}>
      <div className={cls.iconButton}>
        <AddCommunicationButton
          setEditing={setEditing}
          include={props?.include}
        />
      </div>
      <CommunicationsTable
        setEditing={setEditing}
        include={props?.include}
        exclude={props?.exclude}
      />
      <Link
        to={location.pathname.replace('/overview', '/notes')}
        className="p-3 d-flex justify-content-end font-weight-bold"
        style={{ fontSize: '12px' }}
      >
        {t('See all notes')}
      </Link>
      <CommunicationModal editing={editing} setEditing={setEditing} />
    </div>
  )
}

function AddCommunicationButton({
  include,
  setEditing,
}: {
  include?: string[]
  setEditing: (r: Communication) => void
}) {
  const subject = useSubject().reference
  const { me } = useCareTeam()
  const categories = useCommunicationCategories()
  const category = include
    ? [categories.find(c => c.text === include[0])]
    : undefined

  return (
    <Button
      flex
      secondary
      style={{ margin: 0, padding: '0 7px', height: 28 }}
      onClick={() =>
        setEditing(emptyCommunication({ subject, sender: me, category }))
      }
    >
      <Icon>add</Icon>
    </Button>
  )
}

function CommunicationsTable({
  setEditing,
  include,
  exclude,
}: {
  setEditing: (r: Communication) => void
  include?: string[]
  exclude?: string[]
}) {
  const MAX_AMOUNT_NOTES = 5
  const communications = useSearch<Communication>({
    type: 'Communication',
    params: { sign: 1 },
  })?.data?.entry.sort((a, b) => -a.sent?.localeCompare(b.sent))
  const { t } = useTranslation()
  const colors = useCommunicationCategoryColors()

  const filteredCommunications = useMemo(() => {
    if (!communications) return
    if (include)
      return communications.filter(com =>
        com.category?.some(cat => include.includes(cat.text))
      )
    if (exclude)
      return communications.filter(
        com => !com.category?.some(cat => exclude.includes(cat.text))
      )
    return communications
  }, [communications, exclude, include])
  return (
    <table className="tbl tbl--wide tbl--sticky">
      <thead>
        <tr>
          <th>{t('Date')}</th>
          <th>{t('From')}</th>
          <th>{t('To')}</th>
          <th> {t('Note')}</th>
          <th> {}</th>
          <th> {t('Category')}</th>
        </tr>
      </thead>
      <tbody>
        {filteredCommunications
          ?.slice(0, MAX_AMOUNT_NOTES)
          .map((communication, key) => {
            const payload = communication.payload
              ?.map(p => p.contentString)
              .filter(Boolean)
              .join('\n\n')
            return (
              <tr
                key={key}
                onClick={() => setEditing(communication)}
                style={{ cursor: 'pointer' }}
              >
                <td className="td-1 text-nowrap">
                  {humanDatetime(new Date(communication.sent))}
                </td>
                <td>{communication.sender?.display}</td>
                <td>
                  {communication.recipient
                    ?.map(ref => ref.display)
                    .join('\n') || t('Full care team')}
                </td>
                <td>
                  {payload.length > 130
                    ? payload.slice(0, 100) + '...'
                    : payload}
                </td>
                <td>
                  {communication.payload?.find(p => p.contentAttachment) ? (
                    <ActivityIcon icon="paperclip" size={18} color="#BEBEBE" />
                  ) : null}
                </td>
                <td>
                  {communication.category
                    ?.map(
                      p =>
                        colors.find(c => c.text === p.text) ||
                        fallbackColor(p.text)
                    )
                    ?.map((p, key) => <Pill key={key} {...p} />) || null}
                </td>
              </tr>
            )
          })}
      </tbody>
    </table>
  )
}

function FHIR({ type, path }: FHIRProps) {
  const { t } = useTranslation()
  const detail = useContext(Detail)
  const value = deepGet(detail, path || 'user.doc.fhir')
  // const structure = [RiskAssessment].find(s => s.id === type)
  // const elements = structure?.snapshot.element.map(({ path, short }) => ({
  //   label: path.split('.').pop(),
  //   short,
  // })) ||

  const elements = [{ label: 'text', short: '' }]
  return (
    <div
      style={{ display: 'flex', flexWrap: 'wrap', columnGap: 24, rowGap: 8 }}
    >
      {Array.isArray(value)
        ? value
            .filter(r => r.resourceType === type)
            .map((item, index) => (
              <div
                key={index}
                style={{
                  maxWidth: '20em',
                  flexShrink: 0,
                  flexGrow: 1,
                  flexBasis: 200,
                  marginBottom: 18,
                }}
              >
                {elements.map(({ label }, key) => (
                  <PureTextEditor
                    label={t(label)}
                    value={item[label]}
                    lines={label === 'text' ? 5 : 1}
                    key={key}
                    save={text => {
                      const updated = value.slice()
                      if (text) {
                        updated[value.indexOf(item)] = {
                          ...item,
                          [label]: text,
                        }
                      } else {
                        updated.splice(value.indexOf(item), 1)
                      }
                      // @ts-ignore
                      detail.update(path || 'user.doc.fhir', updated)
                    }}
                  />
                ))}
              </div>
            ))
        : null}

      <div
        style={{
          maxWidth: '20em',
          flexShrink: 0,
          flexGrow: 1,
          flexBasis: 200,
          marginBottom: 18,
        }}
      >
        {elements.map(({ label }, key) => (
          <PureTextEditor
            label={t(label)}
            value=""
            placeholder={t('Add_placeholder')}
            lines={label === 'text' ? 5 : 1}
            key={key}
            save={text => {
              const created = { resourceType: type, [label]: text }
              // @ts-ignore
              detail.update(
                path || 'user.doc.fhir',
                (value || []).concat(created)
              )
            }}
          />
        ))}
      </div>
    </div>
  )
}
function Debug({ path, item, ...props }: any) {
  const detail = useContext(Detail)
  const value = deepGet(detail, path || '')
  return (
    <pre
      style={{
        backgroundColor: 'rgba(255, 0, 0, .1)',
        borderRadius: 12,
        padding: '12px',
      }}
    >
      {item.component}
      <br />
      {JSON.stringify(props, null, 2)}
      <br />
      {JSON.stringify(value, null, 2)}
      {/* <small style={{ fontSize: 10, lineHeight: '10px' }}>
        {JSON.stringify(props, null, 2)}
      </small> */}
    </pre>
  )
}

// Specific components (sorted alphabetically)

function Avatar2({ paths }: AvatarProps) {
  const detail = useContext(Detail)
  const avatar = deepGet(detail, 'user.doc.avatar')
  const sign = useSignedURL()
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: '1rem',
      }}
    >
      <Avatar
        xxl
        style={{ margin: '1rem 0 .5rem' }}
        imgUrl={
          (typeof avatar === 'string' && sign(avatar)) ||
          (typeof avatar?.url === 'string' && sign(avatar.url))
        }
        avatarPlaceholder={{
          content: initials(detail.user.name),
          color: 'blue',
        }}
      />
      {paths?.map((path, key) => (
        <p
          key={key}
          style={{ color: key ? '#B3B3B3' : '#4B4C4D', marginTop: 0 }}
        >
          {deepGet(detail, path)}
        </p>
      ))}
    </div>
  )
}

function Calendar(_x: CalendarProps) {
  const activities = useSearch<CarePlanActivity>({
    type: 'CarePlan.Activity',
    params: { subject: useSubject().uid },
  })
  return (
    <div style={{ width: '100%', height: '100%', backgroundColor: '#f7f7f7' }}>
      {activities.data?.entry ? (
        <PatientCalendar
          activities={activities.data.entry}
          refetch={activities.refetch}
        />
      ) : null}
    </div>
  )
}

function Medications() {
  const { t } = useTranslation()
  const detail = useContext(Detail)
  const medications = deepGet(detail, 'user.doc.medications')
  const timezone = deepGet(detail, 'user.timezone')
  return (
    <div style={{ marginBottom: 18 }}>
      {medications?.length ? (
        medications.map((med, key) => (
          <Medication key={key} medication={med} user={{ timezone }} />
        ))
      ) : (
        <p>{t('No medication')}</p>
      )}
    </div>
  )
}

function PushTokens() {
  const { t } = useTranslation()
  const detail = useContext(Detail)
  const pushTokens = deepGet(detail, 'user.doc.pushTokens')
  return (
    <div>
      <div
        style={{
          color: '#8f92a1',
          fontSize: 13,
          fontWeight: 'bold',
          lineHeight: '20px',
          marginBottom: 6,
          textTransform: 'uppercase',
        }}
      >
        {t('Push notifications')}
      </div>
      <div
        style={{
          marginBottom: 18,
          fontWeight: 600,
          fontSize: 14,
          lineHeight: '17px',
          color: '#4D4D4D',
        }}
      >
        {pushTokens?.length ? (
          pushTokens.map((token, key) => (
            <div key={key}>
              {token.deviceName}
              {token.createdAt && (
                <div style={{ fontSize: 12, fontWeight: 500 }}>
                  {format(new Date(token.createdAt), 'PPP HH:mm')}
                </div>
              )}
            </div>
          ))
        ) : (
          <span>{t('Disabled')}</span>
        )}
      </div>
    </div>
  )
}

function Reminders() {
  const { t } = useTranslation()
  const detail = useContext(Detail)
  const reminders = deepGet(detail, 'user.doc.reminders')
  return (
    <div style={{ marginBottom: 18 }}>
      {reminders?.length ? (
        reminders.map((reminder, key) => (
          <Reminder key={key} reminder={reminder} />
        ))
      ) : (
        <p>{t('No daily reminders')}</p>
      )}
    </div>
  )
}

function Row({ columns }: RowProps) {
  if (!Array.isArray(columns)) {
    return null
  }
  return (
    <div className="row">
      {columns?.map((column, key) => (
        <div className="col" key={key}>
          {column.components?.map(ComponentRenderOnly)}
        </div>
      ))}
    </div>
  )
}

function Text({ label, type, path }: TextEditorProps) {
  const { t } = useTranslation()
  const detail = useContext(Detail)
  const value = deepGet(detail, path)
  if (type.startsWith('hb:')) type = type.slice(3)
  return (
    <div style={{ marginBottom: 18 }}>
      <div
        style={{
          color: '#8f92a1',
          fontSize: 13,
          fontWeight: 'bold',
          lineHeight: '20px',
          marginBottom: 6,
          textTransform: 'uppercase',
        }}
      >
        {t(label) || '?'}
      </div>
      <div
        style={{
          fontWeight: 600,
          fontSize: 14,
          lineHeight: '17px',
          color: '#4D4D4D',
        }}
      >
        {textFormat(value, type)}&nbsp;
      </div>
    </div>
  )
}

function textFormat(text: string, type: string) {
  if (!text) {
    return text
  }
  switch (type) {
    case 'Date':
      return format(new Date(text), 'PPP')
    case 'DateTime':
      return format(new Date(text), 'PPP HH:mm')
    case 'Duration':
      return parseInt(text) > 0 && !isNaN(parseInt(text))
        ? formatDuration({ seconds: parseInt(text) })
        : '?'
  }
  return text
}

function TextEditor({
  label,
  path,
  type = 'text',
  lines,
  datalist,
}: TextEditorProps) {
  const { t } = useTranslation()
  const detail = useContext(Detail)
  const value = deepGet(detail, path)
  const [text, setText] = useState<string | null>(null)
  const dirty = typeof text === 'string' && text !== value
  return (
    <form
      data-lpignore="true"
      autoComplete="off"
      onBlur={() => {
        setTimeout(() => {
          setText(text => {
            if (typeof text === 'string' && text !== value) {
              detail.update(path, text)
            }

            return null
          })
        }, 1000)
      }}
      onSubmitCapture={evt => {
        if (dirty) {
          evt.stopPropagation()
          evt.preventDefault()
          detail.update(path, text)
          setText(null)
        }
      }}
    >
      <label
        style={{
          position: 'relative',
          display: 'block',
          marginBottom: 18,
          fontWeight: 600,
          fontSize: 14,
          lineHeight: '17px',
          color: '#4D4D4D',
        }}
      >
        {datalist?.length ? (
          <datalist id={path}>
            {datalist.map((opt, key) => (
              <option key={key}>{t(opt)}</option>
            ))}
          </datalist>
        ) : null}
        {label ? (
          <div
            style={{
              color: '#8f92a1',
              fontSize: 13,
              fontWeight: 'bold',
              lineHeight: '20px',
              marginBottom: 6,
              textTransform: 'uppercase',
            }}
          >
            {t(label)}
          </div>
        ) : null}
        <SubtleTextarea
          value={dirty ? text : value || ''}
          placeholder=""
          type={type}
          list={path}
          onChangeText={setText}
          lineBreaks={lines > 1}
        />
        {dirty && (
          <div
            style={{ position: 'absolute', top: '100%', right: 0, zIndex: 1 }}
          >
            <button
              type="button"
              onClick={() => setText(null)}
              className={cls.inlineButton + ' btn button'}
            >
              x
            </button>
            <button type="submit" className={cls.inlineButton + ' btn button'}>
              v
            </button>
          </div>
        )}
      </label>
    </form>
  )
}

function PureTextEditor({
  label,
  placeholder = '',
  lines,
  value,
  save,
}: {
  label: string
  placeholder?: string
  lines: number
  value: string
  save: (text: string) => void
}) {
  const converge = useRef<any>()
  const [text, _setText] = useState<string | null>(null)
  const dirty = typeof text === 'string' && text !== value
  const setText = (text: string | null) => {
    // Interrupt current converge action
    clearTimeout(converge.current)
    _setText(text)
  }
  const flush = (text: string | null) => {
    // Let parent know that value is final
    save(text)

    // Interrupt current converge action
    clearTimeout(converge.current)

    // This input is fresh, so let's immediately clear it
    if (!value) {
      setText(null)
    } else {
      // This input optimistically will keep its content
      // Let's keep it around for a second
      converge.current = setTimeout(() => setText(null), 1000)
    }
  }
  return (
    <form
      data-lpignore="true"
      autoComplete="off"
      onBlur={() => {
        if (typeof text === 'string' && text !== value) {
          flush(text)
        }
      }}
      onSubmitCapture={evt => {
        if (dirty) {
          evt.stopPropagation()
          evt.preventDefault()
          flush(text)
        }
      }}
    >
      <label
        style={{
          position: 'relative',
          display: 'block',
          fontWeight: 600,
          fontSize: 14,
          lineHeight: '17px',
          color: '#4D4D4D',
        }}
      >
        {label !== 'text' && (
          <div
            style={{
              color: '#8f92a1',
              fontSize: 13,
              fontWeight: 'bold',
              lineHeight: '20px',
              marginBottom: 6,
              textTransform: 'uppercase',
            }}
          >
            {label || '?'}
          </div>
        )}
        <SubtleTextarea
          value={dirty ? text : value || ''}
          placeholder={placeholder}
          onChangeText={setText}
          lineBreaks={lines > 1}
        />
        {dirty && (
          <div
            style={{ position: 'absolute', top: '100%', right: 0, zIndex: 1 }}
          >
            <button
              type="button"
              tabIndex={-1}
              onClick={() => setText(null)}
              className={cls.inlineButton + ' btn button'}
            >
              x
            </button>
            <button
              type="submit"
              tabIndex={-1}
              className={cls.inlineButton + ' btn button'}
            >
              v
            </button>
          </div>
        )}
      </label>
    </form>
  )
}

function initials(name: string) {
  if (!name) {
    return '?'
  }

  const parts = name.split(' ').filter(p => p && !['Dr.', 'Dokter'].includes(p))

  // Short names
  if (parts.length < 2) return name.slice(0, 3)

  return parts.slice(0, 3).map(p => p.charAt(0) + '.')
}
