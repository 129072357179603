import React, { useCallback, useEffect, useRef, useState } from 'react'
import GraphiQL from 'graphiql'
import GraphiQLExplorer from 'graphiql-explorer'
import { buildClientSchema, getIntrospectionQuery } from 'graphql'
import 'graphiql/graphiql.min.css'
import './GraphiQL.module.scss'

import { useProject } from '@healthblocks-io/core/project'
import { useToken, useUser } from '@healthblocks-io/core/auth'

// customarg

import { isEnumType, isWrappingType } from 'graphql'

import type {
  GraphQLField,
  GraphQLArgument,
  GraphQLInputField,
  GraphQLEnumType,
  GraphQLOutputType,
  GraphQLScalarType,
  ValueNode,
} from 'graphql'

function useRole() {
  return useUser()?.['https://hasura.io/jwt/claims']['x-hasura-default-role']
}

export default function () {
  const { env } = useProject()
  const token = useToken()
  const _graphiql = useRef<GraphiQL>(null)

  const [schema, setSchema] = useState(null)
  const [query, setQuery] = useState(defaultQuery)

  const [explorerIsOpen, setExplorerIsOpen] = useState(true)
  const [_role, setRole] = useState(null)
  const myRole = useRole()
  const role = _role || myRole

  const fetcher = useCallback(
    async graphQLParams => {
      const data = await fetch(
        // @ts-ignore
        env.graph.replace('/v1/graphql', '') + '/v1/graphql',
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
            ...(role && { 'X-Hasura-Role': role }),
          },
          body: JSON.stringify(graphQLParams),
          // credentials: 'same-origin',
        }
      )
      return data.json().catch(() => data.text())
    },
    [env, role, token]
  )

  useEffect(() => {
    fetcher({
      query: getIntrospectionQuery(),
    }).then(result => {
      setSchema(buildClientSchema(result.data))
    })
  }, [fetcher])

  return (
    <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
      {schema && (
        <GraphiQLExplorer
          schema={schema}
          query={query}
          onEdit={setQuery}
          onRunOperation={operationName =>
            _graphiql.current.handleRunQuery(operationName)
          }
          explorerIsOpen={explorerIsOpen}
          onToggleExplorer={() => setExplorerIsOpen(a => !a)}
          getDefaultScalarArgValue={getDefaultScalarArgValue}
          makeDefaultArg={makeDefaultArg}
        />
      )}
      <GraphiQL
        ref={_graphiql}
        // docExplorerOpen
        query={query}
        defaultQuery={defaultQuery}
        fetcher={fetcher}
        onEditQuery={setQuery}
      >
        <GraphiQL.Toolbar>
          <select
            className="toolbar-button"
            style={{ width: 90 }}
            value={role}
            onChange={evt => setRole(evt.target.value)}
          >
            <option>owner</option>
            <option>moderator</option>
            <option>user</option>
          </select>
          <GraphiQL.Button
            onClick={() => _graphiql.current.handlePrettifyQuery()}
            label="Prettify"
            title="Prettify Query (Shift-Ctrl-P)"
          />
          <GraphiQL.Button
            onClick={() => _graphiql.current.handleToggleHistory()}
            label="History"
            title="Show History"
          />
          <GraphiQL.Button
            onClick={() => setExplorerIsOpen(a => !a)}
            label="Explorer"
            title="Toggle Explorer"
          />
        </GraphiQL.Toolbar>
      </GraphiQL>
    </div>
  )
}

const gql = String.raw
const defaultQuery = gql`
  query RecentActivities {
    activity(limit: 100) {
      kind
      completed_at
      doc
    }
  }
`

// customarg

function unwrapOutputType(outputType: GraphQLOutputType) {
  let unwrappedType = outputType
  while (isWrappingType(unwrappedType)) {
    unwrappedType = unwrappedType.ofType
  }
  return unwrappedType
}

export function makeDefaultArg(
  parentField: GraphQLField<any, any>,
  arg: GraphQLArgument | GraphQLInputField
): boolean {
  const unwrappedType = unwrapOutputType(parentField.type)
  if (
    unwrappedType.name.startsWith('GitHub') &&
    unwrappedType.name.endsWith('Connection') &&
    (arg.name === 'first' || arg.name === 'orderBy')
  ) {
    return true
  }
  return false
}

export function getDefaultScalarArgValue(
  parentField: GraphQLField<any, any>,
  arg: GraphQLArgument | GraphQLInputField,
  argType: GraphQLEnumType | GraphQLScalarType
): ValueNode {
  const unwrappedType = unwrapOutputType(parentField.type)
  switch (unwrappedType.name) {
    case 'GitHubRepository':
      if (arg.name === 'name') {
        return { kind: 'StringValue', value: 'graphql-js' }
      } else if (arg.name === 'owner') {
        return { kind: 'StringValue', value: 'graphql' }
      }
      break
    case 'NpmPackage':
      if (arg.name === 'name') {
        return { kind: 'StringValue', value: 'graphql' }
      }
      break
    default:
      if (
        isEnumType(argType) &&
        unwrappedType.name.startsWith('GitHub') &&
        unwrappedType.name.endsWith('Connection')
      ) {
        if (
          arg.name === 'direction' &&
          argType
            .getValues()
            .map(x => x.name)
            .includes('DESC')
        ) {
          return { kind: 'EnumValue', value: 'DESC' }
        } else if (
          arg.name === 'field' &&
          argType
            .getValues()
            .map(x => x.name)
            .includes('CREATED_AT')
        ) {
          return { kind: 'EnumValue', value: 'CREATED_AT' }
        }
      }
      return GraphiQLExplorer.defaultValue(argType)
  }
  return GraphiQLExplorer.defaultValue(argType)
}
