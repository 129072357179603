import addDays from 'date-fns/addDays'
import addWeeks from 'date-fns/addWeeks'
import addMonths from 'date-fns/addMonths'

import { gql, useQuery } from '@healthblocks-io/apollo'
import { usePid } from '@healthblocks-io/core/project'

const now = Date.now()

// Based on events

export function useEvent(event: string, range) {
  const pid = usePid()
  return useQuery(
    gql`
      query ActiveUsers(
        $pid: String!
        $event: String!
        $before: timestamptz
        $after: timestamptz
      ) {
        events_aggregate(
          where: {
            event: { _eq: $event }
            receivedAt: { _lt: $before, _gt: $after }
          }
          distinct_on: userId
        ) {
          aggregate {
            count
          }
        }
      }
    `,
    {
      variables: { pid, event, ...beforeAfter(range) },
    }
  ).data?.events_aggregate.aggregate.count
}

export function useDistinctUsers(range) {
  const pid = usePid()
  return useQuery(
    gql`
      query ActiveUsers(
        $pid: String!
        $before: timestamptz
        $after: timestamptz
      ) {
        events_aggregate(
          where: { receivedAt: { _lt: $before, _gt: $after } }
          distinct_on: userId
        ) {
          aggregate {
            count
          }
        }
      }
    `,
    {
      variables: { pid, ...beforeAfter(range) },
    }
  ).data?.events_aggregate.aggregate.count
}

// Helpers

export function beforeAfter({ before, after, day, week, month }) {
  const baseMoment = now
  // const baseMoment = startOfDay(now)
  if (typeof day === 'number') {
    after = after || addDays(baseMoment, day - 1).toJSON()
    before = before || addDays(baseMoment, day).toJSON()
  }
  if (typeof week === 'number') {
    after = after || addWeeks(baseMoment, week - 1).toJSON()
    before = before || addWeeks(baseMoment, week).toJSON()
  }
  if (typeof month === 'number') {
    after = after || addMonths(baseMoment, month - 1).toJSON()
    before = before || addMonths(baseMoment, month).toJSON()
  }
  // console.log('range', before.slice(0, 10), after.slice(0, 10))
  if (typeof before !== 'string' || typeof after !== 'string') {
    throw new Error('Before and after must be string ' + before + ' ' + after)
  }
  return { before, after }
}
