/* eslint-disable no-restricted-globals */

import React, { useState } from 'react'
import { Avatar } from '@duik/avatar'
import { Divider } from '@duik/divider'
import { Select } from '@duik/select'
import { Modal } from '@duik/modal'
import { ContainerVertical } from '@duik/container-vertical'
import Icon from '@duik/icon'

import {
  DeleteUser,
  useMutation,
  useProfileEditor,
} from '@healthblocks-io/apollo'
import { useUser } from '@healthblocks-io/core/auth'
import { small, useUploadWeb } from '@healthblocks-io/core/upload'

import HealthblocksTopBar from './modules/HealthblocksTopBar'
import Input from 'pages/atoms/Input'
import Button from 'pages/atoms/Button'
import inlineAvatar from 'lib/avatar'
import { useTranslation } from 'lib/i18n'
import { DropdownInput } from './users/FormFields'
import Label from './atoms/Label'

const Settings = () => {
  const { t } = useTranslation()
  return (
    <div>
      <HealthblocksTopBar>{t('Account')}</HealthblocksTopBar>
      <ProfileSettings />
    </div>
  )
}
export default Settings

const allLanguages = [
  { label: 'English', value: 'en' },
  { label: 'Nederlands', value: 'nl' },
  { label: 'Français', value: 'fr' },
]

function ProfileSettings() {
  const { t, i18n } = useTranslation()
  const user = useUser()
  const [modalVisible, setModalVisible] = useState(false)
  const deleteUser = useMutation(DeleteUser, { variables: { uid: user.sub } })
  const { profile, updateProfile, updateDoc } = useProfileEditor()
  const upload = useUploadWeb()

  function toggleModal() {
    setModalVisible(!modalVisible)
  }

  return (
    <div className="scroll-area-main">
      <h2 className="title">{t('Personal settings')}</h2>
      <ContainerVertical style={{ height: 'auto' }}>
        <div>
          <div style={{ maxWidth: 400 }}>
            <Input
              label={t('Email address')}
              placeholder={t('Your email')}
              type="email"
              id="email"
              name="email"
              defaultValue={profile.email}
              onChange={e => {
                updateProfile({ email: e.target.value })
              }}
            />
            <Input
              label={t('Name')}
              placeholder={t('Your name')}
              type="text"
              id="name"
              name="name"
              defaultValue={profile.name}
              onChange={e => {
                updateProfile({ name: e.target.value })
              }}
            />
            <Label>{t('Language')}</Label>
            <Select
              ButtonComponent={DropdownInput}
              placeholder={t('Interface language: (English by default)')}
              activeOption={
                profile.language
                  ? {
                      value: '',
                      label:
                        allLanguages.find(
                          ({ value }) =>
                            value === profile.language.slice(0, 2).toLowerCase()
                        )?.label || profile.language,
                    }
                  : null
              }
              onOptionClick={({ value }) => {
                updateProfile({ language: value })
                i18n.changeLanguage(value)
              }}
              options={allLanguages}
              // searchable
              // onFocus={({ target }) => {
              //   if (target.classList.contains('dropdown-item')) {
              //     return
              //   }
              //   setTimeout(
              //     () =>
              //       (
              //         target.parentNode.querySelector(
              //           '.select-search-input'
              //         ) as HTMLElement
              //       )?.focus(),
              //     200
              //   )
              // }}
              // inputSearchProps={{
              //   value: name,
              //   onChange: e => setName(e.target.value),
              // }}
            />
            <Label style={{ marginTop: 24 }}>{t('Profile picture')}</Label>
            <Avatar
              xl
              imgUrl={
                profile.doc.avatar
                  ? small(profile.doc.avatar).url
                  : inlineAvatar(user?.uid)
              }
              style={{ marginBottom: '0.5rem', objectFit: 'fill' }}
            />
            <input
              id="avatar-input"
              name="avatar-input"
              type="file"
              style={{ display: 'none' }}
              onChange={e => {
                upload(e.target.files[0]).then(avatar => updateDoc({ avatar }))
              }}
            />
            <Button secondary style={{ width: 'auto' }}>
              <label
                htmlFor="avatar-input"
                style={{
                  color: 'inherit',
                  textTransform: 'inherit',
                  fontSize: 'inherit',
                  lineHeight: 'inherit',
                  fontWeight: 'inherit',
                  cursor: 'pointer',
                }}
              >
                {t('Upload new picture')} <Icon ml>camera</Icon>
              </label>
            </Button>
          </div>
        </div>
        <Divider style={{ margin: '1rem 0' }} />
        <div style={{ display: 'flex', padding: '1em 0' }}>
          <div>
            <h2 className="title">{t('Help & support')}</h2>
            <Button
              secondary
              component="a"
              href="mailto:hello@healthblocks.io"
              style={{ display: 'block' }}
            >
              {t('Contact help & support')} <Icon ml>message</Icon>
            </Button>
          </div>
          <div className="ml-5">
            <h2 className="title">{t('Delete my account')}</h2>
            <Button secondary onClick={toggleModal}>
              {t('Delete my account')}
            </Button>
          </div>
        </div>
      </ContainerVertical>

      <Modal
        isOpen={modalVisible}
        handleClose={toggleModal}
        closeOnOuterClick
        sm
        style={{ height: 'auto' }}
      >
        <Modal.Header>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete your account? This action can not be
          undone!
        </Modal.Body>
        <Modal.Footer>
          <Button secondary onClick={toggleModal}>
            Don't delete my account
          </Button>
          <Button error onClick={deleteUser}>
            Yes, delete my account
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
