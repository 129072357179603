import React, { useEffect, useMemo, useState } from 'react'
import { Checkbox } from '@duik/checkbox'
import Modal, { ModalProps } from '@duik/modal'
import { Select } from '@duik/select'
import addHours from 'date-fns/addHours'
import startOfDay from 'date-fns/startOfDay'

import { useFHIR } from '@healthblocks-io/core/fhir'
import { useConfig } from '@healthblocks-io/core/project'
import { useSubject } from '@healthblocks-io/core/subject'
import {
  PlanDefinition,
  PlanDefinitionInput,
} from '@healthblocks-io/core/types'

import { useTranslation } from 'lib/i18n'
import { toastError, toastSuccess } from 'lib/toast'
import Button from 'pages/atoms/Button'
import Label from 'pages/atoms/Label'

import { DropdownInput, JSONDateEditor } from '../pages/users/FormFields'

export default function ApplyCarePlanDefinitionModal({
  isOpen,
  closeOnOuterClick,
  handleClose,
}: ModalProps) {
  return (
    <Modal
      style={{ maxWidth: 513, height: 'auto', overflow: 'initial' }}
      sm
      isOpen={isOpen}
      closeOnOuterClick={closeOnOuterClick}
      triggerOnOuterFucus={false}
      handleClose={handleClose}
    >
      <Contents handleClose={handleClose} />
    </Modal>
  )
}

function Contents({ handleClose }: { handleClose: () => void }) {
  const { t } = useTranslation()
  const [start] = useState(addHours(startOfDay(new Date()), 19).toJSON())
  const [askConfirmation, setAskConfirmation] = useState(false)
  const [deleteExisting, setDeleteExisting] = useState(false)
  const [template, setTemplate] = useState<PlanDefinition | null>(null)
  const [variables, setVariables] = useState<{ [key: string]: string }>({})
  const templates = useConfig(p => p.config.PlanDefinition || [])
  const inputs = useMemo(
    () => template?.inputs || defaultTemplateInputs,
    [template]
  )
  const options = useMemo(
    () => templates.map(t => ({ label: t.title || t.name, value: t.name })),
    [templates]
  )
  useEffect(() => {
    if (templates.length) {
      setTemplate(templates[0])
    }
    // eslint-disable-next-line
  }, [!templates.length])

  const subject = useSubject().reference
  const { operation } = useFHIR()
  const apply = async () => {
    try {
      for (const input of inputs) {
        if (!variables[input.name]) {
          variables[input.name] = start
        }
      }
      await operation(template, 'apply', {
        ...variables,
        subject,
        deleteExisting,
      })
      toastSuccess(t('Care plan template applied successfully'))
    } catch (e) {
      toastError(
        t('Failed to apply care plan template') + '\n\n' + t(e.message)
      )
    }
    handleClose()
  }

  const checkDeleteExisting = () => {
    if (deleteExisting) {
      setAskConfirmation(true)
    } else apply()
  }

  if (!templates.length) {
    return (
      <div className="m-4">
        <h3>{t('No templates found')}</h3>
        <p>{t('Contact support for more info')}</p>
      </div>
    )
  }

  return askConfirmation ? (
    <div className="mx-4 mt-4 mb-2">
      <h2>{t('Are you sure')}</h2>
      <p>
        {t(
          'This will remove all finished and scheduled activities for this user'
        )}
      </p>
      <div className="d-flex justify-content-end">
        <Button
          subtle
          inline
          onClick={() => setAskConfirmation(!askConfirmation)}
        >
          {t('Go back')}
        </Button>
        <Button inline onClick={apply}>
          {t('Continue')}
        </Button>
      </div>
    </div>
  ) : (
    <div className="mx-4 mt-4 mb-2">
      <h3>{t('Apply a template')}</h3>
      <div className="my-3">
        <Label>{t('Template')}</Label>
        <Select
          ButtonComponent={DropdownInput}
          activeOption={{
            value: '',
            label: template ? template.title || '?' : 'Select a template...',
          }}
          onOptionClick={({ value }) =>
            setTemplate(templates.find(t => t.name === value))
          }
          options={options}
        />
      </div>

      {inputs
        .filter(t => t.type === 'date')
        .map((input, key) => (
          <div className="mt-3 w-50" key={key}>
            <Label>{t(input.title)}</Label>
            <JSONDateEditor
              value={variables[input.name] || start}
              onChange={date =>
                setVariables(v => ({ ...v, [input.name]: date }))
              }
              cta="Select date"
            />
          </div>
        ))}
      <div style={{ height: 50 }}>
        <Checkbox
          checked={deleteExisting}
          label={t(
            'All previously added activities by this template will be replaced'
          )}
          onChange={() => {
            setDeleteExisting(prev => !prev)
          }}
        />
        {deleteExisting && (
          <p className="mt-0 ml-4">{t('This action can not be undone')}</p>
        )}
      </div>
      <div className="d-flex align-items-end flex-column">
        <Button
          inline
          className=" justify-content-end"
          onClick={() => checkDeleteExisting()}
        >
          {t('Apply')}
        </Button>
      </div>
    </div>
  )
}

const defaultTemplateInputs: PlanDefinitionInput[] = [
  {
    name: 'start',
    title: 'Start',
    type: 'date',
  },
]

// export function OccurrenceEditorModal({
//   activity,
//   onClose,
//   onProtect,
// }: {
//   activity: Activity
//   onClose: () => void
//   /** Make sure modal doesn't close on accident */
//   onProtect: (b: boolean) => void
// }) {
//   const editor = useActivityEditor(activity)
//   const { activity: a, dirty, repeating } = editor
//   const [rescheduling, setRescheduling] = useState(false)
//   const [tab, setTab] = useState(
//     a.kind === 'Questionnaire' && a.status === 'completed' ? 'response' : ''
//   )

//   const protect = rescheduling || !!dirty
//   useEffect(() => onProtect(protect), [protect])

//   return (
//     <div style={{ padding: 25 }}>
//       <h3 style={{ fontWeight: 'bold', margin: '24px 0' }}>
//         {a.kind === 'Questionnaire' && a.status === 'completed'
//           ? a.doc.title
//           : [
//               a.id ? 'Edit' : 'Add',
//               a.kind.charAt(0) === 'A' ? 'an' : 'a',
//               a.kind.toLowerCase(),
//             ].join(' ')}

//         {a.id && (
//           <Status status={a.status}>
//             {a.completed_at
//               ? 'Completed at ' +
//                 format(new Date(a.completed_at), 'MMMM do, HH:mm')
//               : a.status === 'scheduled'
//               ? 'Scheduled'
//               : 'Incomplete'}
//           </Status>
//         )}
//       </h3>

//       <Tabs
//         options={[
//           'General',
//           'Reminders',
//           a.kind === 'Questionnaire' && a.id ? 'Response' : '',
//         ].filter(Boolean)}
//         value={tab}
//         onChange={setTab}
//       />

//       {tab === 'reminders' ? (
//         <Reminders {...editor} />
//       ) : tab === 'response' ? (
//         <QuestionnaireResponse {...editor} />
//       ) : (
//         <General {...editor} />
//       )}

//       {tab !== 'response' && (
//         <div
//           style={{
//             display: 'flex',
//             flexDirection: 'row',
//             justifyContent: a.id ? 'space-between' : 'flex-end',
//             marginTop: a.id ? 24 : 0,
//           }}
//         >
//           {a.id ? (
//             <Button
//               className="mb-0"
//               inline
//               error
//               onClick={() => editor.remove().then(() => onClose?.())}
//             >
//               Remove activity
//             </Button>
//           ) : null}
//           <Button
//             className="mb-0"
//             inline
//             disabled={
//               (['Article', 'Questionnaire'].includes(a.kind) &&
//                 !a.doc.definitionUri) ||
//               !a.doc.title
//             }
//             onClick={async () => {
//               if (a.id && repeating) {
//                 return setRescheduling(true)
//               } else if (a.id) {
//                 await editor.reschedule()
//               } else {
//                 await editor.create()
//               }
//               onClose?.()
//             }}
//           >
//             {a.id && repeating
//               ? 'Save...'
//               : a.id
//               ? 'Save activity'
//               : 'Add activity'}
//           </Button>
//         </div>
//       )}

//       {a.id && repeating && (
//         <Modal
//           style={{ maxWidth: 320, padding: '4px 24px 12px', height: 'auto' }}
//           isOpen={rescheduling}
//           closeOnOuterClick
//           handleClose={() => setRescheduling(false)}
//         >
//           <h3 style={{ fontWeight: 'bold', margin: '24px 0' }}>
//             Reschedule activity
//           </h3>
//           <p>This is a recurring activity. How do you want to edit this?</p>
//           <p>
//             <Button
//               onClick={async () => {
//                 await editor.reschedule('single')
//                 onClose?.()
//               }}
//             >
//               Only this event
//             </Button>
//             <Button
//               onClick={async () => {
//                 await editor.reschedule('all')
//                 onClose?.()
//               }}
//             >
//               All past and future events
//             </Button>
//           </p>
//         </Modal>
//       )}
//     </div>
//   )
// }

// function Status({ status, children }: { status: string; children: ReactNode }) {
//   return (
//     <div className={cls.status}>
//       <div
//         style={{}}
//         className={cls.statusCircle + ' ' + cls2['status_' + status]}
//       />
//       <div className={cls.statusLabel}>{children}</div>
//     </div>
//   )
// }

// function General({
//   activity: a,
//   patchActivity,
//   patchActivityDoc,
// }: ActivityEditor) {
//   return (
//     <div>
//       {'Questionnaire' === a.kind ? (
//         <div style={{ display: 'flex', flexDirection: 'column' }}>
//           <Label>Questionnaire</Label>
//           <QuestionnaireDropdown
//             label={a.doc.title + ''}
//             value={a.doc.definitionUri?.split('/')[1]}
//             onChange={(name, title) =>
//               patchActivityDoc({
//                 definitionUri: 'Questionnaire/' + name,
//                 title: title,
//               })
//             }
//           />
//         </div>
//       ) : 'Article' === a.kind ? (
//         <div style={{ display: 'flex', flexDirection: 'column' }}>
//           <Label>Article</Label>
//           <ArticleDropdown
//             label={a.doc.title + ''}
//             value={a.doc.definitionUri?.split('/')[1]}
//             onChange={(name, title) =>
//               patchActivityDoc({
//                 definitionUri: 'Article/' + name,
//                 title: title,
//               })
//             }
//           />
//         </div>
//       ) : (
//         <div>
//           <Input
//             label="Title"
//             type="text"
//             value={a.doc.title || ''}
//             onChangeText={title => patchActivityDoc({ title: title })}
//             placeholder="Add title..."
//           />
//           <Label>Description</Label>
//           <Textarea
//             value={a.doc.description || ''}
//             onChangeText={description =>
//               patchActivityDoc({ description: description })
//             }
//             placeholder="Add description..."
//           />
//         </div>
//       )}

//       {a.id && (
//         <div style={{ margin: '20px 0' }}>
//           <Label>Status</Label>
//           <div
//             style={{
//               color: a.completed_at ? '#52E8A9' : undefined,
//               marginTop: 6,
//               fontWeight: 'bold',
//               fontSize: 14,
//               lineHeight: '23px',
//             }}
//           >
//             {a.completed_at
//               ? 'Completed at ' +
//                 format(new Date(a.completed_at), 'MMMM do, HH:mm')
//               : a.status === 'scheduled'
//               ? 'Scheduled'
//               : 'Incomplete'}
//           </div>
//         </div>
//       )}

//       <div style={{ display: 'flex', flexDirection: 'row', margin: '20px 0' }}>
//         <div style={{ flexGrow: 1, flexBasis: 0 }}>
//           <div>
//             <Label>Start</Label>
//             <JSONDateEditor
//               cta="set date"
//               value={a.planned_at}
//               onChange={planned_at => {
//                 if (!a.doc.end_at) {
//                   return patchActivity({ planned_at })
//                 }
//                 const shift = Date.parse(planned_at) - Date.parse(a.planned_at)
//                 patchActivity({ planned_at })
//                 patchActivityDoc({
//                   end_at: new Date(Date.parse(a.doc.end_at) + shift).toJSON(),
//                 })
//               }}
//             />
//           </div>
//         </div>
//         <div style={{ padding: '42px 10px 0 10px' }}>-</div>
//         <div style={{ flexGrow: 1, flexBasis: 0 }}>
//           <div>
//             <Label>End</Label>
//             <div>
//               <JSONDateEditor
//                 cta="set date"
//                 value={a.doc.end_at}
//                 onChange={useCallback(
//                   e => patchActivityDoc({ end_at: e }),
//                   [patchActivity]
//                 )}
//               />
//             </div>
//           </div>
//         </div>
//       </div>

//       <RepeatEditor
//         value={{
//           repeatPeriod: a.doc.repeatPeriod,
//           repeatPeriodUnit: a.doc.repeatPeriodUnit,
//           repeatBoundsPeriodEnd: a.doc.repeatBoundsPeriodEnd,
//           repeatCount: a.doc.repeatCount,
//         }}
//         onChange={doc => patchActivityDoc(doc)}
//       />
//     </div>
//   )
// }

// function Reminders({ activity: a, patchActivityDoc }: ActivityEditor) {
//   const { reminders = [] } = a.doc
//   return (
//     <div>
//       <p>
//         Set up reminders before and/or after the activity starts. A reminder
//         means the patient will receive a notification through their personal
//         app.
//       </p>

//       {reminders.length ? (
//         <table className={cls.tblSubtle}>
//           <tbody>
//             <tr>
//               <th>
//                 <Label>Time</Label>
//               </th>
//               <th />
//               <th>
//                 <Label>Before or after the start date</Label>
//               </th>
//             </tr>
//             {reminders.map((reminder, key) => (
//               <tr key={key}>
//                 <td>
//                   <Dropdown
//                     style={{ width: 190 }}
//                     buttonText={reminderLabel(reminder)}
//                     ButtonComponent={DropdownInput}
//                     closeOnOptionClick
//                   >
//                     {[
//                       {
//                         offset: reminderSign(reminder) * 1,
//                         offsetUnit: 'h' as const,
//                       },
//                       {
//                         offset: reminderSign(reminder) * 4,
//                         offsetUnit: 'h' as const,
//                       },
//                       {
//                         offset: reminderSign(reminder) * 1,
//                         offsetUnit: 'd' as const,
//                       },
//                       {
//                         offset: reminderSign(reminder) * 3,
//                         offsetUnit: 'd' as const,
//                       },
//                       {
//                         offset: reminderSign(reminder) * 1,
//                         offsetUnit: 'wk' as const,
//                       },
//                     ].map((reminder, index) => (
//                       <DropdownItem
//                         key={index}
//                         onClick={() =>
//                           patchActivityDoc({
//                             reminders: deepSet(reminders, '' + key, reminder),
//                           })
//                         }
//                       >
//                         {reminderLabel(reminder)}
//                       </DropdownItem>
//                     ))}
//                   </Dropdown>
//                 </td>
//                 <td width="8" />
//                 <td>
//                   <Dropdown
//                     style={{ width: 190 }}
//                     buttonText={reminderAbs(reminder)}
//                     ButtonComponent={DropdownInput}
//                     closeOnOptionClick
//                   >
//                     {[
//                       { ...reminder, offset: -Math.abs(reminder.offset) },
//                       { ...reminder, offset: Math.abs(reminder.offset) },
//                     ].map((reminder, index) => (
//                       <DropdownItem
//                         key={index}
//                         onClick={() =>
//                           patchActivityDoc({
//                             reminders: deepSet(reminders, '' + key, reminder),
//                           })
//                         }
//                       >
//                         {reminderAbs(reminder)}
//                       </DropdownItem>
//                     ))}
//                   </Dropdown>
//                 </td>
//                 <td width="8" />
//                 <td>
//                   <Button
//                     className="mb-0 p-2"
//                     subtle
//                     onClick={() =>
//                       patchActivityDoc({
//                         reminders: reminders.filter(r => r !== reminder),
//                       })
//                     }
//                   >
//                     <CrossIcon />
//                   </Button>
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       ) : null}

//       <Button
//         secondary
//         inline
//         onClick={() =>
//           patchActivityDoc({
//             reminders: reminders.concat({
//               offset: -1,
//               offsetUnit: 'h',
//             }),
//           })
//         }
//       >
//         <Icon className="mr-2">add</Icon>
//         Add a reminder
//       </Button>
//     </div>
//   )
// }

// function QuestionnaireResponse({ activity: a, patchActivity }: ActivityEditor) {
//   const answers = a.questionnaire_response?.answers
//   if (!answers) {
//     return (
//       <p style={{ height: 200, padding: '15%', textAlign: 'center' }}>
//         Once the questionnaire is complete, the patient’s answers will be
//         visualised here.
//       </p>
//     )
//   }
//   if (!answers.length) {
//     return <div>The questionnaire response is not available</div>
//   }
//   return (
//     <div>
//       {answers.map((a, key) => (
//         <Answer key={key} a={a} />
//       ))}
//     </div>
//   )
// }

// function Answer({
//   a,
// }: {
//   a: Activity['questionnaire_response']['answers'][0]
// }) {
//   const [title, description] = splitText(a.question.text)
//   return (
//     <div className={cls.answer}>
//       <div style={{ flexBasis: 28, flexShrink: 0 }}>
//         <QuestionIcon />
//       </div>
//       <div style={{ flex: 1 }}>
//         {title && (
//           <div
//             style={{
//               fontWeight: 600,
//               fontSize: 14,
//               lineHeight: '18px',
//               marginBottom: 10,
//               color: '#4B4C4D',
//             }}
//           >
//             {title}
//           </div>
//         )}
//         {description && (
//           <div
//             style={{
//               fontSize: 12,
//               lineHeight: 1.33,
//               marginBottom: 6,
//               color: '#4B4C4D',
//             }}
//           >
//             {description}
//           </div>
//         )}
//         <p style={{ fontSize: 12, lineHeight: 1.3, color: '#4B4C4D' }}>
//           {a.text}
//         </p>
//       </div>
//     </div>
//   )
// }

// function splitText(text: string) {
//   return text.startsWith('#') ? text.slice(2).split('\n', 2) : ['', text]
// }

// function QuestionIcon() {
//   return (
//     <svg
//       width={16}
//       height={17}
//       viewBox="0 0 16 17"
//       fill="none"
//       style={{ display: 'block' }}
//     >
//       <path
//         d="M8 1.833A6.67 6.67 0 001.333 8.5 6.67 6.67 0 008 15.166 6.67 6.67 0 0014.667 8.5 6.67 6.67 0 008 1.833zm.667 11.333H7.333v-1.333h1.334v1.333zM10.047 8l-.6.613c-.334.34-.574.647-.694 1.127a3.059 3.059 0 00-.086.76H7.333v-.334a2.665 2.665 0 01.78-1.887l.827-.84c.307-.293.453-.733.367-1.2a1.327 1.327 0 00-.927-1.02 1.344 1.344 0 00-1.647.847c-.08.247-.286.434-.546.434h-.2a.577.577 0 01-.547-.747 2.672 2.672 0 012.153-1.887c1.014-.16 1.98.367 2.58 1.2.787 1.087.554 2.254-.126 2.934z"
//         fill="#DAE6FF"
//       />
//     </svg>
//   )
// }

// function reminderSign(reminder: Reminder) {
//   return reminder.offset > 0 ? 1 : -1
// }
// function reminderAbs(reminder: Reminder) {
//   return reminder.offset > 0 ? 'After' : 'Before'
// }

// function reminderLabel(reminder: Reminder) {
//   if (reminder.label) {
//     return reminder.label
//   }

//   const out = Math.abs(reminder.offset) + ' '
//   const s = reminder.offset > 1 ? 's' : ''
//   const u = reminder.offsetUnit
//   switch (u) {
//     case 'min':
//       return out + 'minute' + s
//     case 'h':
//       return out + 'hour' + s
//     case 'd':
//       return out + 'day' + s
//     case 'wk':
//       return out + 'week' + s
//     case 'mo':
//       return out + 'month' + s
//     case 'a':
//       return out + 'year' + s
//     case 's':
//       return out + 'second' + s
//   }
//   // @ts-ignore
//   return out + reminder.offsetUnit
// }

// function CrossIcon(props) {
//   return (
//     <svg
//       width={24}
//       height={24}
//       viewBox="0 0 24 24"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//       {...props}
//     >
//       <path
//         fillRule="evenodd"
//         clipRule="evenodd"
//         d="M15.536 9.61a1 1 0 10-1.415-1.415L12 10.316l-2.121-2.12a1 1 0 00-1.415 1.413l2.122 2.122-2.122 2.121a1 1 0 101.415 1.414L12 13.145l2.121 2.121a1 1 0 001.415-1.414l-2.122-2.121 2.122-2.122z"
//         fill="#0659FD"
//       />
//     </svg>
//   )
// }
