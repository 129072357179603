import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { timeout } from '@bothrs/util/async'
import { Avatar } from '@duik/avatar'
import { Button } from '@duik/button'
import { Dropdown, DropdownItem } from '@duik/dropdown'
import Icon from '@duik/icon'
import { TopBar } from '@duik/top-bar'

import { useProfile } from '@healthblocks-io/apollo'
import { useAnalytics } from '@healthblocks-io/core/analytics'
import { useAuth, useUser } from '@healthblocks-io/core/auth'
import { useProfileData } from '@healthblocks-io/core/profile'
import { usePid } from '@healthblocks-io/core/project'
import { useSignedImage } from '@healthblocks-io/core/upload'

import inlineAvatar from 'lib/avatar'
import { useTranslation } from 'lib/i18n'
import { useAuth0 } from '@auth0/auth0-react'
import { HBAuth0Provider } from 'lib/auth0'
import SignOutAuth0 from '@components/SignOutAuth0'

export default function HealthblocksTopBar({ children, back = '' }) {
  const pid = usePid()
  const user = useUser()
  const [signingOut, setSigningOut] = useState(false)
  const { userId } = useParams<{ userId: string }>()
  const { track } = useAnalytics()
  const { t } = useTranslation()

  const { name, doc } = useProfileData()
  const sign = useSignedImage()
  const AvatarButton = ({ handleToggle, ...rest }) => {
    return (
      <Button
        onClick={handleToggle}
        style={{ border: 0 }}
        className="dropdown-toggle"
      >
        {name || user?.name || ''}
        <Avatar
          style={{ marginTop: 0, marginLeft: '1rem' }}
          imgUrl={
            doc.avatar ? sign(doc.avatar, 76).url : inlineAvatar(user?.sub)
          }
        />
      </Button>
    )
  }

  return (
    <TopBar style={{ padding: '0 25px', borderBottomColor: '#E9E9EC' }}>
      {back ? <Back to={back} /> : null}
      <h2 style={{ flexGrow: 1, marginTop: 2 }} className="title">
        {children || userId}
      </h2>

      {signingOut ? (
        <SignOutAuth0 />
      ) : user ? (
        <>
          <Dropdown ButtonComponent={AvatarButton} style={{ marginTop: 0 }}>
            <DropdownItem
              Component={Link}
              to={`/${pid}/users/${user.sub}/overview`}
              onClick={() => track('My Profile Clicked')}
            >
              <Icon style={{ marginRight: '1rem' }}>edit</Icon>
              {t('My profile')}
            </DropdownItem>
            <DropdownItem
              Component={Link}
              to={`/${pid}/profile`}
              onClick={() => track('Account Settings Clicked')}
            >
              <Icon style={{ marginRight: '1rem' }}>edit</Icon>
              {t('Personal settings')}
            </DropdownItem>
            <DropdownItem onClick={() => setSigningOut(true)}>
              <Icon style={{ marginRight: '1rem' }}>close</Icon>Log out
            </DropdownItem>
          </Dropdown>
        </>
      ) : (
        '-'
      )}
    </TopBar>
  )
}

export function Back({ to }) {
  return (
    <Link to={to}>
      <svg
        width={20}
        height={20}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ marginRight: 8 }}
      >
        <path
          d="M15.833 10H4.167M10 15.833L4.167 10 10 4.167"
          stroke="#787979"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Link>
  )
}

export function TopRightUser() {
  const pid = usePid()
  const user = useUser()
  const { track } = useAnalytics()

  const [signingOut, setSigningOut] = useState(false)
  if (signingOut) return <SignOutAuth0 />

  if (!user) {
    return
  }

  return (
    <div style={{ position: 'absolute', top: 16, right: 25 }}>
      <Dropdown ButtonComponent={AvatarButton} style={{ marginTop: 0 }}>
        <DropdownItem
          Component={Link}
          to={`/${pid}/users/${user.sub}/overview`}
          onClick={() => track('My Profile Clicked')}
        >
          <Icon style={{ marginRight: '1rem' }}>edit</Icon>My profile
        </DropdownItem>
        <DropdownItem
          Component={Link}
          to={`/${pid}/profile`}
          onClick={() => track('Account Settings Clicked')}
        >
          <Icon style={{ marginRight: '1rem' }}>edit</Icon>Account settings
        </DropdownItem>
        <DropdownItem onClick={() => setSigningOut(true)}>
          <Icon style={{ marginRight: '1rem' }}>close</Icon>Log out
        </DropdownItem>
      </Dropdown>
    </div>
  )
}

export function AvatarButton({ handleToggle }) {
  const user = useUser()
  const { name } = useProfile()
  return (
    <Button
      onClick={handleToggle}
      style={{ border: 0 }}
      className="dropdown-toggle"
    >
      {name || user?.name || ''}
      <MyAvatar />
    </Button>
  )
}

export function MyAvatar() {
  const { uid, doc } = useProfile()
  const sign = useSignedImage()
  console.log('av', uid)
  return (
    <Avatar
      style={{ marginTop: 0, marginLeft: '1rem' }}
      imgUrl={doc.avatar ? sign(doc.avatar, 76).url : inlineAvatar(uid)}
    />
  )
}
