import React, { ReactNode, useEffect, useMemo } from 'react'
import {
  ApolloClient,
  ApolloProvider,
  gql,
  useApolloClient,
  useMutation,
  useQuery,
  useSubscription,
} from '@apollo/client'
import zustand from 'zustand'
import { str62 } from '@bothrs/util/random'
import { uniqBy } from '@bothrs/util/uniq'

import { useAnalytics } from '@healthblocks-io/core/analytics'
import { useAuthState } from '@healthblocks-io/core/auth'
import { GlobalHook } from '@healthblocks-io/core/hook'
import { useGraphURL } from '@healthblocks-io/core/project'
import type { Message, SendMessage } from '@healthblocks-io/core/types'

import { createHttpClient, createWebsocketClient } from './client'
import {
  useProfileEditor,
  useProfileUpdate,
  useProfile,
  useProfileQuery,
} from './profile'

export { useProfileEditor, useProfileUpdate, useProfile, useProfileQuery }

export {
  ApolloProvider,
  gql,
  useApolloClient,
  useMutation,
  useQuery,
  useSubscription,
}

export type ApolloClientType = ApolloClient<object>

// General

export function UserApolloProvider({ children }: { children: ReactNode }) {
  const graph = useGraphURL()
  if (!graph) {
    throw new Error(
      'Wait for the project to be loaded or override the graph endpoint using <ProjectProvider graph="..." />'
    )
  }
  const auth = useAuthState()
  if (!auth) {
    throw new Error('Wrap <UserApolloProvider> inside <AuthProvider>')
  }
  const client = useMemo(() => createHttpClient(graph, auth), [graph, auth])
  return React.createElement(ApolloProvider, { client, children })
}

export function UserApolloProviderWs({ children }: { children: ReactNode }) {
  const graph = useGraphURL()
  if (!graph) {
    throw new Error(
      'Wait for the project to be loaded or override the graph endpoint using <ProjectProvider graph="..." />'
    )
  }
  const auth = useAuthState()
  if (!auth) {
    throw new Error('Wrap <UserApolloProvider> inside <AuthProvider>')
  }
  const client = useMemo(
    () => createWebsocketClient(graph, auth),
    [graph, auth]
  )
  return React.createElement(ApolloProvider, { client, children })
}

// User > Profile > Medication

export function useMedication() {
  console.log('?useMedication')
  const { profile, updateDoc } = useProfileEditor()
  const {
    doc: { medications },
  } = profile
  const { track } = useAnalytics()
  return { medications, update, create, remove }

  function update(id: string, updates: any) {
    console.log('useMedication.update', id)
    track('Medication Updated', {
      medication_id: id,
      medication_name: updates.name,
    })
    return updateDoc({
      medications: (medications || []).map(med =>
        med.id === id ? { ...med, ...updates } : med
      ),
    })
  }
  function create(medication: any) {
    // TODO: generate id?
    console.log('useMedication.create', medication.id)
    if (!medication.id) {
      medication = { ...medication, id: str62(20) }
    }
    track('Medication Created', {
      medication_id: medication.id,
      medication_name: medication.name,
    })
    return updateDoc({
      medications: (medications || []).concat(medication),
    })
  }
  function remove(id: string) {
    console.log('useMedication.remove', id)
    track('Medication Removed', {
      medication_id: id,
      medication_name: (medications || []).find(m => m.id === id)?.name,
    })
    return updateDoc({
      medications: (medications || []).filter(m => m.id !== id),
    })
  }
}

// Conversation

type LocalThreadsState = {
  [tid: string]: Partial<Message>[]
}

/** @deprecated Avoid global state */
export const useLocalThreads = zustand<LocalThreadsState>(
  (set, get): LocalThreadsState => ({})
)

export function updateThread(client, variables) {
  client.mutate({ mutation: UpdateThread, variables }).then(result => {
    if (variables.status === 'flow_completed') {
      GlobalHook.emit('track', {
        event: 'Flow Completed',
        properties: {
          thread_id: variables.tid,
          flow_name: result.data.update_threads_by_pk.flow_name,
        },
      })
    }
    return result
  })
}

/** @deprecated Manage optimistic send using local state */
export function send(
  client: ApolloClientType,
  { body, doc, tid, qid, fid, value, summary }: SendMessage & { tid: string }
) {
  // Validate message
  if (!body) {
    throw new Error('message.body is required')
  }
  if (!doc) {
    throw new Error('message.doc is required')
  }

  if (doc.startFlows && !doc.startFlow) {
    console.warn(
      'message.doc.startFlow should be explicit when using message.doc.startFlows'
    )
    doc.startFlow = doc.startFlows[0]
  }

  if (summary) {
    console.warn('message.summary is Deprecated')
    return
  }

  // Save to database
  const variables = {
    tid,
    mid: 'm:' + str62(20),
    body,
    doc,
  } as any

  let observation = false
  if (fid && typeof value === 'string') {
    variables.fid = fid
    variables.valueString = value
    observation = true
  } else if (fid && typeof value === 'number') {
    variables.fid = fid
    variables.valueInteger = value
    observation = true
  } else {
    variables.qid = qid || null
  }
  // console.log('vaa', observation && variables)

  client.mutate({
    mutation: observation ? InsertMessageAndObservation : InsertMessage,
    variables,
  })
  useLocalThreads.setState(all => ({
    [tid]: (all[tid] || []).concat({ ...variables }),
  }))

  // If the question id in airtable starts with user. we want to save this to the users profile.
  if (doc.replyTo?.startsWith('user.')) {
    console.warn('Updating the profile using "user." has been removed')
  }

  // Track responses
  if (doc.replyTo) {
    GlobalHook.emit('track', {
      event: 'Question Answered',
      properties: {
        question_id: doc.replyTo,
        value:
          typeof value !== 'undefined'
            ? value
            : typeof doc.value !== 'undefined'
            ? doc.value
            : body,
      },
    })
  }

  // Track completed flows
  if (variables.doc.stopFlow) {
    GlobalHook.emit('track', {
      event: 'Flow Completed',
      properties: {
        flow_name: variables.doc.stopFlow,
      },
    })
  }

  // Track completed flows
  if (variables.doc.startFlow) {
    GlobalHook.emit('track', {
      event: 'Flow Started',
      properties: { flow_name: variables.doc.startFlow },
    })
  }
}

export function useStartedFlow(flowName: string) {
  return useSubscription(StartedFlowByName, {
    variables: {
      flowName,
      status: 'flow_started',
    },
  })
}

export function insertThread(
  client: ApolloClientType,
  uid: string,
  thread: any
) {
  const variables = {
    tid: 't' + str62(20),
    // TODO: use hasura preset
    user: uid,
    name: 'Flow',
    status: 'insertThread',
    ...thread,
  }
  console.log('insertThread', variables.tid, thread)

  if (variables.status === 'flow_started') {
    GlobalHook.emit('track', {
      event: 'Flow Started',
      properties: {
        thread_id: variables.tid,
        flow_name: thread.flow_name,
      },
    })
  }
  return client.mutate({ mutation: InsertThread, variables })
}

export function useFlow(flowName: string) {
  return useSubscription(FlowByNameSubscription, {
    variables: {
      flowName,
    },
  })
}

/** @deprecated depends on useLocalThreads */
export function useThread2(tid: string) {
  // Temporary data
  const localCreates = useLocalThreads(all => all[tid]) || []

  // Load messages
  const { data, loading, error } = useSubscription(Thread2, {
    variables: { tid },
  })
  const messages: Message[] =
    // TODO: use proper typing
    // @ts-ignore
    (data?.threads_by_pk?.messages || [])
      .concat(localCreates)
      .filter(uniqBy('mid')) || ([] as Message[])

  useEffect(() => {
    // Clear all cached threads after closing the page
    return () => {
      useLocalThreads.setState({ [tid]: [] })
    }
  }, [])

  return {
    loading,
    error,
    data,
    messages,
  }
}

export function useFlowReminders() {
  const { profile, updateDoc } = useProfileEditor()
  const { reminders } = profile.doc
  /** @todo Loading was force to false */
  return { reminders, update, create, remove, loading: false }

  function update(id: string, updates: any) {
    console.log('useFlowReminder.create', id)
    return updateDoc({
      reminders: (reminders || []).map(rem =>
        rem.id === id ? { ...rem, ...updates } : rem
      ),
    })
  }
  function create(reminder: any) {
    // TODO: generate id?
    console.log('useFlowReminder.create', reminder.id)
    if (!reminder.id) {
      reminder.id = str62(20)
    }
    return updateDoc({
      reminders: (reminders || []).concat(reminder),
    })
  }
  function remove(id: string) {
    console.log('useFlowReminder.remove', id)
    return updateDoc({
      reminders: (reminders || []).filter(m => m.id !== id),
    })
  }
}

// TODO: types
export function useQuestionnaires() {
  return useQuery<QuestionnairesResult>(PublicQuestionnairesQuery, {
    fetchPolicy: 'cache-and-network',
  })
}

export function useQuestionnaireByName(name: string) {
  return useQuery<QuestionnairesResult>(PublicQuestionnaireByNameQuery, {
    fetchPolicy: 'cache-and-network',
    variables: { name },
  })
}

export interface Answer {
  question_id: string
  linkId?: string
  text: string
  answer: object
}
interface saveQuestionnaireResponseProps {
  questionnaire_id: string
  answers: Answer[]
  completed_at?: string
}
/** @todo return types */
export function saveQuestionnaireResponse(
  client: ApolloClientType,
  variables: saveQuestionnaireResponseProps
) {
  return client.mutate({
    mutation: InsertQuestionnaireResponse,
    variables,
  })
}

// TODO: return types
export function saveQuestionnaireResponseActivity(
  client: ApolloClientType,
  variables: saveQuestionnaireResponseProps
) {
  if (!variables.completed_at) variables.completed_at = new Date().toJSON()
  return client.mutate({
    mutation: InsertQuestionnaireResponseActivity,
    variables,
  })
}

// Helpers

/**
 * Minimalistic debounce without passing arguments
 *
 * @param {Function} func - Expensive function
 * @param {number} [wait] - Milliseconds to wait before running the function
 */
function debounce(func: () => void, wait = 1000): () => void {
  let timeout
  return function () {
    clearTimeout(timeout)
    timeout = setTimeout(func, wait)
  }
}

// GraphQL

interface QuestionnairesResult {
  questionnaires: Questionnaire[]
}

interface Questionnaire {
  id: string
  name: string
  title: string
  description: string
  date: string
  status: 'draft' | 'active' | 'retired' | 'unknown'
  url: string
  questions: Question[]
}

interface Question {
  id: string
  linkId?: string
  text: string
  type: string
  config: any
}

const PublicQuestionnairesQuery = gql`
  query PublicQuestionnairesQuery {
    questionnaires(where: { status: { _eq: "active" } }) {
      id
      name
      title
      description
      date
      status
      url
      questions(order_by: { index: asc }) {
        config
        id
        linkId
        text
        type
      }
    }
  }
`

const PublicQuestionnaireByNameQuery = gql`
  query PublicQuestionnaireByNameQuery($name: String = "") {
    questionnaires(where: { status: { _eq: "active" }, name: { _eq: $name } }) {
      id
      name
      title
      description
      date
      status
      url
      questions(order_by: { index: asc }) {
        config
        id
        linkId
        text
        type
      }
    }
  }
`

const Conversation = gql`
  subscription Conversation {
    threads {
      tid
      user
      name
      updated_at
      messages(order_by: { sent_at: asc }) {
        body
        doc
        mid
        qid
        sender
        sent_at
        tid
        user {
          role
          uid
          doc
        }
      }
    }
  }
`

const Thread2 = gql`
  subscription Thread2($tid: String!) {
    threads_by_pk(tid: $tid) {
      tid
      doc
      flow_name
      name
      pid
      status
      messages(order_by: { sent_at: asc }) {
        body
        doc
        mid
        qid
        sender
        sent_at
        tid
        user {
          role
          uid
          doc
        }
      }
    }
  }
`

const FlowByNameSubscription = gql`
  subscription FlowByName($flowName: String!) {
    threads(where: { flow_name: { _eq: $flowName } }) {
      tid
      user
      name
      doc
      flow_name
      status
      created_at
      updated_at
    }
  }
`

const StartedFlowByName = gql`
  subscription StartedFlowByName($flowName: String!, $status: String!) {
    threads(
      where: { flow_name: { _eq: $flowName }, status: { _eq: $status } }
    ) {
      tid
      user
      name
      doc
      flow_name
      status
      created_at
      updated_at
    }
  }
`

// const Flow = gql`
//   subscription Flow($flowName: String!) {
//     threads(where: { flow_name: { _eq: $flowName } }) {
//       tid
//       user
//       name
//       updated_at
//       messages(order_by: { sent_at: asc }) {
//         body
//         doc
//         mid
//         qid
//         sender
//         sent_at
//         tid
//         user {
//           role
//           uid
//           doc
//         }
//       }
//     }
//   }
// `

const InsertMessage = gql`
  mutation InsertMessage(
    $body: String!
    $doc: jsonb!
    $mid: String!
    $qid: String
    $tid: String!
  ) {
    insert_messages(
      objects: { body: $body, doc: $doc, mid: $mid, qid: $qid, tid: $tid }
    ) {
      affected_rows
    }
  }
`

const InsertMessageAndObservation = gql`
  mutation InsertMessageAndObservation(
    $body: String!
    $doc: jsonb!
    $mid: String!
    $tid: String!
    $fid: String!
    $valueString: String
    $valueInteger: Int
  ) {
    insert_messages(objects: { body: $body, doc: $doc, mid: $mid, tid: $tid }) {
      affected_rows
    }
    insert_observations(
      objects: {
        valueInteger: $valueInteger
        valueString: $valueString
        mid: $mid
        fid: $fid
        tid: $tid
      }
    ) {
      affected_rows
    }
  }
`

const InsertQuestionnaireResponse = gql`
  mutation InsertQuestionnaireResponse(
    $questionnaire_id: uuid!
    $answers: [question_answer_insert_input!]!
  ) {
    insert_questionnaire_response_one(
      object: {
        questionnaire_id: $questionnaire_id
        answers: { data: $answers }
      }
    ) {
      id
    }
  }
`

const InsertQuestionnaireResponseActivity = gql`
  mutation InsertQuestionnaireResponseActivity(
    $questionnaire_id: uuid!
    $answers: [question_answer_insert_input!]!
    $completed_at: timestamptz
  ) {
    insert_activity_one(
      object: {
        kind: "Questionnaire"
        status: "completed"
        completed_at: $completed_at
        questionnaire_response: {
          data: {
            questionnaire_id: $questionnaire_id
            answers: { data: $answers }
          }
        }
      }
    ) {
      id
    }
  }
`

const InsertThread = gql`
  mutation InsertThread(
    $tid: String!
    $name: String!
    $user: String!
    $flow_name: String
    $status: String
  ) {
    insert_threads(
      objects: {
        tid: $tid
        name: $name
        user: $user
        flow_name: $flow_name
        status: $status
      }
    ) {
      affected_rows
    }
  }
`

const UpdateThread = gql`
  mutation UpdateThread($tid: String!, $status: String) {
    update_threads_by_pk(pk_columns: { tid: $tid }, _set: { status: $status }) {
      tid
      flow_name
    }
  }
`

export const DeleteUser = gql`
  mutation DeleteUser($uid: String!) {
    delete_users_by_pk(uid: $uid) {
      email
    }
  }
`

// Helpers

function pick<T>(obj: T & object, fields: string[]): Partial<T> {
  return fields.reduce((acc, x) => {
    if (obj.hasOwnProperty(x)) {
      acc[x] = obj[x]
    }
    return acc
  }, {})
}

function omit<T>(obj: T & object, fields: string[]): Partial<T> {
  const acc: Partial<T> = {}
  for (const field in obj) {
    if (!fields.includes(field)) {
      acc[field] = obj[field]
    }
  }
  return acc
}
