import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useFilters, usePagination, useSortBy, useTable } from 'react-table'
import { uniqBy } from '@bothrs/util/uniq'
import Modal from '@duik/modal'
import { Select } from '@duik/select'
import { Toggle } from '@duik/toggle'

import { useAnalytics, useTrack } from '@healthblocks-io/core/analytics'
import { useUser } from '@healthblocks-io/core/auth'
import { useCareTeam } from '@healthblocks-io/core/careteam'
import {
  CodeableConceptColor,
  useCommunicationCategories,
  useCommunicationCategoryColors,
} from '@healthblocks-io/core/communication'
import {
  BundleLoader,
  fhirGet,
  useBundle,
  useFHIR,
} from '@healthblocks-io/core/fhir'
import { useSubject } from '@healthblocks-io/core/subject'
import type { Communication, Reference } from '@healthblocks-io/core/types'
import { useSignedURL, useUpload } from '@healthblocks-io/core/upload'

import { humanDatetime } from 'lib/date-fns'
import { useResourceEditor } from 'lib/editor'
import { useTranslation } from 'lib/i18n'
import ActivityIcon, {
  ButtonFeatherIcon,
  FeatherIcon,
} from 'pages/atoms/ActivityIcon'
import Button from 'pages/atoms/Button'
import Input from 'pages/atoms/Input'
import Label from 'pages/atoms/Label'
import MarkdownInput from 'pages/atoms/MarkdownInput'
import SelectParticipant from 'pages/atoms/SelectParticipant'

import { DropdownInput } from './FormFields'

export default function CommunicationsPage({ uid: _uid }: { uid: string }) {
  const [editing, setEditing] = useState<Communication>()

  const subject = useSubject()
  useTrack('Communications Overview Opened', subject.track, [subject.uid])

  return (
    <div>
      <CommunicationsTableTop setEditing={setEditing} />
      <BundleLoader type="Communication" params={{ sign: 1 }}>
        <PureCommunicationsTable setEditing={setEditing} />
      </BundleLoader>
      <CommunicationModal editing={editing} setEditing={setEditing} />
    </div>
  )
}

export function CommunicationModal({
  editing,
  setEditing,
}: {
  editing: Communication
  setEditing: (c: Communication) => void
}) {
  const { upsert, remove } = useFHIR()
  const { track } = useAnalytics()
  return (
    <Modal
      className="modal-top"
      isOpen={!!editing}
      handleClose={() => setEditing(null)}
      closeOnOuterClick
      sm
    >
      <CommunicationEditor
        communication={editing}
        save={async resource => {
          try {
            await upsert(resource)
            setEditing(null)

            const hash: any = {}
            for (const code of resource.category) {
              hash['tag_' + code.text] = 1
            }
            track(
              resource.id ? 'Communication Updated' : 'Communication Created',
              hash
            )
          } catch (e) {
            alert(e.message)
          }
        }}
        remove={async () => {
          try {
            await remove(editing)
            setEditing(null)
            track('Communication Removed')
          } catch (e) {
            alert(e.message)
          }
        }}
      />
    </Modal>
  )
}

export function CommunicationsTableTop({
  setEditing,
}: {
  setEditing: (r: Communication) => void
}) {
  const { t } = useTranslation()
  const subject = useSubject().reference
  const { me } = useCareTeam()
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <h2>{t('Notes')}</h2>
      <Button
        className="m-0"
        primary
        inline
        onClick={() => setEditing(emptyCommunication({ subject, sender: me }))}
      >
        {t('Add note')}
      </Button>
    </div>
  )
}

function PureCommunicationsTable({
  setEditing,
}: {
  setEditing: (r: Communication) => void
}) {
  const { t } = useTranslation()
  const communications = useBundle<Communication>('Communication').sort(
    (a, b) => -a.sent?.localeCompare(b.sent)
  )
  const colors = useCommunicationCategoryColors()
  const { sub } = useUser()
  const relevant = useCallback(
    (ref: Reference) => ref?.reference?.endsWith(sub),
    [sub]
  )

  const team = useCareTeam()
  const data = useMemo(
    () =>
      communications.filter(com =>
        team.can({
          action: 'read',
          resource: com.category?.find(cat => cat.text === 'Medicatieschema')
            ? 'fhir:MedicationStatement'
            : 'fhir:Communication',
        })
      ),
    [communications, team]
  )

  const columns = React.useMemo(
    () => [
      {
        id: 'sent',
        Header: t('Date'),
        accessor: communication => Date.parse(communication.sent),
        Cell: ({ value }) => humanDatetime(new Date(value)),
      },
      {
        id: 'sender',
        Header: t('From'),
        accessor: communication => communication.sender?.display || '?',
        // eslint-disable-next-line react/display-name
        Cell: ({ row, value }) => (
          <div className={relevant(row.original.sender) ? ' th--relevant' : ''}>
            {value}
          </div>
        ),
        filter: 'fuzzyText',
        initialFilterValue: '',
      },
      {
        id: 'recipient',
        Header: t('To'),
        accessor: communication =>
          communication.recipient?.map(ref => ref.display).join('\n') ||
          t('Full care team'),
        // eslint-disable-next-line react/display-name
        Cell: ({ row, value }) => (
          <div
            className={
              row.original.recipient?.find(relevant) ? ' th--relevant' : ''
            }
          >
            {value}
          </div>
        ),
        filter: 'fuzzyText',
        initialFilterValue: '',
      },
      {
        id: 'payload',
        Header: t('Note'),
        accessor: communication =>
          communication.payload
            ?.map(p => p.contentString)
            .filter(Boolean)
            .join('\n\n'),
        filter: 'fuzzyText',
        initialFilterValue: '',
      },
      {
        id: 'attachment',
        Header: ' ',
        accessor: communication =>
          !!communication.payload?.find(p => p.contentAttachment),
        // eslint-disable-next-line react/display-name
        Cell: ({ value }) =>
          value ? (
            <ActivityIcon icon="paperclip" size={18} color="#BEBEBE" />
          ) : null,
        filter: 'boolean',
        filterHeader: t('Attachment'),
        initialFilterValue: { boolean: true },
      },
      {
        id: 'category',
        Header: t('Category'),
        accessor: communication =>
          communication.category?.map(
            p => colors.find(c => c.text === p.text) || fallbackColor(p.text)
          ),
        Cell: ({ value }) =>
          value?.map((p, key) => <Pill key={key} {...p} />) || null,
        filter: 'category',
        initialFilterValue: { categories: [] },
      },
    ],
    [relevant, colors, t]
  )

  const filterTypes = useMemo(
    () => ({
      fuzzyText: (rows, id, filterValue) => {
        if (!filterValue || !filterValue.trim()) return rows
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .includes(String(filterValue).trimLeft().toLowerCase())
            : false
        })
      },
      boolean: (rows, id, filterValue) => {
        if (!filterValue) return rows
        return rows.filter(row => row.values[id] === filterValue.boolean)
      },
      category: (rows, id, filterValue) => {
        if (!filterValue.categories.length) return rows
        return rows.filter(row =>
          row.values[id]?.find(c => filterValue.categories.includes(c.text))
        )
      },
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,

    // Filter
    setFilter,
    setAllFilters,

    // Pagination
    page, // Instead of using 'rows', we'll use page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 50,
        sortBy: [{ id: 'sent', desc: true }],
      },
      filterTypes,
      // autoResetFilters: false,
      autoResetSortBy: false,
    },
    useFilters,
    useSortBy,
    usePagination
  )

  const allColumnOptions = columns
    .filter(col => col.filter)
    .map(col => ({
      value: col.id,
      label: col.filterHeader || col.Header,
      col,
    }))
  const columnOptions = allColumnOptions.filter(
    col => !state.filters.find(f => f.id === col.value)
  )

  // Track enabled filters
  const { track } = useAnalytics()
  useEffect(() => {
    if (!state.filters.length) return
    const t = setTimeout(() => {
      const hash: any = {}
      for (const filter of state.filters) {
        hash['filter_' + filter.id] =
          typeof filter.value === 'object'
            ? JSON.stringify(filter.value)
            : filter.value || '_'
      }
      track('Filters Enabled', hash)
    }, 2000)
    return () => {
      clearTimeout(t)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(state.filters)])

  const numberOptions = [
    { label: 'equals', value: 'eq' },
    { label: 'greater than', value: 'gt' },
    { label: 'lower than', value: 'lt' },
  ]
  return (
    <>
      <div>
        {state.filters.map((filter, key) => (
          <div
            key={key}
            style={{ display: 'flex', alignItems: 'center', margin: '12px 0' }}
          >
            <FeatherIcon d="M22 3H2l8 9.46V19l4 2v-8.54L22 3z" size={18} />
            <Select
              style={{ width: 210, margin: '0 8px' }}
              className="select--pretty"
              ButtonComponent={DropdownInput}
              activeOption={allColumnOptions.find(
                opt => opt.value === filter.id
              )}
              options={columnOptions}
              onOptionClick={({ value }) => {
                if (value === filter.id) {
                  return // no-op
                }
                const opt = columnOptions.find(opt => opt.value === value)
                if (!value) {
                  return
                }
                setAllFilters(v =>
                  v
                    // Remove existing filter because doubles are not allowed
                    .filter(f => f.id !== value)
                    // Replace this filter with newly chosen filter
                    .map(f =>
                      f.id === filter.id
                        ? { id: value, value: opt.col.initialFilterValue }
                        : f
                    )
                )
              }}
              placeholder={t('Choose_placeholder')}
            />
            {filter.value?.type ? (
              <>
                <Select
                  style={{ width: 150, marginRight: 8 }}
                  className="select--pretty"
                  ButtonComponent={DropdownInput}
                  activeOption={numberOptions.find(
                    opt => opt.value === filter.value.type
                  )}
                  options={numberOptions}
                  onOptionClick={({ value }) => {
                    setFilter(filter.id, v => ({ ...v, type: value }))
                  }}
                  placeholder={t('Choose_placeholder')}
                />
                <Input
                  type="number"
                  style={{ width: 150, marginBottom: 0 }}
                  value={filter.value.value}
                  onChangeText={value => {
                    setFilter(filter.id, v => ({ ...v, value }))
                  }}
                />
              </>
            ) : // These conditions should look at the filter type instead of value
            typeof filter.value?.boolean === 'boolean' ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Toggle
                  checked={filter.value?.boolean}
                  onChange={evt =>
                    setFilter(filter.id, { boolean: evt.target.checked })
                  }
                  label={t('Included')}
                />
              </div>
            ) : Array.isArray(filter.value?.categories) ? (
              <Select
                style={{ width: 210, margin: '0 8px' }}
                className="select--pretty"
                ButtonComponent={DropdownInput}
                activeOption={filter.value.categories.map(label => ({
                  label,
                  value: label,
                }))}
                multiple
                options={colors.map(c => ({ label: t(c.text), value: c.text }))}
                onOptionClick={({ value }) => {
                  setFilter(filter.id, ({ categories }) => ({
                    categories: categories.includes(value)
                      ? categories.filter(c => c !== value)
                      : categories.concat(value),
                  }))
                }}
                placeholder={t('Choose_placeholder')}
              />
            ) : (
              <Input
                style={{ width: 150, marginBottom: 0 }}
                value={String(filter.value)}
                onChangeText={text => {
                  setFilter(filter.id, text)
                }}
              />
            )}
            <button
              className="btn--unstyled btn--icon-only"
              onClick={() => {
                setAllFilters(filters =>
                  filters.filter(f => f.id !== filter.id)
                )
              }}
            >
              <FeatherIcon d="M15 9L9 15M9 9l6 6" />
            </button>
          </div>
        ))}
        {columnOptions.length ? (
          <div style={{ marginLeft: -20 }}>
            <Button
              id="filter-communications"
              data-filter1={state.filters[0]?.id}
              data-filter2={state.filters[1]?.id}
              data-filter3={state.filters[2]?.id}
              primary
              subtle
              inline
              onClick={() => {
                const next = columnOptions[0].col
                setFilter(next.id, next.initialFilterValue)
              }}
            >
              <ButtonFeatherIcon d="M12 5v14M5 12h14" size={18} mr />
              {t('Add filter')}
            </Button>
          </div>
        ) : null}
      </div>
      <div className="tbl__border mt-3">
        <table
          className="tbl tbl--mini tbl--sticky tbl--wide"
          {...getTableProps()}
        >
          <thead>
            {headerGroups.map(headerGroup => (
              // eslint-disable-next-line react/jsx-key
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  // eslint-disable-next-line react/jsx-key
                  <th
                    className="th--sort"
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render('Header')}
                    <span className="th__sort">
                      {column.isSorted
                        ? column.isSortedDesc
                          ? ' 🔽'
                          : ' 🔼'
                        : ''}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row)
              return (
                // eslint-disable-next-line react/jsx-key
                <tr
                  className={
                    'tr--clickable' +
                    (relevant(row.original.sender) ||
                    row.original.recipient?.find(relevant)
                      ? ' tr--relevant'
                      : '')
                  }
                  onClick={() => setEditing(row.original)}
                  {...row.getRowProps()}
                >
                  {row.cells.map(cell => {
                    return (
                      // eslint-disable-next-line react/jsx-key
                      <td
                        {...cell.getCellProps()}
                        className={
                          cell.column.id === 'payload' ? 'text-break' : ''
                        }
                      >
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      <div className="pagination mt-2">
        <button
          onClick={() => gotoPage(0)}
          className="btn"
          disabled={!canPreviousPage}
        >
          {'<<'}
        </button>{' '}
        <button
          onClick={() => previousPage()}
          className="btn"
          disabled={!canPreviousPage}
        >
          {'<'}
        </button>{' '}
        <button
          onClick={() => nextPage()}
          className="btn"
          disabled={!canNextPage}
        >
          {'>'}
        </button>{' '}
        <button
          onClick={() => gotoPage(pageCount - 1)}
          className="btn"
          disabled={!canNextPage}
        >
          {'>>'}
        </button>{' '}
        <span className="mx-3">
          {t('Page')}{' '}
          <strong>
            {pageIndex + 1} {t('of')} {pageOptions.length}
          </strong>{' '}
        </span>
        <select
          className="btn"
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value))
          }}
        >
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              {t('Show')} {pageSize}
            </option>
          ))}
        </select>
      </div>
    </>
  )
}

export function fallbackColor(text: string) {
  return { text, color: '#666', background: '#ddd' }
}

export function Pill({ text, background, color }: CodeableConceptColor) {
  const { t } = useTranslation()
  return (
    <div
      style={{
        display: 'inline-block',
        padding: '2px 8px',
        margin: 2,
        fontWeight: 'bold',
        fontSize: 11,
        letterSpacing: 1,
        lineHeight: '19px',
        borderRadius: 19,
        textTransform: 'uppercase',
        background,
        color,
      }}
    >
      {t(text)}
    </div>
  )
}

export function CommunicationEditor({
  communication,
  save,
  remove,
}: {
  communication: Communication
  save: (p: Communication) => void
  remove: () => void
}) {
  const { t } = useTranslation()
  const [{ data: o, saving }, dispatch] =
    useResourceEditor<Communication>(communication)

  const care = useCareTeam()
  const allowEdit = care.can({
    action: o.id ? 'update' : 'create',
    resource: 'fhir:Communication',
    getter: x => fhirGet(x, communication),
  })
  const allowDelete = care.can({
    action: 'delete',
    resource: 'fhir:Communication',
    getter: x => fhirGet(x, communication),
  })

  if (!o) {
    return <div>?</div>
  }

  return (
    <div style={{ padding: 25 }}>
      <h3 style={{ fontWeight: 'bold', margin: '24px 0' }}>
        {t(o.id ? 'Edit a note' : 'Add a note')}
      </h3>

      <General communication={o} dispatch={dispatch} disabled={!allowEdit} />

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: o.id ? 'space-between' : 'flex-end',
          marginTop: 24,
        }}
      >
        {o.id ? (
          <Button
            id="delete-communication"
            className="mb-0"
            inline
            error
            onClick={remove}
            disabled={!allowDelete}
          >
            {t('Remove note')}
          </Button>
        ) : null}
        <Button
          id={o.id ? 'update-communication' : 'create-communication'}
          className="mb-0"
          inline
          onClick={() => save(o)}
          disabled={saving || !allowEdit}
        >
          {t(o.id ? 'Apply changes' : 'Add note')}
        </Button>
      </div>
    </div>
  )
}

function General({
  communication,
  disabled,
  dispatch,
}: {
  communication: Communication
  disabled?: boolean
  dispatch: (o: any) => void
}) {
  const { t } = useTranslation()
  const subject = useSubject()
  const { track } = useAnalytics()
  const categories = useCommunicationCategories()

  const team = useCareTeam()
  const allowedCategories = useMemo(
    () =>
      categories.filter(cat =>
        team.can({
          action: 'read',
          resource:
            cat.text === 'Medicatieschema'
              ? 'fhir:MedicationStatement'
              : 'fhir:Communication',
        })
      ),
    [categories, team]
  )

  const selected = useMemo(
    () => communication.category || [],
    [communication.category]
  )
  const allCategories = useMemo(
    () => allowedCategories.concat(selected).filter(uniqBy('text')),
    [allowedCategories, selected]
  )
  const upload = useUpload()

  const payload = communication.payload || []
  const textNoteIndex = textIndex(payload)
  const textNote = payload?.[textNoteIndex]
  const textLength = textNote?.contentString?.length || 0
  const sign = useSignedURL()

  return (
    <div>
      <div style={{ marginBottom: 20 }}>
        <Label>{t('Note')}</Label>
        <MarkdownInput
          // limit={320}
          disabled={disabled}
          value={textNote?.contentString}
          placeholder="Your remarks here..."
          onChangeText={text =>
            dispatch({ ['payload[' + textNoteIndex + '].contentString']: text })
          }
        />
        <div style={{ marginTop: 6, fontSize: 13 }}>
          {t('Max 320 characters')}.{' '}
          {textLength > 320
            ? 'Over limit'
            : textLength > 30
            ? 320 - textLength + ' characters left.'
            : ''}
        </div>
      </div>

      <div style={{ marginBottom: 20 }}>
        <Label>{t('Category')}</Label>
        <Select
          multiple
          className="select--pretty"
          ButtonComponent={DropdownInput}
          activeOption={
            communication.category?.map(c => ({
              label: t(c.text),
              value: c.text,
            })) || []
          }
          options={allCategories.map(option => ({
            value: option.text,
            label: t(option.text) || '?',
          }))}
          onOptionClick={({ value }) => {
            if (disabled) return
            const category = allCategories.find(c => c.text === value)
            dispatch({
              category: selected.find(v => v.text === category.text)
                ? selected.filter(v => v.text !== category.text)
                : [].concat(selected, category),
            })
          }}
          placeholder={t('Choose_placeholder')}
        />
      </div>
      <Label>{t("Who's this for?")}</Label>
      <SelectParticipant
        placeholder={t('Full care team')}
        value={communication.recipient?.[0]}
        onChange={value =>
          disabled ||
          dispatch(
            Array.isArray(value)
              ? { recipient: value }
              : { 'recipient[0]': value }
          )
        }
      />
      <div style={{ marginTop: 20 }}>
        <Label>{t('Attachment')}</Label>
        {payload
          ?.filter(p => p.contentAttachment)
          .map((p, key) => (
            <div className="inp inp--uploaded" key={key}>
              <ActivityIcon icon="paperclip" color="#8F92A1" size={18} />
              <a
                href={sign(p.contentAttachment.url)}
                target="_blank"
                rel="noopener noreferrer"
                download={
                  new Date().toJSON().slice(0, 10) +
                  ' attachment ' +
                  p.contentAttachment.url?.split('?')[0].split('/').pop()
                }
                className="ml-2"
                style={{ flexGrow: 1 }}
              >
                {p.contentAttachment.url?.split('?')[0].split('/').pop()}
              </a>
              <Button
                className="mb-0 px-3"
                type="button"
                subtle
                inline
                disabled={disabled}
                onClick={() => {
                  dispatch({
                    payload: payload.filter(pa => pa !== p),
                  })
                }}
              >
                <FeatherIcon
                  d="M18 6L6 18M6 6l12 12"
                  color="#8F92A1"
                  size={18}
                />
              </Button>
            </div>
          ))}
        <label className="inp inp--upload" style={{ cursor: 'pointer' }}>
          <ActivityIcon icon="upload" color="#8F92A1" size={18} />
          <span className="ml-2">{t('Upload attachment')}</span>
          <input
            disabled={disabled}
            onChange={async evt => {
              dispatch({ type: 'save' })
              try {
                const file = await upload(evt.target.files[0])
                console.log('upload ok', file)
                dispatch({
                  payload: payload.concat({
                    contentAttachment: file,
                  }),
                })
                track('Communication Attachment Uploaded', {
                  ...subject.track,
                  attachmentCount:
                    1 + payload.filter(p => p.contentAttachment).length,
                })
              } catch (e) {
                console.log('upload err', e)
                alert('Failed to upload file: ' + e.message)
              }
              dispatch({ type: 'unsave' })
            }}
            type="file"
            name="attachment"
            id="attachment"
            accept="application/pdf,image/*"
          />
        </label>
      </div>
    </div>
  )
}

function textIndex(p: any[]) {
  const index = p.findIndex(p => typeof p.contentString === 'string')
  return index === -1 ? p.length : index
}

export function emptyCommunication({
  subject,
  sender,
  category,
}: Partial<Communication>): Communication {
  return {
    category,
    id: '',
    resourceType: 'Communication',
    subject,
    sender,
    sent: new Date().toJSON(),
    payload: [{ contentString: '' }],
  }
}
