import React, {
  useState,
  useReducer,
  useMemo,
  useEffect,
  useCallback,
} from 'react'
import { useHistory } from 'react-router-dom'
import formatRelative from 'date-fns/formatRelative'
import nlBE from 'date-fns/locale/en-GB'
import format from 'date-fns/format'
import { timeout } from '@bothrs/util/async'

import { Select } from '@duik/select'

import {
  gql,
  useApolloClient,
  useMutation,
  useQuery,
} from '@healthblocks-io/apollo'
import { useActivityKindOptions } from '@healthblocks-io/core/activity'
import { usePid } from '@healthblocks-io/core/project'
import type { Activity } from '@healthblocks-io/core/types'

import { useLocalStorage } from '@utils'
import cls from '../users.module.scss'
import Button from '../atoms/Button'
import { UserActivity } from './ActivityEditorCard'
import Input from 'pages/atoms/Input'
import Badge from '@components/Badge'

interface UidProp {
  uid: string
}

export default function CarePlan({ uid }: UidProp) {
  const [tab, setTab] = useLocalStorage<'scheduled' | 'history'>(
    'userCareplanTab',
    'history'
  )

  const [create] = useMutation(InsertActivityMutation, {
    refetchQueries: ['ScheduledActivities'],
  })

  return (
    <div>
      <h2>Care plan</h2>
      <p style={{ maxWidth: '36em' }}>
        This plan describes the care activities that are planned. Healthcare
        professionals can collaborate on personalized care for each patient.
      </p>
      <div style={{ float: 'right', marginTop: 10 }}>
        <Button
          className="mb-0"
          onClick={() =>
            create({
              variables: {
                object: {
                  kind: 'Task',
                  status: tab,
                  uid,
                  planned_at: new Date(),
                },
              },
            }).then(a => {
              retry(() =>
                document
                  .getElementById(a.data.insert_activity_one.id)
                  .scrollIntoView({ behavior: 'smooth', block: 'center' })
              )
            })
          }
        >
          Add activity
        </Button>
      </div>
      <h3>
        <button
          className={cls.btnSubtle}
          onClick={() => setTab('history')}
          disabled={tab === 'history'}
        >
          History
        </button>
        <button
          className={cls.btnSubtle + ' ml-3'}
          onClick={() => setTab('scheduled')}
          disabled={tab === 'scheduled'}
        >
          Scheduled
        </button>
      </h3>
      {tab === 'scheduled' ? (
        <CarePlanScheduled uid={uid} />
      ) : (
        <CarePlanHistory uid={uid} />
      )}
    </div>
  )
}

interface PatchState {
  saving: boolean
  patch: Map<string, any>
}

function initialPatch(): PatchState {
  return {
    saving: false,
    patch: new Map(),
  }
}

function careplanReducer(prev: PatchState, action) {
  if (action.type === 'discard') {
    return initialPatch()
  }
  if (action.type === 'save') {
    return { ...prev, saving: true }
  }
  const patch = new Map(prev.patch)
  if (action.id) {
    const a = { ...patch.get(action.id) }

    switch (action.type) {
      // case 'kind':
      //   a.kind = action.kind
      default:
        const { id, type, ...props } = action
        Object.assign(a, props)
    }
    patch.set(action.id, a)
    return { ...prev, patch }
  }
  return prev
}

const activityFields = [
  'careplan_id',
  'completed_at',
  'created_at',
  'doc',
  'expired_at',
  'id',
  'kind',
  'pid',
  'planned_at',
  'questionnaire_response_id',
  'reminder_at',
  'started_at',
  'status',
  'uid',
]

function CarePlanScheduled({ uid }: UidProp) {
  // Server state
  const { data } = useQuery(ScheduledActivitiesQuery, { variables: { uid } })

  // Merge doc into main object for easier state management
  // TODO: review if actually easier
  const serverData = useMemo(
    () => data?.activity.map(a => ({ ...a.doc, ...a })),
    [data]
  )

  // Track changes
  const [{ patch, saving }, dispatch] = useReducer(
    careplanReducer,
    null,
    initialPatch
  )
  const patchLen = Array.from(patch.keys()).length
  const patchedData = useMemo(
    () =>
      serverData?.map(a => {
        const patched = patch.get(a.id)
        if (patched) return { ...a, ...patched }
        return a
      }),
    [serverData, patch]
  )

  const client = useApolloClient()

  return (
    <div>
      {patchedData?.map(a => (
        <UserActivity activity={a} key={a.id} dispatch={dispatch} />
      )) || <pre>{JSON.stringify(data?.activity, null, 2)}</pre>}

      {patchLen ? (
        <div className={cls.confirmChanges}>
          <Button
            className="mb-0"
            inline
            onClick={() => save(patch, dispatch, client)}
            disabled={saving}
          >
            {patchLen < 2 ? 'Save activity' : 'Save activities'}
          </Button>
          <Button
            className="mb-0 ml-3"
            inline
            error
            onClick={() => dispatch({ type: 'discard' })}
            disabled={saving}
          >
            Discard changes
          </Button>
        </div>
      ) : null}
    </div>
  )
}

function CarePlanHistory({ uid }: UidProp) {
  const { data, error, refetch } = useQuery<{ activity: Activity[] }>(
    CompletedActivitiesQuery,
    { variables: { uid } }
  )

  return (
    <div>
      <FilterBar
        onChange={({ kinds }) =>
          refetch({ uid, kind: kinds ? { _in: kinds } : {} })
        }
      />
      {data?.activity?.length ? (
        <table className="tbl tbl--wide tbl--sticky">
          <thead>
            <tr>
              <th>Date</th>
              <th>Kind</th>
              <th>Title</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {data?.activity?.map((a, key) => (
              <ActivityRow activity={a} key={key} />
            ))}
          </tbody>
        </table>
      ) : data?.activity?.length === 0 ? (
        'No activities found...'
      ) : error ? (
        JSON.stringify(error.message)
      ) : (
        'Loading...'
      )}
    </div>
  )
}

function ActivityRow({ activity: a }) {
  const pid = usePid()
  const history = useHistory()
  const url = `/${pid}/users/${a.uid}/activities/${a.id}`

  const completed_at = new Date(a.completed_at || a.planned_at)
  return (
    <tr
      className={cls.clickableRow}
      onClick={evt =>
        evt.metaKey || evt.shiftKey || evt.ctrlKey
          ? window.open(url)
          : history.push(url)
      }
    >
      <td title={format(completed_at, 'yyyy-MM-dd HH:mm:ss zzz')}>
        {formatRelative(completed_at, new Date(), {
          locale: nlBE,
        })}
      </td>
      <td>{a.kind || a.doc.definitionUri || ''}</td>
      <td>{a.doc.title} </td>
      <td style={{ verticalAlign: 'middle', padding: 0 }}>
        <Badge primary>completed</Badge>
      </td>
    </tr>
  )
}

// function formatRelative2(date: Date) {

// }

function FilterBar({ onChange }: any) {
  const [q, setQ] = useState('')
  const a = useActivityKindOptions()
  const kindOptions = useMemo(
    () =>
      a.map(label => ({
        label,
        value: label,
      })),
    [a]
  )
  const [kinds, setKinds] = useState<null | { label: string; value: string }[]>(
    null
  )

  const toggleKind = useCallback(
    (option: { label: string; value: string }) => {
      // Select all/none
      if (!option.value) {
        setKinds(kinds?.length === 0 ? null : [])
        return
      }
      // Toggle
      if (!kinds || kinds.find(v => v.value === option.value)) {
        // value is selected, now we want to remove it
        setKinds((kinds || kindOptions).filter(v => v.value !== option.value))
      } else {
        // adding new value
        setKinds([...kinds, option])
      }
      return false
    },
    [kinds, kindOptions]
  )

  useEffect(() => {
    onChange({ kinds: kinds?.map(k => k.value) })
    // eslint-disable-next-line
  }, [kinds])

  return (
    <div>
      <div className="mt-4 mb-3 d-flex flex-row justify-content-start align-items-center">
        <Input
          id="UserOverviewSearchInput"
          value={q}
          onChange={evt => {
            setQ(evt.target.value)
          }}
          placeholder="Search..."
          className="mb-0 flex-grow-0"
          style={{ width: 250 }}
        />
        <span className="flex-grow-1" />

        <FilterIcon className="ml-4" />
        <span className="ml-1 mr-2">Kind:</span>
        <Select
          activeOption={kinds || kindOptions}
          multiple
          onOptionClick={toggleKind}
          closeOnOptionClick={false}
          options={useMemo(
            () => [
              {
                label:
                  !kinds || kinds.length === kindOptions.length
                    ? 'Select none'
                    : 'Select all',
                value: '',
              },
              ...kindOptions,
            ],
            [kinds, kindOptions]
          )}
        />
      </div>
    </div>
  )
}

function FilterIcon(props) {
  return (
    <svg
      style={{
        width: 18,
        height: 18,
      }}
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        fill="currentColor"
        d="M14 12v7.88c.04.3-.06.62-.29.83a.996.996 0 01-1.41 0l-2.01-2.01a.989.989 0 01-.29-.83V12h-.03L4.21 4.62a1 1 0 01.17-1.4c.19-.14.4-.22.62-.22h14c.22 0 .43.08.62.22a1 1 0 01.17 1.4L14.03 12H14z"
      />
    </svg>
  )
}

// Actions
async function save(patch, dispatch, client) {
  // Validate

  // Disallow editing
  dispatch({ type: 'save' })

  // Send to  server
  const ok = Array.from(patch.entries()).map(([id, updates]) =>
    client.mutate({
      mutation: UpdateActivityMutation,
      variables: {
        id,
        set: pick(updates, activityFields),
        doc: omit(updates, activityFields),
      },
      refetchQueries: ['ScheduledActivities'],
    })
  )

  const all = await Promise.all(ok)
  console.log('all', all)
  // Update local state

  // Small timeout to give apollo the chance to refetch queries
  await timeout(500)

  // Discard changeset
  dispatch({ type: 'discard' })

  // Allow editing
}

// GraphQL queries & mutations

const ScheduledActivitiesQuery = gql`
  query ScheduledActivities($uid: String!) {
    activity(
      where: { status: { _eq: "scheduled" }, uid: { _eq: $uid } }
      order_by: { planned_at: asc_nulls_first }
    ) {
      id
      uid
      completed_at
      created_at
      reminder_at
      started_at
      kind
      doc
      planned_at
    }
  }
`

const CompletedActivitiesQuery = gql`
  query CompletedActivities($uid: String!, $kind: String_comparison_exp = {}) {
    activity(
      where: { status: { _neq: "scheduled" }, uid: { _eq: $uid }, kind: $kind }
      order_by: { planned_at: desc_nulls_first, completed_at: desc_nulls_first }
    ) {
      id
      uid
      completed_at
      created_at
      reminder_at
      started_at
      kind
      doc
      planned_at
    }
  }
`

const InsertActivityMutation = gql`
  mutation InsertActivity($object: activity_insert_input!) {
    insert_activity_one(object: $object) {
      id
    }
  }
`

const UpdateActivityMutation = gql`
  mutation UpdateActivity(
    $id: uuid!
    $set: activity_set_input = {}
    $doc: jsonb = {}
  ) {
    update_activity_by_pk(
      pk_columns: { id: $id }
      _set: $set
      _append: { doc: $doc }
    ) {
      id
    }
  }
`

// Helpers

function pick<T>(obj: T & object, fields: string[]): Partial<T> {
  return fields.reduce((acc, x) => {
    if (obj.hasOwnProperty(x)) {
      acc[x] = obj[x]
    }
    return acc
  }, {})
}

function omit<T>(obj: T & object, fields: string[]): Partial<T> {
  const acc: Partial<T> = {}
  for (const field in obj) {
    if (!fields.includes(field)) {
      acc[field] = obj[field]
    }
  }
  return acc
}

function retry(func: () => void, max = 4) {
  let tries = 0
  const interval = setInterval(() => {
    tries++
    console.log('try', tries)
    if (tries > max) {
      return clearInterval(interval)
    }
    try {
      func()
      clearInterval(interval)
    } catch (e) {}
  }, tries * 200)
}
