import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { str62 } from '@bothrs/util/random'
import { uniq } from '@bothrs/util/uniq'

import {
  gql,
  useApolloClient,
  useQuery,
  useSubscription,
} from '@healthblocks-io/apollo'
import { useFetch } from '@healthblocks-io/core/fetch'
import { usePid } from '@healthblocks-io/core/project'
import { useUploadWeb } from '@healthblocks-io/core/upload'

import { ThreadsWithLastMessage } from 'lib/getUsers'

import cls from './conversations.module.scss'
import ThreadList from './modules/ThreadList'
import MessageList from './modules/MessageList'
import UserInfo from './modules/UserInfo'
import { ImageIcon } from './atoms/Icon'
import HealthblocksTopBar from './modules/HealthblocksTopBar'

import { userName } from '../lib/user'
import { useLocalStorage } from '@utils'

// Currently opened thread
/** @deprecated Too resource intensive */
const threadSubscription = gql`
  subscription Conversation($tid: String!) {
    threads_by_pk(tid: $tid) {
      tid
      user
      userByUser {
        uid
        doc
        email
        name
        group
        created_at
        messages(order_by: { sent_at: desc }) {
          sent_at
          doc
          mid
          tid
        }
        messages_aggregate {
          aggregate {
            count
          }
        }
      }
      name
      updated_at
      messages(order_by: { sent_at: asc }) {
        body
        doc
        mid
        qid
        preview
        sender
        sent_at
        tid
        url
        user {
          role
          uid
          doc
        }
      }
    }
  }
`

const InsertMessageDocument = gql`
  mutation InsertMessage(
    $tid: String!
    $mid: String!
    $body: String!
    $doc: jsonb!
  ) {
    insert_messages(
      objects: {
        tid: $tid
        mid: $mid
        body: $body
        doc: $doc
        preview: ""
        url: ""
      }
    ) {
      affected_rows
    }
  }
`

const UniqueGroups = gql`
  query UniqueGroups {
    users_aggregate(distinct_on: group) {
      nodes {
        group
      }
    }
  }
`

const ArchiveThread = gql`
  mutation ArchiveThread($tid: String!, $archived_at: timestamptz = null) {
    update_threads_by_pk(
      pk_columns: { tid: $tid }
      _set: { archived_at: $archived_at }
    ) {
      updated_at
    }
  }
`

const ConversationsPage = () => {
  const pid = usePid()
  const { postJSON } = useFetch()
  const client = useApolloClient()
  const upload = useUploadWeb()
  const [savedImagePath, setSavedImagePath] = useState([])
  const { tid, messageId } = useParams<{ tid: string; messageId?: string }>()

  // Thread data
  const { loading, data, error } = useSubscription(threadSubscription, {
    client,
    variables: { tid },
    skip: !tid,
  })
  const thread = data && data.threads_by_pk
  // const userId = thread?.user
  const user = thread?.userByUser

  const savePictureToCloud = async (e, fileFromCanvas?: File) => {
    const file = fileFromCanvas || e.target.files[0]
    console.log('file', fileFromCanvas, e.target.files)

    try {
      const img = await upload(file)
      console.log('img', img)
      setSavedImagePath([img])
    } catch (e) {
      alert('Failed to upload image: ' + e.message)
    }
  }

  // List of threads data
  const [limit, setLimit] = useState(10)
  const [_tab, setTab] = useLocalStorage('conversationsTab', 'hitl')
  const tab = pid === 'biosil' ? 'all' : _tab
  const { data: threadsData, loading: threadsLoading } = useSubscription(
    ThreadsWithLastMessage,
    {
      variables: {
        limit,
        ...(tab === 'all' ? {} : { where: { flow_name: { _eq: tab } } }),
      },
      client,
    }
  )
  const nextPage = useCallback(() => setLimit(lim => lim * 2), [])
  const staleThreadsData = useRef<any>()
  if (threadsData) staleThreadsData.current = threadsData

  // get userId from parameter /assess/:userId
  if (error) {
    console.warn(error)
  }
  useEffect(() => {
    if (!loading && tid && client) {
      client.mutate({
        mutation: ArchiveThread,
        variables: { tid, archived_at: new Date().toJSON() },
      })
      // mark as arvhiced
    }
  }, [client, loading, tid])

  const groups =
    useQuery(UniqueGroups, { client })
      .data?.users_aggregate.nodes.map(n => n.group)
      .filter(uniq)
      .filter(Boolean) || []

  function sendEnter(evt, savedImagePath) {
    if (
      (evt.which !== 13 && evt.keyCode !== 13) ||
      evt.metaKey ||
      evt.ctrlKey ||
      evt.altKey ||
      evt.shiftKey
    ) {
      return
    }
    send(evt, savedImagePath)
  }
  function send(
    evt,
    savedImagePath,
    message = undefined,
    reviewForImageUrl = undefined,
    bodypart = undefined
  ) {
    // console.log('tid', tid)
    if (evt) {
      evt.preventDefault()
    }
    const elem = document.querySelector<HTMLInputElement>('#messageBody')
    if (!elem) {
      console.warn('no elem')
      return
    }
    if (!elem.value && !message) {
      return console.log('no text')
    }
    const variables = {
      tid,
      mid: 'm:' + str62(20),
      body: message || elem.value,
      doc: {
        hitl: true,
        bodypart,
        replyType: 'Text',
        reviewForImageUrl,
        images: savedImagePath.length ? savedImagePath : undefined,
      },
    }
    console.log('insertMessage', variables)
    client.mutate({ mutation: InsertMessageDocument, variables })
    elem.value = ''
    setSavedImagePath([])
    postJSON('/api/notifications', variables)
  }
  const resetAssessment = startFlows => {
    if (!Array.isArray(startFlows)) {
      throw new Error(
        'Unexpected argument for resetAssessment ' + JSON.stringify(startFlows)
      )
    }

    const variables = {
      tid,
      mid: 'm:' + str62(20),
      body: 'Resetting ' + startFlows.join(', '),
      doc: {
        system: true,
        stopFlow: 'reset',
        startFlows,
        startFlow: startFlows[0],
      },
    }
    console.log('resetAssessment', variables)
    client.mutate({ mutation: InsertMessageDocument, variables })
    postJSON('/api/notifications', variables)
  }
  return (
    <div>
      <HealthblocksTopBar>{userName(user, 'Conversations')}</HealthblocksTopBar>
      <div className={cls.messageWrapper}>
        <ThreadList
          {...{
            tid,
            setTab,
            tab,
            nextPage:
              staleThreadsData.current?.threads?.length === limit
                ? nextPage
                : null,
          }}
          threads={staleThreadsData.current?.threads || []}
          loading={threadsLoading}
        />
        <div className={cls.chat}>
          {loading ? (
            <div style={{ flex: 1, height: 'calc(100vh - 140px)' }} />
          ) : (
            <MessageList
              user={user}
              send={send}
              thread={thread}
              savePictureToCloud={savePictureToCloud}
              activeMessage={messageId}
            />
          )}
          <AutoTextArea
            placeholder="New message..."
            rows="1"
            id="messageBody"
            onKeyDown={evt => sendEnter(evt, savedImagePath)}
            onSubmit={evt => send(evt, savedImagePath)}
          />
          <div
            className={cls['conversation-textbar-actions']}
            onClick={evt => send(evt, savedImagePath)}
          />
          <label
            htmlFor="attachment"
            className={
              cls[
                savedImagePath.length
                  ? 'attachment-label-loaded'
                  : 'attachment-label'
              ]
            }
          >
            <ImageIcon />
            <input
              onChange={savePictureToCloud}
              type="file"
              name="attachment"
              id="attachment"
              accept="image/*"
            />
          </label>
        </div>
        <UserInfo
          user={user}
          groups={groups}
          resetAssessment={pid.startsWith('biosil') && resetAssessment}
        />
      </div>
    </div>
  )
}

export default ConversationsPage

const AutoTextArea = props => {
  const textAreaRef = useRef(null)
  const textAreaValue = textAreaRef.current && textAreaRef.current.value
  const [text, setText] = useState('')
  const [textAreaHeight, setTextAreaHeight] = useState('auto')
  const [parentHeight, setParentHeight] = useState(0)

  useEffect(() => {
    const height = textAreaValue // if there is text
      ? textAreaRef.current.scrollHeight // calculate height
      : 'auto' // else, set to initial height
    setParentHeight(height + 10)
    setTextAreaHeight(height)
  }, [text, textAreaValue])

  const onChangeHandler = event => {
    setTextAreaHeight('auto')
    setParentHeight(textAreaRef.current.scrollHeight + 10)
    setText(event.target.value)
  }

  return (
    <form
      className={cls['conversation-textbar']}
      style={{
        maxHeight: 'calc(5.75em + 20px)',
        flexBasis: Math.max(66, parentHeight + 10) || 66,
      }}
      onSubmit={props.onSubmit}
    >
      <textarea
        {...props}
        ref={textAreaRef}
        rows={1}
        style={{
          height: textAreaHeight,
          width: '100%',
          maxHeight: '5.75em', // 5 * lineHeight
        }}
        onChange={onChangeHandler}
      />
    </form>
  )
}
