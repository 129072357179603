import React from 'react'

import { gql, useApolloClient, useQuery } from '@healthblocks-io/apollo'

import { beforeAfter } from 'lib/reporting'

import cls from '../Dashboard.module.scss'

import Card from '../atoms/Card'

const AssessmentsTaken = gql`
  query AssessmentsTaken($after: timestamptz = "2020-01-01Z") {
    hitl: threads_aggregate(
      where: { created_at: { _gt: $after }, flow_name: { _eq: "hitl" } }
    ) {
      aggregate {
        count
      }
    }
    assessments: threads_aggregate(
      where: { created_at: { _gt: $after }, flow_name: { _neq: "hitl" } }
    ) {
      aggregate {
        count
      }
    }
    events(
      where: {
        sentAt: { _gt: $after }
        event: { _eq: "Navigate" }
        properties: { _eq: { route: "Article" } }
      }
    ) {
      id: messageId
      pid
    }
  }
`
// distinct_on: flow_name

export default function Performance({ title, basis }) {
  const { after } = beforeAfter({ [basis]: -1 })
  const { data } = useQuery(AssessmentsTaken, {
    after,
    client: useApolloClient(),
  })
  const assessments = data?.assessments.aggregate.count
  const hitl = data?.hitl.aggregate.count
  const events = data?.events.length

  return (
    <div>
      <h3 className="title">{title || 'Performance'}</h3>
      <div className="row">
        <div className="col-md-4 mb-4">
          <Card className={cls.darkCard}>
            <h4 className="subTitle">Assessments taken</h4>
            <span className={cls.value}>{assessments}</span>
            <span className={cls.infoText}>in the last 30 days</span>
          </Card>
        </div>
        <div className="col-md-4 mb-4">
          <Card className={cls.darkCard}>
            <h4 className="subTitle">New conversations</h4>
            <span className={cls.value}>{hitl}</span>
            <span className={cls.infoText}>from the last 7 days</span>
          </Card>
        </div>
        <div className="col-md-4 mb-4">
          <Card className={cls.darkCard}>
            <h4 className="subTitle">Clicked suggestions</h4>
            <span className={cls.value}>{events}</span>
            <span className={cls.infoText}>in the last 30 days</span>
          </Card>
        </div>
      </div>
    </div>
  )
}
