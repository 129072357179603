import React, { HTMLAttributes, ReactNode } from 'react'
import cls from './Label.module.scss'

interface LabelProps<T = string> extends HTMLAttributes<HTMLLabelElement> {
  children: ReactNode
}

export default function Label(props: LabelProps) {
  return <label {...props} className={cls.label} />
}
