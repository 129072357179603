import React from 'react'
import cls from './SubtleTextarea.module.scss'

export default function SubtleTextarea({
  className,
  list,
  onChangeText,
  placeholder,
  type,
  value,
  lineBreaks = true,
}: React.InputHTMLAttributes<HTMLInputElement> & {
  onChangeText: (s: string) => void
  lineBreaks?: boolean
}) {
  return (
    <span className={cls.input}>
      <span className={cls.wrapper + ' ' + className}>
        <span className={cls.div} data-value={value || placeholder || ''} />
        {lineBreaks ? (
          <textarea
            className={cls.textarea}
            value={value || ''}
            placeholder={placeholder}
            onChange={evt => onChangeText(evt.target.value)}
          />
        ) : (
          <input
            className={cls.textarea}
            list={list}
            onChange={evt => onChangeText(evt.target.value)}
            placeholder={placeholder}
            type={type}
            value={value || ''}
          />
        )}
      </span>
    </span>
  )
}
