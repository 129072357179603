// Configuration schema based on
// https://code.visualstudio.com/api/references/contribution-points#contributes.configuration

export interface ProjectConfig {
  pid: string
  name: string
  config: object
  blocks: string[]
}

export interface Block {
  id: string
  stage?: string
}

export const blocks: Block[] = [
  { id: 'airtable_assessments' },
  { id: 'airtable' },
  { id: 'anonymous' },
  { id: 'anonymous_itsme' },
  { id: 'articles' },
  { id: 'auth0' },
  { id: 'biosil_conversations' },
  { id: 'conversations' },
  { id: 'dashboard' },
  { id: 'google' },
  { id: 'i18n' },
  { id: 'invites' },
  { id: 'mixpanel' },
  { id: 'passwordless_email_code' },
  { id: 'passwordless_email_link' },
  { id: 'passwordless_sms' },
  { id: 'questionnaires' },

  // Alpha: not sure if production ever
  { id: 'sendbird', stage: 'alpha' },
  { id: 'twilio', stage: 'alpha' },

  // Beta: not production ready
  { id: 'activity1', stage: 'beta' },
  { id: 'careteam1', stage: 'beta' },
  { id: 'cumul1', stage: 'beta' },
  { id: 'shopify', stage: 'beta' },

  // Legacy: to be ejected
  { id: 'goals1', stage: 'legacy' },
  { id: 'medications1', stage: 'legacy' },
  { id: 'reminders1', stage: 'legacy' },
]

export interface ConfigSection {
  title: string
  blocks?: string[]
  properties: { [key: string]: ConfigItem }
}
export interface ConfigItem {
  type: string
  items?: ConfigItem
  minItems?: number
  maxItems?: number
  uniqueItems?: boolean
  placeholder?: string
  default?: any
  title?: string
  description?: string
  blocks?: string[]
  enum?: string[]
}

export const sections: ConfigSection[] = [
  {
    title: 'User profile',
    properties: {
      'config.userDetailTabs': {
        type: 'array',
        title: 'Tabs',
        default: ['info', 'logs'],
        items: {
          type: 'string',
          enum: [
            'calendar',
            'overview',
            'info',
            'notes',
            'observations',
            'careteam',
            'logs',
            'careplan',
          ],
        },
        minItems: 1,
      },
    },
  },
  {
    title: 'Care plan',
    properties: {
      'config.activityKindOptions': {
        type: 'array',
        title: 'Activity types',
        default: ['Appointment', 'Article', 'Questionnaire', 'Message', 'Task'],
        items: {
          type: 'string',
          enum: ['Appointment', 'Article', 'Questionnaire', 'Message', 'Task'],
        },
        minItems: 0,
      },
    },
  },
  {
    title: 'Airtable',
    blocks: ['airtable'],
    properties: {
      'secret_airtable.app': {
        type: 'string',
        placeholder: 'app...',
        title: 'App id',
      },
      'secret_airtable.key': {
        type: 'string',
        placeholder: 'key...',
        title: 'API key',
      },
      'config.airtableAssessment': {
        type: 'boolean',
        title: 'Manage assessments in Airtable',
        description: 'Load the Questions & Replies table',
        blocks: ['airtable_assessments'],
      },
      'config.airtableConfig': {
        type: 'boolean',
        title: 'Manage config in Airtable',
        description: 'Load the Config table',
      },
    },
  },
  {
    title: 'Auth0',
    blocks: ['auth0'],
    properties: {
      'auth0.domain': {
        type: 'string',
        placeholder: '<app-name>.eu.auth0.com',
        title: 'Domain',
      },
      'auth0.clientId': {
        type: 'string',
        title: 'Client ID',
      },
      'secret_auth0.clientSecret': {
        type: 'string',
        title: 'Client secret',
      },
      'auth0.passwordless': {
        type: 'boolean',
        title: 'Passwordless',
        description: 'Enable passwordless sign in with SMS',
        blocks: ['passwordless_sms'],
      },
      'auth0.passwordless_email_code': {
        type: 'boolean',
        title: 'Passwordless',
        description: 'Enable passwordless sign in with email code',
        blocks: ['passwordless_email_code'],
      },
      'auth0.passwordless_email_link': {
        type: 'boolean',
        title: 'Passwordless',
        description: 'Enable passwordless sign in with email link',
        blocks: ['passwordless_email_link'],
      },
    },
  },
  {
    title: 'Anonymous Authentication',
    properties: {
      'config.anonymous': {
        type: 'boolean',
        title: 'Sign in anonymously',
        description: 'Allow',
        blocks: ['anonymous'],
      },
      'config.itsmeAnonymous': {
        type: 'boolean',
        title: 'Sign in with itsme',
        description: 'Allow (mocked)',
        blocks: ['anonymous_itsme'],
      },
    },
  },
  {
    title: 'Project',
    properties: {
      name: {
        type: 'string',
        default: null,
        title: 'Name',
      },
    },
  },
  {
    title: 'Internationalisation',
    blocks: ['i18n'],
    properties: {
      'config.defaultLanguage': {
        type: 'string',
        default: 'en',
        title: 'Default language',
        enum: ['en', 'nl', 'fr', 'de', 'es'],
      },
      'config.languages': {
        type: 'array',
        default: ['en'],
        items: {
          type: 'string',
          enum: [
            'en',
            // 'en-GB',
            // 'en-US',
            'nl',
            // 'nl-NL',
            // 'nl-BE',
            'fr',
            // 'fr-FR',
            // 'fr-BE',
            'de',
            'es',
          ],
        },
        minItems: 1,
        title: 'Supported languages',
      },
    },
  },
  {
    title: 'Invitations',
    blocks: ['invites'],
    properties: {
      'config.userInvite': {
        type: 'string',
        default: 'none',
        title: 'Require an invite before registration',
        enum: ['none', 'code', 'phone'],
      },
    },
  },
  {
    title: 'Mixpanel',
    blocks: ['mixpanel'],
    properties: {
      'config.mixpanel': {
        type: 'string',
        default: null,
        title: 'Token',
      },
    },
  },
  {
    title: 'Patient experience',
    properties: {
      'config.appName': {
        type: 'string',
        default: null,
        title: 'App name',
      },
      'config.dashboardPrivacyURL': {
        type: 'string',
        default: null,
        placeholder: 'Optional: will fallback to privacy URL',
        title: 'Privacy statement dashboard URL',
      },
      'config.privacyURL': {
        type: 'string',
        default: null,
        title: 'Privacy statement URL',
      },
      'config.termsAndConditionsURL': {
        type: 'string',
        default: null,
        title: 'Terms and conditions URL',
      },
      'config.theme__primary__color': {
        type: 'string',
        default: '#0659fd',
        title: 'Primary theme color',
      },
    },
  },
  {
    title: 'Shopify',
    blocks: ['shopify'],
    properties: {
      'config.shopify.enabled': {
        type: 'boolean',
        default: false,
        title: 'Enabled',
        description: 'Activate Shopify integration',
      },
      'config.shopify.domain': {
        type: 'string',
        title: 'Domain',
      },
      'config.shopify.storefrontAccessToken': {
        type: 'string',
        default: null,
        title: 'Storefront accesstoken',
      },
    },
  },
]
