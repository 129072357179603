import React from 'react'

import cls from './Card.module.scss'

const Card = ({ children, className = '' }) => {
  return <div className={cls.card + ' ' + className}>{children}</div>
}

export default Card

export function CardGrid({ children }) {
  return <div className={cls.cardGrid}>{children}</div>
}
