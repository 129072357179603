import { useState, useEffect } from 'react'

export function useWindowFocus() {
  const [focused, setFocused] = useState(0) // Focus for first render
  useEffect(() => {
    const trigger = () => setFocused(f => f + 1)
    window.addEventListener('focus', trigger)
    return () => {
      window.removeEventListener('focus', trigger)
    }
  }, [])
  return focused
}
