import React, { useMemo, useState } from 'react'

import { convertFromRaw, convertToRaw, EditorState } from 'draft-js'
import Editor from '@draft-js-plugins/editor'
import createInlineToolbarPlugin from '@draft-js-plugins/inline-toolbar'
import {
  BoldButton,
  HeadlineOneButton,
  UnorderedListButton,
  OrderedListButton,
} from '@draft-js-plugins/buttons'
import '@draft-js-plugins/inline-toolbar/lib/plugin.css'
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js'

import cls from './Textarea.module.scss'

export default function MarkdownInput({
  value,
  disabled,
  onChangeText,
  placeholder,
}: {
  value
  disabled?: boolean
  onChangeText
  placeholder
}) {
  const [state, setState] = useState(() =>
    EditorState.createWithContent(convertFromRaw(markdownToDraft(value)))
  )
  const [plugins, InlineToolbar] = useMemo(() => {
    const inlineToolbarPlugin = createInlineToolbarPlugin()
    return [[inlineToolbarPlugin], inlineToolbarPlugin.InlineToolbar]
  }, [])
  return (
    <div className={cls.input}>
      <div className="editor">
        <Editor
          readOnly={disabled}
          editorState={state}
          onChange={v => {
            setState(v)
            onChangeText(
              draftToMarkdown(convertToRaw(state.getCurrentContent()))
            )
          }}
          plugins={plugins}
        />
        <InlineToolbar>
          {props => (
            <>
              <BoldButton {...props} />
              <HeadlineOneButton {...props} />
              <UnorderedListButton {...props} />
              <OrderedListButton {...props} />
            </>
          )}
        </InlineToolbar>
      </div>
    </div>
    // <div className={cls.input} style={{ paddingLeft: 18 }}>
    //   <Editor
    //     theme={{ ...light, background: 'transparent' }}
    //     defaultValue={value}
    //     onChange={(getter) => {
    //       console.log('ha')
    //     }}
    //     placeholder={placeholder}
    //   />
    // </div>
  )
}

// Too advanced
// import Editor1 from 'rich-markdown-editor'
// import { light } from 'rich-markdown-editor/dist/theme'
// export function MarkdownInput1({ value, onChangeText, placeholder }) {
//   return (
//     <div className={cls.input} style={{ paddingLeft: 18 }}>
//       <Editor1
//         theme={{ ...light, background: 'transparent' }}
//         defaultValue={value}
//         onChange={getter => {
//           console.log('ha')
//         }}
//         placeholder={placeholder}
//       />
//     </div>
//   )
// }
