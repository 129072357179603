import { useMemo } from 'react'
import { useProject } from '@healthblocks-io/core/project'

export interface MenuLink {
  allow: string[]
  block?: string
  pids?: string[]
  text: string
  icon?: string
  href: string
  exact?: boolean
  items?: {
    allow?: string[]
    block?: string
    text: string
    href: string
  }[]
}

export const menuLinks: MenuLink[] = [
  {
    // allow can be set on menuLinks and its items
    // if it is not set the link is visible for anybody
    // we check if the user has one of the roles specified here
    allow: ['moderator', 'owner'],
    href: '/',
    icon: 'home',
    text: 'Dashboard',
    exact: true,
  },
  {
    allow: ['user', 'moderator', 'owner'],
    href: '/users',
    icon: 'users',
    text: 'Users',
  },
  null, // divider
  {
    allow: ['moderator', 'owner'],
    block: 'conversations',
    href: '/conversations',
    icon: 'message_circle',
    text: 'Inbox',
  },
  {
    allow: ['moderator', 'owner'],
    block: 'goals1',
    href: '/reviews',
    icon: 'message_circle',
    text: 'Reviews',
  },
  {
    allow: ['owner', 'moderator'],
    block: 'sendbird',
    text: 'Sendbird',
    href: '/sendbird',
  },
  {
    allow: ['moderator', 'owner'],
    block: 'twilio',
    text: 'Twilio',
    href: '/twilio',
  },
  {
    allow: ['moderator', 'owner'],
    href: '/configuration',
    icon: 'target',
    text: 'Configuration',
    items: [
      {
        block: 'questionnaires',
        href: '/questionnaires',
        text: 'Questionnaires',
      },
      {
        block: 'articles',
        href: '/articles',
        text: 'Articles',
      },
      {
        block: 'goals1',
        href: '/reviews',
        text: 'Reviews',
      },
    ],
  },
  {
    allow: ['owner'],
    text: 'Settings',
    icon: 'settings',
    href: '/settings',
    items: [
      {
        allow: ['owner'],
        text: 'General Settings',
        href: '/general',
      },
      {
        allow: ['owner'],
        text: 'Teams & Roles',
        href: '/access',
      },
      {
        allow: ['owner'],
        block: 'careteam1',
        text: 'Care team Policies',
        href: '/policies',
      },
      {
        allow: ['admin'],
        text: 'GraphiQL',
        href: '/graphiql',
      },
    ],
  },
]

export interface UserLike {
  name?: string
  email?: string
  role?: string
}

export function useMenu(user: UserLike) {
  // @ts-ignore
  const { blocks } = useProject()
  return useMemo(() => filterNavByRole(menuLinks, user, blocks), [blocks, user])
}

// Roles

const getRolesFromUser = user => {
  // if we dont have a user return nothing
  if (!user) return []
  const roles = user['https://hasura.io/jwt/claims'][
    'x-hasura-allowed-roles'
  ].map(r => (r === 'admin' ? 'owner' : r))
  if (
    user.email &&
    (user.email.endsWith('@healthblocks.io') ||
      user.email.endsWith('@bothrs.com')) &&
    roles.includes('owner')
  ) {
    roles.push('admin')
  }
  return roles
}

const isAllowed = (link, rolesFromUser) => {
  // this can be null for dividers
  if (!link) return true
  // if no allow is set, we always show it
  if (!link.allow) return true
  // check if it has at least one role from the user
  return link.allow.some(item => rolesFromUser.includes(item))
}

// checks for every node and subnode of the menuLinks if we have an allowed role to view it
export const filterNavByRole = (
  menuLinks: MenuLink[],
  user: UserLike,
  blocks: string[] = []
) => {
  const rolesFromUser = getRolesFromUser(user)
  const filtered = menuLinks
    .filter(menuLink => isAllowed(menuLink, rolesFromUser))
    .filter(menuLink => !menuLink?.block || blocks.includes(menuLink.block))
    .map(menuLink => {
      if (menuLink?.items) {
        return {
          ...menuLink,
          items: menuLink.items
            .filter(item => !item?.block || blocks.includes(item.block))
            .filter(item => isAllowed(item, rolesFromUser)),
        }
      }
      return menuLink
    })
  if (rolesFromUser.includes('moderator')) {
    return filtered
  }
  return filtered.filter(Boolean)
}
