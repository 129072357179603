import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { uniq } from '@bothrs/util/uniq'
import { Dropdown, DropdownItem } from '@duik/dropdown'
import Icon from '@duik/icon'
import Modal from '@duik/modal'
import format from 'date-fns/format'

import { gql, useApolloClient, useQuery } from '@healthblocks-io/apollo'
import { useToken } from '@healthblocks-io/core/auth'
import { useContent } from '@healthblocks-io/core/content'
import { useFetch } from '@healthblocks-io/core/fetch'
import { useApi, usePid, useProject } from '@healthblocks-io/core/project'
import { Question } from '@healthblocks-io/core/types'

import Badge from '@components/Badge'
import { saveAs } from 'lib/fileSaver'
import { useTranslation } from 'lib/i18n'

import cls from './Questionnaires.module.scss'

import Button from './atoms/Button'
import Card, { CardGrid } from './atoms/Card'
import HealthblocksTopBar from './modules/HealthblocksTopBar'

export default function SelfAssessmentsPage() {
  const pid = usePid()
  const client = useApolloClient()
  const add = useOperation()
  const { data, loading, refetch } = useQuestionnaires()
  const airtable = useAirtableQuestionnaires()
  const refresh = () => refetch()
  const modal = useModal()
  const history = useHistory()
  const [type, setType] = useState('basic')
  const { t } = useTranslation()

  return (
    <div>
      <HealthblocksTopBar>Questionnaires</HealthblocksTopBar>
      <div className="scroll-area-main">
        {!data && loading ? (
          <p>Loading...</p>
        ) : !data ? (
          <ErrorState />
        ) : (
          // ) : !data?.questionnaires?.length && !airtable.length ? (
          //   <EmptyState />
          <QuestionnairesOverview
            {...{ modal, add, data, refresh, airtable }}
          />
        )}
      </div>
      <Modal
        isOpen={modal.isOpen}
        handleClose={modal.close}
        closeOnOuterClick
        style={{ height: 'auto' }}
      >
        <Modal.Body className={cls.fillModal}>
          <Modal.Title className="font-weight-bold text-center">
            {t('Choose your type')}
          </Modal.Title>
          <div className="row mt-5 mb-5 ml-1 mr-1">
            <div className="col">
              <RadioCard
                active={type === 'basic'}
                onClick={() => setType('basic')}
              >
                <h2 className="font-weight-bold">Basic</h2>
                <p>{t('This type of questionnaire p')}</p>
              </RadioCard>
            </div>
            <div className="col">
              <RadioCard
                active={type === 'advanced'}
                onClick={() => setType('advanced')}
              >
                <h2 className="font-weight-bold">{t('Advanced')}</h2>
                <p>{t('Advanced questionnaires p')}</p>
                <p>
                  <Badge muted airtable>
                    Airtable
                  </Badge>
                </p>
              </RadioCard>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              primary
              className="mb-0"
              style={{ width: 'auto' }}
              onClick={
                type === 'basic'
                  ? add.start(async () => {
                      const ok = await addQuestionnaire(client, { title: '' })
                      const { id } = ok.data.insert_questionnaires_one
                      history.push(`/${pid}/assess/questionnaires/${id}/edit`)
                    })
                  : () => alert('Send an email to support@healthblocks.io')
              }
            >
              {type === 'basic' ? 'Start creating' : 'Contact our team'}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

function RadioCard({ active, ...props }) {
  return (
    <div
      tabIndex={0}
      className={cls.radioCard + (active ? ' ' + cls.radioCardActive : '')}
      {...props}
    />
  )
}

function ErrorState() {
  return <div>error</div>
}

function QuestionnairesOverview({ data, refresh, add, modal, airtable }) {
  const pid = usePid()
  const client = useApolloClient()
  const { config } = useProject()
  const { loading } = add
  const { t } = useTranslation()
  return (
    <div>
      <div style={{ float: 'right', marginTop: -16 }}>
        <Button disabled={loading} onClick={modal.open}>
          {t('Add questionnaire')}
          <Icon ml>plus</Icon>
        </Button>
      </div>

      <h3 className="title">{t('Overview of your questionnaires')}</h3>

      {data.questionnaires.length ? (
        <CardGrid>
          {data.questionnaires.map(q => (
            <QuestionnaireCard
              key={q.id}
              questionnaire={q}
              refresh={refresh}
              edit={`/${pid}/assess/questionnaires/${q.id}/edit`}
              onDelete={() => deleteQuestionnaire(client, q.id).then(refresh)}
            />
          ))}
        </CardGrid>
      ) : (
        <p>{t('No questionnaires yet...')}</p>
      )}

      {/* {JSON.stringify(data)} */}

      <h3 className="title">Airtable questionnaires</h3>
      {airtable.length ? (
        <CardGrid>
          {airtable.map(q => (
            <QuestionnaireCard
              key={q.id}
              questionnaire={q}
              refresh={refresh}
              edit={config.airtableQuestions}
            />
          ))}
        </CardGrid>
      ) : null}

      {config.airtableQuestions ? (
        <>
          <p>Assessments are managed through Airtable</p>

          <p>
            <a
              href={config.airtableQuestions}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button>Manage assessments</Button>
            </a>
          </p>
        </>
      ) : null}
    </div>
  )
}

interface QuestionnaireCardProps {
  questionnaire: any
  refresh?: () => {}
  edit?: string
  duplicate?: () => {}
  onDelete?: () => {}
}

function QuestionnaireCard({
  questionnaire,
  refresh,
  edit,
  duplicate,
  onDelete,
}: QuestionnaireCardProps) {
  const stats = questionnaire.stats || { completed: '?' }
  const stale = !stats.fetchedAt || !recent(stats.fetchedAt)
  const api = useApi()
  const auth = useToken()
  const { postJSON } = useFetch()
  useEffect(() => {
    if (stale && !questionnaire.id.startsWith('airtable:')) {
      postJSON('/api/stats', {
        type: 'questionnaire',
        id: questionnaire.id,
      })
        .then(refresh)
        .catch(e => {})
    }
  }, [stale, questionnaire.id, refresh, postJSON])
  return (
    <div style={{ position: 'relative' }}>
      <MayLink to={edit}>
        <Card>
          <div>
            {questionnaire.status === 'active' ? (
              <Badge primary>Published</Badge>
            ) : questionnaire.status === 'retired' ? (
              <Badge muted>Retired</Badge>
            ) : (
              <Badge muted>Draft</Badge>
            )}
            {questionnaire.source === 'airtable' && (
              <Badge muted airtable>
                Airtable
              </Badge>
            )}
          </div>
          <h2>{questionnaire.title || 'Questionnaire without title'}</h2>
          {questionnaire.name && questionnaire.source !== 'airtable' && (
            <p className="m-0">{questionnaire.name || 'NO ID!'}</p>
          )}
          <p className="flex-grow-1 mt-1" style={{ color: 'var(--text-main)' }}>
            {questionnaire.description || 'Questionnaire without description'}
          </p>
          <div className={'row ' + cls.stats + (stale ? ' ' + cls.stale : '')}>
            <div className={'col ' + cls.stat}>
              <b>{stats.completed || 0}</b>
              <div className="subTitle">Completed</div>
            </div>
            <div className={'col ' + cls.stat}>
              <b>
                {typeof stats.completionRate === 'number'
                  ? Math.round(100 * stats.completionRate) + '%'
                  : stats.completionRate || '?'}
              </b>
              <div className="subTitle">Compl. rate</div>
            </div>
            <div className={'col ' + cls.stat}>
              <b>
                {typeof stats.trend === 'number'
                  ? (stats.trend > 0 ? '+' : '') +
                    Math.round(100 * stats.trend) +
                    '%'
                  : stats.trend || '?'}
              </b>
              <div className="subTitle">Trend</div>
            </div>
          </div>
        </Card>
      </MayLink>
      <Dropdown className={cls.actions} ButtonComponent={ActionsIcon}>
        {edit && (
          <DropdownItem Component={MayLink} to={edit}>
            <Icon className="mr-3">edit</Icon>Edit
          </DropdownItem>
        )}
        {duplicate && (
          <DropdownItem onClick={console.log}>
            <Icon className="mr-3">close</Icon>Duplicate
          </DropdownItem>
        )}
        {questionnaire.name && (
          <DropdownItem
            onClick={async () => {
              const blob = await downloadCSV(
                api +
                  '/api/questionnaire_responses_by_name?format=csv&name=' +
                  questionnaire.name,
                { auth }
              )
              saveAs(
                blob,
                format(new Date(), 'yyyy-MM-dd') +
                  ' ' +
                  questionnaire.name +
                  '.csv'
              )
            }}
          >
            <Icon className="mr-3">view_list</Icon>Download responses
          </DropdownItem>
        )}
        {onDelete && (
          <DropdownItem onClick={onDelete}>
            <Icon className="mr-3">close</Icon>Delete
          </DropdownItem>
        )}
      </Dropdown>
    </div>
  )
}

function MayLink({ children, to, ...props }) {
  if (!to) {
    return <>{children}</>
  }
  if (to.startsWith('http')) {
    return (
      <a
        children={children}
        target="_blank"
        rel="noopener noreferrer"
        href={to}
        {...props}
      />
    )
  }
  return <Link children={children} to={to} {...props} />
}
function ActionsIcon({ handleToggle }) {
  return (
    <Icon className={cls.actionsIcon} onClick={handleToggle}>
      more
    </Icon>
  )
}

function useModal() {
  const [state, setState] = useState(false)
  return {
    isOpen: state,
    open: () => setState(true),
    close: () => setState(false),
  }
}

function useAirtableQuestionnaires() {
  const flows = useContent<{ name: string }>('Flows')
  const questions = useContent<Question>('Questions')

  return questions
    .map(q => ((q.flowIds as string) || '').split(','))
    .flat()
    .filter(Boolean)
    .filter(uniq)
    .map(title => ({
      ...flows.find(f => f.name === title),
      id: 'airtable:' + title,
      title,
      source: 'airtable',
    }))
}

function useQuestionnaires() {
  return useQuery(
    gql`
      query MyQuestionnaires {
        questionnaires(
          order_by: { created_at: desc }
          where: { status: { _in: ["active", "draft"] } }
        ) {
          id
          name
          title
          description
          date
          stats
          status
          url
        }
      }
    `,
    {
      client: useApolloClient(),
      fetchPolicy: 'cache-and-network',
    }
  )
}

export function addQuestionnaire(client: any, input) {
  return client.mutate({
    mutation: gql`
      mutation InsertQuestionnaire($input: questionnaires_insert_input!) {
        insert_questionnaires_one(object: $input) {
          id
        }
      }
    `,
    variables: { input },
  })
}

function deleteQuestionnaire(client: any, id) {
  return client.mutate({
    mutation: gql`
      mutation DeleteQuestionnaire($id: uuid!) {
        delete_questionnaires_by_pk(id: $id) {
          id
        }
      }
    `,
    variables: { id },
  })
}

function useOperation() {
  const [state, setState] = useState({
    promise: null,
    data: null,
    loading: false,
    error: null,
  })
  const start = func => {
    return evt => {
      if (evt && evt.preventDefault) evt.preventDefault()
      if (state.loading) return
      setState({
        data: null,
        promise: func()
          .then(data => setState(s => ({ ...s, data, loading: false })))
          .catch(error => setState(s => ({ ...s, error, loading: false }))),
        loading: true,
        error: null,
      })
    }
  }
  return { ...state, start }
}

function recent(fetchedAt: string) {
  return new Date(fetchedAt).valueOf() > Date.now() - 36e5
}

function downloadCSV(url: string, options: { auth: string }) {
  return fetch(url, {
    headers: {
      Authorization: 'Bearer ' + options.auth,
    },
  }).then(r => r.blob())
}
