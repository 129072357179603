/**
 * Here we put all root config, such as routing
 * If necessary, styles from the libraries should be put here as well
 */

import React, { useEffect, useMemo } from 'react'
import { BrowserRouter, Route, Switch, useLocation } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { ContainerHorizontal } from '@duik/container-horizontal'
import { ContainerVertical } from '@duik/container-vertical'

import 'react-toastify/dist/ReactToastify.css'
// styles
import '@duik/it/dist/styles.css'
import '@duik/icon/dist/styles.css'
import './app.module.scss'
import './style.scss'

import {
  ApolloProvider,
  useApolloClient,
  UserApolloProviderWs,
} from '@healthblocks-io/apollo'
import { AnalyticsProvider } from '@healthblocks-io/core/analytics'
import { AuthProvider, useUser } from '@healthblocks-io/core/auth'
import { useFetch } from '@healthblocks-io/core/fetch'
import { FHIRProvider } from '@healthblocks-io/core/fhir'
import { LocalProvider } from '@healthblocks-io/core/local'
import { useProfileData } from '@healthblocks-io/core/profile'
import {
  ProjectProvider,
  useConfig,
  usePid,
  useProjectContext,
} from '@healthblocks-io/core/project'

import MandatoryName from '@components/MandatoryName'
import ProjectPicker from '@components/ProjectPicker'
import TermsAndConditions from '@components/TermsAndConditions'
import { TranslationProvider, useTranslation } from 'lib/i18n'
import { projectRoutes } from 'lib/router'
import Debug from 'pages/Debug'
import Login from 'pages/login'
import NoAccess from 'pages/noAccess'
import EmailVerification from 'pages/onboarding/EmailVerification'
import Privacy from 'pages/privacy'

import Navigation from '../pages/modules/Navigation'

// MVP2 => Create private routes
const PrivateRoute = ({ owner = false, user = false, component, ...rest }) => {
  const me = useUser()
  const allowedRoles = useMemo(
    () =>
      user
        ? ['admin', 'owner', 'moderator', 'user']
        : owner
        ? ['admin', 'owner']
        : ['admin', 'owner', 'moderator'],
    [user, owner]
  )
  rest.component = useMemo(
    () =>
      me &&
      me['https://hasura.io/jwt/claims']['x-hasura-allowed-roles'].some(role =>
        allowedRoles.includes(role)
      )
        ? component
        : () => <NoAccess allowedRoles={allowedRoles} />,
    // eslint-disable-next-line
    [owner, JSON.stringify(me)]
  )

  return <Route {...rest} />
}

export default function App() {
  return (
    <LocalProvider scope="local">
      <BrowserRouter>
        <RouterApp />
      </BrowserRouter>
      <ToastContainer />
    </LocalProvider>
  )
}

/** Project may be unknown */
function RouterApp() {
  // Keep healthblocks in sync with URL
  const pid = useLocation().pathname.split('/')[1]
  const api = (window as any).healthblocksApp?.api
  const graph = (window as any).healthblocksApp?.graph

  if (!pid || ['debug', 'privacy'].includes(pid)) {
    return (
      <Switch>
        <Route path="/debug" component={Debug} />
        <Route path="/:pid/debug" exact component={Debug} />
        <Route path="/privacy" component={Privacy} />
        <Route path="/" component={ProjectPicker} />
      </Switch>
    )
  }

  return (
    <ProjectProvider
      key={pid}
      pid={pid}
      api={api}
      graph={graph}
      app="dashboard"
    >
      <TranslationProvider>
        <AuthProvider>
          <AnalyticsProvider
            properties={{ interface: 'dashboard' }}
            windowEvents
          >
            <ProjectApp />
          </AnalyticsProvider>
        </AuthProvider>
      </TranslationProvider>
    </ProjectProvider>
  )
}

/** Project is known, project data may be loading, user may be unknown */
function ProjectApp() {
  const user = useUser()

  if (!user) {
    return <Login />
  }

  // ProjectUserApp is a separate component
  // Only wrap Apollo here as Healthblocks doesn't provide access without auth
  return (
    <UserApolloProviderWs>
      <FHIRProvider>
        <ProjectUserApp />
      </FHIRProvider>
    </UserApolloProviderWs>
  )
}

/** Project is known, user is known */
function ProjectUserApp() {
  const user = useUser()
  const profile = useProfileData()

  const googletagmanager = useConfig(p => p.config.googletagmanager)
  const { i18n } = useTranslation()

  const fallback = useConfig(p => p.config.defaultLanguage)
  const language = profile?.language?.slice(0, 2).toLowerCase() || fallback
  useEffect(() => {
    if (language !== i18n.language) {
      i18n.changeLanguage(language)
    }
    // eslint-disable-next-line
  }, [language])

  useEffect(() => {
    if (!googletagmanager) return
    // prettier-ignore
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});const f=d.getElementsByTagName(s)[0],
    // @ts-ignore
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer',googletagmanager);
  }, [googletagmanager])

  // Email must be verified to continue
  if (!user.email_verified) {
    return <EmailVerification />
  }

  // Check if our logged in user has given consent, if not
  // Show him the general terms and conditions screen where they can accept
  if (profile.uid && !profile.doc.hasGivenConsent) {
    return <TermsAndConditions uid={user.sub} />
  }

  // Check if user has givenName and familyName set
  // Force user to set a givenName and familyName
  if (profile.uid && (!profile.doc.givenName || !profile.doc.familyName)) {
    return <MandatoryName />
  }

  return (
    <Switch>
      <Route path="/debug" component={Debug} />
      <Route path="/:pid/debug" component={Debug} />
      <Route path="/login" exact component={Login} />
      <Route path="/privacy" component={Privacy} />
      <Route path="/:pid" component={ProjectCheck} />
      <Route path="/" component={ProjectPicker} />
    </Switch>
  )
}

function Layout({ children }) {
  return (
    <ContainerVertical>
      <ContainerHorizontal>
        <Navigation />
        <ContainerVertical>{children}</ContainerVertical>
      </ContainerHorizontal>
    </ContainerVertical>
  )
}

function ProjectCheck() {
  const pid = usePid()
  const { data, loading, error } = useProjectContext()
  if (loading) {
    return <div>Loading project {pid} ...</div>
  }
  if (error) {
    return <div>Error loading project: {error?.message}</div>
  }
  if (!data) {
    return <div>Failed to load project...</div>
  }
  return <ProjectRoutes />
}

function ProjectRoutes() {
  const client = useApolloClient()
  const profile = useProfileData()
  const { getJSON } = useFetch()
  const pid = usePid()
  const name = useConfig(p => p.config.name || p.name)

  useEffect(() => {
    const identify = async ic => {
      if (!ic || !profile.email) return
      const data = await getJSON('/api/intercom')
      const settings = {
        ...data,
        email: profile.email,
        name: profile.name,
        company: {
          id: pid,
          name: name || pid,
        },
      }
      ic('update', settings)
    }
    // @ts-ignore
    identify(window.Intercom).catch(console.error)
    // eslint-disable-next-line
  }, [profile.email])

  return (
    <ApolloProvider client={client}>
      <Layout>
        <Switch>
          {projectRoutes.map((r, key) => (
            <PrivateRoute key={key} {...r} />
          ))}
          <Route path="/:pid/logout" />
          <Route path="/:pid/" component={Fallback} />
        </Switch>
      </Layout>
    </ApolloProvider>
  )
}

function Fallback() {
  return <div>404 this page does not exist</div>
}
