import React, { useEffect, useMemo, useState } from 'react'

import cls from './Textarea.module.scss'

export default function TextareaJSON<T>({
  className,
  value,
  placeholder,
  onChangeValue,
}: Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, 'value'> & {
  value: T
  onChangeValue: (s: T) => void
}) {
  const [editor, setEditor, updates] = useJSONEditor(value)
  useEffect(() => {
    try {
      updates && onChangeValue(JSON.parse(updates))
    } catch (error) {
      //
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updates])

  return (
    <span className={cls.input}>
      <span className={cls.wrapper + ' ' + className}>
        <span className={cls.div} data-value={editor || placeholder || ''} />
        <textarea
          className={cls.textarea}
          style={{ fontFamily: 'Consolas, monospace' }}
          value={editor || ''}
          placeholder={placeholder}
          onChange={evt => setEditor(evt.target.value)}
        />
      </span>
    </span>
  )
}

function useJSONEditor<T>(original: T) {
  const [updates, setUpdates] = useState<string | null>(null)

  useEffect(() => {
    if (
      updates !== null &&
      original &&
      JSON.stringify(original) === JSON.stringify(updates)
    ) {
      setUpdates(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [original])

  return useMemo(
    () =>
      [
        updates === null ? JSON.stringify(original, null, 2) : updates,
        setUpdates,
        updates,
      ] as const,
    [updates, original]
  )
}
