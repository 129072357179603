import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import Icon from '@duik/icon'
import Modal from '@duik/modal'
import addDays from 'date-fns/addDays'
import addHours from 'date-fns/addHours'
import differenceInDays from 'date-fns/differenceInDays'
import endOfDay from 'date-fns/endOfDay'
import format from 'date-fns/format'
import startOfDay from 'date-fns/startOfDay'
import startOfWeek from 'date-fns/startOfWeek'

import {
  useActivityKindOptions,
  withRepetitions,
} from '@healthblocks-io/core/activity'
import {
  AnalyticsDataProvider,
  useTrack,
} from '@healthblocks-io/core/analytics'
import { useSearch } from '@healthblocks-io/core/fhir'
import {
  Activity,
  ActivityKind,
  CarePlanActivity,
} from '@healthblocks-io/core/types'

import ApplyCarePlanDefinitionModal from '@components/ApplyCarePlanDefinitionModal'
import { useTranslation } from 'lib/i18n'
import ActivityIcon from 'pages/atoms/ActivityIcon'
import Button from 'pages/atoms/Button'

import cls from './Calendar.module.scss'

import { KindDropdownCreate } from './FormFields'
import { OccurrenceEditorModal } from './OccurrenceEditorModal'

const laneHeaderWidth = 230
const itemWidth = 45
const gutter = 5
const gridWidth = itemWidth + gutter
// const itemHeight = 40

interface CalendarContextType {
  uid: string
  activities: Activity[]
  rendered: Activity[]
  refetch: () => void
  itemCount: number
  scrollWidth: number
  start: Date
  now: Date
  end: Date
  kinds: ActivityKind[]
  todayIndex: number
}

const CalendarContext = createContext<CalendarContextType>(null)

export function Calendar({ uid }: { uid: string }) {
  const { data, refetch } = useSearch<CarePlanActivity>({
    type: 'CarePlan.Activity',
    params: { subject: uid },
  })
  const kinds = useActivityKindOptions()
  const now = new Date()
  const minStart = useMemo(
    () => startOfWeek(now),
    // eslint-disable-next-line
    []
  )

  useTrack('Calendar Opened', { subjectId: uid })

  const activities = data?.entry
  if (!activities) {
    return <div />
  }

  const firstAct = activities.find(a => a.planned_at)
  const first = firstAct ? new Date(firstAct.planned_at) : minStart

  const start =
    first.valueOf() > minStart.valueOf() ? minStart : startOfDay(first)
  const itemCount = differenceInDays(new Date(), start) + 90
  const scrollWidth = gridWidth * itemCount
  const end = endOfDay(addDays(start, itemCount))
  const todayIndex = Math.floor(differenceInDays(now, start))

  const rendered = withRepetitions(activities, end, now)
    // Clean up data
    .map(a => {
      const aend = endOfDay(smartEnd(a))
      return {
        ...a,
        status:
          a.status === 'scheduled' && aend.valueOf() < Date.now()
            ? 'not-started'
            : a.status,
        doc: {
          ...a.doc,
          title:
            a.doc.title ||
            a.questionnaire_response?.questionnaire?.title ||
            a.questionnaire?.title,
        },
        planned_at:
          !a.planned_at && a.completed_at ? a.completed_at : a.planned_at,
      }
    })

  return (
    <AnalyticsDataProvider properties={{ page: 'Calendar' }}>
      <CalendarContext.Provider
        value={{
          kinds,
          uid,
          activities,
          rendered,
          refetch,
          itemCount,
          scrollWidth,
          start,
          now,
          end,
          todayIndex,
        }}
      >
        <PureCalendar />
      </CalendarContext.Provider>
    </AnalyticsDataProvider>
  )
}

function PureCalendar() {
  const {
    uid,
    rendered,
    kinds,
    refetch,
    itemCount,
    scrollWidth,
    now,
    start,
    todayIndex,
  } = useContext(CalendarContext)
  const { t } = useTranslation()
  const [planning, setPlanning] = useState(false)
  const [editing, _setEditing] = useState<Activity | null>(null)
  const [isProtected, setProtected] = useState(false)
  const setEditing = a => {
    if (!a) refetch()
    _setEditing(a)
  }

  const months = groupBy(range(itemCount), i => {
    const d = addDays(start, i)
    return d.getFullYear() + '-' + d.getMonth()
  })

  useEffect(() => {
    const elem = document.querySelector('.' + cls.now)
    elem?.scrollIntoView({ block: 'nearest', inline: 'center' })
  }, [])

  return (
    <div className={cls.calendar}>
      <div className={cls.createButton}>
        <KindDropdownCreate
          onChange={(kind: Activity['kind']) => {
            setEditing(emptyActivity({ kind, uid }))
          }}
        />
      </div>
      <div className={cls.scrollButtons}>
        <Button
          style={{
            marginTop: -8,
            marginBottom: -8,
            marginRight: 12,
            color: 'var(--hb-main)',
          }}
          subtle
          onClick={() => setPlanning(true)}
        >
          {t('Apply template')}
        </Button>
        <div
          className={cls.scrollButton}
          onClick={() => {
            const elem = document.querySelector('.' + cls.scroll)
            elem.scrollBy({
              left: -0.75 * elem.clientWidth,
              behavior: 'smooth',
            })
          }}
        >
          <ActivityIcon icon="ChevronLeft" />
        </div>
        <div
          className={cls.scrollButton}
          onClick={() => {
            const elem = document.querySelector('.' + cls.scroll)
            elem.scrollBy({ left: 0.75 * elem.clientWidth, behavior: 'smooth' })
          }}
        >
          <ActivityIcon icon="ChevronRight" />
        </div>
      </div>
      <div className={cls.scroll}>
        <div
          className={cls.topRow}
          style={{ width: scrollWidth + laneHeaderWidth }}
        >
          <div className={cls.topRowHeader}>x</div>
          {Object.entries(months).map(([m, days]) => (
            <div key={m}>
              <div>
                <div className={cls.month}>
                  {t(monthNames[parseInt(m.slice(5))])}
                </div>
              </div>
              <div
                className={cls.dayRender}
                style={{ width: gridWidth * days.length }}
              >
                {days.map(i => (
                  <DayNumber
                    key={i}
                    i={i}
                    start={start}
                    active={i === todayIndex}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
        <div
          className={cls.lanes}
          style={{ width: scrollWidth + laneHeaderWidth }}
        >
          {kinds.map(kind => (
            <Lane
              key={kind}
              group={kind}
              items={rendered.filter(a => a.kind === kind)}
              setEditing={setEditing}
            />
          ))}
          <div className={cls.lane + ' ' + cls.laneGrow}>
            <div className={cls.laneHeader} />
            <div
              className={cls.now}
              style={{
                left:
                  (differenceInDays(now, start) + now.getHours() / 24) *
                    gridWidth +
                  230,
              }}
            />
            <div className={cls.rows} style={{ width: scrollWidth }}>
              <div className={cls.row} />
            </div>
          </div>
        </div>
      </div>

      <ApplyCarePlanDefinitionModal
        isOpen={planning}
        closeOnOuterClick
        handleClose={() => {
          setPlanning(false)
          refetch()
        }}
      />
      <Modal
        className="modal-top"
        sm
        isOpen={!!editing}
        closeOnOuterClick={!isProtected}
        handleClose={() => setEditing(null)}
      >
        {editing && (
          <OccurrenceEditorModal
            onProtect={setProtected}
            activity={editing}
            onClose={() => setEditing(null)}
          />
        )}
      </Modal>
    </div>
  )
}

function Lane({
  group,
  items,
  setEditing,
}: {
  group: string
  items: Activity[]
  setEditing: (a: Activity) => void
}) {
  const { scrollWidth, start, end, uid, now } = useContext(CalendarContext)
  const rows = spreadOut(items, start, end)
  return (
    <div className={cls.lane}>
      <LaneHeader
        group={group}
        add={() =>
          setEditing(
            emptyActivity({
              uid,
              // @ts-ignore
              kind: group,
              planned_at: addHours(startOfDay(now), 10).toJSON(),
              doc: { end_at: addHours(startOfDay(now), 11).toJSON() },
            })
          )
        }
      />
      <div
        className={cls.now}
        style={{
          left:
            (differenceInDays(now, start) + now.getHours() / 24) * gridWidth +
            230,
        }}
      />
      <div className={cls.rows} style={{ width: scrollWidth }}>
        {rows.map((row, key) => (
          <div key={key} className={cls.row}>
            {row.activities.map((a, key) => (
              <ActivityPlan
                key={key}
                a={a}
                start={start}
                setEditing={setEditing}
              />
            ))}
            {row.spots.map((spot, key) => (
              <ActivitySpot
                key={key}
                spot={spot}
                onClick={(start, end) =>
                  setEditing(
                    emptyActivity({
                      uid,
                      // @ts-ignore
                      kind: group,
                      planned_at: start.toJSON(),
                      doc: { end_at: end.toJSON() },
                    })
                  )
                }
              />
            ))}
          </div>
        ))}
      </div>
    </div>
  )
}

interface Spot {
  start: Date
  end?: Date
}
function spreadOut(items: Activity[], start: Date, end: Date) {
  const rows: { spots: any[]; activities: Activity[] }[] = []

  // Try to pack activities close together, but not overlapping
  const rowEnds: string[] = []
  for (const a of items) {
    if (!a.planned_at) continue
    let index = rowEnds.findIndex(end => end <= a.planned_at)
    if (index > -1) {
      rows[index].activities.push(a)
    } else {
      index = rows.length
      rows.push({
        spots: [],
        activities: [a],
      })
    }
    // Track last item
    rowEnds[index] = endOfDay(smartEnd(a)).toJSON()
  }

  // Add empty row so we always get spots
  if (!rows.length) {
    rows.push({
      spots: [],
      activities: [],
    })
  }

  // Fill rest with empty spots
  for (const row of rows) {
    let spot: Spot = { start }
    for (const a of row.activities) {
      spot.end = addDays(endOfDay(new Date(a.planned_at)), -1)

      if (spot.end.valueOf() > spot.start.valueOf()) row.spots.push(spot)

      const aend = addDays(startOfDay(smartEnd(a)), 1)
      spot = { start: aend }
    }
    spot.end = end
    row.spots.push(spot)
  }

  return rows
}

function smartEnd(a: Activity) {
  return new Date(
    !a.doc.end_at || a.doc.end_at < a.planned_at ? a.planned_at : a.doc.end_at
  )
}

function LaneHeader({ group, add }: { group: string; add: () => void }) {
  const { t } = useTranslation()
  return (
    <div className={cls.laneHeader}>
      <div className={cls.laneHeaderContent}>
        <div className={cls.laneIcon}>
          <ActivityIcon icon={group} size={18} />
        </div>
        <div className={cls.groupTitle}>{t(group)}</div>
        <div style={{ flexGrow: 1 }} />
        <div className={cls.laneHeaderButton}>
          <Button
            flex
            secondary
            style={{ margin: 0, padding: '0 7px', height: 28 }}
            onClick={add}
          >
            <Icon>add</Icon>
          </Button>
        </div>
      </div>
    </div>
  )
}

// function ActivityDetail({ a }: { a: Activity }) {
//   return (
//     <div className={cls.laneHeaderBox}>
//       <div className={cls.laneHeaderContent}>
//         <div className={cls.laneIcon}>
//           <ArticleIcon />
//         </div>
//         <div className={cls.groupTitle}>{a.kind} </div>
//       </div>
//       <div className={cls.title}>{a.doc.title} </div>
//     </div>
//   )
// }

function ActivityPlan({
  a,
  start,
  setEditing,
}: {
  a: Activity
  start: Date
  setEditing: (empty: Activity) => void
}) {
  const aplan = new Date(a.planned_at)
  const astart = startOfDay(aplan)
  const aend = endOfDay(smartEnd(a))
  const dstart = differenceInDays(astart, start)
  const dwidth = differenceInDays(aend, astart) + 1
  return (
    <div
      className={cls.activity + ' ' + cls['status_' + a.status]}
      style={{
        left: gridWidth * dstart + gutter / 2,
        width: gridWidth * dwidth - gutter,
      }}
      onClick={() => setEditing(a)}
    >
      <div className={cls.activityTitle}>{a.doc.title}</div>
      <div className={cls.activityDescription}>{format(aplan, 'HH:mm')}</div>
      {a.doc.reminders ? <BellIcon active={!!a.reminder_at} /> : null}
    </div>
  )
}

function ActivitySpot({
  spot,
  onClick,
}: {
  spot: Spot
  onClick: (start: Date, end: Date) => void
}) {
  const { start } = useContext(CalendarContext)
  const offset = differenceInDays(spot.start, start)
  const count = differenceInDays(spot.end, spot.start)
  const addHandler = (offset: number) => {
    const start = addDays(startOfDay(spot.start), offset)
    onClick(addHours(start, 10), addHours(start, 11))
  }
  if (count < 0) {
    return null
  }
  return (
    <>
      {range(count + 1).map(i => (
        <div
          key={i}
          className={cls.spot}
          style={{ left: gridWidth * (offset + i) + gutter / 2 }}
          onClick={() => addHandler(i)}
        />
      ))}
    </>
  )
}

function BellIcon({ active }: { active: boolean }) {
  return (
    <div className={cls.bell}>
      <svg
        style={{ display: 'block' }}
        width={7}
        height={7}
        viewBox="0 0 7 7"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.25 2.333a1.75 1.75 0 10-3.5 0c0 2.042-.875 2.625-.875 2.625h5.25s-.875-.583-.875-2.625zM4.005 6.125a.583.583 0 01-1.009 0"
          stroke={active ? '#0659FD' : '#ccc'}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  )
}

function emptyActivity({
  kind,
  planned_at,
  uid,
  doc,
}: Partial<Activity>): Activity {
  if (!uid) throw new Error('uid required')
  return {
    id: '',
    parent_id: null,
    doc: { ...doc },
    planned_at: planned_at || null,
    end_at: null,
    kind: kind || 'Task',
    status: 'scheduled',
    reminder_at: null,
    completed_at: null,
    started_at: null,
    expired_at: null,
    created_at: '',
    uid,
  }
}

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
const dayLetters = ['S', 'M', 'T', 'W', 'T', 'F', 'S']
function DayNumber({
  i,
  start,
  active,
}: {
  i: number
  start: Date
  active: boolean
}) {
  const date = addDays(start, i)
  const dd = date.getDate()
  const day = date.getDay()
  return (
    <div className={cls.dayNumber + (i % 7 === 0 ? ' ' + cls.weekLine : '')}>
      <div>
        <small className={cls.dayLetter}>{dayLetters[day]}</small>
        {dd}
      </div>
    </div>
  )
}

// const ScheduledActivitiesQuery = gql`
//   query ScheduledActivities($uid: String!) {
//     activity(
//       where: { uid: { _eq: $uid } }
//       order_by: { planned_at: asc_nulls_first }
//     ) {
//       id
//       uid
//       completed_at
//       created_at
//       reminder_at
//       started_at
//       kind
//       doc
//       planned_at
//       status

//       questionnaire_response {
//         id
//         answers {
//           id
//           answer
//           text
//           linkId
//           created_at
//           question {
//             id
//             text
//             type
//             config
//             linkId
//             index
//           }
//         }
//         questionnaire {
//           id
//           name
//           title
//         }
//       }
//     }
//   }
// `

function range(count: number) {
  return Array(count)
    .fill(1)
    .map((_, i) => i)
}

// Usage:  groupBy([6.1, 4.2, 6.3], Math.floor);
// Output: { '4': [4.2], '6': [6.1, 6.3] }
export function groupBy<T>(
  items: T[],
  func: (t: T) => string
): { [key: string]: T[] } {
  const grouped: { [key: string]: T[] } = {}
  for (const item of items) {
    const groupKey = func(item)
    if (!grouped[groupKey]) {
      grouped[groupKey] = []
    }
    grouped[groupKey].push(item)
  }
  return grouped
}
