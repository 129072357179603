import { useCallback, useMemo, useState } from 'react'
import { useFHIR } from './fhir'

import { deepMerge } from './set'
import { Activity } from './types'

export interface ActivityEditor {
  activity: Activity
  dirty: number
  remove: () => Promise<void>
  reschedule: () => Promise<{ success: boolean }>
  patchActivity: (data: Partial<Activity>) => void
  patchActivityDoc: (data: Partial<Activity['doc']>) => void
}

export function useActivityEditor(online: Activity) {
  // Local state
  const [state, setState] = useState(createEditor)

  // Mutations
  const patchActivity = useCallback(
    (data: Partial<Activity>) => setState(s => deepMerge(s, 'activity', data)),
    []
  )
  const patchActivityDoc = useCallback(
    (data: Partial<Activity['doc']>) =>
      setState(s => deepMerge(s, 'doc', data)),
    []
  )

  // Merged
  const activity = useMemo(
    () => ({
      ...online,
      ...state.activity,
      doc: { ...online.doc, ...state.doc },
    }),
    [state.activity, state.doc, online]
  )
  const repeating =
    !!activity.doc.repeatPeriod && !!activity.doc.repeatPeriodUnit
  const dirty =
    Object.keys(state.activity).length || Object.keys(state.doc).length

  // Actions
  const fhir = useFHIR()

  const create = useCallback(async () => {
    await fhir.create({ resourceType: 'CarePlan.Activity', ...activity })
  }, [activity])
  const reschedule = useCallback(
    async (strategy?: 'all' | 'single' | 'future') => {
      if (!dirty) return
      if (!online.id) throw new Error('Cannot reschedule non-existing activity')
      if (repeating && !strategy) throw new Error('Strategy required')

      return fhir.operation(
        { resourceType: 'CarePlan.Activity', id: online.id },
        'reschedule',
        {
          exdate: online.planned_at.slice(0, 10),
          planned_at: online.planned_at,
          doc: state.doc,
          activity: state.activity,
          strategy,
        }
      )
    },
    [activity]
  )
  const remove = useCallback(async () => {
    if (!online.id) throw new Error('Cannot remove activity without id')
    await fhir.remove({ resourceType: 'CarePlan.Activity', id: online.id })
  }, [online.id])

  return {
    activity,
    dirty,
    repeating,
    patchActivity,
    patchActivityDoc,
    remove,
    create,
    reschedule,
  }
}

function createEditor() {
  return {
    activity: {},
    doc: {},
    saving: 0,
    version: 0,
    ref: { latest: 0 },
  }
}
