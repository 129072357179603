import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import storage from './storage'
import { LocalData } from './types'

export type LocalContext = [LocalData, (updates: Partial<LocalData>) => void]

export const Local = createContext<LocalContext | null>(null)

export function useLocal() {
  const state = useContext(Local)
  /** @todo Consider throwing error here */
  return state
}

export function useLocalState() {
  const state = useContext(Local)
  if (!state) throw new Error('Local not yet loaded')
  return state[0]
}

/** @todo Consider renaming this hook */
export function useSetLocal() {
  const state = useContext(Local)
  if (!state) throw new Error('Local not yet loaded')
  return state[1]
}

export function LocalProvider({
  children,
  scope,
}: {
  children: ReactNode
  scope: string
}) {
  const loaded = useRef(scope)
  const [data, setData] = useState<LocalData | null>(() => initial(scope))

  const merge = useCallback(
    (updates: Partial<LocalData>) =>
      setData(a => {
        const updated = { ...a, ...updates }
        storage.setItem(scope, JSON.stringify(updated))
        return updated
      }),
    []
  )

  useEffect(() => {
    if (loaded.current !== scope) {
      loaded.current = scope
      setData(initial(scope))
    }
  }, [scope])

  return (
    <Local.Provider
      children={children}
      value={useMemo(() => data && [data, merge], [data])}
    />
  )
}

function initial(scope: string) {
  try {
    return JSON.parse(window.localStorage[scope] || '{}')
  } catch (e) {
    console.warn('LocalProvider.web initial error', e)
  }
}
