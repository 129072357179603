import { uniqBy } from '@bothrs/util/uniq'

import { gql, useApolloClient, useSubscription } from '@healthblocks-io/apollo'
import { usePid } from '@healthblocks-io/core/project'
import { Profile } from '@healthblocks-io/core/types'

// Project > Access

export function useAccess() {
  const result = useSubscription(
    gql`
      subscription Access($pid: String!) {
        projects_by_pk(pid: $pid) {
          pid
          invites {
            email
            role
          }
          users(where: { role: { _neq: "user" } }) {
            doc
            email
            name
            phone
            role
            uid
          }
        }
      }
    `,
    {
      client: useApolloClient(),
      variables: { pid: usePid() },
    }
  )
  if (result.data?.projects_by_pk) {
    const users = result.data.projects_by_pk.users.map(user => ({
      ...user.doc,
      ...user,
    }))
    const invites = result.data.projects_by_pk?.invites || []
    // @ts-ignore
    result.access = [...users, ...invites].filter(uniqBy('email'))
  } else {
    // @ts-ignore
    result.access = []
  }
  return result as typeof result & { access: Profile[] }
}

export async function invite(
  client: any,
  pid: string,
  email: string,
  role: string
) {
  email = email.toLowerCase().trim()
  await client.mutate({
    mutation: gql`
      mutation Invite($pid: String!, $email: String!, $role: String!) {
        insert_invites(objects: { pid: $pid, email: $email, role: $role }) {
          affected_rows
        }
      }
    `,
    variables: { pid, email, role },
  })
}

export async function sendInviteEmail(
  postJSON: any,
  email: string,
  role: string
) {
  postJSON('/api/invites', { email, role })
}

export async function setRole(client: any, pid: string, user, role) {
  if (!['moderator', 'owner'].includes(role)) {
    throw new Error('Invalid role: ' + role)
  }
  console.log('user', user, role)
  if (user.uid) {
    await client.mutate({
      mutation: gql`
        mutation SetRole($pid: String!, $uid: String!, $role: String!) {
          update_users(
            where: { pid: { _eq: $pid }, uid: { _eq: $uid } }
            _set: { role: $role }
          ) {
            affected_rows
          }
        }
      `,
      variables: { pid, uid: user.uid, role },
    })
  } else if (user.email) {
    await client.mutate({
      mutation: gql`
        mutation SetInviteRole($pid: String!, $email: String!, $role: String!) {
          update_invites(
            where: { pid: { _eq: $pid }, email: { _eq: $email } }
            _set: { role: $role }
          ) {
            affected_rows
          }
        }
      `,
      variables: { pid, email: user.email, role },
    })
  }
}

export async function revoke(client: any, pid: string, user) {
  if (user.uid) {
    await client.mutate({
      mutation: gql`
        mutation Revoke($pid: String!, $uid: String!) {
          update_users(
            where: { pid: { _eq: $pid }, uid: { _eq: $uid } }
            _set: { role: "user" }
          ) {
            affected_rows
          }
        }
      `,
      variables: { pid, uid: user.uid },
    })
  } else if (user.email) {
    await client.mutate({
      mutation: gql`
        mutation RevokeInvite($pid: String!, $email: String!) {
          delete_invites(
            where: { pid: { _eq: $pid }, email: { _eq: $email } }
          ) {
            affected_rows
          }
        }
      `,
      variables: { pid, email: user.email },
    })
  }
}
