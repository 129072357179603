import React from 'react'

// prettier-ignore
export default function ActivityIcon(props: IconProps) {
  switch (props.icon) {
    case 'ChevronLeft':
      return <MaterialIcon d="M14.71 6.37a.996.996 0 00-1.41 0l-4.59 4.59a.996.996 0 000 1.41l4.59 4.59a.996.996 0 101.41-1.41l-3.88-3.89 3.88-3.88c.39-.39.38-1.03 0-1.41z"/>
    case 'ChevronRight':
      return <MaterialIcon d="M9.29 6.37a.996.996 0 000 1.41l3.88 3.88-3.88 3.88a.996.996 0 101.41 1.41l4.59-4.59a.996.996 0 000-1.41L10.7 6.36c-.38-.38-1.02-.38-1.41.01z"/>

    // FHIR
    case 'paperclip':
      return <FeatherIcon {...props} d="M21.44 11.05l-9.19 9.19a6 6 0 01-8.49-8.49l9.19-9.19a4 4 0 015.66 5.66l-9.2 9.19a2 2 0 01-2.83-2.83l8.49-8.48" />
    case 'upload':
      return <FeatherIcon {...props} d="M21 15v4a2 2 0 01-2 2H5a2 2 0 01-2-2v-4M17 8l-5-5-5 5M12 3v12" />

    // Activity cards
    case 'checkmark2':
      return <FeatherIcon {...props} d="M19.77 5.7l-11 11-5-5" />
    case 'clock':
      return <FeatherIcon {...props} d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zM12 6v6l4 2" />

    // Activity kinds
    case 'Appointment':
      return <FeatherIcon {...props} d="M19 4H5a2 2 0 00-2 2v14a2 2 0 002 2h14a2 2 0 002-2V6a2 2 0 00-2-2zM16 2v4M8 2v4M3 10h18" />
      // return <MaterialIcon {...props} d="M19 4h-1V3c0-.55-.45-1-1-1s-1 .45-1 1v1H8V3c0-.55-.45-1-1-1s-1 .45-1 1v1H5c-1.11 0-1.99.9-1.99 2L3 20a2 2 0 002 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 15c0 .55-.45 1-1 1H6c-.55 0-1-.45-1-1V9h14v10zM7 11h2v2H7v-2zm4 0h2v2h-2v-2zm4 0h2v2h-2v-2z" />
    case 'Article':
      return <FeatherIcon {...props} d="M17 9.966H3M21 5.966H3M21 13.966H3M17 17.966H3" />
    case 'Message':
      return <FeatherIcon {...props} d="M20.945 11.367a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.381 8.381 0 01-3.8-.9l-5.7 1.9 1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z" />
      // return <MaterialIcon {...props} d="M20 6h-1v8c0 .55-.45 1-1 1H6v1c0 1.1.9 2 2 2h10l4 4V8c0-1.1-.9-2-2-2zm-3 5V4c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v13l4-4h9c1.1 0 2-.9 2-2z" />
    case 'Task':
      return <FeatherIcon {...props} d="M13 2L3 14h9l-1 8 10-12h-9l1-8z" />
      // return <MaterialIcon {...props} d="M10.67 21c-.35 0-.62-.31-.57-.66L11 14H7.5c-.88 0-.33-.75-.31-.78 1.26-2.23 3.15-5.53 5.65-9.93a.577.577 0 011.07.37l-.9 6.34h3.51c.4 0 .62.19.4.66-3.29 5.74-5.2 9.09-5.75 10.05-.1.18-.29.29-.5.29z" />
    case 'Questionnaire':
      return <FeatherIcon {...props} d='M14 2H6a2 2 0 00-2 2v16a2 2 0 002 2h12a2 2 0 002-2V8l-6-6z M14 2v6h6M16 13H8M16 17H8M10 9H8' />
      // return <MaterialIcon {...props} d="M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,7A5,5 0 0,0 7,12A5,5 0 0,0 12,17A5,5 0 0,0 17,12A5,5 0 0,0 12,7Z" />
  }
  return (
    <pre
      style={{
        fontSize: ((props.size || 24) * 2) / 3,
        lineHeight: '.666em',
        color: props.color,
        width: props.size,
        height: props.size,
        overflow: 'hidden',
      }}
    >
      {props.icon?.slice(0, 2) + '\n' + props.icon?.slice(2, 4) || '?'}
    </pre>
  )
}

export interface IconProps {
  icon?: string
  size?: number
  color?: string

  /** @todo Not implemented */
  viewBox?: string
}

export function MaterialIcon(p: { d: string } & IconProps) {
  return (
    <svg
      width={p.size || 24}
      height={p.size || 24}
      style={{ display: 'block' }}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d={p.d} fill={p.color || 'currentColor'} />
    </svg>
  )
}

export function FeatherIcon(p: { d: string } & IconProps) {
  return (
    <svg
      width={p.size || 24}
      height={p.size || 24}
      style={{ display: 'block' }}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d={p.d}
        stroke={p.color || 'currentColor'}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

/** Icon for inside a button */
export function ButtonFeatherIcon(
  p: { d: string; mr?: boolean; ml?: boolean } & IconProps
) {
  return (
    <svg
      width={p.size || 24}
      height={p.size || 24}
      className={'btn-feather-icon ' + (p.mr ? 'btn-feather-icon--mr' : '')}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d={p.d}
        stroke={p.color || 'currentColor'}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
