import React, { useState } from 'react'
import { mutate } from 'swr'

import { useFetch } from '@healthblocks-io/core/fetch'

import { useTranslation } from 'lib/i18n'
import Button from 'pages/atoms/Button'
import cls from './Mandatory.module.scss'
import { useProject } from '@healthblocks-io/core/project'

const TermsAndConditions = ({
  uid,
  onChange,
}: {
  uid: string
  onChange?: () => void
}) => {
  const { t } = useTranslation()
  const { patchJSON } = useFetch()
  const [acceptTerms, setAcceptTerms] = useState(false)
  const config = useProject().config

  const submit = async () => {
    const doc = { hasGivenConsent: true, hasGivenConsentOn: Date.now() }
    mutate(
      '/api/users/' + uid,
      user => ({ ...user, doc: { ...user.doc, ...doc } }),
      false
    )
    await patchJSON('/api/users/' + uid, { doc })
    onChange?.()
  }

  return (
    <section className={cls['mandatory-page']}>
      <div className={cls['mandatory-wrap']}>
        <img
          className={cls.roundedAvatar}
          src="/images/healthblocks-logo-white.png"
          alt="logo"
        />

        <h2>{t('Consent')}</h2>
        <h4>{t('General terms and conditions')}</h4>

        {t('Terms and conditions p')
          .split(/\n+/)
          .map((p, key) => (
            <p key={key} style={key ? null : { fontWeight: 'bold' }}>
              {p}
            </p>
          ))}

        <p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={config.dashboardPrivacyURL || config.privacyURL}
          >
            {t('Learn more about our privacy policy')}
          </a>
        </p>
        <form
          onSubmit={e => {
            e.preventDefault()
            submit()
          }}
        >
          <label htmlFor="acceptTerms">
            <input
              type="checkbox"
              checked={acceptTerms}
              name="acceptTerms"
              id="acceptTerms"
              onChange={() => setAcceptTerms(!acceptTerms)}
            />
            <span>&nbsp;</span> {t(`I understand the privacy policy`)}
          </label>
          <Button disabled={!acceptTerms} className="mt-4">
            {t(`Let's go`)}
          </Button>
        </form>
      </div>
    </section>
  )
}

export default TermsAndConditions
