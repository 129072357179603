import React, { useRef, useEffect } from 'react'
import moment from 'moment'
import cls from './MessageList.module.scss'

import { usePid } from '@healthblocks-io/core/project'
import { useSignedImage } from '@healthblocks-io/core/upload'

import MessageBubble from '@components/MessageBubble'
import MessageContainer from '@components/MessageContainer'
import MessageImage from '@components/MessageImage'
import MessageImageContainer from '@components/MessageImageContainer'
import MessageSender from '@components/MessageSender'
import hbAvatar from '../../assets/LogoHb-white.png'
import { Avatar } from '@duik/avatar'
import { ContainerVertical } from '@duik/container-vertical'
import ImageModal from './ImageModal'
import Summary from './Summary'
import inlineAvatar from 'lib/avatar'

const MessageList = ({
  user,
  send,
  savePictureToCloud,
  thread,
  activeMessage,
}) => {
  const pid = usePid()
  const bottom = useRef(null)
  const activeMessageRef = useRef(null)
  const messages = thread?.messages || []
  const previousLength = usePrevious(messages.length)
  const firstMid = messages[0]?.mid

  // Initial scroll is immediate
  useEffect(() => {
    const elem = activeMessageRef.current || bottom.current
    if (firstMid && elem) elem.scrollIntoView()
  }, [firstMid])

  // Smoothly scroll to incoming message
  useEffect(() => {
    if (previousLength < messages.length) {
      setTimeout(() => {
        if (bottom.current) {
          bottom.current.scrollIntoView({
            behavior: 'smooth',
          })
        }
      }, 10)
    }
  }, [previousLength, messages.length])

  return (
    <div className={cls.wrap}>
      <ContainerVertical>
        {!messages.length ? (
          <div className={cls['conversation-empty']}>
            <h2>Select conversation to display</h2>
          </div>
        ) : (
          <div>
            {(pid === 'biosil' ? formatMessages(messages) : messages).map(
              (message, key) => (
                <Message
                  ref={activeMessage === message.mid ? activeMessageRef : null}
                  savePictureToCloud={savePictureToCloud}
                  send={send}
                  key={key}
                  thread={thread}
                  message={message}
                  user={user}
                  messages={messages}
                />
              )
            )}
            <div className={cls['bottom-message']} ref={bottom} />
          </div>
        )}
      </ContainerVertical>
    </div>
  )
}

export default MessageList

const Message = React.forwardRef<unknown, any>(
  ({ message, user, send, savePictureToCloud, thread, messages }, ref) => {
    const notByMe = message.qid || message.doc.hitl
    const sign = useSignedImage()
    // console.log(messages);
    return (
      // @ts-ignore
      <MessageContainer ref={ref} position={notByMe ? 'left' : 'right'}>
        <MessageSender
          time={message.sent_at && moment(new Date(message.sent_at)).calendar()}
        >
          {/* if a message is a summary, we dont need a avatar */}
          {!message.summary && (
            <Avatar
              style={{ objectPosition: 'cover' }}
              className={message.qid ? 'bot-avatar' : ''}
              imgUrl={
                message.qid
                  ? hbAvatar
                  : inlineAvatar(message.user?.uid || user?.uid)
              }
              name={
                <strong>
                  {message.qid
                    ? 'Healthblocks'
                    : message.user?.name ||
                      message.user?.doc.name ||
                      user?.name ||
                      user?.doc.name ||
                      message.user?.uid.replace('anonymous:', '') ||
                      user?.uid.replace('anonymous:', '') ||
                      'user'}
                </strong>
              }
              sm
            />
          )}
        </MessageSender>
        {message.summary ? (
          <Summary
            scoresData={message.scoresData}
            category={message.category}
            user={user}
          />
        ) : (
          <MessageBubble position={notByMe ? 'left' : 'right'}>
            {message.body || '!! empty message'}
          </MessageBubble>
        )}
        {message.doc && message.doc.images && message.doc.images.length ? (
          <MessageImageContainer>
            {message.doc.images
              .filter(img => img || console.warn('MessageImageContainer', img))
              .map((img, index) => (
                <ImageModal
                  key={index}
                  allImages={message.doc.images}
                  overlays={message.doc.overlays}
                  sentAt={message.sent_at}
                  bodypart={message.doc.bodypart}
                  overlay={message.doc.overlays?.[index]}
                  currentImage={img}
                  send={send}
                  messages={messages}
                >
                  <MessageImage imgUrl={sign(img, 200).url} />
                </ImageModal>
              ))}
          </MessageImageContainer>
        ) : null}
      </MessageContainer>
    )
  }
)

const formatMessages = messages => {
  let combining = false
  let scoresData = []
  let category = ''
  return messages.reduce((acc, mess, i, src) => {
    // set combining to true if we start rating
    if (mess.doc.replyType === 'Rating') {
      combining = true
      // on Rating questions we have the conditionId why this rating is asked, this will be the category
      category = mess.doc.flowIds
    }

    // if replytype is defined (so no answer from the user), and its not rating we should
    // check if we should send a summary
    if (
      (mess.doc.replyType !== 'Rating' && mess.doc.replyType) ||
      mess.doc.hitl
    ) {
      let send = false

      // check if we where busy combining questions into a summary, or if its the last question
      if (combining || i + 1 === src.length) {
        send = true
      }

      combining = false

      if (send) {
        // reset the data for next summary
        const dataCopy = scoresData
        scoresData = []
        return acc.concat(
          dataCopy.length
            ? {
                mid: 'summary:' + mess.mid,
                doc: {},
                user: { uid: 'user' },
                sender: 'user',
                summary: true,
                category,
                scoresData: dataCopy,
              }
            : [],
          mess
        )
      }
    }

    // if it's the last question we should also check if we are combining so we can send the summary
    if (i + 1 === src.length) {
      let send = false

      // check if we where busy combining questions into a summary, or if its the last question
      if (combining) {
        send = true
      }

      combining = false

      if (send) {
        // reset the data for next summary
        const dataCopy = scoresData
        scoresData = []
        return [
          ...acc,
          {
            mid: 'summary:' + mess.mid,
            doc: {},
            user: { uid: 'user' },
            sender: 'user',

            summary: true,
            category,
            scoresData: dataCopy,
          },
        ]
      } else {
        if (!mess.body.startsWith('Stop flow ')) {
          return [...acc, mess]
        }
        return [...acc]
      }
    }

    // build up the message
    if (combining) {
      if (mess.doc.replyType) {
        scoresData.push({ question: mess.doc.shortQuestion || mess.doc.id })
      } else {
        scoresData[scoresData.length - 1].score = mess.body.split('/')[0]
      }
      return [...acc]
    } else {
      if (!mess.body.startsWith('Stop flow ')) {
        return [...acc, mess]
      }
      return [...acc]
    }
  }, [])
}

// Helpers

function usePrevious<T>(value: T) {
  const ref = useRef(value)

  // Store current value in ref
  useEffect(() => {
    ref.current = value
  }, [value]) // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current
}
