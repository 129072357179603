export const logUserOut = () => {
  localStorage.clear()
  document.location.reload()
}

interface UserLike {
  name?: string
  doc: { name?: string; email?: string; phone?: string }
  email?: string
  phone?: string
  uid?: string
  sub?: string
}

export function userName(user: UserLike, fallback = '?') {
  return !user
    ? fallback
    : user.name ||
        user.doc.name ||
        user.email ||
        user.doc.email ||
        user.phone ||
        user.doc.phone ||
        user.uid ||
        user.sub ||
        fallback
}
