import React, { FC, ReactNode, useEffect, useState } from 'react'
import Modal from '@duik/modal'

import { useProfile } from '@healthblocks-io/apollo'
import { CareTeamContext, useCareTeam } from '@healthblocks-io/core/careteam'
import { FHIRProvider, useFHIR } from '@healthblocks-io/core/fhir'
import { useSubject } from '@healthblocks-io/core/subject'

import { emptyParticipant, Participant } from 'lib/careteam'
import { useTranslation } from 'lib/i18n'
import Button from 'pages/atoms/Button'
import EmptyState from 'pages/atoms/EmptyState'

import CareTeamParticipantEditor from './CareTeamParticipantEditor'

export function CareTeamGate({
  children,
  Fallback: _ignore,
}: {
  children: ReactNode
  Fallback?: FC<{ care: CareTeamContext }>
}) {
  const care = useCareTeam()
  if (!care.team && care.loading) {
    return <div style={{ height: 100 }} />
  }
  if (!care.can({ action: 'read', resource: 'fhir:Patient' })) {
    return <Fallback care={care} />
  }
  // This person has no care team at all, but we have access, so let's create it
  if (!care.team && !care.loading) {
    return <CareTeamAutoCreator />
  }
  return (
    <FHIRProvider ctx={{ careteam_id: care.team.id }}>{children}</FHIRProvider>
  )
}

function CareTeamAutoCreator() {
  const subject = useSubject()
  const { create } = useFHIR()
  useEffect(() => {
    // Dedup workaround
    // @ts-ignore
    if (!window.dedup_careteam_auto_create) {
      // @ts-ignore
      window.dedup_careteam_auto_create = {}
    }
    // @ts-ignore
    if (window.dedup_careteam_auto_create?.[subject.uid]) {
      return
    }
    // @ts-ignore
    window.dedup_careteam_auto_create[subject.uid] = true
    // Let's create care team
    console.log('auto create care team')
    create({
      resourceType: 'CareTeam',
      subject_id: subject.uid,
      subject: subject.reference,
      participant: [],
    })
  }, [create, subject])
  return <div style={{ height: 100 }} />
}

function Fallback({ care }: { care: CareTeamContext }) {
  const subject = useSubject()
  const { t } = useTranslation()
  const { create, patch } = useFHIR()
  const [editing, setEditing] = useState<Participant | null>(null)
  const profile = useProfile()

  if (!care.team && care.loading) {
    return null
  }
  return (
    <EmptyState>
      <h1>{t('Join this care team to continue')}</h1>
      <p>{t('Care team p')}</p>
      <Button
        onClick={async () => {
          setEditing(
            emptyParticipant({
              uid: profile.uid,
              user: { name: profile.name, email: profile.email },
              role_title:
                // @ts-ignore
                profile.role_title ||
                (profile.role === 'user' ? '' : 'Administrator'),
            })
          )
          // await postJSON(
          //   app().api + '/api/fhir/CareTeam',
          //   { subject_id: subject },
          //   { auth: await token() }
          // )
          // refetch()
        }}
        inline
      >
        {t('Join')}
      </Button>
      <Modal
        className="modal-top"
        isOpen={!!editing}
        handleClose={() => setEditing(null)}
        closeOnOuterClick
        sm
      >
        <CareTeamParticipantEditor
          participant={editing}
          save={async p => {
            console.log('save', p, 'care', care)
            if (!care.team) {
              await create({
                resourceType: 'CareTeam',
                // @ts-ignore This is already solved in backend actually
                subject_id: subject.uid,
                subject: subject.reference,
                participant: [p],
              })
            } else if (!care.me) {
              await patch({ resourceType: 'CareTeam', id: care.team.id }, [
                {
                  op: 'add',
                  path: '/participant',
                  value: {
                    careteam_id: care.team.id,
                    uid: p.user?.uid || p.uid,
                    ...p,
                  },
                },
              ])
            }
            // if (!careteam) {
            //   // await createCareTeam(subject)
            //   await create({ resourceType: 'CareTeam', subject })
            //   const teams = await readCareTeam(subject)
            //   careteam = teams[0]
            // }
            // await saveParticipant(careteam.id, p)
            care.refetch()
            setEditing(null)
          }}
          remove={console.log}
        />
      </Modal>
    </EmptyState>
  )
}
