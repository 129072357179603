import React, { memo, useState } from 'react'

import { useUser } from '@healthblocks-io/core/auth'

import Button from '../atoms/Button'
import Input from '../atoms/Input'

import { Policy, usePolicyEditor } from 'lib/policy'
import Textarea from 'pages/atoms/Textarea'
import Label from 'pages/atoms/Label'

const PolicyEditor = memo(PolicyEditorComponent)

export default PolicyEditor

export function PolicyEditorComponent({
  policy,
  save,
  remove,
}: {
  policy: Policy
  save: (p: Policy) => void
  remove: () => void
}) {
  const [{ data: p }, dispatch] = usePolicyEditor(policy)
  const user = useUser()
  if (!p) {
    return <div>no particip</div>
  }

  return (
    <div style={{ padding: 25 }}>
      <h3 style={{ fontWeight: 'bold', margin: '24px 0' }}>
        {p.id ? 'Edit a' : 'Add a'} policy
      </h3>

      {user.email?.includes('mas@hea') && <p className="">{p.id}</p>}

      <General p={p} dispatch={dispatch} />

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: p.id ? 'space-between' : 'flex-end',
          marginTop: 24,
        }}
      >
        {p.id ? (
          <Button className="mb-0" inline error onClick={remove}>
            Remove policy
          </Button>
        ) : null}
        <Button className="mb-0" inline onClick={() => save(p)}>
          {p.id ? 'Save policy' : 'Add policy'}
        </Button>
      </div>
    </div>
  )
}

function General({ p, dispatch }: { p: Policy; dispatch: (p: any) => void }) {
  const [text, setText] = useState(JSON.stringify(p.statement, null, 2) || '')
  return (
    <div>
      <Input
        label="Title"
        value={p.title || ''}
        onChangeText={title => dispatch({ title })}
      />
      <Label>Statement</Label>
      <Textarea
        value={text}
        onChangeText={statement => {
          setText(statement)
          try {
            dispatch({ statement: JSON.parse(statement) })
          } catch (e) {}
        }}
      />
    </div>
  )
}
