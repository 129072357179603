import React from 'react'
import useSWR from 'swr'

import { useCareTeam } from '@healthblocks-io/core/careteam'
import { useFetch } from '@healthblocks-io/core/fetch'
import { useConfig } from '@healthblocks-io/core/project'
import { Profile } from '@healthblocks-io/core/types'
import { useUserEditor } from '@healthblocks-io/core/user'

import ProfileComponents from '@components/ProfileComponents'
import Input from 'pages/atoms/Input'

import useBlocks from '../../lib/useBlocks'
import { CardGrid } from '../atoms/Card'
import Medication from './Medication'
import PushToken from './PushToken'
import Reminder from './Reminder'

// import RiskAssessment from '../../assets/riskassessment.profile.json'

interface ProfileProps {
  uid: string
}
interface Preset {
  role_title: string
  policies: string[]
}

export default function ProfilePage({ uid }: ProfileProps) {
  const care = useCareTeam()
  const { user, patch, refetch, error } = useUserEditor({ uid })

  let sections = useConfig(p => p.config.userDetailInfo)
  if (!user && !error) {
    return <div>Loading...</div>
  }
  if (!user) {
    return <div>User not found</div>
  }
  if (!user.doc) {
    return <div>wut{JSON.stringify(user)}</div>
  }
  if (!sections) {
    return (
      <div className="scroll-area-tab">
        <Profile1 uid={uid} update={patch} />
      </div>
    )
  }

  sections = sections.filter(s => {
    const resource = s.resource
    if (resource) return care.can({ action: 'read', resource })
    const type = (s.props as any).type
    if (type)
      return care.can({
        action: 'read',
        resource: 'fhir:' + type.replace('fhir:', ''),
      })
    return true
  })

  return (
    <ProfileComponents
      {...{
        user,
        update: patch,
        refetch,
        sections,
        pageName: 'Profile Information',
      }}
    />
  )
}

function Profile1({ uid, update }: { uid: string; update }) {
  const blocks = useBlocks()
  const presets =
    useConfig<Preset[]>(p => p.config.careteamParticipantPresets) || []
  const { getJSON } = useFetch()
  const { data: user } = useSWR<Profile>('/api/users/' + uid, getJSON)
  return (
    <div className="row">
      <div className="col-md-6">
        <h2>Personal information</h2>
        <div style={{ maxWidth: '20em' }}>
          <Input
            label="Name"
            defaultValue={user.name || ''}
            onChangeText={v => update('user.name', v)}
          />
        </div>
        <div style={{ maxWidth: '20em' }}>
          <Input
            label="Email"
            defaultValue={user.email || ''}
            onChangeText={v => update('user.email', v)}
          />
        </div>
        <div style={{ maxWidth: '20em' }}>
          <Input
            label="Phone"
            defaultValue={user.phone || ''}
            onChangeText={v => update('user.phone', v)}
          />
        </div>
        <div style={{ maxWidth: '20em' }}>
          <Input
            label="Gender"
            defaultValue={user.gender || ''}
            onChangeText={v => update('user.gender', v)}
          />
        </div>
        <div style={{ maxWidth: '20em' }}>
          <Input
            label="Birthdate"
            defaultValue={user.birthDate || ''}
            onChangeText={v => update('user.birthDate', v)}
          />
        </div>
      </div>
      <div className="col-md-6">
        <h2>Practical data</h2>
        <div style={{ maxWidth: '20em' }}>
          <Input
            label="Timezone"
            defaultValue={user.timezone || ''}
            onChangeText={v => update('user.timezone', v)}
          />
        </div>
        {user.role && user.role !== 'user' && <p>Role: {user.role}</p>}
        <div style={{ maxWidth: '20em' }}>
          <Input
            label="Group"
            defaultValue={user.group || ''}
            onChangeText={v => update('user.group', v)}
          />
        </div>
        <div style={{ maxWidth: '20em' }}>
          <Input
            label="Care team role"
            defaultValue={user.role_title || ''}
            onChangeText={v => update('user.role_title', v)}
            list="participant_presets"
          />
          <datalist id="participant_presets">
            {presets.map((p, key) => (
              <option key={key} value={p.role_title} />
            ))}
          </datalist>
        </div>

        {blocks.includes('medications1') && (
          <>
            <h2>Medication</h2>
            {user.doc.medications?.length ? (
              <CardGrid>
                {user.doc.medications.map((med, key) => (
                  <Medication key={key} medication={med} user={user} />
                ))}
              </CardGrid>
            ) : (
              <span>No medication</span>
            )}
          </>
        )}

        {blocks.includes('reminders1') && (
          <>
            <h2>Daily reminders</h2>
            {user.doc.reminders?.length ? (
              <CardGrid>
                {user.doc.reminders.map((reminder, key) => (
                  <Reminder key={key} reminder={reminder} />
                ))}
              </CardGrid>
            ) : (
              <span>No daily reminders</span>
            )}
          </>
        )}

        <h2>Push tokens</h2>
        {user.doc.pushTokens?.length ? (
          <CardGrid>
            {user.doc.pushTokens.map((token, key) => (
              <PushToken key={key} token={token} />
            ))}{' '}
          </CardGrid>
        ) : (
          <span>
            No push tokens registered, {user.name || 'this user'} will not
            receive notifications.
          </span>
        )}
      </div>
    </div>
  )
}
