import React, { useState, useMemo, useCallback } from 'react'
import startOfDay from 'date-fns/startOfDay'
import startOfMonth from 'date-fns/startOfMonth'
import addMonths from 'date-fns/addMonths'

import { renderDays } from '@healthblocks-io/core/activity'
import { Activity } from '@healthblocks-io/core/types'

import IndexList from './IndexList'
import { PatientCalendarItem } from './PatientCalendarItem'
import { PatientCalendarContext } from './PatientCalendarContext'

export default function PatientCalendar({
  activities,
  refetch,
}: {
  activities: Partial<Activity>[]
  refetch: () => void
}) {
  // Third state would be [pivot, setPivot] = useState(() => new Date())
  const [start, setStart] = useState(() => startOfMonth(new Date()))
  const [end, setEnd] = useState(() => addMonths(start, 2))

  // renderDays is probably not perfect, but close enough
  const data = useMemo(() => {
    const now = Date.now()
    const days = renderDays(activities as Activity[], start, end)
    const pivot = days.findIndex(
      d => d.date.valueOf() >= startOfDay(now).valueOf()
    )
    return days.map((data, index) => ({
      data,
      index: index - pivot,
    }))
  }, [activities, start, end])

  // Actions
  const onStartReached = useCallback(() => setStart(d => addMonths(d, -1)), [])
  const onEndReached = useCallback(() => setEnd(d => addMonths(d, 1)), [])

  // Context for actions on the cards
  const value = useMemo(() => ({ refetch }), [refetch])

  return (
    <PatientCalendarContext.Provider value={value}>
      <IndexList
        data={data}
        renderItem={PatientCalendarItem}
        onStartReached={onStartReached} // required, should return a promise
        onEndReached={onEndReached} // required, should return a promise
        // onStartReachedThreshold={300} // optional
        // onEndReachedThreshold={300} // optional
      />
    </PatientCalendarContext.Provider>
  )
}
