import React, { memo, useState } from 'react'
import Checkbox from '@duik/checkbox'
import { Select } from '@duik/select'

import { ApolloClientType, gql, useApolloClient } from '@healthblocks-io/apollo'
import { useConfig } from '@healthblocks-io/core/project'

import Button from '../atoms/Button'
import Input from '../atoms/Input'
import Label from '../atoms/Label'

import { Participant, Preset, useParticipantEditor } from 'lib/careteam'
import { Policy, usePolicies } from 'lib/policy'
import { useSafeAsync } from 'lib/useAsync'
import { DropdownInput } from './FormFields'
import { useTranslation } from 'lib/i18n'

const CareTeamParticipantEditor = memo(CareTeamParticipantEditorComponent)

export default CareTeamParticipantEditor

export function CareTeamParticipantEditorComponent({
  participant,
  save,
  remove,
}: {
  participant: Participant
  save: (p: Participant) => void
  remove: () => void
}) {
  const { t } = useTranslation()
  const [{ data: p }, dispatch] = useParticipantEditor(participant)
  if (!p) {
    return <div>no particip</div>
  }

  return (
    <div style={{ padding: 25 }}>
      <h3 style={{ fontWeight: 'bold', margin: '24px 0' }}>
        {t(p.id ? 'Edit participant' : 'Add participant')}
      </h3>

      <General p={p} dispatch={dispatch} />

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: p.id ? 'space-between' : 'flex-end',
          marginTop: p.id ? 24 : 0,
        }}
      >
        {p.id ? (
          <Button className="mb-0" inline error onClick={remove}>
            {t('Remove participant')}
          </Button>
        ) : null}
        <Button className="mb-0" inline onClick={() => save(p)}>
          {t(p.id ? 'Save participant' : 'Add participant')}
        </Button>
      </div>
    </div>
  )
}

function General({
  p,
  dispatch,
}: {
  p: Participant
  dispatch: (p: any) => void
}) {
  const { t } = useTranslation()
  const { data } = usePolicies()
  const policy_ids = p.policies.map(p => p.policy.id)
  const setPolicies = policy_ids => {
    dispatch({ policies: policy_ids?.map(id => ({ policy: { id } })) || [] })
  }

  const presets = useConfig<Preset[]>(
    p => p.config.careteamParticipantPresets || []
  )

  const otherPolicies =
    data?.policy
      .slice()
      .sort(
        (a, b) =>
          collect(collect(a.statement)[0].Action)[0].localeCompare(
            collect(collect(b.statement)[0].Action)[0]
          ) ||
          collect(collect(a.statement)[0].Resource)[0].localeCompare(
            collect(collect(b.statement)[0].Resource)[0]
          )
      ) || []

  return (
    <div>
      {p.user_inline ? (
        <div className="suggestions mb-3">
          <p style={{ float: 'right', fontSize: 12 }}>
            <button
              className="btn--unstyled label__link"
              onClick={() => dispatch({ user_inline: null })}
            >
              {t('Link to existing member')}
            </button>
          </p>
          <Label>
            {t(p.user_inline ? 'Inline member' : 'Existing member')}
          </Label>
          <Input
            className="mb-0"
            value={p.user_inline.name}
            onChangeText={name =>
              dispatch({ user_inline: { ...p.user_inline, name } })
            }
          />
        </div>
      ) : (
        <div className="suggestions mb-3">
          <p style={{ float: 'right', fontSize: 12 }}>
            <button
              className="btn--unstyled label__link"
              onClick={() =>
                dispatch({ user: null, user_inline: { name: '' } })
              }
            >
              {t('Link inline user')}
            </button>
          </p>
          <Label>
            {t(p.user_inline ? 'Inline member' : 'Existing member')}
          </Label>
          <UserSelect
            value={p.user}
            onChange={({ user, user_inline }) => {
              if (user) {
                const preset =
                  presets.find(p => p.preset_id === user.role_title) ||
                  presets.find(p => p.role_title === user.role_title)
                if (preset) {
                  dispatch({
                    user,
                    role_title: preset.role_title,
                    preset_id: preset.preset_id || preset.role_title,
                  })
                  setPolicies(preset.policies)
                } else {
                  dispatch({ user })
                }
              } else if (user_inline) {
                dispatch({ user_inline })
              }
            }}
          />
        </div>
      )}
      <div className="row">
        <div className="col">
          <datalist id="participant_presets">
            {presets.map((p, key) => (
              <option key={key} value={p.role_title} />
            ))}
          </datalist>
          <Input
            label={t('Role')}
            value={p.role_title}
            onChangeText={role_title => dispatch({ role_title })}
            list="participant_presets"
          />
        </div>
        {!p.user_inline && (
          <div className="col">
            <Label>{t('Permissions')}</Label>
            <Select
              ButtonComponent={DropdownInput}
              activeOption={{
                label: t(p.preset_id || 'Select_placeholder'),
                value: p.preset_id,
              }}
              options={presets.map(preset => ({
                value: preset.preset_id || preset.role_title,
                label: t(preset.preset_id || preset.role_title),
              }))}
              onOptionClick={({ value: preset_id }) => {
                dispatch({ preset_id })
                setPolicies(
                  presets.find(p => p.role_title === preset_id)?.policies ||
                    presets.find(p => p.preset_id === preset_id)?.policies
                )
              }}
              placeholder={t('Role')}
            />
          </div>
        )}
      </div>
      {!p.user_inline && (
        <>
          <p className="mt-0 mb-2">{t('Permissions p')}</p>

          <hr style={{ borderTopWidth: 2 }} />

          <Label>{t('Policies')}</Label>
          <div
            style={{
              marginBottom: 24,
              padding: 16,
              paddingTop: 24,
              border: '1px solid #E9E9EC',
              borderRadius: 8,
              columnWidth: 200,
            }}
          >
            {otherPolicies.map((policy, key) => (
              <PolicyCheckbox
                key={key}
                preset_id={p.preset_id}
                policy_ids={policy_ids}
                policy={policy}
                dispatch={dispatch}
              />
            ))}
          </div>
        </>
      )}
    </div>
  )
}

function PolicyCheckbox({
  preset_id,
  policy,
  policy_ids,
  dispatch,
}: {
  preset_id: string
  policy: Policy
  policy_ids: string[]
  dispatch: (a: any) => void
}) {
  const { t } = useTranslation()
  const setPolicies = policy_ids => {
    dispatch({ policies: policy_ids?.map(id => ({ policy: { id } })) || [] })
  }
  return (
    <div style={{ columnCount: 2, columnWidth: 200 }}>
      <Checkbox
        label={t(policy.title)}
        value={policy.id}
        checked={policy_ids.includes(policy.id)}
        onChange={evt => {
          const { value } = evt.target
          setPolicies(
            policy_ids.includes(value)
              ? policy_ids.filter(r => r !== value)
              : policy_ids.concat(value)
          )

          // TODO: Reconsider this
          if (preset_id !== 'Custom') {
            dispatch({ preset_id: 'Custom' })
          }
        }}
      />
    </div>
  )
}
function UserSelect({
  value,
  onChange,
}: {
  value: { name: string }
  onChange: (u: { user?: User; user_inline?: { name: string } }) => void
}) {
  const { t } = useTranslation()
  const client = useApolloClient()
  const [name, setName] = useState('')
  const [focus, setFocus] = useState(false)

  const users = useSafeAsync(() => searchUsers(client, { name }), [name]) || []
  return (
    <Select
      ButtonComponent={DropdownInput}
      activeOption={{
        value: '',
        label: value
          ? // @ts-ignore
            value.name || value.email || '?'
          : t('Select a user_placeholder'),
      }}
      // @ts-ignore
      onOptionClick={({ user }) =>
        onChange(user ? { user } : { user_inline: { name } })
      }
      options={users
        .map(
          u =>
            ({
              value: u.uid,
              label: u.name + (u.role_title ? ` (${u.role_title})` : ''),
              user: u,
            } as any)
        )
        .concat({
          value: null,
          label: (
            <b style={{ color: 'var(--hb-main)' }}>
              {t(name ? 'Add inline user named "[name]"' : 'Add inline user', {
                name,
              })}
            </b>
          ),
        })}
      // "searchable" introduces a rendering glitch, below is a workaround
      // that only enables it after the input was focused
      searchable={focus}
      onFocus={({ target }) => {
        setFocus(true)
        if (target.classList.contains('dropdown-item')) {
          return
        }
        setTimeout(
          () =>
            (
              target.parentNode.querySelector(
                '.select-search-input'
              ) as HTMLElement
            )?.focus(),
          200
        )
      }}
      inputSearchProps={{
        value: name,
        onChange: e => setName(e.target.value),
      }}
    />
    // <div className="suggestions__list">
    //   <table className="suggestions__table">
    //     <tbody>
    //       {users?.slice(0, 10).map(u => (
    //         <tr key={u.uid} onClick={() => onChange(u)}>
    //           <td style={{ padding: 0, paddingLeft: '.5em' }}>
    //             <div
    //               style={{
    //                 width: 24,
    //                 height: 24,
    //                 backgroundColor: '#ddd',
    //                 borderRadius: 24,
    //               }}
    //             ></div>
    //           </td>
    //           <td>
    //             <div>
    //               <b>{u.name || '?'}</b>
    //             </div>
    //           </td>
    //           <td>{u.email || u.phone}</td>
    //           <td>{u.role_title}</td>
    //         </tr>
    //       ))}
    //     </tbody>
    //   </table>
    // </div>
  )
}
interface User {
  email: string
  name: string
  phone: string
  role_title: string
  uid: string
}

async function searchUsers(
  client: ApolloClientType,
  { name }: { name: string }
) {
  if (!name) {
    return null
  }

  const result = await client.query<{ users: User[] }>({
    query: gql`
      query UserByName($name: String!) {
        users(where: { name: { _ilike: $name } }, limit: 1000) {
          email
          name
          phone
          role_title
          uid
        }
      }
    `,
    variables: {
      name: name ? '%' + name + '%' : '%',
    },
    fetchPolicy: 'cache-first',
  })
  if (!result?.data?.users) {
    return null
  }

  return result.data.users
}

function collect<T>(obj: T | T[]) {
  return Array.isArray(obj) ? obj : [obj]
}
