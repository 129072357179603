import { useConfig } from '@healthblocks-io/core/project'
import { PublicProjectConfig } from '@healthblocks-io/core/types'

const defaultFilters = [
  {
    type: 'search',
    field: 'q',
    placeholder: 'Search...',
  },
  {
    type: 'select',
    field: 'role_title',
    options: [
      { label: 'All users' },
      { label: 'Patients', value: 'patient' },
      { label: 'Care givers', value: 'hcp' },
    ],
  },
] as PublicProjectConfig['userFilters']

export default function useUserFilters() {
  return useConfig(p => p.config.userFilters || defaultFilters)
}
