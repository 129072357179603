import { createContext, useContext, useEffect } from 'react'
import debug from './log'

const { log } = debug('hook')

export const GlobalHook = {
  befores: {},
  callbacks: {},
  emit(event: string, data: any) {
    if (this.befores[event]) {
      data = (this.befores[event] as ((data: any) => any)[]).reduce(
        (data, cb) => cb(data),
        data
      )
    }
    this.callbacks[event]?.forEach(cb => cb(data))
    this.callbacks['*']?.forEach(cb => cb(event, data))
  },
  before(event: string, cb: (data: any) => any, deps: any[]) {
    // eslint-disable-next-line
    return useEffect(() => {
      if (this.befores[event]) {
        this.befores[event].push(cb)
      } else {
        this.befores[event] = [cb]
      }
      return () => {
        const before = this.befores[event].length
        this.befores[event] = this.befores[event].filter(i => i !== cb)
        if (this.befores[event].length !== before - 1) {
          console.warn('Possible hook bug detected', event, before)
        }
      }
    }, deps)
  },
  on(event: string, cb: Function, deps: any[]) {
    // eslint-disable-next-line
    return useEffect(() => {
      if (this.callbacks[event]) {
        this.callbacks[event].push(cb)
      } else {
        this.callbacks[event] = [cb]
      }
      return () => {
        const before = this.callbacks[event].length
        this.callbacks[event] = this.callbacks[event].filter(i => i !== cb)
        if (this.callbacks[event].length !== before - 1) {
          console.warn('Possible hook bug detected', event, before)
        }
      }
    }, deps)
  },
}

export const Hook = createContext(GlobalHook)

export function useHook() {
  return useContext(Hook)
}

export function useHookLogger() {
  const hook = useHook()
  hook.on(
    '*',
    (evt, data) => {
      if (evt === 'track') {
        log(
          'track',
          data.event,
          JSON.stringify(data.properties || '').slice(0, 100)
        )
      } else {
        log(evt, data)
      }
    },
    []
  )
}
