import { Reducer, useReducer } from 'react'
import _set from 'lodash/fp/set'

export type EditorReducer<T> = Reducer<EditorState<T>, any>

export function useEditor<T>(policy: T) {
  return useReducer(editorReducer, policy, initialPatch)
}

export function useResourceEditor<T extends object>(data: T) {
  return useReducer<EditorReducer<T>>(editorReducer, {
    saving: false,
    data,
  })
}

export function editorReducer<T extends object>(prev: EditorState<T>, action) {
  if (action.type === 'discard') {
    return initialPatch(null)
  }
  if (action.type === 'save') {
    return { ...prev, saving: true }
  }
  if (action.type === 'unsave') {
    return { ...prev, saving: false }
  }

  let { data } = prev
  for (const key in action) {
    if (key !== 'id' && key !== 'type') {
      data = _set(key, action[key], data)
    }
  }

  return { ...prev, data }
}

export interface EditorState<T> {
  saving: boolean
  data: T | null
}

export function initialPatch<T>(policy: T | null): EditorState<T> {
  return {
    saving: false,
    data: policy,
  }
}
